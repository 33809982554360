import React from 'react';
import { Container } from '@mui/system';
import Button from 'common/components/mui/Button';
import Link from 'common/components/Link/Link';

import A from 'common/assets';

import { useResponsive } from 'student/hooks';
import { STUDENT_SITE_PATH_NAME, TEACHER_SITE_PATH_NAME, getCurrentSite } from 'common/config';
import { SiteEnum } from 'common/types';
import { Box } from '@mui/material';

export default function PageMaintaining() {
  const { isMobile } = useResponsive();
  const currentSite = getCurrentSite();

  const imgStyle = {
    maxWidth: isMobile ? '60vw' : '40vw',
    maxHeight: isMobile ? '60vh' : '40vh',
    marginBottom: isMobile ? '20px' : '40px',
  };

  return (
    <Container sx={{ marginTop: 4, textAlign: 'center' }}>
      <Box sx={{ color: A.colors.error }}>
        <h5> Trang web đang bảo trì, vui lòng thử lại sau.</h5>
      </Box>
      <Box>
        <img src={A.images.itemnotfound} alt="Item not found" style={imgStyle} />
      </Box>
      <div>
        <Link to={currentSite === SiteEnum.Teacher ? TEACHER_SITE_PATH_NAME : STUDENT_SITE_PATH_NAME}>
          <Button
            variant="contained"
            sx={{
              py: isMobile ? 1 : 2,
              px: isMobile ? 4 : 8,
              fontSize: 20,
            }}
          >
            <b>Quay lại trang chủ</b>
          </Button>
        </Link>
      </div>
    </Container>
  );
}
