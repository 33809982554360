import * as React from 'react';
import { Box, Card, CardContent, Typography, Stack, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import A from 'common/assets';
import Button from 'common/components/Button';
import Text from 'common/components/Text';
import { Clazz } from 'student/api';

const RootStyled = styled('div')(({ theme }) => ({
  marginBottom: 40,
  '& .card-main': {
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '30px',
    gap: theme.spacing(3),
    pt: theme.spacing(1),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      height: 'auto',
      width: 'auto',
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

interface Props {
  clazz: Clazz;
}

export default function CourseDetailSectionContent({ clazz }: Props) {
  const [readMore, setReadMore] = React.useState(true);

  const allLines = clazz.syllabus ? clazz.syllabus.split('\\n') : [];
  const lines = readMore ? allLines.slice(0, 5) : allLines;

  const showButtonReadMoreLess = allLines.length > 5;

  return (
    <RootStyled>
      <Card className="card-main">
        <Box sx={{ py: 5, px: 6 }}>
          <Text variant="h5" mb={3}>
            Nội dung khóa học
          </Text>
          <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, width: 1 }}>
            <Box sx={{ py: 0 + '!important' }}>
              {lines.map((line, key) => {
                return line ? (
                  <Text key={key} variant="body1" sx={{ mb: 1 }}>
                    {line}
                  </Text>
                ) : (
                  <br key={key} />
                );
              })}

              {showButtonReadMoreLess ? (
                <Button onClick={() => setReadMore(!readMore)} variant="outlined">
                  {readMore ? 'Xem thêm' : 'Thu gọn'}
                </Button>
              ) : null}

              <Stack direction="row" alignItems="center" gap={3} mt={{ xl: 2, md: 2, xs: 0.5 }}></Stack>
            </Box>
          </Box>
        </Box>
      </Card>
    </RootStyled>
  );
}
