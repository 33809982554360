/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Enrollment information
 * @export
 * @interface Enrollment
 */
export interface Enrollment {
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    classId: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    scheduleId: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    teacherAvatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    classThumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    dtCreated?: string;
    /**
     * 
     * @type {string}
     * @memberof Enrollment
     */
    status?: EnrollmentStatusEnum;
}


/**
 * @export
 */
export const EnrollmentStatusEnum = {
    PendingConfirmation: 'PENDING_CONFIRMATION',
    Confirmed: 'CONFIRMED',
    Cancelled: 'CANCELLED',
    Rejected: 'REJECTED',
    RefundRequested: 'REFUND_REQUESTED',
    RefundApproved: 'REFUND_APPROVED',
    RefundRejected: 'REFUND_REJECTED'
} as const;
export type EnrollmentStatusEnum = typeof EnrollmentStatusEnum[keyof typeof EnrollmentStatusEnum];


/**
 * Check if a given object implements the Enrollment interface.
 */
export function instanceOfEnrollment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "studentId" in value;
    isInstance = isInstance && "classId" in value;
    isInstance = isInstance && "scheduleId" in value;

    return isInstance;
}

export function EnrollmentFromJSON(json: any): Enrollment {
    return EnrollmentFromJSONTyped(json, false);
}

export function EnrollmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Enrollment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentId': json['studentId'],
        'classId': json['classId'],
        'scheduleId': json['scheduleId'],
        'teacherId': !exists(json, 'teacher_id') ? undefined : json['teacher_id'],
        'teacherName': !exists(json, 'teacher_name') ? undefined : json['teacher_name'],
        'teacherAvatarUrl': !exists(json, 'teacher_avatar_url') ? undefined : json['teacher_avatar_url'],
        'classThumbnailUrl': !exists(json, 'class_thumbnail_url') ? undefined : json['class_thumbnail_url'],
        'className': !exists(json, 'class_name') ? undefined : json['class_name'],
        'dtCreated': !exists(json, 'dtCreated') ? undefined : json['dtCreated'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function EnrollmentToJSON(value?: Enrollment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentId': value.studentId,
        'classId': value.classId,
        'scheduleId': value.scheduleId,
        'teacher_id': value.teacherId,
        'teacher_name': value.teacherName,
        'teacher_avatar_url': value.teacherAvatarUrl,
        'class_thumbnail_url': value.classThumbnailUrl,
        'class_name': value.className,
        'dtCreated': value.dtCreated,
        'status': value.status,
    };
}

