import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import colors from 'common/assets/colors';
import { Section } from 'teacher/layout';
import { Link } from '@mui/material';
import CourseCreateForm from './CourseCreateForm';

export type SectionId = 'teacher' | 'parent';
export type QuestionId = number;

const steps: string[] = ['BƯỚC 1: ĐIỀN THÔNG TIN CƠ BẢN'];

export default function PageCourseCreate() {
  return (
    <Section>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" color={colors.primary} my={4}>
          Tạo khoá học mới
        </Typography>

        <Typography sx={{ lineHeight: 1.8 }}>
          Vui lòng cung cấp các thông tin khoá học bạn muốn dạy trên Amusingo.
          <br />
          <br />
          Lưu ý, các nội dung bạn nhập ở đây sẽ được giữ nguyên và hiển thị trên trang web của Amusingo, do đó hãy cân
          nhắc sử dụng từ ngữ và giọng điệu phù hợp, sao cho phụ huynh và học sinh cảm thấy quan tâm và muốn đăng ký
          khoá học của bạn. Hãy tham khảo nội dung dưới đây để có hình dung rõ hơn những nội dung cần có khi tạo một
          khoá học mới.
          <br />
          <br />
          1. Các câu hỏi thường gặp của giáo viên mới <br />
          <Link underline="none" href="https://amusingo.com/faq">
            https://amusingo.com/faq
          </Link>
          <br />
          <br />
          2. Ví dụ các khoá học có thể dạy trên Amusingo <br />
          <Link underline="none" href="https://amusingo.com/faq/teacher/5">
            https://amusingo.com/faq/teacher/5
          </Link>
          <br />
          <br />
          3. Các lớp học đã hoặc đang diễn ra trên Amusingo <br />
          <Link underline="none" href="https://amusingo.com/courses">
            https://amusingo.com/courses
          </Link>
        </Typography>
      </Box>

      {steps.map((step) => (
        <Accordion
          key={steps.indexOf(step)}
          elevation={0}
          sx={{
            '&.Mui-expanded:before': {
              opacity: 1,
            },
            bgcolor: 'inherit',
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
            <Typography variant="h6" color={colors.primary} sx={{ py: 0 }}>
              {step}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pb: 3, px: 0 }}>
            {step === 'BƯỚC 1: ĐIỀN THÔNG TIN CƠ BẢN' && <CourseCreateForm />}
          </AccordionDetails>
        </Accordion>
      ))}
    </Section>
  );
}
