import * as React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CommonRoutes, commonRoutes, RouteEnhancer } from 'common/Routes';

import { MainLayout } from 'student/layout';

import PageAuth from 'student/pages/PageAuth';
import PageHome from 'student/pages/Home/PageHome';
import NotFound from 'student/pages/NotFound';
import PageCourses from 'student/pages/PageCourses';
import PagePayment from 'student/pages/PagePayment';
import PageCourse from 'student/pages/PageCourse';
import PageLogout from 'student/pages/PageLogout';
import PageFAQ from 'student/pages/PageFAQ/PageFAQ';
import PageMyEnrolledCourses from 'student/pages/PageMyEnrolledCourses';
import PageMySavedCourses from 'student/pages/PageMySavedCourses';
import PageTeacher from 'student/pages/PageTeacher';
import PageMyProfile from 'student/pages/PageMyProfile';
import PageProfileUpdate from 'student/pages/PageProfileUpdate';
import { useAuth } from 'student/hooks';
import nProgress from 'nprogress';

export const routes: CommonRoutes = {
  PageHome: {
    path: '/',
    name: 'PageHome',
    Element: PageHome,
  },
  PageAuth: {
    path: '/auth',
    name: 'PageAuth',
    Element: PageAuth,
  },
  PageCourses: {
    path: '/courses',
    name: 'PageCourses',
    Element: PageCourses,
    params: {
      categoryId: '',
    },
  },
  PageCourse: {
    path: '/courses/:courseId',
    name: 'PageCourse',
    Element: PageCourse,
  },
  PageMyEnrolledCourses: {
    path: '/my-enrolled-courses',
    name: 'PageMyEnrolledCourses',
    Element: PageMyEnrolledCourses,
    needAuth: true,
  },
  PageMyProfile: {
    path: '/profile',
    name: 'PageMyProfile',
    Element: PageMyProfile,
    needAuth: true,
  },
  PageProfileUpdate: {
    path: '/profile/update',
    name: 'PageProfileUpdate',
    Element: PageProfileUpdate,
  },
  PageMySavedCourses: {
    path: '/my-saved-courses',
    name: 'PageMySavedCourses',
    Element: PageMySavedCourses,
    needAuth: true,
  },
  PageTeacher: {
    path: '/teachers/:teacherId',
    name: 'PageTeacher',
    Element: PageTeacher,
  },
  PagePayment: {
    path: '/payment',
    name: 'PagePayment',
    Element: PagePayment,
    params: {
      classId: 'classId',
      teacherId: 'teacherId',
      scheduleId: 'scheduleId',
    },
    needAuth: true,
  },
  PageFAQ: {
    path: '/faq',
    name: 'PageFAQ',
    Element: PageFAQ,
  },
  PageFAQSection: {
    path: '/faq/:sectionId',
    name: 'PageFAQSection',
    Element: PageFAQ,
  },
  PageFAQSectionQuestion: {
    path: '/faq/:sectionId/:questionId',
    name: 'PageFAQSectionQuestion',
    Element: PageFAQ,
  },
  PageBecomeTeacher: {
    path: '/become-teacher',
    name: 'PageBecomeTeacher',
    Element: PageFAQ,
  },
  Logout: {
    path: '/logout',
    name: 'Logout',
    Element: PageLogout,
  },
  NotFound: {
    path: '*',
    name: 'NotFound',
    Element: NotFound,
  },
  ...commonRoutes,
};

// eslint-disable-next-line
export const ProtectedRouteNeedAuth = ({ children }: any) => {
  const { isAuthenticatedWithProfile, parentProfileFetched } = useAuth();
  if (!isAuthenticatedWithProfile && parentProfileFetched) {
    toast.warning('Yêu cầu đăng nhập!');
    return <Navigate to={routes.PageAuth.path} />;
  }
  return children;
};

export default function AppRoutes() {
  const location = useLocation();
  React.useMemo(() => {
    nProgress.start();
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    nProgress.start();
    nProgress.done();
  }, [location]);
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<PageHome />} />

          {Object.values(routes).map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={
                route.needAuth ? (
                  <ProtectedRouteNeedAuth>
                    <route.Element />
                  </ProtectedRouteNeedAuth>
                ) : (
                  <route.Element />
                )
              }
            />
          ))}
        </Route>
      </Routes>
      <RouteEnhancer />
    </>
  );
}
