import * as React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';

import { ClassSchedule } from 'student/api';
import LessonScheduleItemText from './LessonScheduleItemText';
import { useNavigate } from 'react-router';

interface Props {
  classSchedules: ClassSchedule[];
  lengthOfLesson: number; //minutes;
  path?: string;
}

export default function CourseScheduleListText({ classSchedules, lengthOfLesson, path }: Props) {
  const navigate = useNavigate();
  if (!classSchedules || classSchedules.length === 0 || !classSchedules[0].scheduleTimes) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <EventIcon />
      <Stack direction="column" alignItems="start" gap={0}>
        {classSchedules[0] ? (
          <LessonScheduleItemText
            lessonScheduleTime={classSchedules[0].scheduleTimes[0]}
            lengthOfLesson={lengthOfLesson}
          />
        ) : null}

        {classSchedules.length > 1 ? (
          <Box>
            <Typography>
              <b>+{classSchedules.length - 1}</b> thời gian{' '}
              {path ? (
                <Typography
                  component="span"
                  sx={{ fontWeight: 'semi-bold', cursor: 'pointer' }}
                  onClick={() => navigate(path)}
                >
                  <b>khác</b>
                </Typography>
              ) : (
                'khác'
              )}
            </Typography>
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );
}
