import React from 'react';
import { Typography } from '@mui/material';

import colors from 'common/assets/colors';

export default function FormFieldLabel({
  text,
  desc,
  isRequired,
}: {
  text: string;
  desc?: string;
  isRequired: boolean;
}) {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {text}{' '}
        {isRequired ? (
          <Typography component="span" style={{ color: colors.primary }}>
            *
          </Typography>
        ) : null}
      </Typography>
      {desc ? <Typography component="span" dangerouslySetInnerHTML={{ __html: desc }}></Typography> : null}
    </>
  );
}
