import React from 'react';
import { Box, Button, Container } from '@mui/material';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { RootState, useSelector } from 'teacher/store';
import { useNavigate } from 'react-router';
import { routes } from 'teacher/Routes';
import Loading from 'common/components/Loading';

const PageWaitingApprove = () => {
  const navigate = useNavigate();
  const { teacherProfile, teacherProfileLoading } = useSelector((state: RootState) => state.authState);
  React.useEffect(() => {
    if (teacherProfile) navigate(routes.PageHome.path);
  }, [teacherProfile]);

  if (teacherProfileLoading) return <Loading />;
  return (
    <Container sx={{ height: '60vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ textAlign: 'center' }}>
        <CheckCircleSharpIcon color="success" />
        <Box mt={2}>
          <h2>Cảm ơn bạn đã tham gia ứng tuyển!</h2>
          <p>
            Hồ sơ đăng ký của bạn đang được xét duyệt. Amusingo sẽ thông báo trực tiếp cho bạn khi tài khoản được tạo.
          </p>
        </Box>
        <strong>Liên lạc trực tiếp:</strong>
        <Box mt={2}>
          <a href="https://www.messenger.com/t/112136175073627/" target="_blank" rel="noopener noreferrer">
            <Button variant="contained" sx={{ mr: 2 }}>
              Gửi tin nhắn
            </Button>
          </a>
          <a href="tel:+84925779797">
            <Button variant="contained">Gọi điện</Button>
          </a>
        </Box>
      </Box>
    </Container>
  );
};

export default PageWaitingApprove;
