import React from 'react';
import { Clazz } from 'student/api';
import { Favorite, FavoriteBorder, Share } from '@mui/icons-material';
import { Box, Grid, Button } from '@mui/material';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';

import { FavoriteApi } from 'student/api';
import { useSelector, RootState } from 'student/store';
import { selectAuthState } from 'student/features/auth';
import { selectAllCourses, selectMySavedCourses } from '../courseSelector';
import DialogShare from 'common/components/Dialog/DialogShare';
import A from 'common/assets';
import { routes } from 'student/Routes';

interface Props {
  course: Clazz;
  withText?: boolean;
}

export default function CourseItemAction({ course, withText }: Props) {
  const { user } = useSelector(selectAuthState);
  const mySavedCourses = useSelector(selectMySavedCourses);
  const allCourses = useSelector(selectAllCourses);

  const [isSaved, setIsSaved] = React.useState<boolean>(false);
  const [isOpenDialogShare, setIsOpenDialogShare] = React.useState<boolean>(false);

  React.useEffect(() => {
    const savedCourse: Clazz | undefined = mySavedCourses.items.find((c) => c.classId === course.classId);
    if (savedCourse) {
      setIsSaved(true);
    }
  }, [user, allCourses, mySavedCourses]);

  const handleClickFavorite = () => {
    setIsSaved(true);

    new FavoriteApi()
      .createFavoriteClass({
        studentId: `${user?.uid}`,
        favoritePostRequestParams: {
          studentId: `${user?.uid}`,
          classId: course.classId,
          teacherId: course.teacherId,
        },
      })
      .then(() => {
        toast.success('Đã lưu khóa học yêu thích!');
      })
      .catch(() => {
        toast.error('Không thể lưu khóa học yêu thích!');
        setIsSaved(false);
      });
  };

  const handleClickUnFavorite = () => {
    setIsSaved(false);

    new FavoriteApi()
      .removeFavoriteClass({
        studentId: `${user?.uid}`,
        classId: `${course.classId}`,
        teacherId: `${course.teacherId}`,
      })
      .then(() => {
        toast.success('Bỏ lưu khóa học yêu thích!');
      })
      .catch(() => {
        toast.error('Không thể bỏ lưu khóa học yêu thích!');
        setIsSaved(true);
      });
  };

  const FavoriteIcon = isSaved ? <Favorite color="primary" /> : <FavoriteBorder />;
  const handleClickFavoriteAndUnFavorite = () => (isSaved ? handleClickUnFavorite() : handleClickFavorite());

  const ShareIcon = <Share color="primary" />;

  return (
    <Box>
      <Grid container spacing={1} justifyContent="right">
        <Grid item>
          {withText ? (
            <Button
              size="large"
              variant="text"
              sx={{ fontWeight: 800 }}
              startIcon={FavoriteIcon}
              onClick={handleClickFavoriteAndUnFavorite}
            >
              {isSaved ? 'Đã Thích' : 'Thích'}
            </Button>
          ) : (
            <IconButton size="large" onClick={handleClickFavoriteAndUnFavorite}>
              {FavoriteIcon}
            </IconButton>
          )}
        </Grid>
        <Grid item>
          {withText ? (
            <Button
              size="large"
              variant="text"
              color="primary"
              sx={{ fontWeight: 800 }}
              startIcon={ShareIcon}
              onClick={() => setIsOpenDialogShare(true)}
            >
              Chia sẻ
            </Button>
          ) : (
            <IconButton size="large" onClick={() => setIsOpenDialogShare(true)}>
              {ShareIcon}
            </IconButton>
          )}
          <DialogShare
            open={isOpenDialogShare}
            handleChangeOpen={(value: boolean) => setIsOpenDialogShare(value)}
            url={`${A.host}${routes.PageCourse.path.replace(':courseId', `${course?.classId}`)}`}
            title={`${course.className}`}
            content=""
          />
        </Grid>
      </Grid>
    </Box>
  );
}
