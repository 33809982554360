/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Minimum and maximum number of learners allowed per class
 * @export
 * @interface NumAllowedLearners
 */
export interface NumAllowedLearners {
    /**
     * 
     * @type {number}
     * @memberof NumAllowedLearners
     */
    minAllowedLearners?: number;
    /**
     * 
     * @type {number}
     * @memberof NumAllowedLearners
     */
    maxAllowedLearners?: number;
}

/**
 * Check if a given object implements the NumAllowedLearners interface.
 */
export function instanceOfNumAllowedLearners(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NumAllowedLearnersFromJSON(json: any): NumAllowedLearners {
    return NumAllowedLearnersFromJSONTyped(json, false);
}

export function NumAllowedLearnersFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumAllowedLearners {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minAllowedLearners': !exists(json, 'minAllowedLearners') ? undefined : json['minAllowedLearners'],
        'maxAllowedLearners': !exists(json, 'maxAllowedLearners') ? undefined : json['maxAllowedLearners'],
    };
}

export function NumAllowedLearnersToJSON(value?: NumAllowedLearners | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minAllowedLearners': value.minAllowedLearners,
        'maxAllowedLearners': value.maxAllowedLearners,
    };
}

