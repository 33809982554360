/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for Review Post Request
 * @export
 * @interface ReviewPostRequestParams
 */
export interface ReviewPostRequestParams {
    /**
     * 
     * @type {string}
     * @memberof ReviewPostRequestParams
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof ReviewPostRequestParams
     */
    imageFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ReviewPostRequestParams
     */
    rate?: number;
}

/**
 * Check if a given object implements the ReviewPostRequestParams interface.
 */
export function instanceOfReviewPostRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReviewPostRequestParamsFromJSON(json: any): ReviewPostRequestParams {
    return ReviewPostRequestParamsFromJSONTyped(json, false);
}

export function ReviewPostRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewPostRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : json['content'],
        'imageFileName': !exists(json, 'imageFileName') ? undefined : json['imageFileName'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
    };
}

export function ReviewPostRequestParamsToJSON(value?: ReviewPostRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'imageFileName': value.imageFileName,
        'rate': value.rate,
    };
}

