const images = {
  icon_arrow: require('./img/icon-arrow.png'),
  icon_arrow_left: require('./img/icon-arrow-left.png'),
  icon_light: require('./img/light-icon.png'),
  icon_login_black: require('./img/login-black-icon.png'),
  icon_login_white: require('./img/login-white-icon.png'),
  icon_man: require('./img/man-icon.png'),
  icon_play: require('./img/play-icon.png'),
  icon_ques: require('./img/ques-icon.png'),
  icon_search: require('./img/search-icon.png'),
  icon_teacher: require('./img/teacher-icon.png'),
  icon_tracking: require('./img/tracking-icon.png'),
  icon_share: require('./img/share-icon.png'),

  logo: require('./img/logo.png'),
  logoSoical: require('./img/social.png'),
  teacherIcon: require('./img/image27.png'),
  banner_bg: require('./img/banner-bg.png'),
  banner: require('./img/banner-img.png'),
  card: require('./img/card-img-1.png'),
  card_user: require('./img/card-user-img.png'),
  review: require('./img/review-img.png'),
  teacher: require('./img/teacher-img.png'),
  minions: require('./img/minions.png'),
  lion: require('./img/lion.png'),
  eye: require('./img/eye.png'),
  rabbit: require('./img/rabbit.png'),
  gentleMen: require('./img/gentle-men.png'),
  women: require('./img/women.png'),
  qrcode: require('./img/qrcode.png'),
  Bidv: require('./img/BIDV_Logo.png'),
  itemnotfound: require('./img/itemnotfound.png'),
  paymentInfo: require('./img/payment-info.jpeg'),

  Zalo: require('./img/Zalo.svg.png'),
  Messenger: require('./img/MessengerLogo.png'),
};

export default images;
