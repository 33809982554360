import React from 'react';
import { styled } from '@mui/material/styles';
import { SectionTitle } from 'common/components/Text';
import { Box } from '@mui/material';
import { useCoursesByTeacherId, useResponsive } from 'student/hooks';
import ClazzSlideItem from './CourseSlideItem';
import Loading from 'common/components/Loading';

const RootStyled = styled('div')(() => ({
  width: '100%',
  margin: '36px 0',
}));

interface TeacherCourseProps {
  teacherId: string;
}

const TeacherCourses = ({ teacherId }: TeacherCourseProps) => {
  const itemMinWidth = 300;

  const { isMobile } = useResponsive();
  const { loading, coursesByTeacher } = useCoursesByTeacherId(teacherId);

  if (loading) {
    return <Loading />;
  }

  return (
    <RootStyled>
      <SectionTitle marginBottom={2}>Khóa học của giáo viên</SectionTitle>
      <Box
        sx={{
          marginBottom: '80px',
          display: 'flex',
          justifyContent: isMobile ? 'center' : 'flex-start',
          flexWrap: 'wrap',
          gap: '40px',
        }}
      >
        {coursesByTeacher.map((clazz) => (
          <Box key={clazz.classId}>
            <ClazzSlideItem clazz={clazz} itemMinWidth={itemMinWidth} />
          </Box>
        ))}
      </Box>
    </RootStyled>
  );
};

export default TeacherCourses;
