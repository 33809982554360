import React from 'react';

import './style.scss';
import Button from 'common/components/Button';
import Link from 'common/components/Link/Link';
import { Section } from 'student/layout';
import { SectionTitle, SectionTitleSub } from 'common/components/Text';

const BannerBottom = () => {
  return (
    <Section>
      <div className="footer-top-container">
        <SectionTitle>Sẵn sàng để dạy học?</SectionTitle>
        <SectionTitleSub textAlign={'center'}>
          <span>Amusingo đã sẵn sàng, </span>
          <span>còn bạn thì sao?</span>
        </SectionTitleSub>

        <Link to="/become-teacher">
          <Button
            variant="contained"
            sx={{
              height: 74,
              fontSize: 23,
              padding: '12px 23px',
              xs: {
                fontSize: 20,
              },
            }}
          >
            Trở thành giáo viên
          </Button>
        </Link>
      </div>
    </Section>
  );
};

export default BannerBottom;
