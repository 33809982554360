import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
// material components
import { Card, CardContent, CardMedia, Stack, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// assets
import A from 'common/assets';
import { routes } from 'student/Routes';
import CourseItemAction from './CourseItemAction';
import Text from 'common/components/Text';
import Button from 'common/components/Button';

import { Clazz } from 'student/api';
import CourseScheduleListText from 'student/features/course/components/CourseScheduleListText';
import { getCoursePrice } from 'common/utils';
import Rating from 'common/components/Rating';

const RootStyled = styled('div')(({ theme }) => ({
  '& .card-main': {
    display: 'flex',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    gap: theme.spacing(3),
    pt: theme.spacing(1.5),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      width: 300,
      borderRadius: '30px',
      objectFit: 'cover',
      height: 200,
      cursor: 'pointer',
      [theme.breakpoints.down('xl')]: {
        width: '100%',
        height: 200,
      },
    },
  },
}));

interface Props {
  course: Clazz;
}

export default function CourseListItem({ course }: Props) {
  const navigate = useNavigate();
  const [readMore, setReadMore] = React.useState(true);

  const RenderDescription = () => {
    const desArr = (course.description && course.description.split(' ')) || [];
    const showButtonReadMoreLess = desArr.length > 35;

    const description = readMore ? desArr.slice(0, 35).join(' ') + ' ... ' : desArr.join(' ');
    return (
      <>
        {description}{' '}
        {showButtonReadMoreLess ? (
          <Button onClick={() => setReadMore(!readMore)} variant="pure-text" size="small" sx={{ py: 0 }}>
            <Text sx={{ textDecoration: 'underline' }}>{readMore ? 'Xem thêm' : 'Thu gọn'}</Text>
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <RootStyled>
      <Card className="card-main">
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={'auto'} sx={{ display: 'flex' }} justifyContent="center" alignItems="baseline">
            <CardMedia
              sx={{ width: '300px !important' }}
              component="img"
              image={course.thumbnailImageUrl}
              alt={course.className}
              onClick={() => navigate(routes.PageCourse.path.replace(':courseId', `${course.classId}`))}
            />
          </Grid>

          <Grid item xs={12} md={'auto'} sx={{ flex: { md: 1 }, mt: { md: 0, xs: 2, sm: 1 } }}>
            <CardContent
              sx={{
                flex: '1 0 auto',
                py: 0 + '!important',
                px: { xs: 0, sm: 2 },
                pl: { sm: 4 },
              }}
            >
              <Typography variant="body1" fontWeight={'bold'}>
                {course.ageRange?.minAge}-{course.ageRange?.maxAge} tuổi
              </Typography>

              <Typography
                component={Link}
                to={routes.PageCourse.path.replace(':courseId', `${course.classId}`)}
                variant="h5"
                mb={0.5}
              >
                {course.className}
              </Typography>

              <Typography variant="body1" mt={1}>
                <RenderDescription />
              </Typography>

              <Link to={routes.PageTeacher.path.replace(':teacherId', `${course.teacherId}`)}>
                <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                  {course.teacherName}
                </Typography>
              </Link>

              <Stack direction="row" alignItems="center" my={{ xl: 2, xs: 1 }}>
                <Rating
                  readOnly
                  name="hover-feedback"
                  value={course.averageRating === -1 ? 0 : course.averageRating}
                  precision={0.1}
                />
              </Stack>

              {course.schedules ? (
                <CourseScheduleListText
                  classSchedules={course.schedules || []}
                  lengthOfLesson={course.lengthOfLesson || 60}
                  path={routes.PageCourse.path.replace(':courseId', `${course.classId}`)}
                />
              ) : null}
            </CardContent>
          </Grid>

          <Grid item xs={12} md={'auto'}>
            <Stack
              direction={{ xs: 'row-reverse', md: 'column' }}
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', width: { sm: '100%' } }}
            >
              <CourseItemAction course={course} />

              <Text variant="h6" color="text.primary" sx={{ pr: 1 }} textAlign={'right'}>
                {getCoursePrice(course.price, course.numLessons)}
              </Text>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </RootStyled>
  );
}
