import { Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import colors from 'common/assets/colors';
import React from 'react';
import { useResponsive } from 'teacher/hooks/useResponsive';

interface statistics {
  header: string;
}

const columns = [
  { label: 'Tháng', id: 1 },
  { label: 'Thu nhập', id: 2 },
  { label: 'Số học sinh', id: 3 },
  { label: 'Số giờ dạy', id: 4 },
];

const lines = ['Tháng 6, 2023', '3.000.000 VND', '30', '30'];

export default function StatisticsByTime(props: statistics) {
  const { isMobile } = useResponsive();

  return (
    <>
      <Typography variant="h4" color={colors.primary} my={4}>
        {props.header}
      </Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!isMobile && <TableCell sx={{ bgcolor: 'inherit', width: '5%' }}></TableCell>}
              {columns.map((column) => (
                <TableCell key={column.id} sx={{ bgcolor: 'inherit' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableRow>
            {!isMobile && <TableCell sx={{ bgcolor: 'inherit', width: '5%', borderBottom: 0 }}></TableCell>}
            {lines.map((line) => (
              <TableCell key={line} sx={{ bgcolor: 'inherit', borderBottom: 0, minWidth: 150 }}>
                {line}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {!isMobile && <TableCell sx={{ bgcolor: 'inherit', width: '5%', borderBottom: 0 }}></TableCell>}
            {lines.map((line) => (
              <TableCell key={line} sx={{ bgcolor: 'inherit', borderBottom: 0, minWidth: 150 }}>
                {line}
              </TableCell>
            ))}
          </TableRow>
        </Table>
      </TableContainer>
    </>
  );
}
