import { LessonScheduleTime } from 'student/api';
import moment from 'moment';

export const getWeekDay = (date: Date): string => {
  const weekDay = moment(date).locale('vi').isoWeekday() + 1;
  return weekDay === 8 ? 'Chủ nhật' : `Thứ ${weekDay}`;
};

// 00~24
export const getHour = (date: Date): number => {
  // const hourStr = moment(date).locale('vi').format('HH');
  const hourStr = moment(date).locale('vi').format('HH');
  return parseInt(hourStr);
};
// 00~24
export const getHourMinute = (date: Date): string => {
  const hourMinuteStr = moment(date).locale('vi').format('HH:mm');
  return hourMinuteStr;
};

export const getHourByMs = (timeMs: number): number => {
  return getHour(new Date(timeMs));
};

type WeekDayNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export const getWeekDayNumber = (date: Date): WeekDayNumber => {
  const weekDay = moment(date).locale('vi').isoWeekday() + 1;
  return weekDay as WeekDayNumber;
};

export const getClassScheduleStartMs = (schedule: LessonScheduleTime): number => {
  return parseInt(schedule.start) * 1000;
};

export const getClassScheduleEndMs = (schedule: LessonScheduleTime, lengthOfLessonMinute: number): number => {
  const startMs = getClassScheduleStartMs(schedule);
  const lengthOfLessonMs = lengthOfLessonMinute * 60 * 1000;
  return startMs + lengthOfLessonMs;
};

export const getClassAllScheduleEndMs = (schedule: LessonScheduleTime): number => {
  return parseInt(schedule.end) * 1000;
};
