import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { TEACHER_SITE_PATH_NAME } from 'common/config';

export default function PageLogout() {
  React.useEffect(() => {
    try {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          toast.success('Đã đăng xuất!');
        })
        .catch((error) => {
          toast.error('Lỗi đăng xuất: ', error?.message || error);
        })
        .finally(() => {
          window.location.pathname = TEACHER_SITE_PATH_NAME;
        });
    } catch (error: any) {
      toast.error('Lỗi đăng xuất: ', error?.message || error);
    }
  }, []);

  return <></>;
}
