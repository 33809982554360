import React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Avatar,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TEACHER_SITE_PATH_NAME } from 'common/config';

import A from 'common/assets';
import Link from 'common/components/Link/Link';
import Button from 'common/components/Button';
import { NavItem } from './Header';
import './style.scss';

const MyAppBar1 = styled(AppBar)({
  backgroundColor: 'white',
  height: 64,
});

interface Props {
  navItems: NavItem[];
}

export default function HeaderDesktopAppBar1({ navItems }: Props) {
  const navigate = useNavigate();

  const GroupMenuItems = ({ navItem }: { navItem: NavItem }) => {
    const navItems = navItem.navItems;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    return (
      <Box
        sx={{
          flexGrow: 0,
          paddingLeft: 1,
          '& button': {
            height: '50px',
            borderRadius: '30px',
            padding: '6px 8px',
          },
        }}
      >
        <Tooltip
          title={navItem.label}
          onClick={handleOpenMenu}
          sx={{ height: 50, borderRadius: 10, padding: '6px 8px' }}
        >
          {navItem.startIcon}
        </Tooltip>

        <Menu
          sx={{ mt: '45px', right: '16px' }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {navItems?.map((navItem) => (
            <MenuItem key={navItem.label + navItem.route} onClick={() => navigate(navItem.route)} className="p-2">
              <ListItemIcon>{navItem.startIcon}</ListItemIcon>
              <ListItemText>{navItem.label}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  const SingleItem = ({ navItem }: { navItem: NavItem }) => {
    return (
      <Link to={navItem.route} key={navItem.label}>
        <Button
          key={navItem.label}
          variant={navItem?.variant ? navItem.variant : 'text'}
          className="ms-2"
          startIcon={navItem.startIcon}
          sx={{ fontFamily: A.fonts.bold }}
        >
          {navItem.label}
        </Button>
      </Link>
    );
  };

  return (
    <>
      <MyAppBar1 position="static" color="inherit" className="navigation-container">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to={TEACHER_SITE_PATH_NAME}>
              <img src={A.images.logo} alt="Amusingo logo" />
            </Link>
          </Typography>

          {navItems.map((item, i) =>
            item.navItems ? <GroupMenuItems key={i} navItem={item} /> : <SingleItem key={i} navItem={item} />
          )}
        </Toolbar>
      </MyAppBar1>
    </>
  );
}
