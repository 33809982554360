import React from 'react';

import { StatisticApi } from 'teacher/api';
import { changeTeacherStatisticFetched, changeTeacherStatisticLoading, selectStatisticState } from 'teacher/features';
import { dispatch, RootState, useSelector } from 'teacher/store';
import { StatisticState, changeStatisticStateReset, changeOverallStatistic } from 'teacher/features';

export function useStatistic(): StatisticState {
  const statisticState = useSelector(selectStatisticState);
  const teacher = useSelector((state: RootState) => state.authState.teacherProfile);

  function fetchTeacherStatistics(teacherId: string, orgId: string) {
    dispatch(changeTeacherStatisticFetched(false));
    dispatch(changeTeacherStatisticLoading(true));

    new StatisticApi()
      .getTeacherStatistic({
        teacherId: teacherId!,
        orgId: orgId,
      })
      .then((res) => {
        // TO-DO : handle response
        dispatch(changeOverallStatistic(res));
        // fetch the statistics of 3 months here
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch(changeTeacherStatisticFetched(true));
        dispatch(changeTeacherStatisticLoading(false));
      });
  }

  React.useEffect(() => {
    if (teacher) {
      fetchTeacherStatistics(teacher.teacherId!, teacher.organizationId!);
    } else dispatch(changeStatisticStateReset());
  }, [teacher]);

  return { ...statisticState };
}
