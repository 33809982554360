/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Payment confirmation information
 * @export
 * @interface PaymentConfirmation
 */
export interface PaymentConfirmation {
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmation
     */
    paymentType?: PaymentConfirmationPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmation
     */
    confirmationTime?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentConfirmation
     */
    paymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmation
     */
    selectedSchedule?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmation
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmation
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmation
     */
    confirmationStatus?: PaymentConfirmationConfirmationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentConfirmation
     */
    refundStatus?: PaymentConfirmationRefundStatusEnum;
}


/**
 * @export
 */
export const PaymentConfirmationPaymentTypeEnum = {
    QrPayment: 'QR_PAYMENT',
    BankingPayment: 'BANKING_PAYMENT'
} as const;
export type PaymentConfirmationPaymentTypeEnum = typeof PaymentConfirmationPaymentTypeEnum[keyof typeof PaymentConfirmationPaymentTypeEnum];

/**
 * @export
 */
export const PaymentConfirmationConfirmationStatusEnum = {
    Pending: 'PENDING',
    Processing: 'PROCESSING',
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED',
    Retrying: 'RETRYING'
} as const;
export type PaymentConfirmationConfirmationStatusEnum = typeof PaymentConfirmationConfirmationStatusEnum[keyof typeof PaymentConfirmationConfirmationStatusEnum];

/**
 * @export
 */
export const PaymentConfirmationRefundStatusEnum = {
    NotApplicable: 'NOT_APPLICABLE',
    RequestReceived: 'REQUEST_RECEIVED',
    Processing: 'PROCESSING',
    Succeeded: 'SUCCEEDED',
    Failed: 'FAILED',
    Retrying: 'RETRYING'
} as const;
export type PaymentConfirmationRefundStatusEnum = typeof PaymentConfirmationRefundStatusEnum[keyof typeof PaymentConfirmationRefundStatusEnum];


/**
 * Check if a given object implements the PaymentConfirmation interface.
 */
export function instanceOfPaymentConfirmation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentConfirmationFromJSON(json: any): PaymentConfirmation {
    return PaymentConfirmationFromJSONTyped(json, false);
}

export function PaymentConfirmationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentConfirmation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
        'confirmationTime': !exists(json, 'confirmationTime') ? undefined : json['confirmationTime'],
        'paymentAmount': !exists(json, 'paymentAmount') ? undefined : json['paymentAmount'],
        'selectedSchedule': !exists(json, 'selectedSchedule') ? undefined : json['selectedSchedule'],
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'classId': !exists(json, 'classId') ? undefined : json['classId'],
        'confirmationStatus': !exists(json, 'confirmationStatus') ? undefined : json['confirmationStatus'],
        'refundStatus': !exists(json, 'refundStatus') ? undefined : json['refundStatus'],
    };
}

export function PaymentConfirmationToJSON(value?: PaymentConfirmation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentType': value.paymentType,
        'confirmationTime': value.confirmationTime,
        'paymentAmount': value.paymentAmount,
        'selectedSchedule': value.selectedSchedule,
        'studentId': value.studentId,
        'classId': value.classId,
        'confirmationStatus': value.confirmationStatus,
        'refundStatus': value.refundStatus,
    };
}

