/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LessonScheduleTime } from './LessonScheduleTime';
import {
    LessonScheduleTimeFromJSON,
    LessonScheduleTimeFromJSONTyped,
    LessonScheduleTimeToJSON,
} from './LessonScheduleTime';

/**
 * A schedule group
 * @export
 * @interface ClassSchedule
 */
export interface ClassSchedule {
    /**
     * 
     * @type {string}
     * @memberof ClassSchedule
     */
    scheduleId?: string;
    /**
     * 
     * @type {number}
     * @memberof ClassSchedule
     */
    numEnrolledStudents?: number;
    /**
     * 
     * @type {Array<LessonScheduleTime>}
     * @memberof ClassSchedule
     */
    scheduleTimes?: Array<LessonScheduleTime>;
}

/**
 * Check if a given object implements the ClassSchedule interface.
 */
export function instanceOfClassSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassScheduleFromJSON(json: any): ClassSchedule {
    return ClassScheduleFromJSONTyped(json, false);
}

export function ClassScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
        'numEnrolledStudents': !exists(json, 'numEnrolledStudents') ? undefined : json['numEnrolledStudents'],
        'scheduleTimes': !exists(json, 'scheduleTimes') ? undefined : ((json['scheduleTimes'] as Array<any>).map(LessonScheduleTimeFromJSON)),
    };
}

export function ClassScheduleToJSON(value?: ClassSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduleId': value.scheduleId,
        'numEnrolledStudents': value.numEnrolledStudents,
        'scheduleTimes': value.scheduleTimes === undefined ? undefined : ((value.scheduleTimes as Array<any>).map(LessonScheduleTimeToJSON)),
    };
}

