import React from 'react';
import { Checkbox, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Option } from 'common/components/Field/Select';

interface FilterCollapseProps {
  title: string;
  options: Option[];
  selectedOptions: Option[];
  handleChangeOptions: (options: Option[]) => void;
}

const FilterCollapse = ({ title, options, selectedOptions, handleChangeOptions }: FilterCollapseProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const OptionButton = ({ option }: { option: Option }) => {
    const selectedIndex = selectedOptions.indexOf(option);
    const isSelected = selectedIndex !== -1;

    const handleItemClick = () => {
      if (handleChangeOptions) {
        let newOptions = [];
        if (isSelected) {
          newOptions = selectedOptions.filter((opt) => opt.label !== option.label);
        } else {
          newOptions = [...selectedOptions, option];
        }
        handleChangeOptions(newOptions);
      }
    };
    return (
      <ListItemButton onClick={handleItemClick}>
        <ListItemIcon>
          <Checkbox edge="start" checked={isSelected} tabIndex={-1} disableRipple />
        </ListItemIcon>
        <ListItemText primary={option.label} />
      </ListItemButton>
    );
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options.map((option, index) => (
            <div key={index}>
              <OptionButton option={option} />
            </div>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default FilterCollapse;
