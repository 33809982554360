import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { routes } from 'student/Routes';
import Loading from 'common/components/Loading';
import AuthFormPhone from './AuthFormPhone';
import AuthFormProfile from './AuthFormProfile';
import { useAuth } from 'student/hooks';

// Fetch user -> Check has user
// Fetch profile -> Check has profile
// Handle Authenticated
export default function AuthForm() {
  const { user, userLoading, parentProfile, parentProfileLoading } = useAuth();
  const navigate = useNavigate();

  if (userLoading) {
    return <Loading />;
  }
  if (!user) return <AuthFormPhone />;

  if (parentProfileLoading) {
    return <Loading />;
  }
  if (!parentProfile) {
    return <AuthFormProfile />;
  }

  // Authenticated With Profile -> Redirect to home page
  toast.success('Đăng nhập thành công!');
  navigate(routes.PageHome.path);
  return null;
}
