import { ClassSchedule, LessonScheduleTime } from 'student/api/models';

export interface FlatSchedule {
  scheduleId?: string;
  scheduleTimes?: LessonScheduleTime[];
}

export const getFlatSchedules = (classSchedules: ClassSchedule[]): FlatSchedule[] => {
  const flatSchedules: FlatSchedule[] = classSchedules.map((classSchedule) => {
    const flatSchedule: FlatSchedule = {
      scheduleId: classSchedule.scheduleId,
      scheduleTimes: classSchedule.scheduleTimes,
    };

    return flatSchedule;
  });

  return flatSchedules;
};
