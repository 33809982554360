import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Avatar, Typography, Stack, Paper } from '@mui/material';

import { routes } from 'student/Routes';
import { Clazz } from 'student/api';
import Link from 'common/components/Link/Link';
import Image from 'common/components/Image';
import Video from 'common/components/Video';
import CourseItemAction from 'student/features/course/components/CourseItemAction';
import CourseDetailSectionAboutEnroll from 'student/features/course/components/CourseDetail/CourseDetailSectionAboutEnroll';
import Loading from 'common/components/Loading';
import CourseScheduleListText from '../CourseScheduleListText';
import Rating from 'common/components/Rating';

interface Props {
  clazz: Clazz;
}

export default function CourseDetailSectionAbout({ clazz }: Props) {
  const navigate = useNavigate();

  const hasSchedules = !!clazz.schedules && clazz.schedules.length > 0;

  if (!clazz.classId) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2} justifyContent="space-between" id="course-detail-section-about">
      <Grid item xs={12}>
        <Typography variant="h4" color="primary" mb={2}>
          {clazz.className}
        </Typography>
      </Grid>

      <Grid item xs={12} md={5}>
        <Paper className="media-paper">
          {clazz.introductionVideoUrl ? (
            <Video
              src={clazz.introductionVideoUrl}
              style={{
                height: 'auto',
                aspectRatio: '16/9',
                maxHeight: 340,
              }}
            />
          ) : (
            <Image
              src={clazz.thumbnailImageUrl}
              alt={`Amusingo - ${clazz.className}`}
              onClick={() => navigate('/courses/' + clazz.classId)}
              style={{
                height: 'auto',
                aspectRatio: '16/9',
                maxHeight: 340,
              }}
            />
          )}
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        md={7}
        sx={{
          paddingLeft: {
            md: '70px !important',
          },
        }}
      >
        <Typography variant="body1">{clazz.description}</Typography>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ img: { borderRadius: 50 } }}
          my={{ xl: 1.5, xs: 1 }}
        >
          <Link to={routes.PageTeacher.path.replace(':teacherId', `${clazz.teacherId}`)}>
            <Avatar src={clazz.teacherAvatarUrl} alt={`Avatar giáo viên ${clazz.teacherName}`} />
          </Link>
          <Link to={routes.PageTeacher.path.replace(':teacherId', `${clazz.teacherId}`)}>
            <Typography variant="subtitle1" color="primary" fontWeight={600}>
              {clazz.teacherName}
            </Typography>
          </Link>
        </Stack>

        <Stack direction="row" alignItems="center" my={{ xl: 1, xs: 0.5 }}>
          <Rating readOnly value={clazz.averageRating} precision={0.1} size="medium" />
        </Stack>

        {clazz.numLearnedStudents && clazz.numLearnedStudents > 0 ? (
          <Typography variant="body1">{clazz.numLearnedStudents} học sinh đã hoàn thành khóa học này</Typography>
        ) : null}

        <Grid container mt={{ xl: 2, lg: 2, md: 1, xs: 0.5 }} spacing={3}>
          <Grid item xs={12} alignItems={'center'} display={'flex'} gap={2}>
            <CourseDetailSectionAboutEnroll courseId={clazz.classId} />
            <CourseItemAction course={clazz} withText={true} />
          </Grid>
          {hasSchedules ? (
            <Grid item xs={12} alignItems={'center'} display={'flex'} gap={2}>
              <CourseScheduleListText
                classSchedules={clazz.schedules || []}
                lengthOfLesson={clazz.lengthOfLesson || 60}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
