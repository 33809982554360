import React, { useMemo } from 'react';
import { getAuth, User } from 'firebase/auth';
const auth = getAuth();

import { ParentProfileApi } from 'student/api';
import { selectAuthState } from 'student/features';
import { dispatch, useSelector } from 'student/store';
import {
  AuthState,
  changeAuthStateReset,
  changeAuthStateToLogout,
  changeUser,
  changeUserFetched,
  changeUserLoading,
  changeParentProfile,
  changeParentProfileFetched,
  changeParentProfileLoading,
} from 'student/features';
import { ACCESS_TOKEN } from 'student/config';

interface AuthHook extends AuthState {
  isAuthenticated: boolean;
  isAuthenticatedWithProfile: boolean;
}

export function useAuth(): AuthHook {
  const authState = useSelector(selectAuthState);

  const isAuthenticated = useMemo(
    () => authState.userFetched && !!authState.user,
    [authState.userFetched, authState.user]
  );

  const isAuthenticatedWithProfile = useMemo(
    () => !!authState.user && !!authState.parentProfile,
    [authState.user, authState.parentProfile]
  );

  function fetchParentProfile(userId: string) {
    dispatch(changeParentProfileFetched(false));
    dispatch(changeParentProfileLoading(true));

    new ParentProfileApi()
      .getParentProfile({
        userId,
      })
      .then((value) => {
        dispatch(changeParentProfile(value));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        dispatch(changeParentProfileFetched(true));
        dispatch(changeParentProfileLoading(false));
      });
  }

  React.useEffect(() => {
    // Re-load auth state (Should remove this when we use redux-persist for cache the store)
    dispatch(changeAuthStateReset());

    auth.onAuthStateChanged(async (userImpl) => {
      dispatch(changeUserFetched(false));
      dispatch(changeParentProfileFetched(false));

      const isAuthenticated = !!userImpl;
      if (isAuthenticated) {
        const accessToken = await userImpl.getIdToken();
        localStorage.setItem(ACCESS_TOKEN, accessToken);

        const user: User = userImpl.toJSON() as User;
        dispatch(changeUser(user));
        dispatch(changeUserFetched(true));
        dispatch(changeUserLoading(false));

        fetchParentProfile(user.uid);
      } else {
        // Handle firebase not auth
        dispatch(changeAuthStateToLogout());
        localStorage.removeItem(ACCESS_TOKEN);
      }
    });
  }, []);

  return { ...authState, isAuthenticated, isAuthenticatedWithProfile };
}
