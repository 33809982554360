import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import colors from 'common/assets/colors';
import ApplyForm from './ApplyForm';
import { Section } from 'teacher/layout';

export type SectionId = 'teacher' | 'parent';
export type QuestionId = number;

export interface Question {
  id: QuestionId;
  title: string;
  answer: string;
}
const steps: string[] = ['BƯỚC 1: HIỂU VỀ CÁCH AMUSINGO HOẠT ĐỘNG', 'BƯỚC 2: ĐIỀN THÔNG TIN CÁ NHÂN'];
const questions: Question[] = [
  {
    id: 1,
    title: 'Amusingo là gì?',
    answer: `Amusingo là một nền tảng giáo dục online với vô vàn khoá học vui vẻ, thực tiễn từ nhiều lĩnh vực dành cho trẻ từ 3-18 tuổi. Các chủ đề trải dài trên nhiều lĩnh vực, từ ngoại ngữ, lập trình, cho đến kỹ năng, nghệ thuật,... và được giảng dạy bởi những giáo viên giàu nhiệt huyết và có nhiều kinh nghiệm thực tế trong lĩnh vực giảng dạy.
          \nChúng tôi mong muốn mang đến 1 môi trường giáo dục nơi trẻ có thể tiếp cận với những kiến thức cập nhật, thiết thực nhưng chưa được giảng dạy ở các môi trường giáo dục truyền thống. Đồng thời, chúng tôi cũng nỗ lực trong việc thay đổi cách giảng dạy để khiến trẻ cảm thấy gắn kết và yêu thích việc học.
          \nChúng tôi tin rằng, bằng việc để trẻ tự mình lựa chọn học điều mình muốn và có cơ hội trải nghiệm trên nhiều lĩnh vực, trẻ sẽ tìm thấy được niềm vui và đam mê trong học tập, xây dựng thói quen tự học và chủ động tìm tòi, đồng thời trang bị cho mình những kiến thức cũng như kỹ năng để sẵn sàng cho tương lai.`,
  },
  {
    id: 2,
    title: 'Amusingo hoạt động như thế nào?',
    answer: `Giáo viên đăng ký tham gia và trải qua các vòng đánh giá để trở thành giáo viên trên nền tảng Amusingo. Sau khi vượt qua các vòng đánh giá và trở thành giáo viên chính thức, giáo viên có thể bắt đầu đăng các khoá học mình muốn dạy trên nền tảng.
          \nKhi đó, phụ huynh và học sinh có thể nhìn thấy các khoá học của giáo viên, từ đó lựa chọn và đăng ký các khoá học mình quan tâm, yêu thích ngay trên trang web của Amusingo. Sau khi Amusingo xác nhận phụ huynh đã thanh toán thành công, học sinh có thể truy cập vào đường link để tham gia các buổi học.
          \nCác buổi học sẽ được tổ chức trực tuyến thông qua phần mềm Zoom, với sự tham gia của giáo viên và các học sinh đã đăng ký. Link Zoom sẽ do Amusingo tạo và gửi tới giáo viên và các học sinh. (Lưu ý, giáo viên sử dụng Zoom cá nhân để tổ chức lớp học là hành vi vi phạm quy định của Amusingo)`,
  },
  {
    id: 3,
    title: 'THÔNG TIN THAM KHẢO',
    answer: `Hãy tham khảo nội dung dưới đây để có hình dung rõ hơn về nền tảng Amusingo.
    \n
    1. Các câu hỏi thường gặp của giáo viên mới
    <a href="https://amusingo.com/faq">https://amusingo.com/faq</a>
    \n
    2. Ví dụ các khoá học có thể dạy trên Amusingo
    <a href="https://amusingo.com/faq/teacher/5">https://amusingo.com/faq/teacher/5</a>
    \n
    3. Các lớp học đã hoặc đang diễn ra trên Amusingo
    <a href="https://amusingo.com/courses">https://amusingo.com/courses</a>`,
  },
];

export default function PageApply() {
  return (
    <Section>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" color={colors.primary} my={4}>
          Đăng ký ứng tuyển trở thành giáo viên trên nền tảng
        </Typography>

        <Typography>
          Cảm ơn bạn đã quan tâm và dành thời gian cho Amusingo! Vui lòng đọc các thông tin và điền vào form đăng ký
          dưới đây để tham gia ứng tuyển trở thành giáo viên trên nền tảng Amusingo.
        </Typography>
      </Box>

      {steps.map((step) => (
        <Accordion
          key={steps.indexOf(step)}
          elevation={0}
          sx={{
            '&.Mui-expanded:before': {
              opacity: 1,
            },
            bgcolor: 'inherit',
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 0 }}>
            <Typography variant="h6" color={colors.primary} sx={{ py: 0 }}>
              {step}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pb: 3, px: 0 }}>
            {step === 'BƯỚC 1: HIỂU VỀ CÁCH AMUSINGO HOẠT ĐỘNG' &&
              questions.map((question) => (
                <Accordion
                  key={question.id}
                  elevation={0}
                  sx={{
                    '&.Mui-expanded:before': {
                      opacity: 1,
                    },
                    bgcolor: 'inherit',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${question.id}-content`}
                    id={`${question.id}-header`}
                    sx={{ '& .MuiAccordionSummary-content': { my: 3 }, px: 0 }}
                  >
                    <Typography component="div" sx={{ fontSize: '1rem', textTransform: 'uppercase' }} variant="h6">
                      {question.title}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ pb: 3, px: 0 }}>
                    <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: question.answer }} />
                  </AccordionDetails>
                </Accordion>
              ))}
            {step === 'BƯỚC 2: ĐIỀN THÔNG TIN CÁ NHÂN' && <ApplyForm />}
          </AccordionDetails>
        </Accordion>
      ))}
    </Section>
  );
}
