/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Statistics of a teacher
 * @export
 * @interface TeacherStatistic
 */
export interface TeacherStatistic {
    /**
     * 
     * @type {string}
     * @memberof TeacherStatistic
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherStatistic
     */
    teacherId: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherStatistic
     */
    totalStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherStatistic
     */
    totalClazzes?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherStatistic
     */
    confirmedPayments?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherStatistic
     */
    totalRevenue?: number;
}

/**
 * Check if a given object implements the TeacherStatistic interface.
 */
export function instanceOfTeacherStatistic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "teacherId" in value;

    return isInstance;
}

export function TeacherStatisticFromJSON(json: any): TeacherStatistic {
    return TeacherStatisticFromJSONTyped(json, false);
}

export function TeacherStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeacherStatistic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'teacherId': json['teacherId'],
        'totalStudents': !exists(json, 'totalStudents') ? undefined : json['totalStudents'],
        'totalClazzes': !exists(json, 'totalClazzes') ? undefined : json['totalClazzes'],
        'confirmedPayments': !exists(json, 'confirmedPayments') ? undefined : json['confirmedPayments'],
        'totalRevenue': !exists(json, 'totalRevenue') ? undefined : json['totalRevenue'],
    };
}

export function TeacherStatisticToJSON(value?: TeacherStatistic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orgId': value.orgId,
        'teacherId': value.teacherId,
        'totalStudents': value.totalStudents,
        'totalClazzes': value.totalClazzes,
        'confirmedPayments': value.confirmedPayments,
        'totalRevenue': value.totalRevenue,
    };
}

