import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ParentProfile } from 'student/api/models';
import { User } from 'firebase/auth';

export interface AuthState {
  user: User | null;
  userFetched: boolean; // init fetched
  userLoading: boolean;

  parentProfile: ParentProfile | null;
  parentProfileFetched: boolean;
  parentProfileLoading: boolean;
}

const initialState: AuthState = {
  user: null,
  userFetched: false,
  userLoading: false,

  parentProfile: null,
  parentProfileFetched: false,
  parentProfileLoading: false,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    changeAuthStateReset: (state) => {
      state.user = null;
      state.userFetched = false;
      state.userLoading = false;
      state.parentProfile = null;
      state.parentProfileFetched = false;
      state.parentProfileLoading = false;
    },
    changeAuthStateToLogout: (state) => {
      state.user = null;
      state.userFetched = true;
      state.userLoading = false;
      state.parentProfile = null;
      state.parentProfileFetched = true;
      state.parentProfileLoading = false;
    },
    changeUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    changeUserFetched: (state, action: PayloadAction<boolean>) => {
      state.userFetched = action.payload;
    },
    changeUserLoading: (state, action: PayloadAction<boolean>) => {
      state.userLoading = action.payload;
    },
    changeParentProfile: (state, action: PayloadAction<ParentProfile | null>) => {
      state.parentProfile = action.payload;
    },
    changeParentProfileFetched: (state, action: PayloadAction<boolean>) => {
      state.parentProfileFetched = action.payload;
    },
    changeParentProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.parentProfileLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeAuthStateReset,
  changeAuthStateToLogout,
  changeUser,
  changeUserFetched,
  changeUserLoading,
  changeParentProfile,
  changeParentProfileFetched,
  changeParentProfileLoading,
} = authSlice.actions;

export const authState = authSlice.reducer;
