// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { ENV } from 'common/config/env';
import { SITE } from './site';
import { SiteEnum } from 'common/types';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const studentFirebaseConfig = {
  development: {
    apiKey: 'AIzaSyDnne5k0W0BdvUwvFDA_U3odCit7yLdtAQ',
    authDomain: 'amusingo-dev.firebaseapp.com',
    projectId: 'amusingo-dev',
    storageBucket: 'amusingo-dev.appspot.com',
    messagingSenderId: '70979652093',
    appId: '1:70979652093:web:3f4181edcb57754b6ef194',
    measurementId: 'G-MGH58H57JB',
  },
  production: {
    apiKey: 'AIzaSyBuTl9gV2ucoTF4OR5G79sjq3tb7lHpBU0',
    authDomain: 'amusingo-all.firebaseapp.com',
    projectId: 'amusingo-all',
    storageBucket: 'amusingo-all.appspot.com',
    messagingSenderId: '170882138357',
    appId: '1:170882138357:web:59ac0b20565a1a1ac6c5bc',
    measurementId: 'G-0W2M14MNWV',
  },
}[ENV];
const teacherFirebaseConfig = {
  development: {
    apiKey: 'AIzaSyCfx7NCoCmDN9TJQRV7qb6JnyujvNSrwEI',
    authDomain: 'amusingo-teacher-dev-1.firebaseapp.com',
    projectId: 'amusingo-teacher-dev-1',
    storageBucket: 'amusingo-teacher-dev-1.appspot.com',
    messagingSenderId: '198244264386',
    appId: '1:198244264386:web:a9c34ba5b8e9dd3de10e5b',
    measurementId: 'G-5QBXBKGPY8',
  },
  production: {
    apiKey: 'AIzaSyByUtoATNKxSxaPRlIDcnMacGxhmHn7WGY',
    authDomain: 'amusingo-teacher.firebaseapp.com',
    projectId: 'amusingo-teacher',
    storageBucket: 'amusingo-teacher.appspot.com',
    messagingSenderId: '183012435132',
    appId: '1:183012435132:web:455dae7887ffd29ab9dcb9',
    measurementId: 'G-K8F9NYJGJX',
  },
}[ENV];
const firebaseConfig = {
  [SiteEnum.Student]: studentFirebaseConfig,
  [SiteEnum.Teacher]: teacherFirebaseConfig,
}[SITE];

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
