/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeacherProfileAllOf
 */
export interface TeacherProfileAllOf {
    /**
     * 
     * @type {string}
     * @memberof TeacherProfileAllOf
     */
    teacherId?: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherProfileAllOf
     */
    rate?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherProfileAllOf
     */
    numReviews?: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfileAllOf
     */
    accountCreationStatus?: TeacherProfileAllOfAccountCreationStatusEnum;
}


/**
 * @export
 */
export const TeacherProfileAllOfAccountCreationStatusEnum = {
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    RequestChanges: 'REQUEST_CHANGES',
    Rejected: 'REJECTED'
} as const;
export type TeacherProfileAllOfAccountCreationStatusEnum = typeof TeacherProfileAllOfAccountCreationStatusEnum[keyof typeof TeacherProfileAllOfAccountCreationStatusEnum];


/**
 * Check if a given object implements the TeacherProfileAllOf interface.
 */
export function instanceOfTeacherProfileAllOf(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TeacherProfileAllOfFromJSON(json: any): TeacherProfileAllOf {
    return TeacherProfileAllOfFromJSONTyped(json, false);
}

export function TeacherProfileAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeacherProfileAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teacherId': !exists(json, 'teacherId') ? undefined : json['teacherId'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'numReviews': !exists(json, 'numReviews') ? undefined : json['numReviews'],
        'accountCreationStatus': !exists(json, 'accountCreationStatus') ? undefined : json['accountCreationStatus'],
    };
}

export function TeacherProfileAllOfToJSON(value?: TeacherProfileAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teacherId': value.teacherId,
        'rate': value.rate,
        'numReviews': value.numReviews,
        'accountCreationStatus': value.accountCreationStatus,
    };
}

