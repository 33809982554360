/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Favorite } from './Favorite';
import {
    FavoriteFromJSON,
    FavoriteFromJSONTyped,
    FavoriteToJSON,
} from './Favorite';

/**
 * A list of favorite class
 * @export
 * @interface FavoriteList
 */
export interface FavoriteList {
    /**
     * 
     * @type {Array<Favorite>}
     * @memberof FavoriteList
     */
    items: Array<Favorite>;
}

/**
 * Check if a given object implements the FavoriteList interface.
 */
export function instanceOfFavoriteList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function FavoriteListFromJSON(json: any): FavoriteList {
    return FavoriteListFromJSONTyped(json, false);
}

export function FavoriteListFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(FavoriteFromJSON)),
    };
}

export function FavoriteListToJSON(value?: FavoriteList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(FavoriteToJSON)),
    };
}

