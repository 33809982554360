import React from 'react';
import { Grid, Tooltip } from '@mui/material';

import { useSelector } from 'student/store';
import { Category, Clazz } from 'student/api';
import { selectCategoryState, selectCourseState, selectCoursesByCategoryId } from 'student/features';
import './style.scss';
import { Section } from 'student/layout';
import Button from 'common/components/Button';
import Link from 'common/components/Link/Link';
import { SectionTitle } from 'common/components/Text';
import { routes } from 'student/Routes';

const ListClassByCategory = (props: { category: Category }) => {
  const { category } = props;
  const courses = useSelector(selectCoursesByCategoryId(`${category.categoryId}`));

  if (courses.length === 0) {
    return null;
  }

  return (
    <Grid key={category.categoryId} item xs={12} md={6} lg={6} className="mb-5">
      <h6 className="title">{category.categoryName}</h6>

      <Grid
        container
        spacing={2}
        sx={{
          xs: { maxWidth: '100%' },
          maxWidth: '100%',
        }}
      >
        {courses.slice(0, 3).map((course: Clazz) => (
          <Grid item key={course.classId} sx={{ ml: -1, paddingTop: '0 !important', width: '100%' }}>
            <Link to={routes.PageCourse.path.replace(':courseId', `${course.classId}`)}>
              {/* <Tooltip title={course.className}> */}
              <Button
                variant="pure-text"
                sx={{
                  textAlign: 'left',
                  display: 'block',
                  pl: 1,
                  pY: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  justifyContent: 'left',
                }}
                size={'small'}
              >
                {course.className}
              </Button>
              {/* </Tooltip> */}
            </Link>
          </Grid>
        ))}
        <Grid item key={'view-more'} sx={{ ml: -1, paddingTop: '0 !important' }}>
          <Link to={`${routes.PageCourses.path}?categoryId=${category.categoryId}`}>
            <Button
              variant="pure-text"
              sx={{
                textAlign: 'left',
                pl: 1,
                pY: 0,
                textDecoration: 'underline !important',
              }}
              size={'small'}
            >
              Xem thêm
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CategoryClasses = () => {
  const categoryState = useSelector(selectCategoryState);

  return (
    <Section className="themed-classes-container">
      <SectionTitle align="center">Khóa học theo chủ đề</SectionTitle>

      <div className="details">
        <Grid container>
          {categoryState.items.map((category: Category) => (
            <ListClassByCategory key={category.categoryId} category={category} />
          ))}
        </Grid>
      </div>
    </Section>
  );
};

export default CategoryClasses;
