import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Enrollment } from 'student/api/models';

interface EnrollmentArrayState {
  items: Enrollment[];
  loading: boolean;
  fetched: boolean;
}

export interface EnrollmentState {
  allEnrollments: EnrollmentArrayState;
}

const initialState: EnrollmentState = {
  allEnrollments: {
    items: [],
    loading: true,
    fetched: false,
  },
};

export const enrollmentSlice = createSlice({
  name: 'enrollmentSlice',
  initialState,
  reducers: {
    changeAllEnrollments: (state, action: PayloadAction<Partial<EnrollmentArrayState>>) => {
      state.allEnrollments = { ...state.allEnrollments, ...action.payload };
    },
  },
});

export const { changeAllEnrollments } = enrollmentSlice.actions;

export const enrollmentState = enrollmentSlice.reducer;
