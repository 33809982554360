import React from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import A from 'common/assets';
import CourseList from 'teacher/features/courses/components/CourseList';
import { useResponsive, useCoursesByTeacherId } from 'teacher/hooks';

import { dispatch, useSelector } from 'teacher/store';
import { changeCourseByTeacherId, selectCoursesByTeacherId } from 'teacher/features/courses';
import { CoursesFilter } from 'teacher/features/courses/types';
import { selectAuthState } from '../features/auth';
import CourseListFilter from 'teacher/features/courses/components/CoursesFilter/CourseFilter';
import { filterCourses } from '../features/courses/utils';

export default function PageMyCourses() {
  const { isMobile } = useResponsive();

  const authState = useSelector(selectAuthState);

  const teacherCourses = useSelector(selectCoursesByTeacherId);
  const filteredCourses = {
    ...teacherCourses,
    items: filterCourses(teacherCourses.items, teacherCourses.selectedFilter),
  };

  const handleChangeSelectedFilter = (selectedFilter: CoursesFilter) => {
    dispatch(changeCourseByTeacherId({ selectedFilter }));
    window.scrollTo(0, 0);
  };

  const { coursesByTeacher, loading } = useCoursesByTeacherId(authState.user?.uid);

  React.useEffect(() => {
    dispatch(changeCourseByTeacherId({ items: coursesByTeacher, loading }));
  }, [coursesByTeacher]);

  return (
    <Container fixed>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          margin: '25px 0 25px',
        }}
      >
        <Box
          sx={{
            color: A.colors.primary,
            fontSize: 30,
            fontFamily: A.fonts.bold,
            fontWeight: '900',
            marginBottom: isMobile ? '20px' : 0,
          }}
        >
          Lớp học của tôi
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: isMobile ? 2 : 5,
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Button variant="contained" sx={{ fontFamily: A.fonts.bold, p: '6px 32px', maxWidth: '280px' }}>
            Tạo mới
          </Button>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                fontFamily: A.fonts.bold,
                p: '10px 16px',
                border: `1px solid ${A.colors.secondary}`,
                borderTopLeftRadius: '24px',
                borderBottomLeftRadius: '24px',
                cursor: 'pointer',
              }}
            >
              Danh sách
            </Box>
            <Box
              sx={{
                fontFamily: A.fonts.bold,
                p: '10px 16px',
                border: `1px solid ${A.colors.secondary}`,
                borderTopRightRadius: '24px',
                borderBottomRightRadius: '24px',
                cursor: 'pointer',
              }}
            >
              Lịch hàng tuần
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container spacing={5}>
        <Grid item lg={3} xs={12} sx={{ display: { sm: 'block', xs: 'none' } }}>
          <CourseListFilter
            filter={teacherCourses.filter}
            selectedFilter={teacherCourses.selectedFilter}
            handleChangeSelectedFilter={handleChangeSelectedFilter}
          />
        </Grid>
        <Grid item lg={9} xs={12}>
          <CourseList courses={filteredCourses} />
        </Grid>
      </Grid>
    </Container>
  );
}
