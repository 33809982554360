import { Clazz as Course, AgeRange } from 'student/api/models';

interface AgeRangeOption {
  label: string;
  value: AgeRange;
}
interface PriceOption {
  label: string;
  value: {
    minPrice: number;
    maxPrice: number;
  };
}
interface WeekDayOption {
  label: string;
  value: number;
}
interface TimeSlotOption {
  label: string;
  value: {
    minTimeSlot: number;
    maxTimeSlot: number;
  };
}
export type CourseFilterOption = AgeRangeOption | PriceOption | WeekDayOption | TimeSlotOption;

export interface CoursesFilter extends Course {
  ageRangeOptions: AgeRangeOption[];
  priceOptions: PriceOption[];
  weekDayOptions: WeekDayOption[];
  timeSlotOptions: TimeSlotOption[];
}

export const fullCoursesFilter: CoursesFilter = {
  ageRangeOptions: [
    {
      label: '3-5',
      value: {
        minAge: 3,
        maxAge: 5,
      },
    },
    {
      label: '6-10',
      value: {
        minAge: 6,
        maxAge: 10,
      },
    },
    {
      label: '11-15',
      value: {
        minAge: 11,
        maxAge: 15,
      },
    },
    {
      label: '16-18',
      value: {
        minAge: 16,
        maxAge: 18,
      },
    },
  ],
  priceOptions: [
    {
      label: '< 100.000 VND',
      value: {
        minPrice: -100,
        maxPrice: 100000,
      },
    },
    {
      label: '100.000 - 150.000 VND',
      value: {
        minPrice: 100000,
        maxPrice: 150000,
      },
    },
    {
      label: '150.000 - 200.000 VND',
      value: {
        minPrice: 150000,
        maxPrice: 200000,
      },
    },
  ],
  weekDayOptions: [
    {
      label: 'T2',
      value: 2,
    },
    {
      label: 'T3',
      value: 3,
    },
    {
      label: 'T4',
      value: 4,
    },
    {
      label: 'T5',
      value: 5,
    },
    {
      label: 'T6',
      value: 6,
    },
    {
      label: 'T7',
      value: 7,
    },
    {
      label: 'CN',
      value: 8,
    },
  ],
  timeSlotOptions: [
    {
      label: 'Buổi sáng (7:00-12:00)',
      value: {
        minTimeSlot: 7,
        maxTimeSlot: 12,
      },
    },
    {
      label: 'Buổi chiều (12:00-19:00)',
      value: {
        minTimeSlot: 12,
        maxTimeSlot: 19,
      },
    },
    {
      label: 'Buổi tối (19:00-23:00)',
      value: {
        minTimeSlot: 19,
        maxTimeSlot: 23,
      },
    },
  ],
};

export const initCoursesFilter: CoursesFilter = {
  ageRangeOptions: [],
  priceOptions: [],
  weekDayOptions: [],
  timeSlotOptions: [],
};
