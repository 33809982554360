import * as React from 'react';
import { Box, Card, CardContent, CardMedia, Rating, Typography, Stack, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import Loading from 'common/components/Loading';
import { useSelector, RootState } from 'teacher/store';
import { useNavigate } from 'react-router';
import { routes } from 'teacher/Routes';
import A from 'common/assets';
import Link from 'common/components/Link';
import { toast } from 'react-toastify';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useResponsive } from 'teacher/hooks';

const RootStyled = styled('div')(({ theme }) => ({
  '& .card-main': {
    position: 'relative',
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '30px',
    gap: theme.spacing(3),
    pt: theme.spacing(1),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      height: 'auto',
      width: 'auto',
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    '& .update-btn': {
      position: 'absolute',
      top: '20px',
      right: '20px',
    },
  },
}));

export default function ProfileSectionBasicInfo() {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { user, teacherProfile, teacherProfileLoading, userLoading } = useSelector(
    (state: RootState) => state.authState
  );

  React.useEffect(() => {
    if (!user && !userLoading) {
      toast.warning('Yêu cầu đăng nhập!');
      navigate(routes.PageAuth.path);
    }
  }, [user, userLoading]);

  if (teacherProfileLoading || userLoading) {
    return <Loading />;
  }

  return (
    <RootStyled>
      <Card className="card-main">
        <CardContent
          sx={{
            pb: '48px !important',
            width: '100%',
            xs: { p: 1 },
            sm: { p: 2 },
            md: { p: 3 },
            lg: { p: 4 },
            xl: { p: 5 },
          }}
        >
          <Typography variant="h5" mb={3}>
            Thông tin tài khoản
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <CardMedia
                component="img"
                image={teacherProfile?.avatar ? teacherProfile.avatar : A.images.teacherIcon}
                alt="Live from space album cover"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { md: 'column', xs: 'row' },
                  width: 1,
                }}
              >
                <CardContent sx={{ flex: '1 0 auto', py: 0 + '!important', width: '100%' }}>
                  <Typography variant="h5" color="primary">
                    Giáo viên: {teacherProfile?.name}
                  </Typography>
                  <Stack direction="row" alignItems="center" my={{ xl: 2, xs: 1 }}>
                    <Rating
                      readOnly
                      name="hover-feedback"
                      value={teacherProfile?.rate === -1 ? 0 : teacherProfile?.rate}
                      precision={0.1}
                    />
                  </Stack>
                  <br />
                  <Typography variant="subtitle1" sx={{ span: { color: 'primary.main' } }}>
                    Email: {teacherProfile?.email}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ span: { color: 'primary.main' } }}>
                    Số điện thoại: {teacherProfile?.phone}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ span: { color: 'primary.main' } }}>
                    Giới thiệu: {teacherProfile?.introduction}
                  </Typography>
                  <br />
                  <Typography variant="subtitle1" sx={{ span: { color: 'primary.main' } }}>
                    Lĩnh vực giảng dạy: {teacherProfile?.teachingFields?.join(', ')}
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={3} mt={{ xl: 2, md: 2, xs: 0.5 }}></Stack>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Link to={routes.PageProfileUpdate.path}>
          <Button variant="contained" className="update-btn">
            {isMobile ? <EditRoundedIcon /> : 'Chỉnh sửa thông tin'}
          </Button>
        </Link>
      </Card>
    </RootStyled>
  );
}
