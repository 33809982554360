/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClazzStatistic,
  OrgStatistic,
  TeacherStatistic,
} from '../models';
import {
    ClazzStatisticFromJSON,
    ClazzStatisticToJSON,
    OrgStatisticFromJSON,
    OrgStatisticToJSON,
    TeacherStatisticFromJSON,
    TeacherStatisticToJSON,
} from '../models';

export interface GetClassStatisticRequest {
    teacherId: string;
    classId: string;
    orgId?: string;
    startDate?: string;
    endDate?: string;
}

export interface GetOrgStatisticRequest {
    orgId: string;
    startDate?: string;
    endDate?: string;
}

export interface GetTeacherStatisticRequest {
    teacherId: string;
    orgId?: string;
    startDate?: string;
    endDate?: string;
}

/**
 * 
 */
export class StatisticApi extends runtime.BaseAPI {

    /**
     * Get statistic of a class
     */
    async getClassStatisticRaw(requestParameters: GetClassStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzStatistic>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getClassStatistic.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getClassStatistic.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/statistics/teachers/{teacherId}/classes/{classId}/info`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzStatisticFromJSON(jsonValue));
    }

    /**
     * Get statistic of a class
     */
    async getClassStatistic(requestParameters: GetClassStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzStatistic> {
        const response = await this.getClassStatisticRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statistic of an organization
     */
    async getOrgStatisticRaw(requestParameters: GetOrgStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgStatistic>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrgStatistic.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/statistics/orgs/{orgId}/info`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgStatisticFromJSON(jsonValue));
    }

    /**
     * Get statistic of an organization
     */
    async getOrgStatistic(requestParameters: GetOrgStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgStatistic> {
        const response = await this.getOrgStatisticRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statistic of a teacher
     */
    async getTeacherStatisticRaw(requestParameters: GetTeacherStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeacherStatistic>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getTeacherStatistic.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/statistics/teachers/{teacherId}/info`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeacherStatisticFromJSON(jsonValue));
    }

    /**
     * Get statistic of a teacher
     */
    async getTeacherStatistic(requestParameters: GetTeacherStatisticRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeacherStatistic> {
        const response = await this.getTeacherStatisticRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
