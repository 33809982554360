/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Category,
  CategoryList,
  CategoryPostRequestParams,
} from '../models';
import {
    CategoryFromJSON,
    CategoryToJSON,
    CategoryListFromJSON,
    CategoryListToJSON,
    CategoryPostRequestParamsFromJSON,
    CategoryPostRequestParamsToJSON,
} from '../models';

export interface CreateCategoryRequest {
    categoryPostRequestParams: CategoryPostRequestParams;
}

export interface DeleteCategoryRequest {
    categoryId: string;
}

export interface GetCategoryRequest {
    categoryId: string;
}

/**
 * 
 */
export class CategoryApi extends runtime.BaseAPI {

    /**
     * Create a new category
     */
    async createCategoryRaw(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryPostRequestParams === null || requestParameters.categoryPostRequestParams === undefined) {
            throw new runtime.RequiredError('categoryPostRequestParams','Required parameter requestParameters.categoryPostRequestParams was null or undefined when calling createCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CategoryPostRequestParamsToJSON(requestParameters.categoryPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     * Create a new category
     */
    async createCategory(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Category> {
        const response = await this.createCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete a category
     */
    async deleteCategoryRaw(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     * delete a category
     */
    async deleteCategory(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Category> {
        const response = await this.deleteCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all categories as a list
     */
    async getCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryListFromJSON(jsonValue));
    }

    /**
     * Get all categories as a list
     */
    async getCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryList> {
        const response = await this.getCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * get details of a category based on categoryId
     */
    async getCategoryRaw(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     * get details of a category based on categoryId
     */
    async getCategory(requestParameters: GetCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Category> {
        const response = await this.getCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
