import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Section } from 'student/layout';
import { SectionTitle } from 'common/components/Text';
import Loading from 'common/components/Loading';
import TeacherCard from 'common/components/Cards/TeacherCard';
import { useTeachers } from 'student/hooks';

const Teachers = () => {
  const theme = useTheme();

  const { teachers } = useTeachers();

  if (teachers.length === 0) {
    return <Loading />;
  }

  const customTeachers = teachers.filter(
    (teacher) =>
      teacher.teacherId === 'TC00003' ||
      teacher.teacherId === 'TC00006' ||
      teacher.teacherId === 'TC00002' ||
      teacher.teacherId === 'TC00004'
  );

  const customTeachers2 = [];
  customTeachers2.push(customTeachers.find((teacher) => teacher.teacherId === 'TC00003'));
  customTeachers2.push(customTeachers.find((teacher) => teacher.teacherId === 'TC00006'));
  customTeachers2.push(customTeachers.find((teacher) => teacher.teacherId === 'TC00002'));
  customTeachers2.push(customTeachers.find((teacher) => teacher.teacherId === 'TC00004'));

  return (
    <Section>
      <SectionTitle align="center" sx={{ paddingBottom: theme.spacing(8) }}>
        Giáo viên nổi bật
      </SectionTitle>

      <Grid container spacing={4} className="justify-content-center" sx={{ paddingBottom: theme.spacing(2) }}>
        {customTeachers2.slice(0, 4).map((teacher, idx) => (
          <Grid item md={3} key={idx}>
            <TeacherCard teacher={teacher} />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};

export default Teachers;
