import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';

import {
  ClazzApi,
  ClazzPutRequestParams,
  GetUploadUrlOperationRequest,
  IndividualTeacherApplicationApi,
} from 'teacher/api';
import { useNavigate } from 'react-router';
import { routes } from 'teacher/Routes';
import { normalizeImageName, uploadImage } from 'common/utils';
import FieldLabel from '../../../common/components/Field/FormFieldLabel';
import { useSelector } from 'react-redux';
import { selectAuthState } from 'teacher/features';
import { useResponsive } from 'teacher/hooks';

const frequencyRange = [1, 2, 3, 4, 5, 6, 7];
const timeRange = [30, 45, 60, 75, 90, 105, 120];

export default function CourseUpdateForm(props: { clazzDetail: any }) {
  const { handleSubmit } = useForm();
  const navigate = useNavigate();
  const isMobile = useResponsive();

  const teacher = useSelector(selectAuthState).teacherProfile;
  const [loading, setLoading] = React.useState<boolean>(false);

  const [imageUpload, setImageUpload] = React.useState<File>();
  const [imagePreviewUrl, setImagePreview] = React.useState<string>();
  const [videoPreviewUrl, setVideoPreview] = React.useState<string>();
  const [videoUpload, setVideoUpload] = React.useState<File>();
  const [updateClazzData, setUpdateClazzData] = React.useState<ClazzPutRequestParams>(props.clazzDetail);
  const spaces = { mb: 2, mt: 1 };

  const onChangeNewClazzData = (event: any) => {
    if (event.target.type === 'number') {
      setUpdateClazzData({
        ...updateClazzData,
        [event.target.name]: parseInt(event.target.value),
      });
    } else {
      setUpdateClazzData({
        ...updateClazzData,
        [event.target.name]: event.target.value,
      });
    }
  };
  const onUploadImage = (e: any) => {
    const file: any = e.target.files[0];
    setImageUpload({ ...file, name: 'thumbnail image' });
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const onUploadVideo = (e: any) => {
    const file: any = e.target.files[0];
    setVideoUpload({ ...file, name: 'introduction_video.' });
    setVideoPreview(URL.createObjectURL(e.target.files[0]));
  };

  const onSubmit = (data: any) => {
    const submit = new ClazzApi();
    const UploadUrlRequest: GetUploadUrlOperationRequest = { getUploadUrlRequest: { email: '', filename: '' } };
    let CallApiUploadUrl: any;
    setLoading(true);
    if (teacher && teacher.teacherId) {
      // //an api is called to upload the avatar
      if (imageUpload) {
        UploadUrlRequest.getUploadUrlRequest.filename = normalizeImageName(imageUpload.name, '', imageUpload.name);
        UploadUrlRequest.getUploadUrlRequest.email = teacher.email;
        CallApiUploadUrl = new IndividualTeacherApplicationApi().getUploadUrl(UploadUrlRequest).then(async (value) => {
          const uploadUrl: string | undefined = value.uploadUrl;
          const uploaded = await uploadImage(imageUpload, uploadUrl || '');
          if (!uploaded) {
            toast.error('Lỗi upload ảnh.');
            setLoading(false);
          }
          if (uploadUrl) {
            updateClazzData.thumbnailImage = uploadUrl;
          }
        });
      }
      if (videoUpload) {
        // an api is called to upload the description video
        UploadUrlRequest.getUploadUrlRequest.filename = normalizeImageName(videoUpload.name, '', videoUpload.name);
        UploadUrlRequest.getUploadUrlRequest.email = teacher.email;
        CallApiUploadUrl = new IndividualTeacherApplicationApi().getUploadUrl(UploadUrlRequest).then(async (value) => {
          const uploadUrl: string | undefined = value.uploadUrl;
          const uploaded = await uploadImage(videoUpload, uploadUrl || '');
          if (!uploaded) {
            toast.error('Lỗi upload video trong form.');
            setLoading(false);
          }
          updateClazzData.introductionVideo = uploadUrl;
        });
      }
      //an api is called to post a update  course form
      submit
        .updateClazz({
          orgId: teacher?.organizationId,
          teacherId: teacher?.teacherId,
          clazzId: updateClazzData.clazzId,
          clazzPutRequestParams: updateClazzData,
        })
        .then((res) => {
          toast.success('Update thông tin khóa học thành công');
          setLoading(false);
          navigate(routes.PageHome.path);
        })
        .catch((err) => {
          //if the response is an error
          console.error(err);
          setLoading(false);
        });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mb: 1 }}>
      <FieldLabel text="TÊN KHOÁ HỌC" isRequired={true} />
      <TextField
        margin="normal"
        type="text"
        required
        fullWidth
        label=""
        value={updateClazzData.clazzName}
        name="clazzName"
        onChange={onChangeNewClazzData}
        sx={spaces}
      />
      <FieldLabel text="LĨNH VỰC" isRequired={true} />
      <RadioGroup sx={spaces}>
        <FormControlLabel
          control={<Radio />}
          name="categoryId"
          label="Kỹ Năng"
          value="SKILL"
          onChange={onChangeNewClazzData}
          checked={updateClazzData.categoryId === 'SKILL' && true}
        />
        <FormControlLabel
          control={<Radio />}
          name="categoryId"
          label="Ngoại Ngữ"
          value="LANG"
          onChange={onChangeNewClazzData}
          checked={updateClazzData.categoryId === 'LANG' && true}
        />
        <FormControlLabel
          control={<Radio />}
          name="categoryIdSTEM"
          label="Lập Trình/STEM"
          value="STEM"
          onChange={onChangeNewClazzData}
          checked={updateClazzData.categoryId === 'STEM' && true}
        />
        <FormControlLabel
          control={<Radio />}
          name="categoryId"
          label="Nghệ Thuật"
          value="ART"
          onChange={onChangeNewClazzData}
          checked={updateClazzData.categoryId === 'ART' && true}
        />
        <FormControlLabel
          control={<Radio />}
          name="categoryId"
          label="Kinh doanh"
          value="BIZ"
          onChange={onChangeNewClazzData}
          checked={updateClazzData.categoryId === 'BIZ' && true}
        />
      </RadioGroup>

      <FieldLabel
        text="ẢNH BÌA"
        isRequired={true}
        desc="
        Hãy chọn một hình ảnh hoặc video hấp dẫn, liên quan đến khoá học, phù hợp với lứa tuổi học viên và rộng ít nhất 600 pixel. 
        <br/>
        Lưu ý:<br/>
        - Hãy đảm bảo rằng bạn được phép sử dụng hình ảnh của mình (vui lòng không sử dụng hình ảnh có bản quyền).<br/>"
      />
      <Box>
        {updateClazzData.thumbnailImage ? (
          <Card sx={{ width: isMobile ? 300 : 264, my: 2 }}>
            <CardContent sx={{ ':last-child': { pb: 0 } }}>
              <CardMedia
                component="img"
                image={imageUpload ? imagePreviewUrl : updateClazzData.thumbnailImage}
              ></CardMedia>
              <Button variant="outlined" component="label" sx={{ my: 2 }}>
                Thay đổi ảnh
                <input hidden accept="image/*" type="file" onChange={onUploadImage} />
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Button variant="outlined" component="label" sx={{ my: 2 }}>
            Upload ảnh bìa
            <input hidden accept="image/*" type="file" onChange={onUploadImage} />
          </Button>
        )}
        {/* {imageUpload && imageUpload.name} */}
      </Box>

      <FieldLabel
        text="VIDEO GIỚI THIỆU KHOÁ HỌC "
        isRequired={false}
        desc="(được khuyến nghị)<br/>
            Phụ huynh và học sinh có xu hướng thích xem video giới thiệu trước tiên rồi mới cân nhắc đọc kỹ hơn về các nội dung được mô tả bằng lời. Do đó, hãy tạo một video ngắn (khoảng 30-90 giây) để giúp phụ huynh và học sinh nhanh chóng hiểu về bạn cũng như về khoá học.
            <br/><br/>
            Một số mẹo tạo video giới thiệu chân thực và hấp dẫn:<br/><br/>
            <u><i>1. Bạn không cần phải là người am hiểu công nghệ hoặc là chuyên gia sản xuất video để tạo ra một video hấp dẫn</i></u><br/>
            Bạn có thể quay một video ngắn chỉ với chiếc điện thoại hoặc webcam máy tính! Điều quan trọng nhất là làm nổi bật một cách rõ ràng và hấp dẫn bạn là ai và lớp học của bạn dạy về điều gì.<br/><br/>

            <u><i>2. Giới thiệu những gì học viên có thể tạo ra hoặc thực hiện trong lớp học của bạn</i></u><br/>
            Nếu người học tạo hoặc thực hiện điều gì đó trong lớp học của bạn, thì video là một cách tuyệt vời để thể hiện điều đó! Phụ huynh và học sinh thích xem những gì họ có thể tạo ra khi tham gia lớp học của bạn.<br/><br/>

            <u><i>3. Sử dụng cách diễn đạt gần gũi, dễ hiểu như đang nói chuyện trực tiếp với người học</i></u><br/>
            Chúng tôi thường nghe từ các gia đình kể rằng, bố mẹ sẽ tìm kiếm và lưu các lớp học có vẻ thú vị trước, sau đó để con họ xem các video giới thiệu để quyết định nên chọn khoá học nào. Do đó, video này chính là cơ hội để bạn nói chuyện trực tiếp với những người học đang xem xét lớp học của bạn. Hãy cân nhắc sử dụng từ ngữ và giọng điệu phù hợp với nhóm tuổi mục tiêu bạn đang hướng tới.<br/><br/>"
      />
      <Box>
        {/* <Button variant="outlined" component="label" sx={{ mb: 2 }}>
          Upload Video
          <input hidden accept="video/*" type="file" onChange={onUploadVideo} />
        </Button>{' '}
        {videoUpload && videoUpload.name} */}
        {updateClazzData.introductionVideo ? (
          <Card sx={{ width: isMobile ? 300 : 264, my: 2 }}>
            <CardContent sx={{ ':last-child': { pb: 0 } }}>
              <CardMedia
                component="video"
                src={videoUpload ? videoPreviewUrl : updateClazzData.introductionVideo}
                itemType="video/*"
                controls
                // autoPlay={true}
              ></CardMedia>
              <Button variant="outlined" component="label" sx={{ my: 2 }}>
                Thay đổi video
                <input hidden accept="video/*" type="file" onChange={onUploadVideo} />
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Button variant="outlined" component="label" sx={{ my: 2 }}>
            Upload video
            <input hidden accept="video/*" type="file" onChange={onUploadVideo} />
          </Button>
        )}
      </Box>

      <FieldLabel
        text="GIỚI THIỆU KHOÁ HỌC"
        isRequired={true}
        desc="Hãy đảm bảo phần mô tả khóa học của bạn (không quá 200 chữ) đã tóm tắt phải rõ ràng và dễ hiểu, giúp phụ huynh và học sinh nhanh chóng hiểu được bạn đang muốn dạy gì. Nếu bạn chưa biết nên viết câu giới thiệu như thế nào, hãy tham khảo một số ví dụ <a href='https://amusingo.com/faq/teacher/5'>ở đây.</a>"
      />
      <TextField
        margin="normal"
        multiline
        rows={5}
        type="text"
        required
        fullWidth
        label=""
        value={updateClazzData.description}
        name="description"
        onChange={onChangeNewClazzData}
        sx={{ mb: 2 }}
      />
      <FieldLabel
        text="ĐỘ DÀI KHOÁ HỌC"
        isRequired={true}
        desc="Hãy chỉnh sửa nếu bạn cảm thấy độ dài khóa học chưa hợp lí, chúng tôi khuyến khích các khoá học chỉ nên kéo dài từ 4-12 tuần. Nếu bạn cảm thấy khoá học của bạn nên ngắn hơn 4 tuần hoặc dài hơn 12 tuần, hãy trao đổi với bộ phận chăm sóc khách hàng của chúng tôi.<br/>"
      />
      <TextField
        margin="normal"
        type="number"
        required
        fullWidth
        // label=""
        inputProps={{ inputProps: { min: 1, max: 15, step: 1 } }}
        value={updateClazzData.numLessons}
        name="numLessons"
        onChange={onChangeNewClazzData}
        sx={{ mb: 2 }}
      />
      <FieldLabel
        text="QUY MÔ LỚP HỌC"
        isRequired={true}
        desc="Hãy chỉnh sửa nếu bạn cảm thấy quy mô lớp học chưa hợp lí, chúng tôi khuyến khích các lớp học có quy mô nhỏ, đặc biệt là những lớp dành cho học sinh nhỏ tuổi. Cụ thể, với lứa tuổi 6-12 chỉ nên có tối đa 10 học viên/lớp, với lứa tuổi 12 trở lên chỉ nên có tối đa 15 học viên/lớp.<br/>"
      />
      <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        Từ{' '}
        <TextField
          margin="normal"
          type="number"
          required
          // label=""
          InputProps={{ inputProps: { min: 1, max: 15, step: 1 } }}
          value={updateClazzData.minStudents}
          name="minStudents"
          onChange={onChangeNewClazzData}
          sx={{ mb: 2, mx: 1 }}
        />
        học sinh đến
        <TextField
          margin="normal"
          type="number"
          required
          // label=""
          InputProps={{ inputProps: { min: 1, max: 15, step: 1 } }}
          value={updateClazzData.maxStudents}
          name="maxStudents"
          onChange={onChangeNewClazzData}
          sx={{ mb: 2, mx: 1 }}
        />
        học sinh
      </Box>
      <FieldLabel
        text="ĐỘ TUỔI HỌC VIÊN"
        isRequired={true}
        desc="Hãy chỉnh sửa nếu bạn cảm thấy độ tuổi học viên chưa hợp lí. Những lớp học có học viên gần độ tuổi với nhau mang lại đạt hiệu quả tốt hơn. Do đó, chúng tôi khuyên bạn nên thu hẹp phạm vi độ tuổi từ 3-4 tuổi và tối đa là 5 tuổi.
        <br/><br/>
        Lưu ý, trong giai đoạn hiện tại, chúng tôi muốn tập trung vào nhóm học sinh cấp 1-2 trước. Độ tuổi học viên sẽ được mở rộng trong tương lai."
      />
      <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        Từ{' '}
        <TextField
          margin="normal"
          type="number"
          required
          // label=""
          InputProps={{ inputProps: { min: 6, max: 15, step: 1 } }}
          value={updateClazzData.minAge}
          name="minAge"
          onChange={onChangeNewClazzData}
          sx={{ mb: 2, mx: 1 }}
        />
        tuổi đến
        <TextField
          margin="normal"
          type="number"
          required
          // label=""
          InputProps={{ inputProps: { min: 6, max: 15, step: 1 } }}
          value={updateClazzData.maxAge}
          name="maxAge"
          onChange={onChangeNewClazzData}
          sx={{ mb: 2, mx: 1 }}
        />
        tuổi
      </Box>
      <FieldLabel
        text="TẦN SUẤT TỔ CHỨC LỚP HỌC"
        isRequired={true}
        desc="Lớp học của bạn sẽ diễn ra mấy buổi một tuần? Tuỳ vào nội dung dạy của bạn, nhưng chúng tôi thường khuyến khích mỗi khoá học nên diễn ra 1-3 buổi/tuần.<br/>"
      />
      <Select
        name="frequencyPerWeek"
        value={updateClazzData.frequencyPerWeek}
        onChange={onChangeNewClazzData}
        fullWidth
        sx={{ mb: 2 }}
      >
        {frequencyRange.map((time) => (
          <MenuItem value={time} key={time}>
            {time} buổi/tuần
          </MenuItem>
        ))}
      </Select>
      <FieldLabel
        text="THỜI LƯỢNG MỖI BUỔI HỌC"
        isRequired={true}
        desc="Hãy chỉnh sửa nếu bạn cảm thấy thời lượng buổi học chưa hợp lí, tuỳ vào nội dung dạy của bạn, nhưng chúng tôi thường khuyến khích mỗi buổi học nên diễn ra trong khoảng 60-90 phút. Buổi học dưới 60 phút là quá ngắn, còn trên 120 phút là quá dài và không được khuyến nghị."
      />
      <Select
        name="lessonTime"
        value={updateClazzData.lessonTime}
        onChange={onChangeNewClazzData}
        fullWidth
        sx={{ mb: 2 }}
      >
        {timeRange.map((time) => (
          <MenuItem value={time} key={time}>
            {time} phút
          </MenuItem>
        ))}
      </Select>
      {/* <FieldLabel
        text="THỜI GIAN BẮT ĐẦU DỰ KIẾN"
        isRequired={true}
        desc="Hãy cho chúng tôi biết ngày tháng cụ thể bạn có thể bắt đầu dạy khoá học này. Chúng tôi sẽ căn cứ vào mốc thời gian này để tìm kiếm học sinh cho khoá học của bạn.<br/><br/>
       Lưu ý, nếu bạn không thể bắt đầu dạy khoá học sau khoảng 2 tuần kể từ khi khoá học được phê duyệt và đăng trên trang web của Amusingo, hãy cho chúng tôi biết càng sớm càng tốt."
      /> */}
      <FieldLabel
        text="HỌC PHÍ KHOÁ HỌC"
        isRequired={true}
        desc="Đây là mức học phí mà phụ huynh sẽ cần chi trả cho <b>mỗi học viên trong toàn bộ khóa học</b>. Tuỳ vào số lượng học viên/lớp và nội dung giảng dạy, nhưng chúng tôi khuyến khích học phí nên trong khoảng 50.000-250.000 VND/giờ/học viên.
        <br/><br/>
        Từ học phí, thu nhập của bạn cho mỗi lớp học được tính như sau: 
        Thu nhập/lớp = Học phí toàn khoá/học viên x Số lượng học viên/lớp x 70%"
      />
      <TextField
        margin="normal"
        type="number"
        required
        fullWidth
        label=""
        value={updateClazzData.price}
        name="price"
        onChange={onChangeNewClazzData}
        sx={{ mb: 2 }}
        InputProps={{
          inputProps: { min: 0, step: 1000 },
          endAdornment: <InputAdornment position="end">VND</InputAdornment>,
        }}
      />
      <FieldLabel
        text="NỘI DUNG KHOÁ HỌC"
        isRequired={true}
        desc="Phụ huynh thường cảm thấy tin tưởng và có xu hướng đăng ký nhiều hơn khi biết trước khoá học sẽ diễn ra như thế nào. Ngoài ra, Amusingo cũng sẽ dựa trên bản mô tả này để  <b>đánh giá và kiểm tra</b> nội dung khoá học. 
        <br/><br/>
        Do đó, hãy đảm bảo phần nội dung khoá học (tối thiểu 300 chữ) cung cấp những nội dung sau (không cần trả lời theo thứ tự mà hãy viết sao cho tự nhiên và dễ hiểu nhất)<br/>
        - Nội dung dự kiến cho mỗi buổi học<br/>
        - Cách bạn tổ chức lớp học, phong cách giảng dạy của bạn<br/>
        - Mức độ tương tác của người học với bạn và với nhau (đề cập đến các chi tiết cụ thể như: theo dõi bài giảng, phát biểu ý kiến, chơi trò chơi, thảo luận nhóm,...)<br/>
        - Các lưu ý khi tham gia khoá học (kiến thức cần thiết, dụng cụ học tập cần chuẩn bị, yêu cầu đối với học viên,...)<br/>
      
      Lưu ý: Tuỳ vào nội dung giảng dạy mà chúng tôi có thể sẽ yêu cầu bạn gửi thêm các bằng cấp liên quan."
      />
      <TextField
        margin="normal"
        multiline
        rows={5}
        type="text"
        required
        fullWidth
        label=""
        value={updateClazzData.syllabus}
        name="syllabus"
        onChange={onChangeNewClazzData}
        sx={{ mb: 2 }}
      />

      <Button
        type="submit"
        disabled={loading}
        fullWidth
        variant="contained"
        sx={{ pt: 1.5, pb: 1.5, mt: 4 }}
        startIcon={loading ? <CircularProgress size={20} /> : null}
      >
        Cập nhật thông tin khóa học
      </Button>
    </Box>
  );
}
