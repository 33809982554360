/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LessonScheduleTime } from './LessonScheduleTime';
import {
    LessonScheduleTimeFromJSON,
    LessonScheduleTimeFromJSONTyped,
    LessonScheduleTimeToJSON,
} from './LessonScheduleTime';

/**
 * A class schedule post request param
 * @export
 * @interface ClassSchedulePostRequest
 */
export interface ClassSchedulePostRequest {
    /**
     * 
     * @type {Array<LessonScheduleTime>}
     * @memberof ClassSchedulePostRequest
     */
    scheduleTime?: Array<LessonScheduleTime>;
}

/**
 * Check if a given object implements the ClassSchedulePostRequest interface.
 */
export function instanceOfClassSchedulePostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassSchedulePostRequestFromJSON(json: any): ClassSchedulePostRequest {
    return ClassSchedulePostRequestFromJSONTyped(json, false);
}

export function ClassSchedulePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSchedulePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheduleTime': !exists(json, 'scheduleTime') ? undefined : ((json['scheduleTime'] as Array<any>).map(LessonScheduleTimeFromJSON)),
    };
}

export function ClassSchedulePostRequestToJSON(value?: ClassSchedulePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduleTime': value.scheduleTime === undefined ? undefined : ((value.scheduleTime as Array<any>).map(LessonScheduleTimeToJSON)),
    };
}

