import { Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import colors from 'common/assets/colors';
import React from 'react';
import { useResponsive } from 'teacher/hooks/useResponsive';

const columns = [
  { label: 'Khoá học', id: 1 },
  { label: 'Thu nhập', id: 2 },
  { label: 'Số học sinh', id: 3 },
  { label: 'Số lớp học', id: 4 },
  { label: 'Số giờ dạy', id: 5 },
  { label: 'Đánh giá', id: 6 },
  { label: 'Lượng review', id: 7 },
  { label: 'Tỉ lệ quay lại', id: 8 },
];

const lines = ['Tư duy phản bien', '9.000.000 VND', '27', '9', '9', '4.95', '9', '49.5%'];

export default function StatisticsByCourses() {
  const { isMobile } = useResponsive();

  return (
    <>
      <Typography variant="h4" color={colors.primary} my={4}>
        Thống kê theo khoá học - 3 tháng gần nhất
      </Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!isMobile && <TableCell sx={{ bgcolor: 'inherit', width: '5%' }}></TableCell>}
              {columns.map((column) => (
                <TableCell key={column.id} sx={{ bgcolor: 'inherit' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableRow>
            {!isMobile && <TableCell sx={{ bgcolor: 'inherit', width: '5%', borderBottom: 0 }}></TableCell>}
            {lines.map((line) => (
              <TableCell key={line} sx={{ bgcolor: 'inherit', borderBottom: 0, minWidth: isMobile ? 150 : 100 }}>
                {line}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {!isMobile && <TableCell sx={{ bgcolor: 'inherit', width: '5%', borderBottom: 0 }}></TableCell>}
            {lines.map((line) => (
              <TableCell key={line} sx={{ bgcolor: 'inherit', borderBottom: 0, minWidth: isMobile ? 150 : 100 }}>
                {line}
              </TableCell>
            ))}
          </TableRow>
        </Table>
      </TableContainer>
    </>
  );
}
