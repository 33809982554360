import React from 'react';

// material components
import { Container, Grid, Typography, Box } from '@mui/material';

// components
import Filter from '../features/course/components/CoursesFilter/CoursesFilter';
import CourseList from 'student/features/course/components/CourseList';
import Loading from 'common/components/Loading';

import { Clazz, FavoriteApi, Favorite } from 'student/api';
import { RootState, dispatch, useSelector } from 'student/store';
import {
  filterCourses,
  changeMySavedCourses,
  selectAllCourses,
  selectMySavedCourses,
  CoursesFilter,
} from 'student/features';
import { ClazzComponent } from 'student/types/ClazzComponentEnum';

export default function PageMySavedCourses() {
  const { user } = useSelector((state: RootState) => state.authState);
  const allCourses = useSelector(selectAllCourses);
  const mySavedCourses = useSelector(selectMySavedCourses);
  const { items, filter, selectedFilter, loading, fetched } = mySavedCourses;

  const handleChangeSelectedFilter = (changedFilter: CoursesFilter) => {
    dispatch(changeMySavedCourses({ selectedFilter: changedFilter }));
  };

  React.useEffect(() => {
    const filteredClazzes = filterCourses(items, selectedFilter);
    dispatch(changeMySavedCourses({ items: filteredClazzes, loading: false }));
  }, [selectedFilter]);

  React.useEffect(() => {
    if (user && allCourses.items.length) {
      const fetchFavoriteClazzes = function () {
        new FavoriteApi()
          .getFavoriteClasses({ studentId: user.uid })
          .then((res) => {
            const favorites: Favorite[] = res.items;
            const clazzes: Clazz[] = [];

            favorites.map((favorite) => {
              const savedClazz = allCourses.items.find((clazz) => clazz.classId == favorite.classId);
              if (savedClazz) {
                clazzes.push(savedClazz);
              }
            });

            dispatch(changeMySavedCourses({ items: clazzes }));
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            dispatch(changeMySavedCourses({ loading: false, fetched: true }));
          });
      };

      fetchFavoriteClazzes();
    }
  }, [user, allCourses]);

  if (!fetched) {
    return <Loading />;
  }

  return (
    <Container fixed>
      <Box py={4}>
        <Typography variant="h4" color="text.primary" mb={5}>
          Khóa học yêu thích
        </Typography>
        <Grid container spacing={5}>
          <Grid item lg={3} xs={12} sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Filter
              filter={filter}
              selectedFilter={selectedFilter}
              handleChangeSelectedFilter={handleChangeSelectedFilter}
            />
          </Grid>
          <Grid item lg={9} xs={12}>
            <CourseList courses={mySavedCourses} clazzComponent={ClazzComponent.Favorites} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
