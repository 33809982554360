import React from 'react';

import Banner from './Banner';
// import HowWorks from './HowWorks';
// import ExploreVariety from './ExploreVariety';
// import Review from './Review';
import Teachers from './Teachers';
import CategoryClasses from './CategoryClasses';
import WhatIsDifference from './WhatIsDifference';
import BecomeTeacher from './BecomeTeacher';

import { useSelector, RootState } from 'student/store';
import PageHomeSectionCourseSlideList from './PageHomeSectionCourseSlideList';

const Home = () => {
  const featuredCourses = useSelector((state: RootState) => state.courseState.featuredCourses);

  return (
    <div>
      <Banner />
      {/* <ExploreVariety /> */}
      <PageHomeSectionCourseSlideList
        loading={featuredCourses.loading}
        clazzes={featuredCourses.items}
        title={'Khám phá vô vàn lớp học từ nhiều chủ đề khác nhau'}
        titleSub={'Cùng con lựa chọn khóa học phù hợp với mình từ kho bài giảng đa dạng của Amusingo'}
      />
      <WhatIsDifference />
      <CategoryClasses />
      {/* <Review /> */}
      <Teachers />
      {/* <HowWorks /> */}
      <BecomeTeacher />
    </div>
  );
};

export default Home;
