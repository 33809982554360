import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import A from 'common/assets';
import { ClazzApprovalStatusEnum } from 'teacher/api/models';
import { Option } from 'common/components/Field/Select';
interface FilterStatusProps {
  options: Option[];
  selectedOptions: Option[];
  handleChangeOptions: (options: Option[]) => void;
}

const FilterStatus = ({ options, selectedOptions, handleChangeOptions }: FilterStatusProps) => {
  const [status, setStatus] = React.useState<ClazzApprovalStatusEnum>(ClazzApprovalStatusEnum.Approved);

  return (
    <FormControl>
      <Box
        id="demo-controlled-radio-buttons-group"
        sx={{ color: A.colors.secondary, fontSize: 20, fontFamily: A.fonts.bold, marginBottom: 1 }}
      >
        Trạng thái
      </Box>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={status}
        onChange={(e) => {
          const newOption = options.find((option) => option.value === e.target.value);
          if (newOption) {
            handleChangeOptions([newOption]);
            setStatus(newOption.value);
          }
        }}
      >
        <FormControlLabel value={ClazzApprovalStatusEnum.Approved} control={<Radio />} label="Đã đăng" />
        <FormControlLabel value={ClazzApprovalStatusEnum.Pending} control={<Radio />} label="Đang chờ" />
        <FormControlLabel value={ClazzApprovalStatusEnum.ChangesRequested} control={<Radio />} label="Bản nháp" />
        <FormControlLabel value={ClazzApprovalStatusEnum.Rejected} control={<Radio />} label="Bị từ chối" />
      </RadioGroup>
    </FormControl>
  );
};

export default FilterStatus;
