import React from 'react';
import { Box } from '@mui/material';

import A from 'common/assets';
import Button from 'common/components/Button';
import { Review } from 'student/api';
import CourseDetailSectionReviewsListItem from './CourseDetailSectionReviewsListItem';

interface Props {
  classId: string;
  reviews: Review[];
}

export default function CourseDetailSectionReviewsList({ reviews }: Props) {
  const [showTo, setShowTo] = React.useState<number>(5);

  const reviewsLength = reviews.length;
  const showingReviews = reviews.slice(0, showTo);
  const hasViewMoreButton = showTo < reviewsLength;

  return (
    <>
      {showingReviews.length > 0
        ? showingReviews.map((review, index) => <CourseDetailSectionReviewsListItem key={index} review={review} />)
        : null}
      {hasViewMoreButton && (
        <Box sx={{ textAlign: 'center', pt: 6, pb: 3 }}>
          <Button variant="contained" size="large" onClick={() => setShowTo(showTo + 5)}>
            Xem thêm đánh giá
          </Button>
        </Box>
      )}
    </>
  );
}
