/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Enrollment post request
 * @export
 * @interface EnrollmentPostRequest
 */
export interface EnrollmentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof EnrollmentPostRequest
     */
    studentId: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollmentPostRequest
     */
    classId: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollmentPostRequest
     */
    scheduleId: string;
}

/**
 * Check if a given object implements the EnrollmentPostRequest interface.
 */
export function instanceOfEnrollmentPostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "studentId" in value;
    isInstance = isInstance && "classId" in value;
    isInstance = isInstance && "scheduleId" in value;

    return isInstance;
}

export function EnrollmentPostRequestFromJSON(json: any): EnrollmentPostRequest {
    return EnrollmentPostRequestFromJSONTyped(json, false);
}

export function EnrollmentPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnrollmentPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentId': json['studentId'],
        'classId': json['classId'],
        'scheduleId': json['scheduleId'],
    };
}

export function EnrollmentPostRequestToJSON(value?: EnrollmentPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentId': value.studentId,
        'classId': value.classId,
        'scheduleId': value.scheduleId,
    };
}

