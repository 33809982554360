import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { int } from 'aws-sdk/clients/datapipeline';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

interface SkeletonComponentProps {
  title?: string;
  subTitle?: string;
  width?: int;
  height?: int;
}

export default function HorizontalSkeletonLoading(props: SkeletonComponentProps) {
  return (
    <Box sx={{ width: 'calc(100vw - 48px)', overflow: 'scroll' }}>
      <Box sx={{ width: props?.width ? props.width : 300 * 4, p: 2, display: 'flex', gap: 2 }}>
        {[1, 2, 3, 4].map((_e, index) => (
          <Box key={index} sx={{ width: 1200 }}>
            <Skeleton variant="rectangular" height={250} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
            <div style={{ display: 'flex' }}>
              <Skeleton variant="circular" style={{ marginRight: 10 }}>
                <Avatar />
              </Skeleton>
              <Skeleton width="100%">
                <Typography>.</Typography>
              </Skeleton>
            </div>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
