import React from 'react';

const MOBILE_SCREEN_WIDTH_BREAKPOINT = 600;

export function useResponsive() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isMobile = width <= MOBILE_SCREEN_WIDTH_BREAKPOINT;

  return { isMobile, isDesktop: !isMobile, screen: window.screen };
}
