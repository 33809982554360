/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for Payment Information Post Request
 * @export
 * @interface PaymentPostRequestParams
 */
export interface PaymentPostRequestParams {
    /**
     * 
     * @type {string}
     * @memberof PaymentPostRequestParams
     */
    paymentType?: PaymentPostRequestParamsPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentPostRequestParams
     */
    confirmationTime?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentPostRequestParams
     */
    paymentAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentPostRequestParams
     */
    selectedSchedule?: string;
}


/**
 * @export
 */
export const PaymentPostRequestParamsPaymentTypeEnum = {
    QrPayment: 'QR_PAYMENT',
    BankingPayment: 'BANKING_PAYMENT'
} as const;
export type PaymentPostRequestParamsPaymentTypeEnum = typeof PaymentPostRequestParamsPaymentTypeEnum[keyof typeof PaymentPostRequestParamsPaymentTypeEnum];


/**
 * Check if a given object implements the PaymentPostRequestParams interface.
 */
export function instanceOfPaymentPostRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentPostRequestParamsFromJSON(json: any): PaymentPostRequestParams {
    return PaymentPostRequestParamsFromJSONTyped(json, false);
}

export function PaymentPostRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPostRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
        'confirmationTime': !exists(json, 'confirmationTime') ? undefined : json['confirmationTime'],
        'paymentAmount': !exists(json, 'paymentAmount') ? undefined : json['paymentAmount'],
        'selectedSchedule': !exists(json, 'selectedSchedule') ? undefined : json['selectedSchedule'],
    };
}

export function PaymentPostRequestParamsToJSON(value?: PaymentPostRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentType': value.paymentType,
        'confirmationTime': value.confirmationTime,
        'paymentAmount': value.paymentAmount,
        'selectedSchedule': value.selectedSchedule,
    };
}

