import * as React from 'react';
import { Alert, Grid } from '@mui/material';

import { Clazz, ClassSchedule } from 'student/api';
import CourseScheduleCard from './CourseScheduleCard';
import { useEnrollment } from 'student/hooks';
import { getClassScheduleStartMs } from 'common/utils';

interface Props {
  clazz: Clazz;
  enrollable?: boolean;
}

export default function CourseDetailSectionScheduleList({ clazz, enrollable }: Props) {
  if (!clazz.schedules) {
    return <></>;
  }
  const classSchedules: ClassSchedule[] = clazz.schedules;
  const { allEnrollments } = useEnrollment();
  const numOnGoingCourses = React.useMemo(() => {
    let count = 0;
    classSchedules.forEach((classSchedule) => {
      const { scheduleId, scheduleTimes } = classSchedule;

      if (!scheduleTimes || scheduleTimes.length === 0) {
        return 0;
      }

      const isEnrolledCourseSchedule = allEnrollments.items.some(
        (enroll) => enroll.classId === clazz.classId && enroll.scheduleId === scheduleId
      );

      const allStartMsTimes: number[] = scheduleTimes.map((schedule) => getClassScheduleStartMs(schedule));
      const startAtMs = Math.min(...allStartMsTimes);
      const maxNumAllowedLearners = Number(clazz.numAllowedLearners?.maxAllowedLearners);
      const numEnrolledStudents = Number(classSchedule.numEnrolledStudents);
      const numAllowedLearners = maxNumAllowedLearners - numEnrolledStudents;
      if (allEnrollments.fetched) {
        if (Date.now() > startAtMs) {
          if (!isEnrolledCourseSchedule) count++;
        } else if (numAllowedLearners === 0) {
          count++;
        }
      }
    });

    return count;
  }, [classSchedules, allEnrollments]);

  return (
    <>
      {numOnGoingCourses !== 0 && (
        <Alert severity="info" sx={{ fontWeight: 'bold', color: '#696984' }}>
          {numOnGoingCourses} lớp học đang diễn ra!
        </Alert>
      )}
      <Grid container spacing={3} alignItems="center" justifyContent={'center'}>
        {classSchedules.map((classSchedule) => (
          <Grid item key={classSchedule.scheduleId}>
            <CourseScheduleCard
              classId={`${clazz.classId}`}
              lengthOfLesson={clazz.lengthOfLesson || 60}
              classSchedule={classSchedule}
              enrollable={enrollable}
              maxNumAllowedLearners={Number(clazz.numAllowedLearners?.maxAllowedLearners)}
              numEnrolledStudents={Number(classSchedule.numEnrolledStudents)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
