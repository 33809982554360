/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Favorite information
 * @export
 * @interface Favorite
 */
export interface Favorite {
    /**
     * 
     * @type {string}
     * @memberof Favorite
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Favorite
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof Favorite
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof Favorite
     */
    dtCreated?: string;
}

/**
 * Check if a given object implements the Favorite interface.
 */
export function instanceOfFavorite(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FavoriteFromJSON(json: any): Favorite {
    return FavoriteFromJSONTyped(json, false);
}

export function FavoriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Favorite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'classId': !exists(json, 'classId') ? undefined : json['classId'],
        'teacherId': !exists(json, 'teacherId') ? undefined : json['teacherId'],
        'dtCreated': !exists(json, 'dtCreated') ? undefined : json['dtCreated'],
    };
}

export function FavoriteToJSON(value?: Favorite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentId': value.studentId,
        'classId': value.classId,
        'teacherId': value.teacherId,
        'dtCreated': value.dtCreated,
    };
}

