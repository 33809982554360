/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * age range
 * @export
 * @interface AgeRange
 */
export interface AgeRange {
    /**
     * 
     * @type {number}
     * @memberof AgeRange
     */
    minAge?: number;
    /**
     * 
     * @type {number}
     * @memberof AgeRange
     */
    maxAge?: number;
}

/**
 * Check if a given object implements the AgeRange interface.
 */
export function instanceOfAgeRange(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AgeRangeFromJSON(json: any): AgeRange {
    return AgeRangeFromJSONTyped(json, false);
}

export function AgeRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minAge': !exists(json, 'minAge') ? undefined : json['minAge'],
        'maxAge': !exists(json, 'maxAge') ? undefined : json['maxAge'],
    };
}

export function AgeRangeToJSON(value?: AgeRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minAge': value.minAge,
        'maxAge': value.maxAge,
    };
}

