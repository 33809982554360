/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaymentConfirmation,
  PaymentConfirmationList,
  PaymentPostRequestParams,
} from '../models';
import {
    PaymentConfirmationFromJSON,
    PaymentConfirmationToJSON,
    PaymentConfirmationListFromJSON,
    PaymentConfirmationListToJSON,
    PaymentPostRequestParamsFromJSON,
    PaymentPostRequestParamsToJSON,
} from '../models';

export interface CreatePaymentConfirmationRequest {
    studentId: string;
    classId: string;
    paymentPostRequestParams: PaymentPostRequestParams;
}

export interface GetPaymentConfirmationsRequest {
    studentId: string;
}

/**
 * 
 */
export class PaymentConfirmationApi extends runtime.BaseAPI {

    /**
     * confirm a payment
     */
    async createPaymentConfirmationRaw(requestParameters: CreatePaymentConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentConfirmation>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling createPaymentConfirmation.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling createPaymentConfirmation.');
        }

        if (requestParameters.paymentPostRequestParams === null || requestParameters.paymentPostRequestParams === undefined) {
            throw new runtime.RequiredError('paymentPostRequestParams','Required parameter requestParameters.paymentPostRequestParams was null or undefined when calling createPaymentConfirmation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/paymentConfirmations/{classId}/paymentConfirmationCreation`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentPostRequestParamsToJSON(requestParameters.paymentPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentConfirmationFromJSON(jsonValue));
    }

    /**
     * confirm a payment
     */
    async createPaymentConfirmation(requestParameters: CreatePaymentConfirmationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentConfirmation> {
        const response = await this.createPaymentConfirmationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all payment confirmations of a student (based on studentId)
     */
    async getPaymentConfirmationsRaw(requestParameters: GetPaymentConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentConfirmationList>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getPaymentConfirmations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/paymentConfirmations`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentConfirmationListFromJSON(jsonValue));
    }

    /**
     * Get all payment confirmations of a student (based on studentId)
     */
    async getPaymentConfirmations(requestParameters: GetPaymentConfirmationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentConfirmationList> {
        const response = await this.getPaymentConfirmationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
