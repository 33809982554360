import React from 'react';
import { DummyTeacher, DummyTeacherApi } from '../api';

export function useTeachers() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [teachers, setTeachers] = React.useState<DummyTeacher[]>([]);

  React.useEffect(() => {
    new DummyTeacherApi()
      .getAllDummyTeachers()
      .then((res) => {
        setTeachers(res.items);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    teachers,
    loading,
  };
}
