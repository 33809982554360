import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';

import Button from 'common/components/Button';

import { useSelector } from 'student/store';
import { Category } from 'student/api';
import { selectCategoryState } from 'student/features';

interface Props {
  activatedCategories?: Category[];
  handleCategoryClick?: (category: Category) => void;
}

export function CategoryNameList({ activatedCategories, handleCategoryClick }: Props) {
  const theme = useTheme();
  const categoryState = useSelector(selectCategoryState);

  const activeCategories = activatedCategories || categoryState.items;

  const CategoryNameItem = ({ category }: { category: Category }) => {
    const categoryName = `${category.categoryName}`;
    const selectedIndex = activeCategories.indexOf(category);
    const isSelected = selectedIndex !== -1;

    const onCategoryClick = () => {
      if (handleCategoryClick) {
        handleCategoryClick(category);
      }
    };

    return (
      <Grid item sx={{ color: theme.palette.grey[400], fontSize: 14 }}>
        <Button
          size="small"
          // variant={isSelected ? 'contained' : 'outlined'}
          variant={'outlined'}
          color={isSelected ? 'primary' : 'inherit'}
          onClick={onCategoryClick}
          sx={{
            fontSize: '100%',
          }}
        >
          {categoryName}
        </Button>
      </Grid>
    );
  };

  if (categoryState.loading) {
    return <Skeleton variant="text" sx={{ fontSize: '1rem' }} />;
  }

  return (
    <Grid container spacing={1}>
      {categoryState.items.map((category) => (
        <CategoryNameItem key={category.categoryId} category={category} />
      ))}
    </Grid>
  );
}
