import React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Avatar,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material';
import { Search, ArrowDropDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { NavItem } from './Header';

import A from 'common/assets';
import Button from 'common/components/Button';

import './style.scss';

const MyAppBar1 = styled(AppBar)({
  backgroundColor: 'white',
  height: 64,
});

const MyAppBar2 = styled(MyAppBar1)({
  boxShadow: '0px 0px 10px -10px rgba(0, 0, 0, 0.4)',
  alignItems: 'center',
});

interface Props {
  navItems: NavItem[];
}

export default function HeaderDesktopAppBar2({ navItems }: Props) {
  const navigate = useNavigate();

  return (
    <>
      <MyAppBar2 position="static" color="inherit" className="navigation-container">
        <Toolbar>
          {navItems.map((navItem) => (
            <Button
              key={navItem.label}
              variant={navItem.active ? 'contained' : 'text'}
              className="m-2"
              sx={{ fontFamily: navItem.active ? A.fonts.bold : 'inherit' }}
              onClick={() => navigate(navItem.route)}
            >
              {navItem.label}
            </Button>
          ))}
        </Toolbar>
      </MyAppBar2>
    </>
  );
}
