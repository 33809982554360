/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Schedule of a class/lesson
 * @export
 * @interface LessonScheduleTime
 */
export interface LessonScheduleTime {
    /**
     * 
     * @type {string}
     * @memberof LessonScheduleTime
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof LessonScheduleTime
     */
    end: string;
}

/**
 * Check if a given object implements the LessonScheduleTime interface.
 */
export function instanceOfLessonScheduleTime(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;

    return isInstance;
}

export function LessonScheduleTimeFromJSON(json: any): LessonScheduleTime {
    return LessonScheduleTimeFromJSONTyped(json, false);
}

export function LessonScheduleTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessonScheduleTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': json['start'],
        'end': json['end'],
    };
}

export function LessonScheduleTimeToJSON(value?: LessonScheduleTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'end': value.end,
    };
}

