import * as React from 'react';
import Button from 'common/components/Button';
import Link from 'common/components/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, Box, Grid } from '@mui/material';
import { formatPrice } from 'common/utils';
import { routes } from 'student/Routes';
import { PaymentConfirmationApi, PaymentPostRequestParamsPaymentTypeEnum } from 'student/api';
import A from 'common/assets';

interface Props {
  price: number;
  content: string;
  studentId: string;
  courseId: string;
  scheduleId: string | undefined;
}

export default function PaymentConfirm({ price, content, studentId, courseId, scheduleId }: Props) {
  const [openThankPopup, setOpenThankPopup] = React.useState(false);
  const [openConsiderPopup, setOpenConsiderPopup] = React.useState(false);

  const handleClickConfirm = () => {
    new PaymentConfirmationApi()
      .createPaymentConfirmation({
        studentId,
        classId: courseId,
        paymentPostRequestParams: {
          paymentType: PaymentPostRequestParamsPaymentTypeEnum.BankingPayment,
          confirmationTime: new Date().toUTCString(),
          paymentAmount: price,
          selectedSchedule: courseId + '_0001',
        },
      })
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {});

    setOpenThankPopup(true);
  };

  const handleClickConsider = () => {
    setOpenConsiderPopup(true);
  };

  const handleClose = () => {
    setOpenThankPopup(false);
  };

  const handleCloseConsider = () => {
    setOpenConsiderPopup(false);
  };

  function ButtonConfirm() {
    return (
      <Button
        variant="contained"
        size="large"
        onClick={handleClickConfirm}
        sx={{
          m: 1,
          height: { lg: 80, xs: 60 },
          px: { lg: 6, xs: 2 },
          borderRadius: { lg: '60px', xs: '27px' },
          fontSize: { lg: 24, xs: 18 },
          fontWeight: { lg: 800, xs: 400 },
        }}
        fullWidth
      >
        Tôi đã chuyển khoản
      </Button>
    );
  }

  function ButtonConsider() {
    return (
      <Button
        variant="outlined"
        size="large"
        onClick={handleClickConsider}
        sx={{
          m: 1,
          height: { lg: 80, xs: 60 },
          px: { lg: 6, xs: 2 },
          borderRadius: { lg: '60px', xs: '27px' },
          fontSize: { lg: 24, xs: 18 },
          fontWeight: { lg: 800, xs: 400 },
        }}
        fullWidth
      >
        Tôi muốn xem xét thêm
      </Button>
    );
  }

  function ThankPopup() {
    return (
      <Box>
        <Dialog
          open={openThankPopup}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          sx={{
            '& .MuiDialog-paper': {
              width: '100%',
              alignItems: 'center',
              p: 3,
              borderRadius: '30px',
              boxShadow: '0px 10px 60px rgba(38, 45, 118, 0.08)',
            },
            '& .MuiDialogContent-root': {
              textAlign: 'center',
            },
            '& .MuiDialogActions-root': {
              justifyContent: 'center',
            },
          }}
        >
          <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography variant="h5" color="text.primary">
              Cảm ơn bạn đã tin tưởng và lựa chọn Amusingo. <br />
              Chúng tôi sẽ xác nhận và nhanh chóng gửi thông báo tới bạn.
            </Typography>
          </DialogTitle>

          <DialogActions
            sx={{
              button: {
                height: { lg: 82, xs: 40 },
                px: { lg: 6, xs: '2' },
                borderRadius: { lg: '60px', xs: '27px' },
                fontSize: { lg: 24, xs: 14 },
                fontWeight: { lg: 800, xs: 400 },
              },
              gap: { lg: 7, xs: 3 },
            }}
          >
            <Link to={routes.PageCourses.path}>
              <Button variant="contained" size="large">
                Xem khoá học khác
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  function ConsiderPopup() {
    return (
      <Box>
        <Dialog
          open={openConsiderPopup}
          onClose={handleCloseConsider}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          sx={{
            '& .MuiDialog-paper': {
              width: '100%',
              alignItems: 'center',
              p: 3,
              borderRadius: '30px',
              boxShadow: '0px 10px 60px rgba(38, 45, 118, 0.08)',
            },
            '& .MuiDialogContent-root': {
              textAlign: 'center',
            },
            '& .MuiDialogActions-root': {
              justifyContent: 'center',
            },
          }}
        >
          <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography variant="h5" color="text.primary">
              Liên hệ với chúng tôi để đăng ký buổi học thử MIỄN PHÍ <br />
              và nhận ngay mức ưu đãi giảm 30% học phí
            </Typography>
          </DialogTitle>

          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: 50,
              marginTop: 20,
            }}
          >
            <Box display="flex" flexDirection="column">
              <a href="https://m.me/amusingo.edu/" target="_blank" rel="noreferrer">
                <img src={A.images.Messenger} height={150} width={150} />
              </a>
            </Box>
            <Box display="flex" flexDirection="column">
              <a href="https://zalo.me/0925779797" target="_blank" rel="noreferrer">
                <img src={A.images.Zalo} height={150} width={150} />
              </a>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              button: {
                height: { lg: 82, xs: 40 },
                px: { lg: 6, xs: '2' },
                borderRadius: { lg: '60px', xs: '27px' },
                fontSize: { lg: 24, xs: 14 },
                fontWeight: { lg: 800, xs: 400 },
              },
              gap: { lg: 7, xs: 3 },
            }}
          >
            <Button variant="outlined" size="large" onClick={handleCloseConsider}>
              Đóng
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <Grid container textAlign="center" justifyContent={'center'}>
      <Grid xs={12}>
        <Typography variant="h6" color="text.primary" sx={{ fontFamily: A.fonts.regular }}>
          Số tiền: <b>{formatPrice(price)} VND</b>
        </Typography>
        <Typography variant="h6" color="text.primary" sx={{ fontFamily: A.fonts.regular }}>
          Nội dung: <b>{content}</b>
        </Typography>
      </Grid>
      <Grid xs={12} md={6} sx={{ p: { md: 2 } }}>
        <ButtonConfirm />
      </Grid>
      <Grid xs={12} md={6} sx={{ p: { md: 2 } }}>
        <ButtonConsider />
      </Grid>
      <ThankPopup />
      <ConsiderPopup />
    </Grid>
  );
}
