import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Category, ParentProfileApi } from 'student/api';
import { dispatch, useSelector, RootState } from 'student/store';
import { changeParentProfile } from 'student/features';
import { CategoryNameList } from 'common/components/Category';

// Input Parent name
// Input children names
// Submit to create new parent profile
export default function AuthFormProfile() {
  const { register, handleSubmit } = useForm();
  const { user } = useSelector((state: RootState) => state.authState);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = React.useState<Category[]>([]);

  const handleCategoryClick = (category: Category) => {
    const selectedIndex = selectedCategories.indexOf(category);
    const isSelected = selectedIndex !== -1;

    if (isSelected) {
      setSelectedCategories(selectedCategories.filter((o) => o.categoryId !== category.categoryId));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    new ParentProfileApi()
      .createParentProfile({
        userId: `${user?.uid}`,
        parentProfilePostRequest: {
          ...data,
          phone: `${user?.phoneNumber}`,
          // email: `${user?.email}`,
          studentNames: [data.studentName],
          interestedFields: selectedCategories.map((category) => category.categoryName),
          referredFromExistingUser: data.referredFromExistingUser,
        },
      })
      .then((value) => {
        dispatch(changeParentProfile(value));
        toast.success('Tạo tài khoản mới thành công!');
      })
      .catch((err) => {
        dispatch(changeParentProfile(null));
        console.error(err);
        toast.error('Lỗi tạo tài khoản, vui lòng thử lại sau!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 1,
        pt: 2,
        pb: 4,
        px: 5,
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <SupervisedUserCircleIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Thông tin tài khoản mới
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3, mb: 1 }}>
        <TextField
          margin="normal"
          type="text"
          required
          fullWidth
          label="Họ tên phụ huynh"
          {...register('parentName')}
        />

        <TextField margin="normal" type="email" fullWidth label="Email phụ huynh" {...register('email')} />
        <hr style={{ width: '0' }} />

        <TextField margin="normal" type="text" required fullWidth label="Họ tên con" {...register('studentName')} />

        <InputLabel sx={{ mt: 1, mb: 1 }}>Lĩnh vực con yêu thích</InputLabel>
        <CategoryNameList activatedCategories={selectedCategories} handleCategoryClick={handleCategoryClick} />
        <hr style={{ width: '0' }} />

        <TextField
          margin="normal"
          type="text"
          fullWidth
          label="Nhập mã giới thiệu"
          {...register('referredFromExistingUser')}
        />

        <Button
          type="submit"
          disabled={loading}
          fullWidth
          variant="contained"
          sx={{ pt: 1.5, pb: 1.5, mt: 4 }}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          Tạo tài khoản
        </Button>
      </Box>
    </Paper>
  );
}
