/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DummyTeacher } from './DummyTeacher';
import {
    DummyTeacherFromJSON,
    DummyTeacherFromJSONTyped,
    DummyTeacherToJSON,
} from './DummyTeacher';

/**
 * A list of dummy teachers
 * @export
 * @interface DummyTeacherList
 */
export interface DummyTeacherList {
    /**
     * 
     * @type {Array<DummyTeacher>}
     * @memberof DummyTeacherList
     */
    items: Array<DummyTeacher>;
}

/**
 * Check if a given object implements the DummyTeacherList interface.
 */
export function instanceOfDummyTeacherList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function DummyTeacherListFromJSON(json: any): DummyTeacherList {
    return DummyTeacherListFromJSONTyped(json, false);
}

export function DummyTeacherListFromJSONTyped(json: any, ignoreDiscriminator: boolean): DummyTeacherList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(DummyTeacherFromJSON)),
    };
}

export function DummyTeacherListToJSON(value?: DummyTeacherList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(DummyTeacherToJSON)),
    };
}

