import React from 'react';
import { Card, CardMedia, CardContent, CardActionArea, Typography, Chip, Box, Stack, Skeleton } from '@mui/material';

import { routes } from 'student/Routes';
import Link from 'common/components/Link/Link';
import Loading from 'common/components/Loading';
import { DummyTeacher } from 'student/api';
import { useCoursesByTeacherId, useResponsive } from 'student/hooks';

interface TeacherCardProps {
  teacher?: DummyTeacher;
}

const TeacherCard = ({ teacher }: TeacherCardProps) => {
  const { isMobile } = useResponsive();
  const { coursesByTeacher, loading } = useCoursesByTeacherId(teacher?.teacherId);

  return (
    <Card sx={{ width: isMobile ? 300 : 264 }}>
      <Link to={routes.PageTeacher.path.replace(':teacherId', `${teacher?.teacherId}`)}>
        <CardActionArea>
          <CardMedia component="img" height="240" image={teacher?.avatar} alt="green iguana" />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div" height={24}>
              {teacher?.name}
            </Typography>
            <div className="card-user-img-cont">
              <span>{teacher?.teachingFields?.join(' ')}</span>
            </div>
          </CardContent>
        </CardActionArea>
      </Link>
      {loading ? (
        <Box px={2} display="flex" gap={1} height={64}>
          <Skeleton height={40} variant="text" animation="wave" sx={{ fontSize: '2rem' }} width={'100%'} />
        </Box>
      ) : coursesByTeacher.length !== 0 ? (
        <Box px={2} display="flex" gap={1} height={64}>
          {coursesByTeacher.map((classItem) => (
            <Box key={classItem.classId} width={coursesByTeacher.length === 2 ? '50%' : '100%'}>
              <Link to={routes.PageCourse.path.replace(':courseId', `${classItem.classId}`)}>
                <Chip label={classItem.className} sx={{ cursor: 'pointer' }} />
              </Link>
            </Box>
          ))}
        </Box>
      ) : (
        <Box height={64}></Box>
      )}
    </Card>
  );
};

export default TeacherCard;
