import React from 'react';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import SelectItemButton from './SelectItemButton';

export interface Option {
  label: string; // id
  value: any;
}

export interface SelectItemProps {
  label: string;
  isSelected: boolean;
  handleClick: () => void;
}

interface Props {
  title: string;
  options: Option[];
  selectedOptions: Option[];
  handleChangeOptions: (options: Option[]) => void;
  CustomSelectItem?: React.FunctionComponent<SelectItemProps>;
  optionItemStyle?: any;
}

export default function Select({
  title,
  options,
  selectedOptions,
  handleChangeOptions,
  CustomSelectItem,
  optionItemStyle = {},
}: Props) {
  const theme = useTheme();

  const onClearFilter = () => {
    handleChangeOptions([]);
  };

  const OptionButton = ({ option }: { option: Option }) => {
    const selectedIndex = selectedOptions.indexOf(option);
    const isSelected = selectedIndex !== -1;

    const handleItemClick = () => {
      if (handleChangeOptions) {
        let newOptions = [];
        if (isSelected) {
          newOptions = selectedOptions.filter((opt) => opt.label !== option.label);
        } else {
          newOptions = [...selectedOptions, option];
        }
        handleChangeOptions(newOptions);
      }
    };
    if (CustomSelectItem) {
      return <CustomSelectItem label={option.label} isSelected={isSelected} handleClick={handleItemClick} />;
    }
    return <SelectItemButton label={option.label} isSelected={isSelected} handleClick={handleItemClick} />;
  };

  return (
    <>
      <Stack direction={'row'} spacing={1} sx={{ pb: 1 }}>
        <Typography variant="h6" color="text.primary">
          {title}
        </Typography>
        {selectedOptions.length > 0 ? (
          <Button
            size="small"
            variant={'outlined'}
            color={'primary'}
            onClick={onClearFilter}
            sx={{
              fontSize: '80%',
              height: 30,
            }}
          >
            Bỏ lọc
          </Button>
        ) : null}
      </Stack>

      <Grid container spacing={0.5}>
        {options.map((option) => (
          <Grid
            key={option.label}
            item
            sx={{
              color: theme.palette.grey[600],
              fontSize: 14,
              ...optionItemStyle,
            }}
          >
            <OptionButton option={option} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
