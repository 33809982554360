/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Clazz,
  ClazzList,
} from '../models';
import {
    ClazzFromJSON,
    ClazzToJSON,
    ClazzListFromJSON,
    ClazzListToJSON,
} from '../models';

export interface EnrollClassRequest {
    studentId: string;
    classId: string;
    scheduleId: string;
}

export interface GetClassRequest {
    teacherId: string;
    classId: string;
}

export interface GetClassesByCategoryRequest {
    categoryId: string;
}

export interface GetClassesByClassIdRequest {
    classId: string;
}

export interface GetClassesByTeacherRequest {
    teacherId: string;
}

export interface UnenrollClassRequest {
    studentId: string;
    classId: string;
}

/**
 * 
 */
export class ClassApi extends runtime.BaseAPI {

    /**
     * Enroll a class
     */
    async enrollClassRaw(requestParameters: EnrollClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling enrollClass.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling enrollClass.');
        }

        if (requestParameters.scheduleId === null || requestParameters.scheduleId === undefined) {
            throw new runtime.RequiredError('scheduleId','Required parameter requestParameters.scheduleId was null or undefined when calling enrollClass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/classes/{classId}/schedules/{scheduleId}/enrolment`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"scheduleId"}}`, encodeURIComponent(String(requestParameters.scheduleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * Enroll a class
     */
    async enrollClass(requestParameters: EnrollClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.enrollClassRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all classes as a list
     */
    async getAllClassesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/classes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * Get all classes as a list
     */
    async getAllClasses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getAllClassesRaw(initOverrides);
        return await response.value();
    }

    /**
     * get details of a class based on classId and teacherId
     */
    async getClassRaw(requestParameters: GetClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getClass.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getClass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teachers/{teacherId}/classes/{classId}`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * get details of a class based on classId and teacherId
     */
    async getClass(requestParameters: GetClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.getClassRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all classes of a category
     */
    async getClassesByCategoryRaw(requestParameters: GetClassesByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling getClassesByCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories/{categoryId}/classes`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * get all classes of a category
     */
    async getClassesByCategory(requestParameters: GetClassesByCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getClassesByCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get class by class_id
     */
    async getClassesByClassIdRaw(requestParameters: GetClassesByClassIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getClassesByClassId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/classes/{classId}/individualClassByClassId`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * get class by class_id
     */
    async getClassesByClassId(requestParameters: GetClassesByClassIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.getClassesByClassIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all classes of a teacher
     */
    async getClassesByTeacherRaw(requestParameters: GetClassesByTeacherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getClassesByTeacher.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teachers/{teacherId}/classes`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * get all classes of a teacher
     */
    async getClassesByTeacher(requestParameters: GetClassesByTeacherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getClassesByTeacherRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get featured classes
     */
    async getFeaturedClassesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/classes/featuredClasses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * Get featured classes
     */
    async getFeaturedClasses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getFeaturedClassesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get next week classes
     */
    async getNextWeekClassesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/classes/nextWeek`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * Get next week classes
     */
    async getNextWeekClasses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getNextWeekClassesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all ongoing classes
     */
    async getOngoingClassesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/classes/ongoing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * Get all ongoing classes
     */
    async getOngoingClasses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getOngoingClassesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Unenroll a class
     */
    async unenrollClassRaw(requestParameters: UnenrollClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling unenrollClass.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling unenrollClass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/classes/{classId}/unenrolment`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * Unenroll a class
     */
    async unenrollClass(requestParameters: UnenrollClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.unenrollClassRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
