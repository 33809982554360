import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TeacherProfile } from 'teacher/api/models';
import { User } from 'firebase/auth';

export interface AuthState {
  user: User | null;
  userFetched: boolean; // init fetched
  userLoading: boolean;

  teacherProfile: TeacherProfile | null;
  teacherProfileFetched: boolean;
  teacherProfileLoading: boolean;
}

const initialState: AuthState = {
  user: null,
  userFetched: false,
  userLoading: false,

  teacherProfile: null,
  teacherProfileFetched: false,
  teacherProfileLoading: false,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    changeAuthStateCached: (state, action: PayloadAction<AuthState>) => {
      state = { ...action.payload };
    },
    changeAuthStateToLogout: (state) => {
      state.user = null;
      state.userFetched = true;
      state.userLoading = false;
      state.teacherProfile = null;
      state.teacherProfileFetched = true;
      state.teacherProfileLoading = false;
    },
    changeUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    changeUserFetched: (state, action: PayloadAction<boolean>) => {
      state.userFetched = action.payload;
    },
    changeUserLoading: (state, action: PayloadAction<boolean>) => {
      state.userLoading = action.payload;
    },
    changeTeacherProfile: (state, action: PayloadAction<TeacherProfile | null>) => {
      state.teacherProfile = action.payload;
    },
    changeTeacherProfileFetched: (state, action: PayloadAction<boolean>) => {
      state.teacherProfileFetched = action.payload;
    },
    changeTeacherProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.teacherProfileLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeAuthStateCached,
  changeAuthStateToLogout,
  changeUser,
  changeUserFetched,
  changeUserLoading,
  changeTeacherProfile,
  changeTeacherProfileFetched,
  changeTeacherProfileLoading,
} = authSlice.actions;

export const authState = authSlice.reducer;
