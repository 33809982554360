import React from 'react';
import { CustomChat, FacebookProvider, MessageUs, MessengerCheckbox } from 'react-facebook';
import { Box, SpeedDial, SpeedDialAction } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import images from 'common/assets/images';
import Link from 'common/components/mui/Link';

export default function ContactChatBubble() {
  return (
    <Box sx={{ position: 'fixed', bottom: 20, right: 20, cursor: 'pointer', zIndex: 100 }}>
      <MessageUsButton />
    </Box>
  );
}

export function MessageUsButton() {
  return (
    // <FacebookProvider appId="1191223854848540">
    //   <MessageUs messengerAppId="1191223854848540" pageId="112136175073627" size="xlarge" />
    // </FacebookProvider>
    <SpeedDial icon={<QuestionAnswerIcon />} ariaLabel="SpeedDial controlled open" open={true} hidden={true}>
      <SpeedDialAction
        sx={{ width: 60, height: 60, borderRadius: '100%', bgcolor: 'green' }}
        icon={
          <a rel="noopener noreferrer" target="_blank" href="tel://+84925779797">
            <PhoneIcon sx={{ color: 'white' }} />
          </a>
        }
      />
      <SpeedDialAction
        sx={{ width: 60, height: 60, borderRadius: '100%', bgcolor: 'unset' }}
        icon={
          <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/amusingo.edu">
            <img
              src={images.Messenger}
              alt="icon"
              className="img-fluid"
              style={{ width: 60, borderRadius: '100%', overflow: 'initial' }}
            />
          </a>
        }
      />
      <SpeedDialAction
        sx={{ width: 60, height: 60, borderRadius: '100%', bgcolor: 'unset' }}
        icon={
          <a rel="noopener noreferrer" target="_blank" href="https://zalo.me/0925779797">
            <img src={images.Zalo} alt="icon" className="img-fluid" style={{ width: 60, borderRadius: '100%' }} />
          </a>
        }
      />
    </SpeedDial>
  );
}
