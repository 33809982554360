import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Checkbox, Chip, FormControlLabel, FormGroup, IconButton, Tooltip } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { ApplicationPostRequest, GetUploadUrlOperationRequest, IndividualTeacherApplicationApi } from 'teacher/api';
import { useNavigate } from 'react-router';
import { routes } from 'teacher/Routes';
import { normalizeImageName, uploadImage } from 'common/utils';
import FieldLabel from '../../../common/components/Field/FormFieldLabel';

// Input Teacher's infor: name, phone number, email, address, avatar, description, teachingExperience, profileUrl, introductionVideo, teachingFields, certificates
export default function ApplyForm() {
  const { handleSubmit } = useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);

  const [imageUpload, setImageUpload] = React.useState<File>();
  const [videoUpload, setVideoUpload] = React.useState<File>();
  const [certificateList, setCertificateList] = React.useState<string[]>([]);
  const [inputCertificate, setInputCertificate] = React.useState<string>('');
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);
  const [applicationData, setApplicationData] = React.useState<ApplicationPostRequest>({
    name: '',
    email: '',
    address: '',
    phone: '',
    description: '',
    profileUrl: '',
    teachingExperience: '',
    teachingFields: [''],
    avatar: '',
    introductionVideo: '',
  });

  const spaces = { mb: 2, mt: 1 };

  const onChangeApplicationData = (event: any) => {
    setApplicationData({ ...applicationData, [event.target.name]: event.target.value });
  };

  const onUploadImage = (e: any) => {
    const file: any = e.target.files[0];
    setImageUpload({ ...file, name: 'avatar.' + file.type.replace('image/', '') });
  };

  const onUploadVideo = (e: any) => {
    const file: any = e.target.files[0];
    setVideoUpload({ ...file, name: 'introduction_video.' + file.type.replace('video/', '') });
  };

  const addCertificate = () => {
    setCertificateList([...certificateList, inputCertificate]);
    setInputCertificate('');
  };

  const deleteCertificate = (name: string) => {
    const newList = certificateList.filter((item) => item !== name);
    setCertificateList(newList);
  };

  const handleCategoryClick = (event: any) => {
    let newList: string[] = selectedCategories;
    if (selectedCategories.find((item) => item === event.target.value)) {
      newList = selectedCategories.filter((item) => item !== event.target.value);
    } else {
      newList.push(event.target.value);
    }
    setSelectedCategories(newList);
  };

  const onSubmit = (data: any) => {
    applicationData.certificates = certificateList;
    applicationData.teachingFields = selectedCategories;
    const submit = new IndividualTeacherApplicationApi();
    const ApplyPostRequest: GetUploadUrlOperationRequest = { getUploadUrlRequest: { email: '', filename: '' } };
    let CallApiUploadUrl: any;
    setLoading(true);
    if (imageUpload && applicationData.email) {
      ApplyPostRequest.getUploadUrlRequest.filename = normalizeImageName(imageUpload.name, '', imageUpload.name);
      ApplyPostRequest.getUploadUrlRequest.email = applicationData.email;
      CallApiUploadUrl = submit.getUploadUrl(ApplyPostRequest).then(async (value) => {
        const uploadUrl: string | undefined = value.uploadUrl;
        const uploaded = await uploadImage(imageUpload, uploadUrl || '');
        if (!uploaded) {
          toast.error('Lỗi upload ảnh nền trong apply form.');
          setLoading(false);
        }
        if (uploadUrl) {
          applicationData.avatar = uploadUrl;
        }
      });
    }

    // an api is called to upload the avatar
    if (videoUpload && applicationData.email) {
      // an api is called to upload the description video
      ApplyPostRequest.getUploadUrlRequest.filename = normalizeImageName(videoUpload.name, '', videoUpload.name);
      ApplyPostRequest.getUploadUrlRequest.email = applicationData.email;
      CallApiUploadUrl = submit.getUploadUrl(ApplyPostRequest).then(async (value) => {
        const uploadUrl: string | undefined = value.uploadUrl;
        const uploaded = await uploadImage(videoUpload, uploadUrl || '');
        if (!uploaded) {
          toast.error('Lỗi upload video giới thiệu trong apply form.');
          setLoading(false);
        }
        applicationData.introductionVideo = uploadUrl;
      });
    }
    //an api is called to post an application form
    submit
      .submitTeacherApplication({ applicationPostRequest: applicationData })
      .then((res) => {
        if (res.status) {
          navigate(routes.PageWaitingApprove.path);
        }
        setLoading(false);
      })
      .catch((err) =>
        //if the response is an error
        console.error(err)
      );
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mb: 1 }}>
      <FieldLabel text="TÊN GIÁO VIÊN (họ và tên đầy đủ)" isRequired={true} />
      <TextField
        margin="normal"
        type="text"
        required
        fullWidth
        label=""
        value={applicationData.name}
        name="name"
        onChange={onChangeApplicationData}
        sx={spaces}
      />

      <FieldLabel text="SỐ ĐIỆN THOẠI" isRequired={true} />
      <TextField
        margin="normal"
        type="text"
        required
        fullWidth
        label=""
        value={applicationData.phone}
        name="phone"
        onChange={onChangeApplicationData}
        sx={spaces}
      />

      <FieldLabel text="EMAIL" isRequired={true} />
      <TextField
        margin="normal"
        type="email"
        required
        fullWidth
        label=""
        value={applicationData.email}
        name="email"
        onChange={onChangeApplicationData}
        sx={spaces}
      />

      <FieldLabel
        text="PROFILE URL"
        isRequired={true}
        desc="Nếu bạn có trang web chuyên nghiệp, trang Facebook hoặc hồ sơ LinkedIn giúp mô tả kinh nghiệm và kiến thức
        chuyên môn của bạn, hãy chia sẻ với chúng tôi ở đây! Nó sẽ giúp cho việc xác thực trở nên thuận lợi và nhanh
        chóng hơn."
      />
      <TextField
        margin="normal"
        type="text"
        required
        fullWidth
        label=""
        value={applicationData.profileUrl}
        name="profileUrl"
        onChange={onChangeApplicationData}
        sx={spaces}
      />

      <FieldLabel text="ĐỊA CHỈ" isRequired={true} />
      <TextField
        margin="normal"
        type="text"
        required
        fullWidth
        label=""
        value={applicationData.address}
        name="address"
        onChange={onChangeApplicationData}
        sx={spaces}
      />

      <FieldLabel
        text="KINH NGHIỆM GIẢNG DẠY"
        isRequired={true}
        desc="Hãy chia sẻ những kinh nghiệm giảng dạy của bạn với trẻ em từ 3-18 tuổi (với tư cách là giáo viên đứng lớp, cho
        tới trợ giảng, tình nguyện viên, thậm chí là việc dạy cho em bạn tại nhà,...) Hãy ghi cụ thể về thời gian bạn đã
        làm việc với trẻ em, các môn học bạn đã dạy và độ tuổi của những người học."
      />
      <TextField
        margin="normal"
        multiline
        rows={3}
        type="text"
        required
        fullWidth
        label=""
        value={applicationData.teachingExperience}
        name="teachingExperience"
        onChange={onChangeApplicationData}
        sx={spaces}
      />

      <FieldLabel
        text="AVATAR"
        isRequired={true}
        desc="Hãy chọn 1 ảnh cá nhân để làm hình nền đại diện, giúp phụ huynh và học sinh dễ dàng nhận biết và tăng sự tin
        tưởng ở bạn. Lưu ý, hãy chọn ảnh vuông, hiển thị rõ gương mặt bạn, có kích cỡ không quá nhỏ, không bị mờ."
      />
      <Box>
        <Button variant="outlined" component="label" sx={{ my: 2 }}>
          Upload Avatar
          <input hidden accept="image/*" type="file" onChange={onUploadImage} />
        </Button>{' '}
        {imageUpload && imageUpload.name}
      </Box>

      <FieldLabel text="VIDEO GIỚI THIỆU (NẾU CÓ)" isRequired={false} />
      <Box>
        <Button variant="outlined" component="label" sx={{ mb: 2 }}>
          Upload Video
          <input hidden accept="video/*" type="file" onChange={onUploadVideo} />
        </Button>{' '}
        {videoUpload && videoUpload.name}
      </Box>

      <FieldLabel
        text="BẰNG CẤP/CHỨNG CHỈ"
        isRequired={true}
        desc="Vui lòng liệt kê mọi bằng cấp học thuật, chứng chỉ giảng dạy (hiện tại hoặc đã hết hạn), đào tạo chuyên môn hoặc
        các chứng chỉ chuyên môn mà bạn có. Chúng tôi sử dụng bằng cấp như một tiêu chí để đánh giá bạn đạt tiêu chuẩn
        giảng dạy trên nền tảng Amusingo hay không."
      />
      <Box sx={{ display: 'flex', alignItems: 'center', height: 'fit-content' }}>
        <TextField
          margin="normal"
          type="text"
          label=""
          size="small"
          sx={{ mt: 1, mb: 2 }}
          fullWidth
          value={inputCertificate}
          onChange={(e) => setInputCertificate(e.target.value)}
        />
        <Tooltip title="Thêm bằng cấp/chứng chỉ" placement="top">
          <IconButton type="button" aria-label="search" onClick={addCertificate}>
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {certificateList.map((certificate) => (
          <Chip
            key={certificate}
            label={certificate}
            variant="outlined"
            onDelete={() => deleteCertificate(certificate)}
          />
        ))}
      </Box>

      <FieldLabel text="LĨNH VỰC GIẢNG DẠY" isRequired={true} />
      <FormGroup sx={spaces}>
        <FormControlLabel control={<Checkbox />} label="Kỹ Năng" value="Kỹ Năng" onChange={handleCategoryClick} />
        <FormControlLabel control={<Checkbox />} label="Ngoại Ngữ" value="Ngoại Ngữ" onChange={handleCategoryClick} />
        <FormControlLabel
          control={<Checkbox />}
          label="Lập Trình/STEM"
          value="Lập Trình/STEM"
          onChange={handleCategoryClick}
        />
        <FormControlLabel control={<Checkbox />} label="Nghệ Thuật" value="Nghệ Thuật" onChange={handleCategoryClick} />
      </FormGroup>

      <FieldLabel
        text="MÔ TẢ"
        isRequired={true}
        desc="Hãy giới thiệu bản thân trong 1-2 câu ngắn gọn, giúp phụ huynh và học sinh nhanh chóng hiểu về bạn. Lưu ý, thông
        tin này sẽ được hiển thị trên trang web của Amusingo, do đó hãy viết câu văn đầy đủ, tròn ý, thể hiện được nét
        đặc trưng của bản thân."
      />
      <TextField
        margin="normal"
        multiline
        rows={5}
        type="text"
        required
        fullWidth
        label=""
        value={applicationData.description}
        name="description"
        onChange={onChangeApplicationData}
      />

      <Button
        type="submit"
        disabled={loading}
        fullWidth
        variant="contained"
        sx={{ pt: 1.5, pb: 1.5, mt: 4 }}
        startIcon={loading ? <CircularProgress size={20} /> : null}
      >
        Đăng kí ứng tuyển
      </Button>
    </Box>
  );
}
