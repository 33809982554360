import { SiteEnum } from 'common/types';

export const STUDENT_SITE_PATH_NAME = '/';
export const TEACHER_SITE_PATH_NAME = '/teacher';
export const TEACHER_SITE_PATH_NAME_PURE = 'teacher';

export function getCurrentSite(): SiteEnum {
  const pathnames = location.pathname.split('/');
  const isTeacherSite = pathnames.length > 1 && pathnames[1] === TEACHER_SITE_PATH_NAME_PURE;

  if (isTeacherSite) {
    return SiteEnum.Teacher;
  } else {
    return SiteEnum.Student;
  }
}

export const SITE: SiteEnum = getCurrentSite();
