import * as React from 'react';
import { Box, Grid, Card, CardContent, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import CakeIcon from '@mui/icons-material/Cake';
import TodayIcon from '@mui/icons-material/Today';

import Button from 'common/components/Button';
import { Clazz } from 'student/api';
import { routes } from 'student/Routes';
import { getCoursePrice } from 'common/utils';
import Loading from 'common/components/Loading';

interface Props {
  clazz: Clazz;
}

const RootStyled = styled('div')(({ theme }) => ({
  '& .card-main': {
    display: 'flex',
    boxShadow: 'none',
    gap: theme.spacing(3),
    pt: theme.spacing(1),
    borderRadius: '30px',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardContent-root': {
      width: '100%',
    },
  },
}));

export default function ClazzInfoSectionPrice({ clazz }: Props) {
  if (!clazz) {
    return <Loading />;
  }

  const numberOfWeeks = (clazz.numLessons || 1) / (clazz.frequencyPerWeek || 1);
  const minAllowedLearners = clazz.numAllowedLearners?.minAllowedLearners || 5;
  const maxAllowedLearners = clazz.numAllowedLearners?.maxAllowedLearners || 10;

  const userData = [
    {
      icon: <GroupIcon />,
      name: `${minAllowedLearners}-${maxAllowedLearners}`,
      detail: 'học sinh một lớp',
    },
    {
      icon: <AccessTimeIcon />,
      name: `${clazz.lengthOfLesson} phút`,
      detail: 'một buổi',
    },
    {
      icon: <CakeIcon />,
      name: `${clazz.ageRange?.minAge}-${clazz.ageRange?.maxAge}`,
      detail: 'tuổi',
    },
    {
      icon: <TodayIcon />,
      name: <Box>{clazz.frequencyPerWeek + ' buổi/tuần'}</Box>,
      detail: 'trong ',
      name2: <Box>{numberOfWeeks + ' tuần'} </Box>,
    },
  ];

  return (
    <RootStyled>
      <Card className="card-main">
        <CardContent
          sx={{
            pt: 5,
            px: {
              xs: 3,
              sm: 6,
              md: 6,
              lg: 6,
            },
            pb: '40px !important',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <Grid container spacing={2}>
                {userData.map((v) => (
                  <Grid item xs={12} md={6} key={Math.random()}>
                    <Stack direction="row" alignItems="center" justifyItems="center" spacing={2} width="fit-content">
                      <Box>{v.icon}</Box>
                      <Typography variant="h4" display="flex" alignItems="center" mb={0} gap={1} noWrap>
                        {v.name}
                        <Typography variant="body1">{v.detail}</Typography>
                        {v.name2 || ''}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4} textAlign={{ md: 'end' }} my="auto">
              <Button size="large" variant="outlined" color="secondary">
                <Typography variant="h4" display="flex" mx="auto" px={1}>
                  {clazz.price === -1 ? (
                    'Miễn phí'
                  ) : (
                    <Stack>
                      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                        {getCoursePrice(clazz.price, clazz.numLessons)}
                      </Stack>

                      {/*
                      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                        <Typography fontSize="0.8rem !important">(</Typography>
                        <Typography sx={{ mr: 0.6 }} fontSize="1.3rem !important" fontWeight="800 !important">
                          ~
                        </Typography>
                        <Typography variant="h6">
                          {formatPrice((clazz.price ?? 0) * (clazz.numLessons ?? 0))}
                        </Typography>
                        <Typography sx={{ ml: 0.6 }} fontSize="0.8rem !important">
                          đ/khóa)
                        </Typography>
                      </Stack>
                      */}
                    </Stack>
                  )}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </RootStyled>
  );
}
