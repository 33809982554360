import React from 'react';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import './style.scss';
import Link from 'common/components/Link';
import A from 'common/assets';

interface Props {
  handleDrawerToggle: () => void;
}

export default function HeaderMobileAppBar({ handleDrawerToggle }: Props) {
  return (
    <>
      <AppBar component="nav" style={{ background: '#FFFFFF' }}>
        <Toolbar>
          <Link to="/">
            <img src={A.images.logo} alt="Amusingo logo" style={{ maxWidth: '80%', maxHeight: '80%' }} />
          </Link>
          <IconButton
            color="primary"
            aria-label="open drawer"
            size="large"
            onClick={handleDrawerToggle}
            style={{ position: 'absolute', right: '0' }}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
