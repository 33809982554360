import React from 'react';
import { Courses } from '../types';
import CourseLoadingCard from 'common/components/Cards/ClassesCard/CourseLoadingCard';
import { Box } from '@mui/material';
import Divider from 'common/components/Divider';
import CourseListItem from './CourseListItem';
import ItemNotFound from 'common/components/ItemNotFound/ItemNotFoundPage';

interface CourseListProps {
  courses: Courses;
}

const CourseScheduleList = ({ courses }: CourseListProps) => {
  const { items, loading } = courses;

  if (loading) {
    return <CourseLoadingCard />;
  }

  return (
    <Box sx={{ mb: 10 }}>
      {items.length > 0 ? (
        <>
          {items.map((course, index) => (
            <React.Fragment key={course.clazzId}>
              <CourseListItem course={course} />
              {index !== items.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ))}
        </>
      ) : (
        <ItemNotFound exploreMore={true}></ItemNotFound>
      )}
    </Box>
  );
};

export default CourseScheduleList;
