import { Clazz as Course } from 'teacher/api/models';
import { CoursesFilter } from '../types';
import {
  getWeekDayNumber,
  getFlatSchedules,
  FlatSchedule,
  getClassScheduleStartMs,
  getClassScheduleEndMs,
  getHourByMs,
} from 'common/utils';

export const filterCourses = (courses: Course[], selectedFilter: CoursesFilter): Course[] => {
  let filteredCourses = [...courses];

  // Filter by status
  if (selectedFilter.statusOptions.length > 0) {
    filteredCourses = filteredCourses.filter((course: Course) => {
      const { approvalStatus } = course;
      if (!approvalStatus) return false;
      for (const status of selectedFilter.statusOptions) {
        const { value } = status;
        if (value === approvalStatus) return true;
      }
    });
  }

  // TO-DO: filter by time

  // Filter by week day
  if (selectedFilter.weekDayOptions.length > 0) {
    filteredCourses = filteredCourses.filter((clazz: Course) => {
      const classSchedules = clazz.schedules;
      if (classSchedules === undefined) return false;

      const flatSchedules: FlatSchedule[] = getFlatSchedules(classSchedules);

      for (const weekDayOption of selectedFilter.weekDayOptions) {
        const { value } = weekDayOption;
        if (value === undefined) return false;

        for (const element of flatSchedules) {
          const schedule: FlatSchedule = element;
          const isWeekDayNumber = schedule.scheduleTimes?.some((sch) => {
            const weekDayNumber = getWeekDayNumber(new Date(parseInt(sch.start) * 1000));
            return weekDayNumber === value;
          });
          if (isWeekDayNumber) return true;
        }
      }

      return false;
    });
  }

  // Filter by time slot
  if (selectedFilter.timeSlotOptions.length > 0) {
    filteredCourses = filteredCourses.filter((clazz: Course) => {
      const classSchedules = clazz.schedules;
      if (classSchedules === undefined) {
        return false;
      }

      const flatSchedules: FlatSchedule[] = getFlatSchedules(classSchedules);

      for (const element of selectedFilter.timeSlotOptions) {
        const { value } = element;
        if (value === undefined || value.minTimeSlot === undefined || value.maxTimeSlot === undefined) return false;

        for (const element of flatSchedules) {
          const schedule: FlatSchedule = element;

          const isTimeSlotSatisfied = schedule.scheduleTimes?.some((sch) => {
            const startHour = getHourByMs(getClassScheduleStartMs(sch));
            const endHour = getHourByMs(getClassScheduleEndMs(sch, clazz.lessonTime || 0));

            return (
              (value.minTimeSlot <= startHour && startHour <= value.maxTimeSlot) ||
              (value.minTimeSlot <= endHour && endHour <= value.maxTimeSlot)
            );
          });

          if (isTimeSlotSatisfied) return true;
        }
      }

      return false;
    });
  }

  return filteredCourses;
};
