/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BasicResponse,
  ParentProfile,
  ParentProfilePostRequest,
  ParentProfilePutRequest,
} from '../models';
import {
    BasicResponseFromJSON,
    BasicResponseToJSON,
    ParentProfileFromJSON,
    ParentProfileToJSON,
    ParentProfilePostRequestFromJSON,
    ParentProfilePostRequestToJSON,
    ParentProfilePutRequestFromJSON,
    ParentProfilePutRequestToJSON,
} from '../models';

export interface CreateParentProfileRequest {
    userId: string;
    parentProfilePostRequest: ParentProfilePostRequest;
}

export interface GetParentProfileRequest {
    userId: string;
}

export interface UpdateParentProfileRequest {
    userId: string;
    parentProfilePutRequest: ParentProfilePutRequest;
}

/**
 *
 */
export class ParentProfileApi extends runtime.BaseAPI {

    /**
     * Create a new parent profile
     */
    async createParentProfileRaw(requestParameters: CreateParentProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParentProfile>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling createParentProfile.');
        }

        if (requestParameters.parentProfilePostRequest === null || requestParameters.parentProfilePostRequest === undefined) {
            throw new runtime.RequiredError('parentProfilePostRequest','Required parameter requestParameters.parentProfilePostRequest was null or undefined when calling createParentProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parent-profiles/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParentProfilePostRequestToJSON(requestParameters.parentProfilePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParentProfileFromJSON(jsonValue));
    }

    /**
     * Create a new parent profile
     */
    async createParentProfile(requestParameters: CreateParentProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParentProfile> {
        const response = await this.createParentProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get detailed profile of a parent
     */
    async getParentProfileRaw(requestParameters: GetParentProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParentProfile>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getParentProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parent-profiles/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParentProfileFromJSON(jsonValue));
    }

    /**
     * Get detailed profile of a parent
     */
    async getParentProfile(requestParameters: GetParentProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParentProfile> {
        const response = await this.getParentProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update parent profile
     */
    async updateParentProfileRaw(requestParameters: UpdateParentProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ParentProfile>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateParentProfile.');
        }

        if (requestParameters.parentProfilePutRequest === null || requestParameters.parentProfilePutRequest === undefined) {
            throw new runtime.RequiredError('parentProfilePutRequest','Required parameter requestParameters.parentProfilePutRequest was null or undefined when calling updateParentProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parent-profiles/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ParentProfilePutRequestToJSON(requestParameters.parentProfilePutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParentProfileFromJSON(jsonValue));
    }

    /**
     * Update parent profile
     */
    async updateParentProfile(requestParameters: UpdateParentProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ParentProfile> {
        const response = await this.updateParentProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
