import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AuthForm from 'student/features/auth/components/AuthForm';

export default function PageAuth() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ py: 4, px: 0, width: '100%', height: '100%' }}>
        <AuthForm />
      </Box>
    </Container>
  );
}
