import { createTheme } from '@mui/material/styles';
import A from 'common/assets';

import 'common/assets/fonts/SVN-Poppins-Black.ttf';
import 'common/assets/fonts/SVN-Poppins-Bold.ttf';
import 'common/assets/fonts/SVN-Poppins-Light.ttf';
import 'common/assets/fonts/SVN-Poppins-Medium.ttf';
import 'common/assets/fonts/SVN-Poppins-Regular.ttf';
import 'common/assets/fonts/SVN-Poppins-SemiBold.ttf';

interface Props {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}
function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg, xl }: Props) {
  return {
    '@media (max-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
    '@media (min-width:ack)': {
      fontSize: pxToRem(xl),
    },
  };
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: A.colors.primary,
    },
    secondary: {
      main: A.colors.secondary,
    },
    error: {
      main: A.colors.error,
    },
    info: {
      main: A.colors.secondary,
    },
    text: {
      primary: A.colors.text,
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: A.colors.white,
      default: A.colors.white,
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  typography: {
    fontFamily: 'SVN-Poppins-Regular',
    fontSize: 16,
    ...responsiveFontSizes({ sm: 14, md: 14, lg: 16, xl: 18 }),
    h4: {
      fontFamily: 'SVN-Poppins-Bold',
      ...responsiveFontSizes({ sm: 22, md: 22, lg: 28, xl: 36 }),
    },
    h5: {
      fontFamily: 'SVN-Poppins-Bold',
      ...responsiveFontSizes({ sm: 20, md: 22, lg: 24, xl: 30 }),
    },
    h6: {
      fontFamily: 'SVN-Poppins-Bold',
      ...responsiveFontSizes({ sm: 18, md: 20, lg: 20, xl: 24 }),
    },
    subtitle1: {
      fontFamily: 'SVN-Poppins-Regular',
      ...responsiveFontSizes({ sm: 18, md: 20, lg: 20, xl: 24 }),
    },
    subtitle2: {
      fontFamily: 'SVN-Poppins-Regular',
      ...responsiveFontSizes({ sm: 14, md: 14, lg: 16, xl: 20 }),
    },
    body1: {
      fontSize: '16px !important',
      fontFamily: 'SVN-Poppins-Regular',
      ...responsiveFontSizes({ sm: 14, md: 14, lg: 16, xl: 20 }),
    },
    body2: {
      fontSize: '16px !important',
      fontFamily: 'SVN-Poppins-Regular',
      ...responsiveFontSizes({ sm: 12, md: 13, lg: 14, xl: 16 }),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
        },
      },
    },
  },
});

export default customTheme;
