/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * clazz Post Request Params
 * @export
 * @interface ClazzPostRequestParams
 */
export interface ClazzPostRequestParams {
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    organizationName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    teacherId: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    clazzName: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    categoryId: string;
    /**
     * 
     * @type {any}
     * @memberof ClazzPostRequestParams
     */
    thumbnailImage: any | null;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    introductionVideo?: string;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    numLessons: number;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    maxStudents: number;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    minStudents: number;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    clazzType: ClazzPostRequestParamsClazzTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    minAge: number;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    maxAge: number;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    lessonTime: number;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    syllabus: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    references?: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzPostRequestParams
     */
    requirements?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClazzPostRequestParams
     */
    sampleFinalResults?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ClazzPostRequestParams
     */
    frequencyPerWeek?: number;
}


/**
 * @export
 */
export const ClazzPostRequestParamsClazzTypeEnum = {
    MultipleLessons: 'MULTIPLE_LESSONS',
    OneLesson: 'ONE_LESSON'
} as const;
export type ClazzPostRequestParamsClazzTypeEnum = typeof ClazzPostRequestParamsClazzTypeEnum[keyof typeof ClazzPostRequestParamsClazzTypeEnum];


/**
 * Check if a given object implements the ClazzPostRequestParams interface.
 */
export function instanceOfClazzPostRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "teacherId" in value;
    isInstance = isInstance && "clazzName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "categoryId" in value;
    isInstance = isInstance && "thumbnailImage" in value;
    isInstance = isInstance && "numLessons" in value;
    isInstance = isInstance && "maxStudents" in value;
    isInstance = isInstance && "minStudents" in value;
    isInstance = isInstance && "clazzType" in value;
    isInstance = isInstance && "minAge" in value;
    isInstance = isInstance && "maxAge" in value;
    isInstance = isInstance && "lessonTime" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "syllabus" in value;

    return isInstance;
}

export function ClazzPostRequestParamsFromJSON(json: any): ClazzPostRequestParams {
    return ClazzPostRequestParamsFromJSONTyped(json, false);
}

export function ClazzPostRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClazzPostRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'teacherId': json['teacherId'],
        'clazzName': json['clazzName'],
        'description': json['description'],
        'categoryId': json['categoryId'],
        'thumbnailImage': json['thumbnailImage'],
        'introductionVideo': !exists(json, 'introductionVideo') ? undefined : json['introductionVideo'],
        'numLessons': json['numLessons'],
        'maxStudents': json['maxStudents'],
        'minStudents': json['minStudents'],
        'clazzType': json['clazzType'],
        'minAge': json['minAge'],
        'maxAge': json['maxAge'],
        'lessonTime': json['lessonTime'],
        'price': json['price'],
        'syllabus': json['syllabus'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'requirements': !exists(json, 'requirements') ? undefined : json['requirements'],
        'sampleFinalResults': !exists(json, 'sampleFinalResults') ? undefined : json['sampleFinalResults'],
        'frequencyPerWeek': !exists(json, 'frequencyPerWeek') ? undefined : json['frequencyPerWeek'],
    };
}

export function ClazzPostRequestParamsToJSON(value?: ClazzPostRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'organizationName': value.organizationName,
        'teacherId': value.teacherId,
        'clazzName': value.clazzName,
        'description': value.description,
        'categoryId': value.categoryId,
        'thumbnailImage': value.thumbnailImage,
        'introductionVideo': value.introductionVideo,
        'numLessons': value.numLessons,
        'maxStudents': value.maxStudents,
        'minStudents': value.minStudents,
        'clazzType': value.clazzType,
        'minAge': value.minAge,
        'maxAge': value.maxAge,
        'lessonTime': value.lessonTime,
        'price': value.price,
        'syllabus': value.syllabus,
        'references': value.references,
        'requirements': value.requirements,
        'sampleFinalResults': value.sampleFinalResults,
        'frequencyPerWeek': value.frequencyPerWeek,
    };
}

