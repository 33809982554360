import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import A from 'common/assets';

export default function CustomLink(props: any) {
  const L = styled(Link)({
    color: A.colors.text,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  });

  return <L {...props} />;
}
