/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Review details
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    parentName?: string;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    rate?: number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    imageS3PresignedUrl?: string;
}

/**
 * Check if a given object implements the Review interface.
 */
export function instanceOfReview(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReviewFromJSON(json: any): Review {
    return ReviewFromJSONTyped(json, false);
}

export function ReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Review {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classId': !exists(json, 'classId') ? undefined : json['classId'],
        'parentName': !exists(json, 'parentName') ? undefined : json['parentName'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'imageS3PresignedUrl': !exists(json, 'imageS3PresignedUrl') ? undefined : json['imageS3PresignedUrl'],
    };
}

export function ReviewToJSON(value?: Review | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classId': value.classId,
        'parentName': value.parentName,
        'rate': value.rate,
        'content': value.content,
        'imageS3PresignedUrl': value.imageS3PresignedUrl,
    };
}

