/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Teacher Profile Application Information
 * @export
 * @interface ApplicationPostRequest
 */
export interface ApplicationPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    teachingExperience: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    profileUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationPostRequest
     */
    introductionVideo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationPostRequest
     */
    teachingFields: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationPostRequest
     */
    certificates?: Array<string>;
}

/**
 * Check if a given object implements the ApplicationPostRequest interface.
 */
export function instanceOfApplicationPostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "avatar" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "teachingExperience" in value;
    isInstance = isInstance && "profileUrl" in value;
    isInstance = isInstance && "teachingFields" in value;

    return isInstance;
}

export function ApplicationPostRequestFromJSON(json: any): ApplicationPostRequest {
    return ApplicationPostRequestFromJSONTyped(json, false);
}

export function ApplicationPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'address': json['address'],
        'phone': json['phone'],
        'avatar': json['avatar'],
        'description': json['description'],
        'teachingExperience': json['teachingExperience'],
        'profileUrl': json['profileUrl'],
        'introductionVideo': !exists(json, 'introductionVideo') ? undefined : json['introductionVideo'],
        'teachingFields': json['teachingFields'],
        'certificates': !exists(json, 'certificates') ? undefined : json['certificates'],
    };
}

export function ApplicationPostRequestToJSON(value?: ApplicationPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'address': value.address,
        'phone': value.phone,
        'avatar': value.avatar,
        'description': value.description,
        'teachingExperience': value.teachingExperience,
        'profileUrl': value.profileUrl,
        'introductionVideo': value.introductionVideo,
        'teachingFields': value.teachingFields,
        'certificates': value.certificates,
    };
}

