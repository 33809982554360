/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Achievement,
  AchievementList,
  AchievementPostRequestParams,
  AchievementUploadUrls,
} from '../models';
import {
    AchievementFromJSON,
    AchievementToJSON,
    AchievementListFromJSON,
    AchievementListToJSON,
    AchievementPostRequestParamsFromJSON,
    AchievementPostRequestParamsToJSON,
    AchievementUploadUrlsFromJSON,
    AchievementUploadUrlsToJSON,
} from '../models';

export interface CreateAchievementRequest {
    classId: string;
    studentId: string;
    achievementPostRequestParams: AchievementPostRequestParams;
}

export interface GetAchievementByStudentRequest {
    classId: string;
    studentId: string;
}

export interface GetAchievementUploadUrlsRequest {
    classId: string;
    studentId: string;
    achievementPostRequestParams: AchievementPostRequestParams;
}

export interface GetAchievementsRequest {
    classId: string;
}

export interface UpdateAchievementRequest {
    classId: string;
    studentId: string;
    achievementPostRequestParams: AchievementPostRequestParams;
}

/**
 *
 */
export class AchievementApi extends runtime.BaseAPI {

    /**
     * create a new achievement
     */
    async createAchievementRaw(requestParameters: CreateAchievementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Achievement>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling createAchievement.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling createAchievement.');
        }

        if (requestParameters.achievementPostRequestParams === null || requestParameters.achievementPostRequestParams === undefined) {
            throw new runtime.RequiredError('achievementPostRequestParams','Required parameter requestParameters.achievementPostRequestParams was null or undefined when calling createAchievement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/classes/{classId}/students/{studentId}/achievements`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AchievementPostRequestParamsToJSON(requestParameters.achievementPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AchievementFromJSON(jsonValue));
    }

    /**
     * create a new achievement
     */
    async createAchievement(requestParameters: CreateAchievementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Achievement> {
        const response = await this.createAchievementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all achievements of a student based on classId and studentId
     */
    async getAchievementByStudentRaw(requestParameters: GetAchievementByStudentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AchievementList>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getAchievementByStudent.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getAchievementByStudent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/classes/{classId}/students/{studentId}/achievements`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AchievementListFromJSON(jsonValue));
    }

    /**
     * Get all achievements of a student based on classId and studentId
     */
    async getAchievementByStudent(requestParameters: GetAchievementByStudentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AchievementList> {
        const response = await this.getAchievementByStudentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get upload urls for achievements
     */
    async getAchievementUploadUrlsRaw(requestParameters: GetAchievementUploadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AchievementUploadUrls>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getAchievementUploadUrls.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getAchievementUploadUrls.');
        }

        if (requestParameters.achievementPostRequestParams === null || requestParameters.achievementPostRequestParams === undefined) {
            throw new runtime.RequiredError('achievementPostRequestParams','Required parameter requestParameters.achievementPostRequestParams was null or undefined when calling getAchievementUploadUrls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/classes/{classId}/students/{studentId}/achievement-upload-urls`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AchievementPostRequestParamsToJSON(requestParameters.achievementPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AchievementUploadUrlsFromJSON(jsonValue));
    }

    /**
     * Get upload urls for achievements
     */
    async getAchievementUploadUrls(requestParameters: GetAchievementUploadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AchievementUploadUrls> {
        const response = await this.getAchievementUploadUrlsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all achievements of a class based on classId
     */
    async getAchievementsRaw(requestParameters: GetAchievementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AchievementList>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getAchievements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/classes/{classId}/achievements`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AchievementListFromJSON(jsonValue));
    }

    /**
     * Get all achievements of a class based on classId
     */
    async getAchievements(requestParameters: GetAchievementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AchievementList> {
        const response = await this.getAchievementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update an achievement
     */
    async updateAchievementRaw(requestParameters: UpdateAchievementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Achievement>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling updateAchievement.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling updateAchievement.');
        }

        if (requestParameters.achievementPostRequestParams === null || requestParameters.achievementPostRequestParams === undefined) {
            throw new runtime.RequiredError('achievementPostRequestParams','Required parameter requestParameters.achievementPostRequestParams was null or undefined when calling updateAchievement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/classes/{classId}/students/{studentId}/achievements`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AchievementPostRequestParamsToJSON(requestParameters.achievementPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AchievementFromJSON(jsonValue));
    }

    /**
     * update an achievement
     */
    async updateAchievement(requestParameters: UpdateAchievementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Achievement> {
        const response = await this.updateAchievementRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
