import * as React from 'react';
import { Box, Typography } from '@mui/material';

import { LessonScheduleTime } from 'student/api';
import { getHourMinute, getClassScheduleStartMs, getClassScheduleEndMs, getWeekDay } from 'common/utils';

interface Props {
  lessonScheduleTime: LessonScheduleTime;
  lengthOfLesson: number; // minutes
}

export default function LessonScheduleItemText({ lessonScheduleTime, lengthOfLesson }: Props) {
  const startDate = new Date(getClassScheduleStartMs(lessonScheduleTime));
  const endDate = new Date(getClassScheduleEndMs(lessonScheduleTime, lengthOfLesson)); // lessonScheduleTime.end is the life time end of the lesson

  return (
    <Box>
      <Typography variant="body1" color="text.primary">
        <b>{getWeekDay(startDate)}</b> hàng tuần lúc{' '}
        <b>
          {getHourMinute(startDate)}~{getHourMinute(endDate)}
        </b>
      </Typography>
    </Box>
  );
}
