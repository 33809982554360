import React from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Avatar,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material';
import {
  Search,
  School,
  Help,
  Login,
  Logout,
  Menu as MenuIcon,
  Settings,
  Today,
  ArrowDropDown,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import A from 'common/assets';
import Link from 'common/components/Link/Link';
import Button from 'common/components/Button';
import { CategoryNameList } from 'common/components/Category/CategoryNameList';
import { RootState } from 'student/store';
import { useSelector } from 'react-redux';
import { NavItem } from './Header';

import './style.scss';

const drawerWidth = 270;

interface Props {
  isOpenDrawer: boolean;
  handleDrawerToggle: () => void;
  navItems: NavItem[];
}

export default function HeaderMobileDrawer({ navItems, isOpenDrawer, handleDrawerToggle }: Props) {
  const navigate = useNavigate();

  const GroupMenuItems = ({ navItem }: { navItem: NavItem }) => {
    const navItems = navItem.navItems;

    return (
      <>
        <Divider />
        {navItems?.map((navItem) => (
          <SingleItem key={navItem.label} navItem={navItem} />
        ))}
      </>
    );
  };

  const SingleItem = ({ navItem }: { navItem: NavItem }) => {
    return (
      <ListItem key={navItem.label} disablePadding>
        <Link to={navItem.route}>
          <Button variant={navItem?.variant ? navItem.variant : 'text'} className="ms-2" startIcon={navItem.startIcon}>
            {navItem.label}
          </Button>
        </Link>
      </ListItem>
    );
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box sx={{ p: 2 }}>
        <Link to="/">
          <img src={A.images.logo} alt="Amusingo logo" />
        </Link>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) =>
          item.navItems ? (
            <GroupMenuItems key={item.label + item.route} navItem={item} />
          ) : (
            <SingleItem key={item.label + item.route} navItem={item} />
          )
        )}
      </List>
      <Divider />
      <Box sx={{ p: 2 }}>
        <CategoryNameList handleCategoryClick={(category) => navigate(`/courses?categoryId=${category.categoryId}`)} />
      </Box>
    </Box>
  );

  return (
    <>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={isOpenDrawer}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}
