/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Teacher Profile Post Request (to create a new profile)
 * @export
 * @interface TeacherProfilePostRequest
 */
export interface TeacherProfilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    introduction: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    introductionVideo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherProfilePostRequest
     */
    teachingFields: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfilePostRequest
     */
    profileUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherProfilePostRequest
     */
    certificates?: Array<string>;
}

/**
 * Check if a given object implements the TeacherProfilePostRequest interface.
 */
export function instanceOfTeacherProfilePostRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "introduction" in value;
    isInstance = isInstance && "teachingFields" in value;

    return isInstance;
}

export function TeacherProfilePostRequestFromJSON(json: any): TeacherProfilePostRequest {
    return TeacherProfilePostRequestFromJSONTyped(json, false);
}

export function TeacherProfilePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeacherProfilePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'name': json['name'],
        'address': json['address'],
        'phone': json['phone'],
        'email': json['email'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'introduction': json['introduction'],
        'introductionVideo': !exists(json, 'introductionVideo') ? undefined : json['introductionVideo'],
        'teachingFields': json['teachingFields'],
        'profileUrl': !exists(json, 'profileUrl') ? undefined : json['profileUrl'],
        'certificates': !exists(json, 'certificates') ? undefined : json['certificates'],
    };
}

export function TeacherProfilePostRequestToJSON(value?: TeacherProfilePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'name': value.name,
        'address': value.address,
        'phone': value.phone,
        'email': value.email,
        'avatar': value.avatar,
        'introduction': value.introduction,
        'introductionVideo': value.introductionVideo,
        'teachingFields': value.teachingFields,
        'profileUrl': value.profileUrl,
        'certificates': value.certificates,
    };
}

