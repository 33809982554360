import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card, CardContent, CardMedia, Rating, IconButton, Typography, Stack, Button } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'teacher/Routes';
import { CoPresent } from '@mui/icons-material';
import A from 'common/assets';
import { useResponsive } from 'teacher/hooks';
import { Clazz as Course } from 'teacher/api/models';
import { getNextLesson } from '../utils/getNextLesson';

interface CourseListItemProps {
  course: Course;
}

const RootStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  '& .card-main': {
    display: 'flex',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    gap: theme.spacing(3),
    pt: theme.spacing(1.5),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      width: 339,
      borderRadius: '30px',
      objectFit: 'cover',
      height: 191,
      cursor: 'pointer',
      [theme.breakpoints.down('xl')]: {
        width: 250,
        height: 150,
      },
    },
  },
  '& .join-btn': {
    position: 'absolute',
    bottom: '20px',
    right: 0,
    fontFamily: A.fonts.bold,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      bottom: 0,
      left: '16px',
    },
  },
  '& .share-btn': {
    position: 'absolute',
    top: '16px',
    right: 0,
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 0,
      right: '-90px',
    },
  },
}));

const CourseListItem = ({ course }: CourseListItemProps) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  return (
    <RootStyled>
      <Card className="card-main">
        <Box sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start', alignItems: 'center' }}>
          <CardMedia
            sx={{ width: isMobile ? '300px !important' : '250px' }}
            component="img"
            image={course.thumbnailImageUrl}
            alt="Live from space album cover"
            onClick={() => navigate(routes.PageMyCourse.path.replace(':courseId', `${course.clazzId}`))}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' }, width: 1, padding: '1rem 0' }}>
          <CardContent sx={{ flex: '1 0 auto', py: 0 + '!important' }}>
            <Typography
              component={Link}
              to={routes.PageMyCourse.path.replace(':courseId', `${course.clazzId}`)}
              variant="h5"
              mb={0.5}
            >
              {course.clazzName}
            </Typography>
            <Stack direction="row" alignItems="center" my={{ xl: 1, xs: 0.5 }}>
              <Rating readOnly name="hover-feedback" value={course.averageRating} precision={0.5} />
              <Box sx={{ ml: 1 }}>( {course.numReviews} )</Box>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <CoPresent sx={{ fontSize: 20 }} />
              <Box>
                <Typography variant="body1" color="text.primary">
                  Đã có {course.numEnrolledStudents} học sinh đăng ký
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" gap={2}>
              <EventIcon sx={{ fontSize: 20 }} />
              <Box>
                <Typography variant="body1" color="text.primary" sx={{ maxWidth: '300px' }}>
                  Buổi học tiếp theo: {getNextLesson(course.schedules)}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Box>
      </Card>
      <Button variant="contained" className="join-btn">
        Tham gia buổi học
      </Button>
      <Button className="share-btn">
        <img src={A.images.icon_share} alt="Share Icon" />
      </Button>
    </RootStyled>
  );
};

export default CourseListItem;
