import * as React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import { CommonRoutes, commonRoutes } from 'common/Routes';
import { TEACHER_SITE_PATH_NAME } from 'common/config';

import { TeacherLayout } from 'teacher/layout';
import PageApply from 'teacher/pages/PageApply/PageApply';
import PageAuth from 'teacher/pages/PageAuth';
import PageHome from 'teacher/pages/PageHome';
import PageDashboard from 'teacher/pages/PageDashboard/PageDashboard';
import PageMyCourse from 'teacher/pages/PageMyCourse';
import PageMyCourses from 'teacher/pages/PageMyCourses';
import PageMyCourseUpdate from 'teacher/pages/PageMyCourseUpdate/PageMyCourseUpdate';
import PageMyOrganization from 'teacher/pages/PageMyOrganization';
import PageProfile from 'teacher/pages/PageProfile';
import PageProfileUpdate from 'teacher/pages/PageProfileUpdate';
import PageSchedule from 'teacher/pages/PageSchedule';
import PageFAQ from 'teacher/pages/PageFAQ';
import PageLogout from 'teacher/pages/PageLogout';
import PageCourseCreate from './pages/PageCourseCreate/PageCourseCreate';
import PageWaitingApprove from './pages/PageWaitingApprove';
import nProgress from 'nprogress';

export const routes: CommonRoutes = {
  PageHome: {
    path: TEACHER_SITE_PATH_NAME + '/',
    name: 'PageHome',
    Element: PageHome,
  },
  PageApply: {
    path: TEACHER_SITE_PATH_NAME + '/apply',
    name: 'PageApply',
    Element: PageApply,
  },
  PageAuth: {
    path: TEACHER_SITE_PATH_NAME + '/auth',
    name: 'PageAuth',
    Element: PageAuth,
  },
  PageDashboard: {
    path: TEACHER_SITE_PATH_NAME + '/dashboard',
    name: 'PageDashboard',
    Element: PageDashboard,
  },
  PageMyCourses: {
    path: TEACHER_SITE_PATH_NAME + '/courses',
    name: 'PageMyCourses',
    Element: PageMyCourses,
  },
  PageMyCourse: {
    path: TEACHER_SITE_PATH_NAME + '/courses/:courseId',
    name: 'PageMyCourse',
    Element: PageMyCourse,
  },
  PageMyCourseUpdate: {
    path: TEACHER_SITE_PATH_NAME + '/courses/:courseId/update',
    name: 'PageMyCourseUpdate',
    Element: PageMyCourseUpdate,
  },
  PageMyOrganization: {
    path: TEACHER_SITE_PATH_NAME + '/organization',
    name: 'PageMyOrganization',
    Element: PageMyOrganization,
  },
  PageProfile: {
    path: TEACHER_SITE_PATH_NAME + '/profile',
    name: 'PageProfile',
    Element: PageProfile,
  },
  PageProfileUpdate: {
    path: TEACHER_SITE_PATH_NAME + '/profile/update',
    name: 'PageProfileUpdate',
    Element: PageProfileUpdate,
  },
  PageSchedule: {
    path: TEACHER_SITE_PATH_NAME + '/schedules',
    name: 'PageSchedule',
    Element: PageSchedule,
  },
  PageFAQ: {
    path: TEACHER_SITE_PATH_NAME + '/faq',
    name: 'PageFAQ',
    Element: PageFAQ,
  },
  Logout: {
    path: TEACHER_SITE_PATH_NAME + '/logout',
    name: 'Logout',
    Element: PageLogout,
  },
  PageCourseCreate: {
    path: TEACHER_SITE_PATH_NAME + '/createCourse',
    name: 'PageCourseCreate',
    Element: PageCourseCreate,
  },
  PageWaitingApprove: {
    path: TEACHER_SITE_PATH_NAME + '/waitingApprove',
    name: 'PageWaitingApprove',
    Element: PageWaitingApprove,
  },
  ...commonRoutes,
};

export default function AppRoutes() {
  const location = useLocation();
  React.useMemo(() => {
    nProgress.start();
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    nProgress.start();
    nProgress.done();
  }, [location]);
  return (
    <>
      <Routes>
        <Route path="/" element={<TeacherLayout />}>
          <Route index element={<PageHome />} />

          {Object.values(routes).map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={route.needAuth ? <route.Element /> : <route.Element />}
            />
          ))}
        </Route>
      </Routes>
    </>
  );
}
