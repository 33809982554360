/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * parent profile post request params
 * @export
 * @interface ParentProfilePostRequest
 */
export interface ParentProfilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ParentProfilePostRequest
     */
    parentName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentProfilePostRequest
     */
    studentNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParentProfilePostRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentProfilePostRequest
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentProfilePostRequest
     */
    interestedFields?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParentProfilePostRequest
     */
    referredFromExistingUser?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentProfilePostRequest
     */
    dtCreated?: string;
}

/**
 * Check if a given object implements the ParentProfilePostRequest interface.
 */
export function instanceOfParentProfilePostRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParentProfilePostRequestFromJSON(json: any): ParentProfilePostRequest {
    return ParentProfilePostRequestFromJSONTyped(json, false);
}

export function ParentProfilePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParentProfilePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentName': !exists(json, 'parentName') ? undefined : json['parentName'],
        'studentNames': !exists(json, 'studentNames') ? undefined : json['studentNames'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'interestedFields': !exists(json, 'interestedFields') ? undefined : json['interestedFields'],
        'referredFromExistingUser': !exists(json, 'referredFromExistingUser') ? undefined : json['referredFromExistingUser'],
        'dtCreated': !exists(json, 'dtCreated') ? undefined : json['dtCreated'],
    };
}

export function ParentProfilePostRequestToJSON(value?: ParentProfilePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentName': value.parentName,
        'studentNames': value.studentNames,
        'phone': value.phone,
        'email': value.email,
        'interestedFields': value.interestedFields,
        'referredFromExistingUser': value.referredFromExistingUser,
        'dtCreated': value.dtCreated,
    };
}

