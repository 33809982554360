import React from 'react';
import { styled } from '@mui/material/styles';
import { RatingProps, Rating as MuiRating } from '@mui/material';

export default function Rating(props: RatingProps) {
  const Rating = styled(MuiRating)({});

  // Todo: Log event

  return <Rating {...props} />;
}
