/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Teacher Profile
 * @export
 * @interface TeacherProfile
 */
export interface TeacherProfile {
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    introduction: string;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    introductionVideo?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherProfile
     */
    teachingFields: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    profileUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeacherProfile
     */
    certificates?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    teacherId?: string;
    /**
     * 
     * @type {number}
     * @memberof TeacherProfile
     */
    rate?: number;
    /**
     * 
     * @type {number}
     * @memberof TeacherProfile
     */
    numReviews?: number;
    /**
     * 
     * @type {string}
     * @memberof TeacherProfile
     */
    accountCreationStatus?: TeacherProfileAccountCreationStatusEnum;
}


/**
 * @export
 */
export const TeacherProfileAccountCreationStatusEnum = {
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    RequestChanges: 'REQUEST_CHANGES',
    Rejected: 'REJECTED'
} as const;
export type TeacherProfileAccountCreationStatusEnum = typeof TeacherProfileAccountCreationStatusEnum[keyof typeof TeacherProfileAccountCreationStatusEnum];


/**
 * Check if a given object implements the TeacherProfile interface.
 */
export function instanceOfTeacherProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "introduction" in value;
    isInstance = isInstance && "teachingFields" in value;

    return isInstance;
}

export function TeacherProfileFromJSON(json: any): TeacherProfile {
    return TeacherProfileFromJSONTyped(json, false);
}

export function TeacherProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeacherProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'name': json['name'],
        'address': json['address'],
        'phone': json['phone'],
        'email': json['email'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'introduction': json['introduction'],
        'introductionVideo': !exists(json, 'introductionVideo') ? undefined : json['introductionVideo'],
        'teachingFields': json['teachingFields'],
        'profileUrl': !exists(json, 'profileUrl') ? undefined : json['profileUrl'],
        'certificates': !exists(json, 'certificates') ? undefined : json['certificates'],
        'teacherId': !exists(json, 'teacherId') ? undefined : json['teacherId'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'numReviews': !exists(json, 'numReviews') ? undefined : json['numReviews'],
        'accountCreationStatus': !exists(json, 'accountCreationStatus') ? undefined : json['accountCreationStatus'],
    };
}

export function TeacherProfileToJSON(value?: TeacherProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'name': value.name,
        'address': value.address,
        'phone': value.phone,
        'email': value.email,
        'avatar': value.avatar,
        'introduction': value.introduction,
        'introductionVideo': value.introductionVideo,
        'teachingFields': value.teachingFields,
        'profileUrl': value.profileUrl,
        'certificates': value.certificates,
        'teacherId': value.teacherId,
        'rate': value.rate,
        'numReviews': value.numReviews,
        'accountCreationStatus': value.accountCreationStatus,
    };
}

