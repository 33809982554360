/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClassSchedule } from './ClassSchedule';
import {
    ClassScheduleFromJSON,
    ClassScheduleFromJSONTyped,
    ClassScheduleToJSON,
} from './ClassSchedule';

/**
 * clazz information
 * @export
 * @interface Clazz
 */
export interface Clazz {
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    organizationName?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    teacherId: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    clazzName: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    categoryId: string;
    /**
     * 
     * @type {any}
     * @memberof Clazz
     */
    thumbnailImage: any | null;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    introductionVideo?: string;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    numLessons: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    maxStudents: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    minStudents: number;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    clazzType: ClazzClazzTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    minAge: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    maxAge: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    lessonTime: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    syllabus: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    references?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    requirements?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Clazz
     */
    sampleFinalResults?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    frequencyPerWeek?: number;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    clazzId?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    orgName?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    teacherAvatarUrl?: string;
    /**
     * 
     * @type {Array<ClassSchedule>}
     * @memberof Clazz
     */
    schedules: Array<ClassSchedule>;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    approvalStatus?: ClazzApprovalStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    numReviews?: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    numEnrolledStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    averageRating?: number;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    thumbnailImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    introductionVideoUrl?: string;
}


/**
 * @export
 */
export const ClazzClazzTypeEnum = {
    MultipleLessons: 'MULTIPLE_LESSONS',
    OneLesson: 'ONE_LESSON'
} as const;
export type ClazzClazzTypeEnum = typeof ClazzClazzTypeEnum[keyof typeof ClazzClazzTypeEnum];

/**
 * @export
 */
export const ClazzApprovalStatusEnum = {
    Approved: 'APPROVED',
    Pending: 'PENDING',
    Rejected: 'REJECTED',
    ChangesRequested: 'CHANGES_REQUESTED'
} as const;
export type ClazzApprovalStatusEnum = typeof ClazzApprovalStatusEnum[keyof typeof ClazzApprovalStatusEnum];


/**
 * Check if a given object implements the Clazz interface.
 */
export function instanceOfClazz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "teacherId" in value;
    isInstance = isInstance && "clazzName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "categoryId" in value;
    isInstance = isInstance && "thumbnailImage" in value;
    isInstance = isInstance && "numLessons" in value;
    isInstance = isInstance && "maxStudents" in value;
    isInstance = isInstance && "minStudents" in value;
    isInstance = isInstance && "clazzType" in value;
    isInstance = isInstance && "minAge" in value;
    isInstance = isInstance && "maxAge" in value;
    isInstance = isInstance && "lessonTime" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "syllabus" in value;
    isInstance = isInstance && "schedules" in value;

    return isInstance;
}

export function ClazzFromJSON(json: any): Clazz {
    return ClazzFromJSONTyped(json, false);
}

export function ClazzFromJSONTyped(json: any, ignoreDiscriminator: boolean): Clazz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'organizationName': !exists(json, 'organizationName') ? undefined : json['organizationName'],
        'teacherId': json['teacherId'],
        'clazzName': json['clazzName'],
        'description': json['description'],
        'categoryId': json['categoryId'],
        'thumbnailImage': json['thumbnailImage'],
        'introductionVideo': !exists(json, 'introductionVideo') ? undefined : json['introductionVideo'],
        'numLessons': json['numLessons'],
        'maxStudents': json['maxStudents'],
        'minStudents': json['minStudents'],
        'clazzType': json['clazzType'],
        'minAge': json['minAge'],
        'maxAge': json['maxAge'],
        'lessonTime': json['lessonTime'],
        'price': json['price'],
        'syllabus': json['syllabus'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'requirements': !exists(json, 'requirements') ? undefined : json['requirements'],
        'sampleFinalResults': !exists(json, 'sampleFinalResults') ? undefined : json['sampleFinalResults'],
        'frequencyPerWeek': !exists(json, 'frequencyPerWeek') ? undefined : json['frequencyPerWeek'],
        'clazzId': !exists(json, 'clazzId') ? undefined : json['clazzId'],
        'orgName': !exists(json, 'orgName') ? undefined : json['orgName'],
        'teacherName': !exists(json, 'teacherName') ? undefined : json['teacherName'],
        'teacherAvatarUrl': !exists(json, 'teacherAvatarUrl') ? undefined : json['teacherAvatarUrl'],
        'schedules': ((json['schedules'] as Array<any>).map(ClassScheduleFromJSON)),
        'approvalStatus': !exists(json, 'approvalStatus') ? undefined : json['approvalStatus'],
        'numReviews': !exists(json, 'numReviews') ? undefined : json['numReviews'],
        'numEnrolledStudents': !exists(json, 'numEnrolledStudents') ? undefined : json['numEnrolledStudents'],
        'averageRating': !exists(json, 'averageRating') ? undefined : json['averageRating'],
        'thumbnailImageUrl': !exists(json, 'thumbnailImageUrl') ? undefined : json['thumbnailImageUrl'],
        'introductionVideoUrl': !exists(json, 'introductionVideoUrl') ? undefined : json['introductionVideoUrl'],
    };
}

export function ClazzToJSON(value?: Clazz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'organizationName': value.organizationName,
        'teacherId': value.teacherId,
        'clazzName': value.clazzName,
        'description': value.description,
        'categoryId': value.categoryId,
        'thumbnailImage': value.thumbnailImage,
        'introductionVideo': value.introductionVideo,
        'numLessons': value.numLessons,
        'maxStudents': value.maxStudents,
        'minStudents': value.minStudents,
        'clazzType': value.clazzType,
        'minAge': value.minAge,
        'maxAge': value.maxAge,
        'lessonTime': value.lessonTime,
        'price': value.price,
        'syllabus': value.syllabus,
        'references': value.references,
        'requirements': value.requirements,
        'sampleFinalResults': value.sampleFinalResults,
        'frequencyPerWeek': value.frequencyPerWeek,
        'clazzId': value.clazzId,
        'orgName': value.orgName,
        'teacherName': value.teacherName,
        'teacherAvatarUrl': value.teacherAvatarUrl,
        'schedules': ((value.schedules as Array<any>).map(ClassScheduleToJSON)),
        'approvalStatus': value.approvalStatus,
        'numReviews': value.numReviews,
        'numEnrolledStudents': value.numEnrolledStudents,
        'averageRating': value.averageRating,
        'thumbnailImageUrl': value.thumbnailImageUrl,
        'introductionVideoUrl': value.introductionVideoUrl,
    };
}

