import * as React from 'react';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import './common/assets/nprogress.scss';
import 'common/override/theme.scss';
import 'common/config/firebase';
import theme from 'common/override/theme';
import { store } from 'common/store';
import { useSite } from 'common/features';
import { SiteEnum } from 'common/types';
import { RouteEnhancer } from 'common/Routes';
import PageLoading from 'common/pages/PageLoading';
import PageMaintaining from 'common/pages/PageMaintaining';

import StudentSite from 'student/student';

import TeacherSite from 'teacher/teacher';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

function SiteController() {
  const { currentSite, loading } = useSite();

  if (loading) {
    return <PageLoading />;
  } else if (currentSite === SiteEnum.Student) {
    return <StudentSite />;
  } else if (currentSite === SiteEnum.Teacher) {
    return <TeacherSite />;
  } else {
    return <PageMaintaining />;
  }
}

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <SiteController />
        <RouteEnhancer />
      </BrowserRouter>

      <ToastContainer />
    </ThemeProvider>
  </Provider>
);
