/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DummyTeacher,
  DummyTeacherList,
  DummyTeacherPostRequest,
} from '../models';
import {
    DummyTeacherFromJSON,
    DummyTeacherToJSON,
    DummyTeacherListFromJSON,
    DummyTeacherListToJSON,
    DummyTeacherPostRequestFromJSON,
    DummyTeacherPostRequestToJSON,
} from '../models';

export interface CreateNewDummyTeacherProfileRequest {
    dummyTeacherPostRequest: DummyTeacherPostRequest;
}

export interface GetDummyTeacherInformationRequest {
    teacherId: string;
}

/**
 * 
 */
export class DummyTeacherApi extends runtime.BaseAPI {

    /**
     * Create a dummy teacher profile for testing purpose
     */
    async createNewDummyTeacherProfileRaw(requestParameters: CreateNewDummyTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DummyTeacher>> {
        if (requestParameters.dummyTeacherPostRequest === null || requestParameters.dummyTeacherPostRequest === undefined) {
            throw new runtime.RequiredError('dummyTeacherPostRequest','Required parameter requestParameters.dummyTeacherPostRequest was null or undefined when calling createNewDummyTeacherProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dummy/teachers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DummyTeacherPostRequestToJSON(requestParameters.dummyTeacherPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyTeacherFromJSON(jsonValue));
    }

    /**
     * Create a dummy teacher profile for testing purpose
     */
    async createNewDummyTeacherProfile(requestParameters: CreateNewDummyTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DummyTeacher> {
        const response = await this.createNewDummyTeacherProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all dummy teacher profiles as a list
     */
    async getAllDummyTeachersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DummyTeacherList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dummy/teachers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyTeacherListFromJSON(jsonValue));
    }

    /**
     * Get all dummy teacher profiles as a list
     */
    async getAllDummyTeachers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DummyTeacherList> {
        const response = await this.getAllDummyTeachersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get detailed information of a dummy teacher profile
     */
    async getDummyTeacherInformationRaw(requestParameters: GetDummyTeacherInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DummyTeacher>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getDummyTeacherInformation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dummy/teachers/{teacherId}`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DummyTeacherFromJSON(jsonValue));
    }

    /**
     * Get detailed information of a dummy teacher profile
     */
    async getDummyTeacherInformation(requestParameters: GetDummyTeacherInformationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DummyTeacher> {
        const response = await this.getDummyTeacherInformationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
