import React from 'react';
import { Box, Card, CardContent, Rating, Typography, Stack, Grid, CardMedia, Avatar } from '@mui/material';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import A from 'common/assets';
import Button from 'common/components/Button';
import { Review } from 'student/api';
import { stringToColor, nameToAvatarText } from 'common/utils';

interface Props {
  review: Review;
}

export default function CourseDetailSectionReviewsListItem({ review }: Props) {
  return (
    <Box py={4}>
      <Stack spacing={2} direction="row" alignItems="start" sx={{ width: '100%' }}>
        <Avatar
          alt={'Amusingo - Avatar ' + name}
          sx={{
            width: 50,
            height: 50,
            bgcolor: stringToColor(`${review.parentName}`),
          }}
        >
          {nameToAvatarText(`${review.parentName}`)}
        </Avatar>

        <Box sx={{ width: '100%' }}>
          <Rating name="half-rating" readOnly defaultValue={review.rate} />

          <Typography
            variant="subtitle1"
            sx={{ lineHeight: 0.75, fontWeight: 800, fontSize: '16px !important', mt: 0.5 }}
          >
            {review.parentName}
          </Typography>

          <Typography variant="subtitle1" my={1} sx={{ fontSize: '16px !important' }}>
            {review.content}
          </Typography>

          <Grid container spacing={2} sx={{ width: '100%' }}>
            {review.imageS3PresignedUrl
              ? [review.imageS3PresignedUrl].map((imgSrc) => (
                  <Grid item key={Math.random()}>
                    <Box
                      sx={{
                        img: {
                          height: 137,
                          width: 137,
                          objectFit: 'contain',
                          borderRadius: '30px',
                        },
                      }}
                    >
                      <img alt="Review image" src={imgSrc} />
                    </Box>
                  </Grid>
                ))
              : null}
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
}
