import React from 'react';
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CourseDetailSectionTeacher from 'student/features/course/components/CourseDetail/CourseDetailSectionTeacher';

const RootStyled = styled('div')(({ theme }) => ({
  margin: theme.spacing(4, 0),
}));

export default function PageCourse() {
  const { teacherId } = useParams();
  if (!teacherId) {
    toast.warning('Không có giáo viên!');
  }

  return (
    <RootStyled>
      <Container fixed>
        <CourseDetailSectionTeacher teacherId={`${teacherId}`} />
      </Container>
    </RootStyled>
  );
}
