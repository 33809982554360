import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { usePageFAQParams } from './usePageFAQParams';
import Button from 'common/components/mui/Button';

export type SectionId = 'teacher' | 'parent';
export type QuestionId = number;

export interface Section {
  id: SectionId;
  title: string;
  questions: { id: QuestionId; title: string; answer: string }[];
}

const sections: Section[] = [
  {
    id: 'teacher',
    title: 'Dành cho giáo viên',
    questions: [
      {
        id: 1,
        title: 'Amusingo là gì?',
        answer: `Amusingo là một nền tảng giáo dục online với vô vàn khoá học vui vẻ, thực tiễn từ nhiều lĩnh vực dành cho trẻ từ 3-18 tuổi. Các chủ đề trải dài trên nhiều lĩnh vực, từ ngoại ngữ, lập trình, cho đến kỹ năng, nghệ thuật,... và được giảng dạy bởi những giáo viên giàu nhiệt huyết và có nhiều kinh nghiệm thực tế trong lĩnh vực giảng dạy.
          \nChúng tôi mong muốn mang đến 1 môi trường giáo dục nơi trẻ có thể tiếp cận với những kiến thức cập nhật, thiết thực nhưng chưa được giảng dạy ở các môi trường giáo dục truyền thống. Đồng thời, chúng tôi cũng nỗ lực trong việc thay đổi cách giảng dạy để khiến trẻ cảm thấy gắn kết và yêu thích việc học.
          \nChúng tôi tin rằng, bằng việc để trẻ tự mình lựa chọn học điều mình muốn và có cơ hội trải nghiệm trên nhiều lĩnh vực, trẻ sẽ tìm thấy được niềm vui và đam mê trong học tập, xây dựng thói quen tự học và chủ động tìm tòi, đồng thời trang bị cho mình những kiến thức cũng như kỹ năng để sẵn sàng cho tương lai.`,
      },
      {
        id: 2,
        title: 'Amusingo hoạt động như thế nào?',
        answer: `Giáo viên đăng ký tham gia và trải qua các vòng đánh giá để trở thành giáo viên trên nền tảng Amusingo. Sau khi vượt qua các vòng đánh giá và trở thành giáo viên chính thức, giáo viên có thể bắt đầu đăng các khoá học mình muốn dạy trên nền tảng.
          \nKhi đó, phụ huynh và học sinh có thể nhìn thấy các khoá học của giáo viên, từ đó lựa chọn và đăng ký các khoá học mình quan tâm, yêu thích ngay trên trang web của Amusingo. Sau khi Amusingo xác nhận phụ huynh đã thanh toán thành công, học sinh có thể truy cập vào đường link để tham gia các buổi học.
          \nCác buổi học sẽ được tổ chức trực tuyến thông qua phần mềm Zoom, với sự tham gia của giáo viên và các học sinh đã đăng ký. Link Zoom sẽ do Amusingo tạo và gửi tới giáo viên và các học sinh. (Lưu ý, giáo viên sử dụng Zoom cá nhân để tổ chức lớp học là hành vi vi phạm quy định của Amusingo)`,
      },
      {
        id: 3,
        title: 'Tại sao nên trở thành giáo viên trên Amusingo?',
        answer: `Chúng tôi cung cấp một nền tảng để giáo viên có thể tổ chức các lớp học trực tuyến của mình một cách dễ dàng. Điều nay bao gồm:
          - Giáo viên không còn phải lo lắng tìm kiếm học viên mà chỉ cần tập trung xây dựng và dạy các khoá học mình yêu thích
          - Giáo viên sẽ nhận phần lớn doanh thu từ việc dạy, Amusingo chỉ giữ lại một phần để phát triển trang web cũng như marketing cho các khoá học của giáo viên
          - Amusingo hỗ trợ tối đa các công việc hậu cần (như quản lý tài khoản Zoom, quản lý khoá học, quản lý thu nhập,...) cho phép giáo viên tập trung vào việc giảng dạy
          - Amusingo cũng hỗ trợ giáo viên trong quá trình xây dựng khoá học và tiến hành giảng dạy để giúp cho chất lượng khoá học ngày càng tốt hơn
          \nNgoài ra, giáo viên sẽ còn nhận thêm được nhiều quyền lợi như:
          - Các lớp học trực tuyến của giáo viên sẽ được hiển thị chuyên nghiệp trên trang web của Amusingo
          - Tiếp cận cộng đồng những học sinh có chung niềm đam mê với các môn học của giáo viên trên toàn quốc (và có thể cả quốc tế)
          - Đội ngũ hỗ trợ khách hàng nhanh chóng, tận tình (qua điện thoại, zalo và facebook)`,
      },
      {
        id: 4,
        title: 'Việc giảng dạy trên Amusingo có phù hợp với tôi không?',
        answer: `- Bạn thích được làm việc ở bất cứ đâu bạn muốn, theo lịch trình của riêng bạn?
          - Bạn muốn thoả sức sáng tạo, tự mình thiết kế chương trình và giảng dạy theo phong cách của riêng mình?
          - Bạn đam mê và có kinh nghiệm thực tế trong một hoặc nhiều lĩnh vực nào đó và mong muốn được truyền đạt những kiến thức và kỹ năng của mình tới mọi người (và sẽ thật tốt nếu bạn có thể nhận được phần thưởng từ điều đó)?
          - Bạn yêu thích việc giảng dạy, đặc biệt là với trẻ em từ 3-18 tuổi?
          - Bạn trên 18 tuổi và đang sinh sống tại Việt Nam hoặc nước ngoài?
          \nNếu những điều trên nghe có vẻ hấp dẫn đối với bạn, thì việc giảng dạy trên Amusingo phù hợp với bạn!`,
      },
      {
        id: 5,
        title: 'Tôi có thể dạy những lớp học nào?',
        answer: `Chúng tôi khuyến khích bạn dạy các lớp học về các chủ đề mà bạn đam mê và có kinh nghiệm, và lý tưởng nhất là bạn đã từng dạy trước đây. Chúng tôi hoan nghênh các lớp học về tất cả các môn học và ở mọi hình thức, dành cho học viên từ 3-18 tuổi. Chúng tôi thường đề nghị giáo viên cung cấp các lớp học kéo dài trong khoảng 1-3 tháng, với tần suất 1-3 buổi/tuần.
          \nMặc dù luôn nỗ lực để tìm kiếm các học viên phù hợp với các lớp học của bạn, nhưng ngay cả chúng tôi cũng rất khó để có thể biết trước khoá học nào sẽ được yêu thích và trở nên phổ biến. Do đó, chúng tôi khuyên bạn nên thử nghiệm một số lớp học và sau đó cải thiện dần dựa trên phản hồi (từ phụ huynh, học sinh và từ Amusingo).
          Lưu ý, chúng tôi không cung cấp các chương trình giảng dạy có sẵn cho giáo viên.
          \nMột số ví dụ các lớp học có thể dạy trên nền tảng Amusingo:
          \n<strong style="color: #932191;">Lập trình/STEM</strong>
          - Lập trình game Packman với Python (cấp độ 1)
          Trong khóa học trực tuyến 10 buổi này, học sinh sẽ sử dụng Python - một trong những ngôn ngữ lập trình phổ biến nhất hiện nay, để tạo ra một phiên bản đơn giản của game Packman nổi tiếng.
          - Thiết kế 11 trò chơi bằng phần mềm Unity
          Tham gia cùng Ms. Vinh và các bạn khác trong khóa học thú vị này, nơi bạn học cách tạo 11 trò chơi điện tử của riêng mình bằng Unity - công cụ thiết kế trò chơi phổ biến nhất thế giới!
          - Viết mã và soạn nhạc với Scratch, bạn đã biết cách chưa?
          Nếu bạn yêu thích âm nhạc và lập trình, khoá học này có thể sẽ khiến bạn hào hứng! Cùng học cách sáng tác các bản nhạc của riêng bạn thông qua lập trình Scratch.
          \n<strong style="color: #932191;">Ngoại ngữ</strong>
          - Câu lạc bộ sách: Luyện đọc, dịch và thảo luận về bộ sách Harry Potter bằng tiếng Anh
          Tại câu lạc bộ sách, những người học sẽ đọc, dịch và thảo luận bằng tiếng Anh về các phần của bộ sách nổi tiếng Harry Potter. Lớp học diễn ra định kỳ hàng tuần, tập trung phát triển khả năng đọc hiểu, từ vựng, luyện nói và hơn thế nữa!
          - Cải thiện khả năng thuyết trình bằng tiếng Anh của bạn
          Bạn muốn tăng khả năng thuyết trình bằng tiếng Anh để chuẩn bị cho bài phát biểu trước lớp, hay chuẩn bị cho kế hoạch du học sắp tới. Đây là một kỹ năng có thể cải thiện trong thời gian ngắn khi luyện tập với một giáo viên giỏi! Hãy tham gia và quan sát sự khác biệt mà một vài lớp học có thể tạo ra.
          - Cùng nói tiếng Nhật (trình độ sơ cấp)
          MINASAN, KONNICHIWA! Bạn yêu thích văn hoá Nhật Bản và muốn học ngôn ngữ của đất nước này? Hãy tham gia cùng Mrs. Duyên trong chuyến hành trình khám phá ngôn ngữ và văn hóa Nhật Bản. Khi kết thúc khóa học kéo dài 4 tuần này, bạn sẽ có thể viết tên của chính mình, giới thiệu bản thân, đặt câu hỏi đơn giản và bày tỏ sở thích bằng tiếng Nhật. Bạn cũng sẽ khám phá văn hóa truyền thống của Nhật Bản, hát một số bài hát bằng tiếng Nhật và tham quan các điểm tham quan nổi tiếng trực tuyến.
          \n<strong style="color: #932191;">Kỹ năng</strong>
          - Học cách diễn thuyết và tranh luận về các chủ đề xã hội (11-15 tuổi)
          Người học sẽ có được sự tự tin thông qua những buổi thực hành kỹ năng nói trước đám đông và tranh luận về các chủ đề xã hội hiện nay.
          - Cùng nhau làm bánh! Trải nghiệm làm bánh không cần lò nướng cho các đầu bếp trẻ
          Trong lớp học này, học sinh sẽ được học nhiều công thức làm các loại bánh mà không cần sử dụng lò nướng.
          - Xây dựng kỹ năng học tập và thói quen quản lý thời gian
          Khoá học này là nơi học sinh phát triển các kỹ năng quản lý thời gian và kỹ năng học tập, đồng thời hướng dẫn học sinh ứng dụng các kỹ năng đó vào cuộc sống.
          \n<strong style="color: #932191;">Nghệ thuật</strong>
          - Anime 101 - Vẽ nhân vật anime cho người mới bắt đầu
          Trong khoá học 4 tuần này, chúng ta sẽ học các nguyên tắc cơ bản khi vẽ anime. Mỗi tuần chúng ta sẽ học vẽ từng bộ phận, yếu tố khác nhau (như tóc, mắt, tay, khuôn mặt,...) của nhân vật anime.
          - Tập vẽ phác thảo thiết kế thời trang
          Trong mỗi buổi học, chúng ta sẽ học cách phác thảo và thiết kế các bộ trang phục theo phong cách thời trang của riêng mình. Khoá học này sẽ giúp bạn tự tin hơn với các thiết kế thời trang của mình, đồng thời tìm hiểu về lịch sử và xu hướng thời trang trong quá trình học.
          - Lớp nhiếp ảnh sáng tạo dành cho trẻ từ 7-12 tuổi
          Trong khóa học kéo dài 8 tuần này, học sinh học cách chọn đối tượng, suy nghĩ về ánh sáng và sử dụng các quy tắc bố cục để tạo ra những bức ảnh sáng tạo bằng bất kỳ máy ảnh hoặc điện thoại/máy tính bảng nào.
          \n<strong style="color: #932191;">Kinh doanh</strong>
          - Tài chính cá nhân cho thanh thiếu niên! (11-16 tuổi)
          Khóa học này sẽ giúp các bạn hiểu rõ hơn về những yếu tố quan trọng trong việc quản lý tài chính cá nhân, bao gồm cách tiết kiệm, đầu tư và xử lý các khoản vay. Hãy tham gia khóa học này ngay hôm nay để bắt đầu khẳng định tương lai tài chính cá nhân của riêng bạn!
          - Khởi nghiệp: Ý tưởng kinh doanh là gì?
          Trong khóa học 6 tuần này, người học sẽ hiểu được điều gì tạo nên một ý tưởng kinh doanh độc đáo, có tiềm năng từ những case study của các doanh nhân thành đạt trong nước cũng như quốc tế.`,
      },
      {
        id: 6,
        title: 'Tôi nên dạy vào khung giờ nào?',
        answer: `Chúng tôi thường khuyến khích giáo viên tổ chức lớp học vào những khung giờ ngoài giờ học chính quy của học sinh, với thời lượng dạy trong khoảng 60-120 phút/buổi. Tuy nhiên, tuỳ vào tính chất môn học mà sẽ có sự sai khác, nên chúng tôi khuyên bạn nên tự mình thử nghiệm để tìm ra thời điểm tốt nhất cho các khoá học của bạn!`,
      },
      {
        id: 7,
        title: 'Tôi nên định giá lớp học của mình như thế nào?',
        answer: `Trên nền tảng Amusingo, giáo viên tự quyết định học phí cho các lớp học của mình. Đây là một thị trường mở nên hãy cân nhắc mức phí bạn cảm thấy phù hợp. Tuy nhiên, chúng tôi thường đề xuất mức giá 50.000-250.000 VND/buổi học cho mỗi học viên.
          \nLưu ý, cần phân biệt rõ giữa <strong style="color: #932191;">học phí</strong> và <strong style="color: #932191;">lương</strong> nhận được.
          - Mức học phí do giáo viên đặt ra sẽ được hiển thị trên trang web Amusingo và phụ huynh sẽ cần thanh toán 100% học phí để học sinh có thể tham gia khoá học.
          - Giáo viên sẽ nhận được mức lương tương ứng 70% tổng học phí và sẽ được thanh toán vào ngày mồng 5 hàng tháng thông qua tài khoản ngân hàng định sẵn. 30% còn lại sẽ được Amusingo sử dụng để phát triển nền tảng và tìm học viên cho các khoá học của giáo viên.`,
      },
      {
        id: 8,
        title: 'Dạy trên Amusingo có mất phí không?',
        answer: `Bạn không mất phí để đăng ký trở thành giáo viên trên Amusingo. Amusingo chỉ thu một khoản phí dịch vụ duy nhất, tương ứng 30% trên mỗi lượt đăng ký khoá học của phụ huynh, học sinh. Ngoài khoản phí trên sẽ không phát sinh bất cứ chi phí nào khác.`,
      },
      {
        id: 9,
        title: 'Amusingo trả tiền cho giáo viên như thế nào?',
        answer: `Chúng tôi sẽ chuyển khoản tiền dạy hàng tháng cho giáo viên qua tài khoản ngân hàng định sẵn vào ngày mồng 5 tháng tiếp theo.
          Lưu ý, bạn sẽ cần phải tự kê khai và trả thuế cho thu nhập của mình và báo cáo chúng một cách thích hợp.`,
      },
      {
        id: 10,
        title: 'Làm thế nào để đăng ký trở thành giáo viên trên Amusingo?',
        answer: `Các bước để đăng ký:
          \n- Bước 1: Nạp đơn đăng ký ứng tuyển trở thành giáo viên bằng cách điền form <a href="https://forms.gle/25N72968xUTDaVP1A">ở đây</a>
          Bạn sẽ cần hiểu rõ về cách Amusingo hoạt động, đồng thời cung cấp các thông tin định danh cá nhân, kinh nghiệm giảng dạy, bằng cấp chứng chỉ liên quan và mô tả khoá học bạn muốn dạy trên Amusingo.
          \n- Bước 2: Tham gia phỏng vấn online với bộ phận chuyên môn của Amusingo
          Sau khi đánh giá hồ sơ ứng tuyển của bạn, chúng tôi sẽ liên lạc với bạn thông qua mail hoặc điện thoại để sắp xếp một buổi phỏng vấn online (dự kiến 60-90 phút). Nội dung phỏng vấn sẽ tập trung vào kinh nghiệm chuyên môn, kinh nghiệm giảng dạy và nội dung khoá học bạn muốn triển khai trên Amusingo.
          \n- Bước 3: Ký cam kết và gửi các giấy tờ cần thiết để hoàn tất thủ tục và trở thành giáo viên trên nền tảng Amusingo
          Khi bạn đã vượt qua 2 vòng trên, chúng tôi sẽ yêu cầu bạn ký bản cam kết và gửi các giấy tờ cần thiết tới văn phòng của chúng tôi. Chúng tôi sẽ gửi mail hoặc gọi điện tới bạn để thông báo khi hoàn tất thủ tục. Khi đó, bạn đã chính thức trở thành giáo viên trên nền tảng của chúng tôi và bắt đầu có thể đăng những khoá học đầu tiên. Hãy tham khảo <a href="https://amusingo.com/faq">cách đăng khoá học trên Amusingo</a> để hiểu hơn về cách làm.`,
      },
      {
        id: 11,
        title: 'Làm thế nào để đăng lớp học đầu tiên lên nền tảng?',
        answer: `Các bước để đăng khoá học lên nền tảng Amusingo:
          \n- Bước 1: Điền vào <a href="https://forms.gle/25N72968xUTDaVP1A">form</a> các thông tin liên quan đến khoá học
          - Bước 2: Chờ xác nhận kiểm duyệt từ Amusingo
          - Bước 3: Xác nhận khoá học được đăng thành công trên trang web Amusingo
          \n Vui lòng tham khảo <a href="https://amusingo.com/faq">các yêu cầu khi tạo khoá học</a> trước khi điền form đăng ký.
          Sau khi lớp học đã qua kiểm duyệt, nếu bạn muốn thay đổi một số nội dung, vui lòng liên hệ với bộ phận chăm sóc khách hàng của chúng tôi để được hỗ trợ.`,
      },
      {
        id: 12,
        title: 'Tôi cần sử dụng công cụ nào để dạy trên Amusingo?',
        answer: `Tất cả những gì bạn cần là tốc độ kết nối internet ít nhất 3 Mb/giây và một máy tính có thể sử dụng Zoom.`,
      },
      {
        id: 13,
        title: 'Tiêu chuẩn cộng đồng khi tham gia nền tảng Amusingo là gì?',
        answer: `Amusingo là một cộng đồng mở bao gồm người học (trẻ em từ 3-18 tuổi), phụ huynh và giáo viên. Để đảm bảo quyền lợi của người dùng trên nền tảng, chúng tôi yêu cầu các thành viên tuân thủ một số hướng dẫn dưới đây. Thành viên nào không đáp ứng các nguyên tắc này sẽ bị xóa khỏi trang web.
          \n<strong style="color: #932191;">Quy tắc ứng xử của giáo viên</strong>
          - Chỉ cung cấp các lớp học khi bạn có nền tảng và kiến thức chuyên môn phù hợp.
          - Luôn chuẩn bị sẵn sàng, bắt đầu/kết thúc buổi học đúng giờ và đối xử tôn trọng với tất cả người học.
          - Không giảng dạy ngay sau khi sử dụng rượu bia, đặc biệt nghiêm cấm việc làm mẫu bất kỳ hành vi nào không phù hợp với học sinh dưới 18 tuổi.
          - Phản hồi nhanh chóng, kịp thời các câu hỏi và yêu cầu của phụ huynh và Amusingo.
          - Không cung cấp thông tin liên hệ cá nhân cho phụ huynh hoặc học sinh để liên lạc <strong><i>“ngoài nền tảng”</i></strong> hoặc gặp mặt trực tiếp.
          - Tuân thủ chính sách nội dung lớp học của Amusingo, chỉ cung cấp các lớp học theo thuần phong mỹ tục, phù hợp với lứa tuổi, và tuân thủ quy định của luật pháp Việt Nam.
          \n<strong style="color: #932191;">Quy tắc ứng xử của phụ huynh</strong>
          - Người học chỉ nên tham gia lớp học do mình đăng ký, với tư cách là chính mình. Việc học sinh sử dụng thông tin đăng ký của anh chị em hoặc học sinh khác để tham gia lớp học là hành vi không được khuyến khích.
          - Đặt câu hỏi cho giáo viên vào đầu hoặc cuối buổi học, hoặc liên hệ với bộ phận chăm sóc khách hàng của Amusingo để làm rõ bất kỳ chi tiết còn thiếu nào về các lớp học.
          - Đảm bảo người học tham gia khoá học đầy đủ, nhiều lớp học tổ chức theo hình thức làm việc nhóm và sự vắng mặt của một học sinh có thể ảnh hưởng lớn tới chất lượng buổi học.
          - Cung cấp phản hồi mang tính xây dựng về trải nghiệm lớp học để đảm bảo lợi ích của giáo viên và các phụ huynh khác.
          - Sử dụng từ ngữ phù hợp, tôn trọng các ý kiến, quan điểm trái chiều.
          \n<strong style="color: #932191;">Quy tắc ứng xử của học sinh</strong>
          - Hãy hành xử một cách lịch sự, tử tế, tôn trọng và giúp tất cả người học cảm thấy được chào đón và hòa nhập.
          - Hãy mặc trang phục phù hợp, chuẩn bị đầy đủ dụng học tập và không làm việc riêng trong buổi học (ăn uống, xem video, trò chuyện với người khác,...).
          \n<strong style="color: #932191;">Quy tắc ứng xử của Amusingo</strong>
          - Chúng tôi hoan nghênh các thành viên đến từ mọi miền tổ quốc và cam kết tạo ra một không gian an toàn cho người dùng.
          - Chúng tôi sẽ luôn lắng nghe ý kiến phản hồi từ phụ huynh, giáo viên và học sinh để có những cải tiến, thay đổi phù hợp.
          - Chúng tôi sẽ luôn xây dựng và thực thi các chính sách để tạo ra một cộng đồng học tập chất lượng cao, đáng tin cậy và an toàn. Điều này có thể bao gồm xóa nội dung hoặc xoá người dùng khỏi nền tảng.
          - Chúng tôi sẽ luôn nhanh chóng trả lời các câu hỏi và hỗ trợ giải quyết những vấn đề phát sinh.
          \nCuối cùng, khi xảy ra sự cố, chúng tôi khuyến khích bạn hãy báo cáo sự cố và gửi phản hồi mang tính xây dựng.`,
      },
    ],
  },
  {
    id: 'parent',
    title: 'Dành cho phụ huynh',
    questions: [
      {
        id: 1,
        title: 'Amusingo là gì?',
        answer: `Amusingo là một nền tảng giáo dục online với vô vàn khoá học vui vẻ, thực tiễn từ nhiều lĩnh vực dành cho trẻ từ 3-18 tuổi. Các chủ đề trải dài trên nhiều lĩnh vực, từ ngoại ngữ, lập trình, cho đến kỹ năng, nghệ thuật,... và được giảng dạy bởi những giáo viên giàu nhiệt huyết và có nhiều kinh nghiệm thực tế trong lĩnh vực giảng dạy.
          \nChúng tôi mong muốn mang đến 1 môi trường giáo dục nơi trẻ có thể tiếp cận với những kiến thức cập nhật, thiết thực nhưng chưa được giảng dạy ở các môi trường giáo dục truyền thống. Đồng thời, chúng tôi cũng nỗ lực trong việc thay đổi cách giảng dạy để khiến trẻ cảm thấy gắn kết và yêu thích việc học.
          \nChúng tôi tin rằng, bằng việc để trẻ tự mình lựa chọn học điều mình muốn và có cơ hội trải nghiệm trên nhiều lĩnh vực, trẻ sẽ tìm thấy được niềm vui và đam mê trong học tập, xây dựng thói quen tự học và chủ động tìm tòi, đồng thời trang bị cho mình những kiến thức cũng như kỹ năng để sẵn sàng cho tương lai.`,
      },
      {
        id: 2,
        title: 'Amusingo hoạt động như thế nào?',
        answer: `Trên trang web của Amusingo có rất nhiều khoá học trên nhiều lĩnh vực được tạo bởi những giáo viên nhiệt huyết và nhiều kinh nghiệm.
          \nPhụ huynh và học sinh có thể dễ dàng tìm kiếm và đăng ký các khoá học mình quan tâm, yêu thích ngay trên trang web của Amusingo. Sau khi Amusingo xác nhận phụ huynh đã thanh toán thành công, học sinh có thể truy cập vào đường link để tham gia các buổi học.
          \nCác buổi học sẽ được tổ chức trực tuyến thông qua phần mềm Zoom, với sự tham gia của giáo viên và các học sinh đã đăng ký. Link Zoom sẽ do Amusingo tạo và gửi tới giáo viên và các học sinh.`,
      },
      {
        id: 3,
        title: 'Có những khoá học nào trên Amusingo?',
        answer: `Amusingo hiện đang tập trung vào 5 lĩnh vực chính và dự định sẽ mở rộng dần các chủ đề trong tương lai.
          \n1. Lập trình/STEM (VD: Làm quen với lập trình Scratch, Lập trình Python dành cho học sinh tiểu học,...)\
          \n2. Ngoại ngữ (VD: Tiếng Anh giao tiếp với giáo viên 8.0 IELTS, Làm quen với bảng chữ cái tiếng Nhật,...)\
          \n3. Kỹ năng (VD: Tư duy logic và cách giải quyết vấn đề, Hiểu rõ bản thân,...)\
          \n4. Nghệ thuật (VD: Thêu dành cho người mới bắt đầu, Nhảy Kpop với Blackpink, Tự làm kẹp sách chỉ trong 10 phút,...)\
          \n5. Kinh doanh (VD: Quản lý tài chính cho học sinh cấp 2,...)
          \nPhụ huynh và học sinh có thể xem danh sách các khoá học hiện có <a href="http://amusingo.com/courses">ở đây</a>.`,
      },
      {
        id: 4,
        title: 'Làm thế nào để đăng ký khoá học và thanh toán học phí?',
        answer: `Các bước để đăng ký khoá học và thanh toán học phí:
          \nBước 1: Truy cập vào trang lớp học phụ huynh và học sinh muốn đăng ký (ảnh)\
          \nBước 2: Chọn khung thời gian phù hợp và ấn Đăng ký (ảnh)\
          \nBước 3: Chuyển khoản qua internet banking hoặc chuyển khoản trực tiếp tại ngân hàng (ảnh)\
          \n&#20;&#20;&#20;&#20;- Cách 1: Chuyển khoản qua internet banking\
          \n&#20;&#20;&#20;&#20;&#20;&#20;Quét mã QR để nhập tự động hoặc nhập thủ công các thông tin chuyển khoản (STK, chủ tài khoản, chi nhánh, nội dung chuyển khoản)\
          \n&#20;&#20;&#20;&#20;- Cách 2: Chuyển khoản trực tiếp tại ngân hàng\
          \n&#20;&#20;&#20;&#20;&#20;&#20;Thực hiện giao dịch chuyển khoản tại ngân hàng với các thông tin được hiển thị ở đây (link trang thanh toán)
          \nLưu ý:\
          \n- Cần ghi rõ nội dung chuyển khoản theo cú pháp SĐT đăng ký_Tên học sinh_Mã khoá học (Ví dụ: 0901234567_Phan Thanh An_STEM0001). Phụ huynh có thể xác nhận thông tin chuyển khoản ở trang thanh toán (link)????.\
          \n- Nếu nội dung chuyển khoản không chính xác chúng tôi có thể sẽ không xác nhận được giao dịch. Trong trường hợp này, việc đăng ký tham gia lớp học sẽ không thành công và số tiền đã chuyển sẽ được trả lại vào tài khoản gửi (phụ huynh vui lòng chịu phí chuyển tiền).`,
      },
      {
        id: 5,
        title: 'Có thể trả học phí theo nhiều đợt không?',
        answer: `Có. Hiện tại, chúng tôi cung cấp 2 phương thức thanh toán là <strong style="color: #932191;">thanh toán một lần</strong> hoặc <strong style="color: #932191;">thanh toán từng phần</strong> (chia thành hai đợt đầu khoá và giữa khoá).
          \n- Với cách thanh toán một lần, phụ huynh cần chuyển khoản thành công toàn bộ học phí khoá học tối thiểu 3 ngày trước khi buổi học đầu tiên bắt đầu.\
          \n- Với cách thanh toán từng phần, phụ huynh cần chuyển khoản thành công 1/2 học phí tối thiểu 3 ngày trước khi buổi học đầu tiên bắt đầu, và chuyển nửa còn lại tối thiểu 3 ngày trước khi buổi học giữa khoá bắt đầu.
          \nLưu ý: Thanh toán từng phần chỉ áp dụng cho khoá học kéo dài từ 2 tháng trở lên. Thông báo nhắc nhở chuyển khoản giữa khoá sẽ được gửi tới phụ huynh trong vòng 1 tuần trước khi buổi học giữa khoá bắt đầu. Nếu phụ huynh không thanh toán kịp thời học sinh có thể không tham gia được nửa còn lại của khoá học.`,
      },
      {
        id: 6,
        title: 'Có thể đăng ký khoá học cho nhiều học viên trên một tài khoản không?',
        answer: `Hiện tại trang web của Amusingo chưa hỗ trợ đầy đủ việc đăng ký lớp học cho nhiều học viên trên một tài khoản. Nếu một phụ huynh muốn đăng ký lớp học cho hai hay nhiều học viên, sẽ có 2 trường hợp xảy ra như dưới đây:\
          \n\n- Trường hợp 1: Phụ huynh muốn đăng ký <strong style="color: #932191;">các khoá học khác nhau</strong> cho hai hoặc nhiều học viên\
          \nTrong trường hợp này, phụ huynh có thể dùng 1 tài khoản và đăng ký các lớp khác nhau cho các con của mình mà không gặp vấn đề gì. Tuy nhiên nếu các lớp học diễn ra đồng thời cùng lúc, phụ huynh sẽ cần nhiều thiết bị để học viên có thể tham gia các lớp học khác nhau. Trong tương lai, chúng tôi sẽ tiếp tục xây dựng tính năng này để phụ huynh và học sinh có thể dễ dàng sử dụng hơn.\
          \n\n- Trường hợp 2: Phụ huynh muốn đăng ký <strong style="color: #932191;">cùng một khoá học cho hai</strong> hoặc nhiều học viên\
          \nTrong trường hợp này, chúng tôi khuyến khích phụ huynh hãy tạo 2 tài khoản phụ huynh sử dụng 2 số điện thoại khác nhau. Nếu phụ huynh không có 2 số điện thoại hãy liên lạc với bộ phận chăm sóc khách hàng của chúng tôi thông qua SĐT XXX hoặc qua facebook XXX.`,
      },
      {
        id: 7,
        title: 'Sau khi đăng ký thành công, làm thế nào để tham gia các buổi học?',
        answer: `Phụ huynh và học sinh sẽ cần tải và cài đặt sẵn phần mềm Zoom vào laptop hoặc máy tính bảng trước khi tham gia lớp học. Nếu chưa cài đặt Zoom, phụ huynh và học sinh có thể tham khảo hướng dẫn cài đặt Zoom <a href="https://zoom.org.vn/huong-dan-su-dung/tai-cai-dat-cach-su-dung-zoom-meeting-an-toan-tren-may-tinh-va-dien-thoai/">ở đây</a>.\
          \n\nĐể tham gia lớp học:\
          \n- Bước 1: Truy cập lịch học sắp tới\
          \n- Bước 2: Chọn khoá học sắp diễn ra mà học sinh muốn tham gia\
          \n- Bước 3: Ấn nút Tham gia khoá học, Zoom dành cho buổi học sẽ được tự động mở\
          \n\nNgoài ra, để buổi học diễn ra 1 cách thuận lợi, học sinh cần đảm bảo tham gia lớp học trong môi trường internet ổn định và yên tĩnh.`,
      },
      {
        id: 8,
        title: 'Chính sách hoàn tiền của Amusingo như thế nào?',
        answer: `Phù huynh sẽ được hoàn tiền học phí trong những trường hợp sau:\
          \n\n- Lý do đến từ phụ huynh, học sinh: Phụ huynh và học sinh không hài lòng với chất lượng khoá học và muốn nghỉ những buổi học còn lại, hoặc vì một lý do nào đó (như sức khoẻ học sinh không đảm bảo, lịch học thay đổi,...) khiến học sinh không thể tiếp tục tham gia khoá học.\
          \n- Lý do đến từ giáo viên: Vì một lý do nào đó (như sức khoẻ giáo viên không đảm bảo,...) khiến giáo viên không thể dạy hết khoá học theo chương trình dự kiến.\
          \n- Lý do đến từ nền tảng: Trong quá trình triển khai khoá học, Amusingo liên tục tiếp nhận phản hồi từ phụ huynh, học sinh và tiến hành đánh giá chất lượng. Khi nhận thấy khoá học không đạt chất lượng tiêu chuẩn, Amusingo sẽ đưa ra quyết định ngừng khoá học để đảm báo chất lượng chung trên nền tảng.\
          \n\nTrong những trường hợp trên, số tiền tương ứng với học phí cho những buổi học chưa triển khai sẽ được hoàn lại cho phụ huynh.`,
      },
      {
        id: 9,
        title: 'Tiêu chuẩn cộng đồng khi tham gia nền tảng Amusingo là gì?',
        answer: `Amusingo là một cộng đồng mở bao gồm người học (trẻ em từ 3-18 tuổi), phụ huynh và giáo viên. Để đảm bảo quyền lợi của người dùng trên nền tảng, chúng tôi yêu cầu các thành viên tuân thủ một số hướng dẫn dưới đây. Thành viên nào không đáp ứng các nguyên tắc này sẽ bị xóa khỏi trang web.
          \n<strong style="color: #932191;">Quy tắc ứng xử của giáo viên</strong>
          - Chỉ cung cấp các lớp học khi bạn có nền tảng và kiến thức chuyên môn phù hợp.
          - Luôn chuẩn bị sẵn sàng, bắt đầu/kết thúc buổi học đúng giờ và đối xử tôn trọng với tất cả người học.
          - Không giảng dạy ngay sau khi sử dụng rượu bia, đặc biệt nghiêm cấm việc làm mẫu bất kỳ hành vi nào không phù hợp với học sinh dưới 18 tuổi.
          - Phản hồi nhanh chóng, kịp thời các câu hỏi và yêu cầu của phụ huynh và Amusingo.
          - Không cung cấp thông tin liên hệ cá nhân cho phụ huynh hoặc học sinh để liên lạc <strong><i>“ngoài nền tảng”</i></strong> hoặc gặp mặt trực tiếp.
          - Tuân thủ chính sách nội dung lớp học của Amusingo, chỉ cung cấp các lớp học theo thuần phong mỹ tục, phù hợp với lứa tuổi, và tuân thủ quy định của luật pháp Việt Nam.
          \n<strong style="color: #932191;">Quy tắc ứng xử của phụ huynh</strong>
          - Người học chỉ nên tham gia lớp học do mình đăng ký, với tư cách là chính mình. Việc học sinh sử dụng thông tin đăng ký của anh chị em hoặc học sinh khác để tham gia lớp học là hành vi không được khuyến khích.
          - Đặt câu hỏi cho giáo viên vào đầu hoặc cuối buổi học, hoặc liên hệ với bộ phận chăm sóc khách hàng của Amusingo để làm rõ bất kỳ chi tiết còn thiếu nào về các lớp học.
          - Đảm bảo người học tham gia khoá học đầy đủ, nhiều lớp học tổ chức theo hình thức làm việc nhóm và sự vắng mặt của một học sinh có thể ảnh hưởng lớn tới chất lượng buổi học.
          - Cung cấp phản hồi mang tính xây dựng về trải nghiệm lớp học để đảm bảo lợi ích của giáo viên và các phụ huynh khác.
          - Sử dụng từ ngữ phù hợp, tôn trọng các ý kiến, quan điểm trái chiều.
          \n<strong style="color: #932191;">Quy tắc ứng xử của học sinh</strong>
          - Hành xử một cách lịch sự, tử tế, tôn trọng và giúp tất cả người học cảm thấy được chào đón và hòa nhập.
          - Mặc trang phục phù hợp, chuẩn bị đầy đủ dụng học tập và không làm việc riêng trong buổi học (ăn uống, xem video, trò chuyện với người khác,...).
          - Tham gia buổi học đầy đủ và đúng giờ, Trong môi trường internet ổn định và yên tĩnh
          \n<strong style="color: #932191;">Quy tắc ứng xử của Amusingo</strong>
          - Chúng tôi hoan nghênh các thành viên đến từ mọi miền tổ quốc và cam kết tạo ra một không gian an toàn cho người dùng.
          - Chúng tôi sẽ luôn lắng nghe ý kiến phản hồi từ phụ huynh, giáo viên và học sinh để có những cải tiến, thay đổi phù hợp.
          - Chúng tôi sẽ luôn xây dựng và thực thi các chính sách để tạo ra một cộng đồng học tập chất lượng cao, đáng tin cậy và an toàn. Điều này có thể bao gồm xóa nội dung hoặc xoá người dùng khỏi nền tảng.
          - Chúng tôi sẽ luôn nhanh chóng trả lời các câu hỏi và hỗ trợ giải quyết những vấn đề phát sinh.
          \nCuối cùng, khi xảy ra sự cố, chúng tôi khuyến khích bạn hãy báo cáo sự cố và gửi phản hồi mang tính xây dựng.`,
      },
      {
        id: 10,
        title: 'Amusingo đảm bảo an toàn và quyền riêng tư cho học viên như thế nào?',
        answer: `Chúng tôi cũng dựa vào phụ huynh/người giám hộ hợp pháp và giáo viên để giúp chúng tôi xây dựng một môi trường học tập trực tuyến an toàn và tích cực. Do đó, với tư cách là phụ huynh/người giám hộ hợp pháp, chúng tôi mong muốn phụ huynh hãy:
          \n- Hướng dẫn học sinh cách sử dụng Zoom, cũng như các hành vi ứng xử tích cực trong lớp học trước khi lớp học bắt đầu. Điều này sẽ giúp học sinh tham gia một cách tôn trọng trong lớp học mà không làm những người học khác mất tập trung.
          - Xem lại Quy tắc ứng xử trước khi học sinh tham gia lớp học đầu tiên.
          - Giám sát trẻ (từ một khoảng cách thoải mái) trong khi con tham gia lớp học cho đến khi anh/chị chắc chắn rằng con có thể tự mình tham gia một cách an toàn và tôn trọng. Đối với những người học lớn hơn, điều này có thể chỉ mất vài phút. Đối với những học sinh nhỏ tuổi hơn hoặc những học sinh có khó khăn về hành vi, bạn có thể cần phải ở gần trong mỗi lớp học.
          - Amusingo yêu cầu các phụ huynh hạn chế đặt các câu hỏi trong quá trình lớp học diễn ra. Nếu phụ huynh có câu hỏi hoặc cần hỗ trợ khi lớp học đang diễn ra, phụ huynh hãy nhắn hoặc gọi điện tới bộ phận hỗ trợ khách hàng của Amusingo. Ngoài ra, xin lưu ý rằng việc nghe hoặc xem lớp học từ một thiết bị riêng biệt khác với thiết bị học sinh đang sử dụng để học là vi phạm chính sách của Amusingo và giáo viên được hướng dẫn xóa các thông tin đăng nhập trùng lặp.
          - Chỉ trẻ em từ 3-18 tuổi mới được học các lớp Outschool. Không ai được phép vào lớp trừ khi giáo viên có thể xác minh danh tính của họ khi bắt đầu lớp học. Tên học viên phải khớp với tên học viên trên trang web của Amusingo, do đó vui lòng đảm bảo rằng họ và tên học viên của bạn được hiển thị chính xác trên tài khoản Zoom của bạn. (Việc học sinh sử dụng thông tin đăng ký của anh chị em hoặc học sinh khác để tham gia lớp học là vi phạm chính sách Amusingo.)
          - Vì lý do bảo mật, vui lòng đảm bảo rằng con đã bật máy quay video khi đăng nhập vào lớp học. Học viên sẽ được yêu cầu kết nối với giáo viên của họ khi bắt đầu mỗi lớp học, vì vậy, điều cần thiết là họ phải chuẩn bị bật video của mình để cho phép giáo viên xác nhận danh tính học viên của bạn. Nếu gia đình bạn không có máy ảnh hoặc không cảm thấy thoải mái khi xuất hiện khuôn mặt của họ trên máy ảnh, vui lòng liên hệ với bộ phận chăm sóc khách hàng trước khi buổi học diễn ra.
          - Không yêu cầu giáo viên cung cấp thông tin đăng nhập hoặc trao đổi liên kết Zoom với giáo viên qua tin nhắn bên ngoài nền tảng Amusingo. Giáo viên chia sẻ liên kết Zoom, ID cuộc họp hoặc mật mã là trái với chính sách của Outschool, vì tất cả các cuộc họp lớp phải bắt đầu bằng nút Tham gia lớp học vì mục đích bảo mật. Nếu bạn gặp sự cố khi đăng nhập vào lớp học của mình, vui lòng kiểm tra kỹ xem bạn đã đăng nhập thành công vào trang web của Amusingo chưa. Vui lòng liên hệ với bộ phận chăm sóc khách hàng nếu bạn không thể truy cập lớp học để chúng tôi có thể giúp khắc phục sự cố.
          - Thông tin cá nhân không bao giờ được chia sẻ hoặc yêu cầu. Thông tin cá nhân có thể là số điện thoại, email, liên kết tới trang web cá nhân và hoặc các kênh mạng xã hội. Tất cả thông tin liên lạc, lớp học và thanh toán sẽ được duy trì trên nền tảng Amusingo. Nếu học sinh của bạn muốn giữ liên lạc với một bạn cùng lớp ngoài nền tảng có thể trao đổi với nhau vào cuối buổi học khi buổi học đã kết thúc. Trong một số trường hợp cần thiết, học sinh và phụ huynh có thể cần phải thông qua ý kiến của Amusingo trước.`,
      },
    ],
  },
];

export default function FaqCenter() {
  const { sectionId, questionId, changeSectionId, changeQuestionId } = usePageFAQParams();
  const selectedSection = sections.find((section) => section.id === sectionId);

  const [expandedQuestionIds, setExpandedQuestionIds] = React.useState<QuestionId[]>([]);

  const handleExpandChange = (qId: QuestionId, expanded: boolean) => {
    if (expanded) {
      changeQuestionId(qId);
    } else {
      setExpandedQuestionIds(expandedQuestionIds.filter((expandedQId) => expandedQId !== qId));
    }
  };

  React.useEffect(() => {
    if (questionId === -1) {
      setExpandedQuestionIds([]);
    } else {
      const hasDefaultActiveQuestion = expandedQuestionIds.length === 0;
      if (hasDefaultActiveQuestion) {
        // Scroll to active question after render
        setTimeout(() => {
          const element = document.getElementById(`question-${questionId}`);
          element?.scrollIntoView({ behavior: 'smooth' });
        }, 400);
      }
      setExpandedQuestionIds([...expandedQuestionIds, questionId]);
    }
  }, [questionId]);

  return (
    <Box
      component="section"
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? 'background.default' : 'white'),
      }}
    >
      <Container maxWidth="lg" sx={{ py: 10 }}>
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography variant="h4" color="secondary">
            Câu hỏi thường gặp
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center" sx={{ mb: 4 }}>
          {sections.map((section) => (
            <Grid key={section.title} item>
              <Button
                onClick={() => changeSectionId(section.id)}
                variant={sectionId === section.id ? 'contained' : 'outlined'}
                sx={{ borderRadius: 50, px: 3 }}
              >
                <CardActionArea>
                  <CardContent
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      {section.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Button>
            </Grid>
          ))}
        </Grid>

        {selectedSection?.questions?.map((question) => (
          <Accordion
            key={question.id}
            disableGutters={true}
            elevation={0}
            sx={{
              '&.Mui-expanded:before': {
                opacity: 1,
              },
            }}
            id={`question-${question.id}`}
            onChange={(_e, expaneded) => handleExpandChange(question.id, expaneded)}
            expanded={expandedQuestionIds.includes(question.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${question.id}-content`}
              id={`${question.id}-header`}
              sx={{ '& .MuiAccordionSummary-content': { my: 3 } }}
            >
              <Typography component="div" sx={{ fontSize: '1rem' }} variant="h6">
                {question.title}
              </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ pb: 3 }}>
              <p style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: question.answer }} />
              {/* <Typography component="p" sx={{ whiteSpace: 'pre-line' }}>
                {question.answer}
              </Typography> */}
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
}
