import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TeacherProfile, TeacherStatistic } from 'teacher/api/models';
import { User } from 'firebase/auth';

export interface StatisticState {
  overallStatistic: any;
  teacherStatisticFetched: boolean;
  teacherStatisticLoading: boolean;
  statisticByMonths: any;
}

const initialState: StatisticState = {
  overallStatistic: null,
  teacherStatisticFetched: false,
  teacherStatisticLoading: false,
  statisticByMonths: null,
};

export const statisticSlice = createSlice({
  name: 'statisticSlice',
  initialState,
  reducers: {
    changeStatisticStateReset: (state) => {
      state.overallStatistic = null;
      state.statisticByMonths = null;
    },
    changeOverallStatistic: (state, action: PayloadAction<any | null>) => {
      state.overallStatistic = action.payload;
    },
    changeStatisticByMonths: (state, action: PayloadAction<any | null>) => {
      state.statisticByMonths = action.payload;
    },
    changeTeacherStatisticFetched: (state, action: PayloadAction<boolean>) => {
      state.teacherStatisticFetched = action.payload;
    },
    changeTeacherStatisticLoading: (state, action: PayloadAction<boolean>) => {
      state.teacherStatisticLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeStatisticStateReset,
  changeOverallStatistic,
  changeStatisticByMonths,
  changeTeacherStatisticFetched,
  changeTeacherStatisticLoading,
} = statisticSlice.actions;

export const statisticState = statisticSlice.reducer;
