import React from 'react';
import Box from '@mui/material/Box';

import Divider from 'common/components/Divider';
import Select, { Option } from 'common/components/Field/Select';
import SelectItemButton from 'common/components/Field/SelectItemButton';

import { CoursesFilter } from 'student/features';
import { useResponsive } from 'student/hooks';
import { List, ListSubheader } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterCollapse from './FilterCollapse';

interface Props {
  filter: CoursesFilter;
  selectedFilter: CoursesFilter;
  handleChangeSelectedFilter: (changedFilter: CoursesFilter) => void;
}

export default function ClazzListFilter({ filter, selectedFilter, handleChangeSelectedFilter }: Props) {
  const { isMobile } = useResponsive();

  const handleChangeAgeRangeOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      ageRangeOptions: options,
    });
  };
  const handleChangeWeekdayOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      weekDayOptions: options,
    });
  };
  const handleChangeTimeSlotOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      timeSlotOptions: options,
    });
  };
  const handleChangePriceOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      priceOptions: options,
    });
  };

  return isMobile ? (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" sx={{ borderBottom: '1px solid #dcdcdc' }}>
          Lọc <FilterAltIcon />
        </ListSubheader>
      }
    >
      <Box sx={{ borderBottom: '1px solid #dcdcdc' }}>
        <FilterCollapse
          title="Tuổi"
          options={filter.ageRangeOptions}
          selectedOptions={selectedFilter.ageRangeOptions}
          handleChangeOptions={handleChangeAgeRangeOptions}
        />
      </Box>
      <Box sx={{ borderBottom: '1px solid #dcdcdc' }}>
        <FilterCollapse
          title="Thứ ngày"
          options={filter.weekDayOptions}
          selectedOptions={selectedFilter.weekDayOptions}
          handleChangeOptions={handleChangeWeekdayOptions}
        />
      </Box>
      <Box sx={{ borderBottom: '1px solid #dcdcdc' }}>
        <FilterCollapse
          title="Khung giờ"
          options={filter.timeSlotOptions}
          selectedOptions={selectedFilter.timeSlotOptions}
          handleChangeOptions={handleChangeTimeSlotOptions}
        />
      </Box>
      <Box>
        <FilterCollapse
          title="Học phí/Buổi"
          options={filter.priceOptions}
          selectedOptions={selectedFilter.priceOptions}
          handleChangeOptions={handleChangePriceOptions}
        />
      </Box>
    </List>
  ) : (
    <Box position="sticky" top={16}>
      <Select
        title="Tuổi"
        options={filter.ageRangeOptions}
        selectedOptions={selectedFilter.ageRangeOptions}
        handleChangeOptions={handleChangeAgeRangeOptions}
      />
      <Divider />

      <Select
        title="Thứ ngày"
        options={filter.weekDayOptions}
        selectedOptions={selectedFilter.weekDayOptions}
        handleChangeOptions={handleChangeWeekdayOptions}
        CustomSelectItem={(props) => <SelectItemButton {...props} />}
        optionItemStyle={{ width: 30, mr: 0.5 }}
      />
      <Divider />

      <Select
        title="Khung giờ"
        options={filter.timeSlotOptions}
        selectedOptions={selectedFilter.timeSlotOptions}
        handleChangeOptions={handleChangeTimeSlotOptions}
        optionItemStyle={{ width: '100%' }}
      />
      <Divider />

      <Select
        title="Học phí/Buổi"
        options={filter.priceOptions}
        selectedOptions={selectedFilter.priceOptions}
        handleChangeOptions={handleChangePriceOptions}
        optionItemStyle={{ width: '100%' }}
      />
      <Divider />
    </Box>
  );
}
