/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompactEnrolledClassInfo } from './CompactEnrolledClassInfo';
import {
    CompactEnrolledClassInfoFromJSON,
    CompactEnrolledClassInfoFromJSONTyped,
    CompactEnrolledClassInfoToJSON,
} from './CompactEnrolledClassInfo';

/**
 * parent information schema
 * @export
 * @interface ParentProfile
 */
export interface ParentProfile {
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    parentName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentProfile
     */
    studentNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentProfile
     */
    interestedFields?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    referredFromExistingUser?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    dtCreated?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    dtModified?: string;
    /**
     * 
     * @type {string}
     * @memberof ParentProfile
     */
    referralCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentProfile
     */
    paymentConfirmations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParentProfile
     */
    favorites?: Array<string>;
    /**
     * 
     * @type {Array<CompactEnrolledClassInfo>}
     * @memberof ParentProfile
     */
    inProgressClasses?: Array<CompactEnrolledClassInfo>;
    /**
     * 
     * @type {Array<CompactEnrolledClassInfo>}
     * @memberof ParentProfile
     */
    learnedClasses?: Array<CompactEnrolledClassInfo>;
    /**
     * 
     * @type {Array<CompactEnrolledClassInfo>}
     * @memberof ParentProfile
     */
    incomingClasses?: Array<CompactEnrolledClassInfo>;
}

/**
 * Check if a given object implements the ParentProfile interface.
 */
export function instanceOfParentProfile(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParentProfileFromJSON(json: any): ParentProfile {
    return ParentProfileFromJSONTyped(json, false);
}

export function ParentProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParentProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentName': !exists(json, 'parentName') ? undefined : json['parentName'],
        'studentNames': !exists(json, 'studentNames') ? undefined : json['studentNames'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'interestedFields': !exists(json, 'interestedFields') ? undefined : json['interestedFields'],
        'referredFromExistingUser': !exists(json, 'referredFromExistingUser') ? undefined : json['referredFromExistingUser'],
        'dtCreated': !exists(json, 'dtCreated') ? undefined : json['dtCreated'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'dtModified': !exists(json, 'dtModified') ? undefined : json['dtModified'],
        'referralCode': !exists(json, 'referralCode') ? undefined : json['referralCode'],
        'paymentConfirmations': !exists(json, 'paymentConfirmations') ? undefined : json['paymentConfirmations'],
        'favorites': !exists(json, 'favorites') ? undefined : json['favorites'],
        'inProgressClasses': !exists(json, 'inProgressClasses') ? undefined : ((json['inProgressClasses'] as Array<any>).map(CompactEnrolledClassInfoFromJSON)),
        'learnedClasses': !exists(json, 'learnedClasses') ? undefined : ((json['learnedClasses'] as Array<any>).map(CompactEnrolledClassInfoFromJSON)),
        'incomingClasses': !exists(json, 'incomingClasses') ? undefined : ((json['incomingClasses'] as Array<any>).map(CompactEnrolledClassInfoFromJSON)),
    };
}

export function ParentProfileToJSON(value?: ParentProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentName': value.parentName,
        'studentNames': value.studentNames,
        'phone': value.phone,
        'email': value.email,
        'interestedFields': value.interestedFields,
        'referredFromExistingUser': value.referredFromExistingUser,
        'dtCreated': value.dtCreated,
        'userId': value.userId,
        'dtModified': value.dtModified,
        'referralCode': value.referralCode,
        'paymentConfirmations': value.paymentConfirmations,
        'favorites': value.favorites,
        'inProgressClasses': value.inProgressClasses === undefined ? undefined : ((value.inProgressClasses as Array<any>).map(CompactEnrolledClassInfoToJSON)),
        'learnedClasses': value.learnedClasses === undefined ? undefined : ((value.learnedClasses as Array<any>).map(CompactEnrolledClassInfoToJSON)),
        'incomingClasses': value.incomingClasses === undefined ? undefined : ((value.incomingClasses as Array<any>).map(CompactEnrolledClassInfoToJSON)),
    };
}

