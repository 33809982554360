import React from 'react';

import {
  FormControl,
  InputLabel,
  Input,
  TextField,
  CircularProgress,
  FormHelperText,
  Button,
  Rating,
  Box,
} from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useForm, Controller } from 'react-hook-form';

import { Review, ReviewApi, ReviewPostRequestParams, UploadImageUrl } from 'student/api';
import { RootState, useSelector } from 'student/store';
import { selectAuthState } from 'student/features/auth';
import { isCourseEnrolled } from 'student/features/course';
import { toast } from 'react-toastify';
import { uploadImage, normalizeImageName } from 'common/utils';

interface Props {
  classId: string;
  onCreateNewReview: (newReview: Review) => void;
}

export default function CourseDetailSectionReviewsForm({ classId, onCreateNewReview }: Props) {
  const { handleSubmit, register, control, reset } = useForm();

  const { user } = useSelector(selectAuthState);
  const studentId = `${user?.uid}`;
  const isEnrolled = useSelector((state: RootState) => isCourseEnrolled(state, classId));

  const [file, setFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = (data: any, e: any) => {
    const { content, rate } = data;
    const reviewPostRequestParams: ReviewPostRequestParams = { content, rate };
    if (file) {
      reviewPostRequestParams.imageFileName = normalizeImageName('review', classId, file.name);
    }

    const callApiUploadImage = file
      ? new ReviewApi().createUploadImageUrl({
          studentId,
          classId,
          reviewPostRequestParams,
        })
      : null;

    const callApiCreateReview = new ReviewApi().createReview({
      studentId,
      classId,
      reviewPostRequestParams,
    });

    setLoading(true);
    Promise.all([callApiUploadImage, callApiCreateReview])
      .then(async (values) => {
        if (file && values[0]) {
          const { uploadImageUrl } = values[0];
          const uploaded = await uploadImage(file, uploadImageUrl || '');
          if (!uploaded) {
            toast.error('Lỗi tải ảnh trong đánh giá mới.');
          }
        }

        const review = values[1];
        toast.success('Đã thêm đánh giá mới.');
        onCreateNewReview(review);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Lỗi tạo đánh giá mới! Vui lòng thử lại sau!');
      })
      .then(() => {
        setLoading(false);
        setFile(null);
        reset();
      });
  };

  const handleChangeFiles = (e: any) => {
    const files: File[] = e.target.files;
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  return isEnrolled ? (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <Controller
          name="rate"
          control={control}
          defaultValue={-1}
          rules={{ required: true, min: 1, max: 5 }}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => {
            return (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Rating name="rate" onChange={(_e, newValue) => onChange(newValue || 0)} value={Number(value)} />
                <FormHelperText error={Boolean(error)}>{error?.type ? 'Đánh giá từ 1 đến 5 sao' : ''}</FormHelperText>
              </Box>
            );
          }}
        />

        <TextField
          id="outlined-multiline-static"
          required
          label="Nội dung"
          multiline
          rows={3}
          sx={{ mt: 2 }}
          {...register('content')}
        />

        <Box
          sx={{
            mt: 2,
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'left' },
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Button
            variant="outlined"
            size={'small'}
            sx={{ width: 'auto', fontSize: 14, pt: 0.5, textTransform: 'none' }}
            component="label"
            onChange={handleChangeFiles}
            endIcon={<AddAPhotoIcon sx={{ marginRight: 0, marginTop: '-4px', marginLeft: '-6px' }} />}
          >
            Thêm ảnh
            <input type="file" hidden accept="image/png, image/jpeg" />
          </Button>
          <FormHelperText>{file ? file.name : ''}</FormHelperText>
        </Box>

        <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Gửi đánh giá
          </Button>
        </Box>
      </FormControl>
    </Box>
  ) : (
    <></>
  );
}
