import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Section } from 'student/layout';
import { BannerSubtitle, BannerTitle } from 'common/components/Text';
import Button from 'common/components/mui/Button';
import A from 'common/assets';
import './style.scss';
import { routes } from 'student/Routes';
import { useResponsive } from 'student/hooks';

const Banner = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  return (
    <Section className="banner-container">
      <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
        <Grid item xs={12} md={5} lg={5}>
          <div className="left">
            <BannerTitle>
              Để con học <br /> điều mình muốn
            </BannerTitle>
            <BannerSubtitle>
              Nơi con học kiến thức thực tiễn trên nhiều lĩnh vực và tìm thấy niềm vui trong học tập
            </BannerSubtitle>
            <Grid
              container
              spacing={2}
              alignItems={isMobile ? 'center' : 'left'}
              justifyContent={isMobile ? 'center' : 'left'}
            >
              <Grid item className="text-center my-2">
                <Button
                  variant="contained"
                  sx={{
                    py: isMobile ? 1 : 2,
                    px: isMobile ? 4 : 8,
                    fontSize: 20,
                  }}
                  onClick={() => navigate(routes.PageCourses.path)}
                >
                  <b>Tìm lớp học</b>
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={7} lg={7} order={isMobile ? -1 : 2}>
          <div className="right">
            <img src={A.images.banner} alt="banner" className="banner-img" />
          </div>
        </Grid>
      </Grid>
    </Section>
  );
};

export default Banner;
