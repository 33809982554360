import * as React from 'react';
import { Card, CardContent, CardMedia, Stack, Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Text from 'common/components/Text';

const RootStyled = styled('div')(({ theme }) => ({
  '& .card-main': {
    display: 'flex',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    gap: theme.spacing(3),
    pt: theme.spacing(1.5),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      width: 300,
      borderRadius: '30px',
      objectFit: 'cover',
      height: 200,
      cursor: 'pointer',
      [theme.breakpoints.down('xl')]: {
        width: '100%',
        height: 200,
      },
    },
  },
}));

export default function CourseListItem() {
  return (
    <RootStyled>
      <Card className="card-main" sx={{ pb: 2 }}>
        <Grid container alignItems="stretch">
          <Grid item xs={12} md={'auto'} sx={{ display: 'flex' }} justifyContent="center" alignItems="baseline">
            <Skeleton variant="rectangular" height={300} width={300} />
          </Grid>

          <Grid item xs={12} md={'auto'} sx={{ flex: { md: 1 }, mt: { md: 0, xs: 2, sm: 1 } }}>
            <CardContent
              sx={{
                flex: '1 0 auto',
                py: 0 + '!important',
                px: { xs: 0, sm: 2 },
                pl: { sm: 4 },
              }}
            >
              <Typography variant="body1" fontWeight={'bold'}>
                <Skeleton width={100} />
              </Typography>
              <Typography variant="h5" mb={0.5}>
                <Skeleton width={'100%'} sx={{ py: 2 }} />
              </Typography>
              <Typography variant="body1" mt={1}>
                <Skeleton variant="rectangular" height={100} width={'100%'} />
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                <Skeleton width={100} />
              </Typography>
              <Stack direction="row" alignItems="center" my={{ xl: 2, xs: 1 }}>
                <Skeleton width={150} />
              </Stack>
              <Skeleton width={300} />
            </CardContent>
          </Grid>

          <Grid item xs={12} md={'auto'}>
            <Stack
              direction={{ xs: 'row-reverse', md: 'column' }}
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', width: { sm: '100%' } }}
            >
              <Skeleton variant="rectangular" height={50} width={'100%'} />

              <Text variant="h6" color="text.primary" sx={{ pr: 1 }} textAlign={'right'}>
                <Typography variant="body2" component="span">
                  <Skeleton width={150} />
                </Typography>
              </Text>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </RootStyled>
  );
}
