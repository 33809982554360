/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Image url
 * @export
 * @interface UploadImageUrl
 */
export interface UploadImageUrl {
    /**
     * 
     * @type {string}
     * @memberof UploadImageUrl
     */
    uploadImageUrl?: string;
}

/**
 * Check if a given object implements the UploadImageUrl interface.
 */
export function instanceOfUploadImageUrl(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UploadImageUrlFromJSON(json: any): UploadImageUrl {
    return UploadImageUrlFromJSONTyped(json, false);
}

export function UploadImageUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadImageUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadImageUrl': !exists(json, 'uploadImageUrl') ? undefined : json['uploadImageUrl'],
    };
}

export function UploadImageUrlToJSON(value?: UploadImageUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uploadImageUrl': value.uploadImageUrl,
    };
}

