import React from 'react';
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Loading from 'common/components/Loading';
import CourseDetailSectionAbout from 'student/features/course/components/CourseDetail/CourseDetailSectionAbout';
import CourseDetailSectionPrice from 'student/features/course/components/CourseDetail/CourseDetailSectionPrice';
import CourseDetailSectionSchedule from 'student/features/course/components/CourseDetail/SectionSchedule/CourseDetailSectionSchedule';
import CourseDetailSectionContent from 'student/features/course/components/CourseDetail/CourseDetailSectionContent';
import CourseDetailSectionReviews from 'student/features/course/components/CourseDetail/SectionReviews/CourseDetailSectionReviews';
import CourseDetailSectionTeacher from 'student/features/course/components/CourseDetail/CourseDetailSectionTeacher';

import ClassSlide from 'student/features/course/components/CourseSlide';

import { Clazz } from 'student/api';
import { useSelector, RootState } from 'student/store';
import { selectCourseById } from 'student/features';
import CourseDetailSectionStudentAchievement from 'student/features/course/components/CourseDetail/SectionStudentAchievement/CourseDetailSectionStudentAchievement';

const RootStyled = styled('div')(({ theme }) => ({
  margin: theme.spacing(4, 0),
}));

export default function PageCourse() {
  const { courseId } = useParams();
  if (!courseId) {
    toast.warning('Không có thông tin khóa học!');
  }

  const clazz: Clazz | undefined = useSelector((state: RootState) => selectCourseById(state, `${courseId}`));
  const { featuredCourses } = useSelector((state: RootState) => state.courseState);

  if (!clazz) {
    return <Loading />;
  }

  return (
    <RootStyled>
      <Container fixed>
        <CourseDetailSectionAbout clazz={clazz} />
        {/* <CourseDetailSectionAction clazz={clazz} /> */}
        <CourseDetailSectionPrice clazz={clazz} />
        <CourseDetailSectionSchedule clazz={clazz} />
        <CourseDetailSectionContent clazz={clazz} />
        <CourseDetailSectionTeacher teacherId={`${clazz.teacherId}`} />
        <CourseDetailSectionStudentAchievement clazz={clazz} />
        <CourseDetailSectionReviews clazz={clazz} />

        <ClassSlide
          loading={featuredCourses.loading}
          title="Khám phá vô vàn lớp học từ nhiều chủ đề khác nhau"
          titleSub="Cùng con lựa chọn khóa học phù hợp với mình từ kho bài giảng đa dạng của Amusingo"
          clazzes={featuredCourses.items.filter((item) => item.classId !== courseId)}
        />
      </Container>
    </RootStyled>
  );
}
