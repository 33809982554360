/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Review,
  ReviewList,
  ReviewPostRequestParams,
  ReviewPutRequestParams,
  UploadImageUrl,
} from '../models';
import {
    ReviewFromJSON,
    ReviewToJSON,
    ReviewListFromJSON,
    ReviewListToJSON,
    ReviewPostRequestParamsFromJSON,
    ReviewPostRequestParamsToJSON,
    ReviewPutRequestParamsFromJSON,
    ReviewPutRequestParamsToJSON,
    UploadImageUrlFromJSON,
    UploadImageUrlToJSON,
} from '../models';

export interface CreateReviewRequest {
    studentId: string;
    classId: string;
    reviewPostRequestParams: ReviewPostRequestParams;
}

export interface CreateUploadImageUrlRequest {
    classId: string;
    studentId: string;
    reviewPostRequestParams: ReviewPostRequestParams;
}

export interface DeleteReviewRequest {
    classId: string;
    studentId: string;
}

export interface GetReviewRequest {
    classId: string;
    studentId: string;
}

export interface GetReviewsRequest {
    classId: string;
}

export interface UpdateReviewRequest {
    classId: string;
    studentId: string;
    reviewPutRequestParams: ReviewPutRequestParams;
}

/**
 * 
 */
export class ReviewApi extends runtime.BaseAPI {

    /**
     * Create a new review
     */
    async createReviewRaw(requestParameters: CreateReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling createReview.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling createReview.');
        }

        if (requestParameters.reviewPostRequestParams === null || requestParameters.reviewPostRequestParams === undefined) {
            throw new runtime.RequiredError('reviewPostRequestParams','Required parameter requestParameters.reviewPostRequestParams was null or undefined when calling createReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/class/{classId}/review`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewPostRequestParamsToJSON(requestParameters.reviewPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Create a new review
     */
    async createReview(requestParameters: CreateReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Review> {
        const response = await this.createReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload image url
     */
    async createUploadImageUrlRaw(requestParameters: CreateUploadImageUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadImageUrl>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling createUploadImageUrl.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling createUploadImageUrl.');
        }

        if (requestParameters.reviewPostRequestParams === null || requestParameters.reviewPostRequestParams === undefined) {
            throw new runtime.RequiredError('reviewPostRequestParams','Required parameter requestParameters.reviewPostRequestParams was null or undefined when calling createUploadImageUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/class/{classId}/review/uploadImageUrl`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewPostRequestParamsToJSON(requestParameters.reviewPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadImageUrlFromJSON(jsonValue));
    }

    /**
     * Upload image url
     */
    async createUploadImageUrl(requestParameters: CreateUploadImageUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadImageUrl> {
        const response = await this.createUploadImageUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete a review
     */
    async deleteReviewRaw(requestParameters: DeleteReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling deleteReview.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling deleteReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/classes/{classId}/review`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * delete a review
     */
    async deleteReview(requestParameters: DeleteReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Review> {
        const response = await this.deleteReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get details of a review based on classId and studentId
     */
    async getReviewRaw(requestParameters: GetReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getReview.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/students/{studentId}/classes/{classId}/review`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * get details of a review based on classId and studentId
     */
    async getReview(requestParameters: GetReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Review> {
        const response = await this.getReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all reviews of class based on classId
     */
    async getReviewsRaw(requestParameters: GetReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewList>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getReviews.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/classes/{classId}/reviews`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewListFromJSON(jsonValue));
    }

    /**
     * Get all reviews of class based on classId
     */
    async getReviews(requestParameters: GetReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewList> {
        const response = await this.getReviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing review
     */
    async updateReviewRaw(requestParameters: UpdateReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Review>> {
        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling updateReview.');
        }

        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling updateReview.');
        }

        if (requestParameters.reviewPutRequestParams === null || requestParameters.reviewPutRequestParams === undefined) {
            throw new runtime.RequiredError('reviewPutRequestParams','Required parameter requestParameters.reviewPutRequestParams was null or undefined when calling updateReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/classes/{classId}/review`.replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))).replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewPutRequestParamsToJSON(requestParameters.reviewPutRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFromJSON(jsonValue));
    }

    /**
     * Update an existing review
     */
    async updateReview(requestParameters: UpdateReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Review> {
        const response = await this.updateReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
