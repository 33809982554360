import React from 'react';
import Button from '@mui/material/Button';

import { SelectItemProps } from './Select';

export default function SelectItemButton({ label, isSelected, handleClick, ...props }: SelectItemProps) {
  return (
    <Button
      size="small"
      variant={isSelected ? 'contained' : 'outlined'}
      color={isSelected ? 'primary' : 'inherit'}
      onClick={handleClick}
      sx={{
        fontSize: '90%',
        maxWidth: 200,
        minWidth: 30,
        width: '100%',
      }}
      {...props}
    >
      {label}
    </Button>
  );
}
