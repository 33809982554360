/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Enrollment,
  EnrollmentList,
  EnrollmentPostRequest,
} from '../models';
import {
    EnrollmentFromJSON,
    EnrollmentToJSON,
    EnrollmentListFromJSON,
    EnrollmentListToJSON,
    EnrollmentPostRequestFromJSON,
    EnrollmentPostRequestToJSON,
} from '../models';

export interface CreateEnrollmentRequest {
    enrollmentPostRequest: EnrollmentPostRequest;
}

export interface GetClassEnrollmentRequest {
    studentId: string;
    classId: string;
}

export interface GetConfirmedEnrollmentsByStudentIdRequest {
    studentId: string;
}

export interface GetEnrollmentsByStudentIdRequest {
    studentId: string;
}

export interface GetPendingEnrollmentsByStudentIdRequest {
    studentId: string;
}

/**
 * 
 */
export class EnrollmentApi extends runtime.BaseAPI {

    /**
     * Create an enrollment
     */
    async createEnrollmentRaw(requestParameters: CreateEnrollmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Enrollment>> {
        if (requestParameters.enrollmentPostRequest === null || requestParameters.enrollmentPostRequest === undefined) {
            throw new runtime.RequiredError('enrollmentPostRequest','Required parameter requestParameters.enrollmentPostRequest was null or undefined when calling createEnrollment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/enrollments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnrollmentPostRequestToJSON(requestParameters.enrollmentPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnrollmentFromJSON(jsonValue));
    }

    /**
     * Create an enrollment
     */
    async createEnrollment(requestParameters: CreateEnrollmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Enrollment> {
        const response = await this.createEnrollmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get enrollment by student id and class id
     */
    async getClassEnrollmentRaw(requestParameters: GetClassEnrollmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Enrollment>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getClassEnrollment.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getClassEnrollment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/enrollments/students/{studentId}/classes/{classId}`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnrollmentFromJSON(jsonValue));
    }

    /**
     * Get enrollment by student id and class id
     */
    async getClassEnrollment(requestParameters: GetClassEnrollmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Enrollment> {
        const response = await this.getClassEnrollmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get confirmed enrollments by student id
     */
    async getConfirmedEnrollmentsByStudentIdRaw(requestParameters: GetConfirmedEnrollmentsByStudentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnrollmentList>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getConfirmedEnrollmentsByStudentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/enrollments/students/{studentId}/confirmed`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnrollmentListFromJSON(jsonValue));
    }

    /**
     * Get confirmed enrollments by student id
     */
    async getConfirmedEnrollmentsByStudentId(requestParameters: GetConfirmedEnrollmentsByStudentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnrollmentList> {
        const response = await this.getConfirmedEnrollmentsByStudentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get enrollments by student id
     */
    async getEnrollmentsByStudentIdRaw(requestParameters: GetEnrollmentsByStudentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnrollmentList>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getEnrollmentsByStudentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/enrollments/students/{studentId}`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnrollmentListFromJSON(jsonValue));
    }

    /**
     * Get enrollments by student id
     */
    async getEnrollmentsByStudentId(requestParameters: GetEnrollmentsByStudentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnrollmentList> {
        const response = await this.getEnrollmentsByStudentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get pending enrollments by student id
     */
    async getPendingEnrollmentsByStudentIdRaw(requestParameters: GetPendingEnrollmentsByStudentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EnrollmentList>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getPendingEnrollmentsByStudentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/enrollments/students/{studentId}/pending`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnrollmentListFromJSON(jsonValue));
    }

    /**
     * Get pending enrollments by student id
     */
    async getPendingEnrollmentsByStudentId(requestParameters: GetPendingEnrollmentsByStudentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EnrollmentList> {
        const response = await this.getPendingEnrollmentsByStudentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
