import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import { EnrollmentStatusEnum } from 'student/api';
import A from 'common/assets';

interface Props {
  paymentStatus: EnrollmentStatusEnum;
}

export default function PaymentStatus({ paymentStatus }: Props) {
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    switch (paymentStatus) {
      case EnrollmentStatusEnum.PendingConfirmation:
        setMessage('Bạn đã đăng kí khoá học này, chúng tôi đang xác nhận thanh toán.');
        break;
      case EnrollmentStatusEnum.Confirmed:
        setMessage(
          'Bạn đã đăng kí khoá học này, vui lòng xem thông tin lịch học và link Zoom để kết nối đến khoá học.'
        );
        break;
      case EnrollmentStatusEnum.Cancelled:
        setMessage('Bạn đã huỷ đăng kí khoá học này.');
        break;
      case EnrollmentStatusEnum.Rejected:
        setMessage('Bạn đã đăng kí khoá học này, chúng tôi xác nhân thanh toán không thành công.');
        break;
      case EnrollmentStatusEnum.RefundRequested:
        setMessage('Bạn đã yêu cầu hoàn tiền, chúng tôi đang xác nhận yêu cầu.');
        break;
      case EnrollmentStatusEnum.RefundApproved:
        setMessage('Bạn đã yêu cầu hoàn tiền, chúng tôi đã hoàn tiền cho bạn.');
        break;
      case EnrollmentStatusEnum.RefundRejected:
        setMessage('Bạn đã yêu cầu hoàn tiền, chúng tôi không thể hoàn tiền cho bạn.');
        break;

      default:
        break;
    }
  }, [paymentStatus]);

  return (
    <Grid container textAlign="center" justifyContent={'center'}>
      <Grid xs={12}>
        <Typography variant="h6" color="text.primary" sx={{ fontFamily: A.fonts.regular, fontWeight: 600 }}>
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
}
