import React from 'react';
import { useSelector, CommonState } from 'common/store';
import { useDispatch } from 'react-redux';
import { changeCurrentSite } from 'common/features/site';
import { SiteEnum } from 'common/types';
import { useLocation } from 'react-router';

export const useSite = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const siteState = useSelector((commonState: CommonState) => commonState.siteState);

  React.useEffect(() => {
    const pathnames = location.pathname.split('/');
    const isTeacherSite = pathnames.length > 1 && pathnames[1] === 'teacher';
    const changingSite = isTeacherSite ? SiteEnum.Teacher : SiteEnum.Student;

    const isChangingToNewSite = siteState.currentSite !== changingSite;
    if (isChangingToNewSite) {
      if (isTeacherSite) {
        dispatch(changeCurrentSite(SiteEnum.Teacher));
      } else {
        dispatch(changeCurrentSite(SiteEnum.Student));
      }
    }
  }, [location]);

  return siteState;
};
