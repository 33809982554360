import * as React from 'react';
import { Box, Card, Rating, Typography, Stack, Grid, Avatar, CardActionArea } from '@mui/material';
import { styled } from '@mui/material/styles';

import Link from 'common/components/Link';
import Loading from 'common/components/Loading';
import { routes } from 'student/Routes';
import { useDummyTeacher } from 'student/hooks';
import TeacherCourses from '../TeacherCourses';

const RootStyled = styled('div')(({ theme }) => ({
  marginBottom: 40,
  '& .card-main': {
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '30px',
    gap: theme.spacing(3),
    pt: theme.spacing(1),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      height: 'auto',
      width: 'auto',
      maxWidth: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

interface Props {
  teacherId: string;
}

export default function CourseDetailSectionTeacher({ teacherId }: Props) {
  const [readMore, setReadMore] = React.useState(true);
  const { teacher, loading } = useDummyTeacher(teacherId);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  if (!teacher.teacherId || loading) {
    return <Loading />;
  }

  return (
    <RootStyled>
      <Card className="card-main">
        <Box sx={{ py: 5, px: 6 }}>
          <Typography variant="h5" mb={3}>
            Thông tin về giáo viên
          </Typography>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Link to={routes.PageTeacher.path.replace(':teacherId', `${teacher.teacherId}`)}>
                <CardActionArea sx={{ borderRadius: '50%' }}>
                  <Avatar
                    src={teacher.avatar}
                    alt={teacher.name}
                    sx={{
                      width: 220,
                      height: 220,
                    }}
                  />
                </CardActionArea>
              </Link>
            </Grid>

            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { md: 'column', xs: 'row' },
                  width: 1,
                }}
              >
                <Box sx={{ py: 0 + '!important' }}>
                  <Link to={routes.PageTeacher.path.replace(':teacherId', `${teacherId}`)}>
                    <Typography variant="subtitle1" color="primary" fontWeight={600}>
                      {teacher?.name}
                    </Typography>
                  </Link>
                  <Typography
                    variant="body1"
                    sx={{
                      span: { color: 'primary.main' },
                    }}
                  >
                    {teacher?.address}
                    <br />
                    <br />
                    {/* {readMore ? `${teacher?.description?.substring(0, 10)}` : teacher?.description}
                    <span onClick={toggleReadMore} color="primary">
                      {readMore ? '...xem thêm' : ' giản lược'}
                    </span> */}
                    {teacher?.description?.split('\\n').map((item, key) => {
                      if (item.includes('Kinh nghiệm/Thành tích:')) {
                        return (
                          <span
                            key={key}
                            style={{
                              color: 'inherit',
                            }}
                          >
                            <b>Kinh nghiệm/Thành tích: </b>
                            <Typography
                              dangerouslySetInnerHTML={{
                                __html: item.replace('Kinh nghiệm/Thành tích:', '').replaceAll(';', '<br/>'),
                              }}
                            ></Typography>
                          </span>
                        );
                      }

                      return (
                        <span
                          key={key}
                          style={{
                            color: 'inherit',
                          }}
                        >
                          {item}
                          <br />
                        </span>
                      );
                    })}
                  </Typography>

                  <Stack direction="row" alignItems="center" my={{ xl: 1.5, md: 1, xs: 0.5 }}>
                    <Rating
                      readOnly
                      name="hover-feedback"
                      value={teacher?.rate != -1 ? teacher?.rate : 0}
                      precision={0.5}
                    />
                    <Box sx={{ ml: 1, mt: 0.5 }}>
                      <Typography variant="subtitle1">
                        {teacher?.numReviewReceived && teacher?.numReviewReceived > 0
                          ? `( ${teacher?.numReviewReceived} )`
                          : null}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={3} mt={{ xl: 2, md: 2, xs: 0.5 }}>
                    {/* <Button variant="contained" size="large" sx={{ px: 2, fontWeight: 800 }}>
                      Nhận xét
                    </Button> */}
                    {/* <Button
                      variant="outlined"
                      color="secondary"
                      size="large"
                      sx={{ px: 2, fontWeight: 800 }}
                      startIcon={<PlayCircleOutlineIcon />}
                    >
                      Xem video giới thiệu
                    </Button> */}
                  </Stack>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
      <TeacherCourses teacherId={teacherId} />
    </RootStyled>
  );
}
