import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Courses, initCourses } from './types';
import { Clazz as Course } from 'student/api/models';

export interface CourseState {
  allCourses: Courses;
  coursesById: {
    [key: string]: Course;
  };
  featuredCourses: Courses;
  myEnrolledCourses: Courses;
  mySavedCourses: Courses;
}

const initialState: CourseState = {
  allCourses: initCourses,
  coursesById: {},
  featuredCourses: initCourses,
  myEnrolledCourses: initCourses,
  mySavedCourses: initCourses,
};

export const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    changeAllCourses: (state, action: PayloadAction<Partial<Courses>>) => {
      state.allCourses = { ...state.allCourses, ...action.payload };
      state.allCourses.items.map((course: Course) => {
        if (course.classId) {
          state.coursesById[course.classId] = course;
        }
      });
    },
    changeFeaturedCourses: (state, action: PayloadAction<Partial<Courses>>) => {
      state.featuredCourses = { ...state.featuredCourses, ...action.payload };
    },
    changeMyEnrolledCourses: (state, action: PayloadAction<Partial<Courses>>) => {
      state.myEnrolledCourses = { ...state.myEnrolledCourses, ...action.payload };
    },
    changeMySavedCourses: (state, action: PayloadAction<Partial<Courses>>) => {
      state.mySavedCourses = { ...state.mySavedCourses, ...action.payload };
    },
  },
});

export const { changeAllCourses } = courseSlice.actions;
export const { changeFeaturedCourses } = courseSlice.actions;
export const { changeMyEnrolledCourses } = courseSlice.actions;
export const { changeMySavedCourses } = courseSlice.actions;

export const courseState = courseSlice.reducer;
