import { CoursesFilter, fullCoursesFilter, initCoursesFilter } from './CoursesFilter';
import { Clazz as Course } from 'student/api/models';

export interface Courses {
  items: Course[];
  filter: CoursesFilter;
  selectedFilter: CoursesFilter;
  loading: boolean;
  fetched: boolean;
}

export const initCourses: Courses = {
  items: [],
  filter: fullCoursesFilter,
  selectedFilter: initCoursesFilter,
  loading: true,
  fetched: false,
};
