/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * class with only fundamental information
 * @export
 * @interface CompactEnrolledClassInfo
 */
export interface CompactEnrolledClassInfo {
    /**
     * 
     * @type {string}
     * @memberof CompactEnrolledClassInfo
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactEnrolledClassInfo
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactEnrolledClassInfo
     */
    scheduleId?: string;
}

/**
 * Check if a given object implements the CompactEnrolledClassInfo interface.
 */
export function instanceOfCompactEnrolledClassInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompactEnrolledClassInfoFromJSON(json: any): CompactEnrolledClassInfo {
    return CompactEnrolledClassInfoFromJSONTyped(json, false);
}

export function CompactEnrolledClassInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompactEnrolledClassInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teacherId': !exists(json, 'teacherId') ? undefined : json['teacherId'],
        'classId': !exists(json, 'classId') ? undefined : json['classId'],
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
    };
}

export function CompactEnrolledClassInfoToJSON(value?: CompactEnrolledClassInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teacherId': value.teacherId,
        'classId': value.classId,
        'scheduleId': value.scheduleId,
    };
}

