import React from 'react';
import { Container } from '@mui/system';
import { Grid } from '@mui/material';
import { Facebook } from '@mui/icons-material';
import Link from 'common/components/Link';

import './style.scss';
import { routes } from 'student/Routes';

const Footer = () => {
  return (
    <div className="footer-container">
      <Container>
        <div className="footer-details">
          <Grid container>
            <Grid item xs={12} md={4} className="mt-3">
              <h6>
                <b>Liên hệ</b>
              </h6>
              <p className="mb-1">
                Điện thoại: <a href="tel:+84925779797">0925779797</a>
              </p>
              <p>
                Email: <a href="mailto:hello@amusingo.com">hello@amusingo.com</a>
              </p>
            </Grid>
            <Grid item xs={12} md={4} className="mt-3">
              <h6>
                <b>Điều khoản</b>
              </h6>
              <p className="mb-1">
                <Link to={routes.PageFAQSection.path.replace(':sectionId', 'teacher')}>Dành cho giáo viên</Link>
              </p>
              <p className="mb-1">
                <Link to={routes.PageFAQSection.path.replace(':sectionId', 'parent')}>Dành cho phụ huynh</Link>
              </p>
            </Grid>
            <Grid item xs={12} md={4} className="mt-3">
              <h6>
                <b>Theo dõi chúng tôi</b>
              </h6>
              <Link to="//www.facebook.com/amusingo.edu" target="_blank" rel="noopener">
                <div className="social-icon-cont">
                  <Facebook className="facebook-icon" />
                </div>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <div className="text-center mt-2">© 2022 Amusingo</div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
