/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Teacher Profile Application Status
 * @export
 * @interface ApplicationStatus
 */
export interface ApplicationStatus {
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationStatus
     */
    createdAt?: string;
}

/**
 * Check if a given object implements the ApplicationStatus interface.
 */
export function instanceOfApplicationStatus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationStatusFromJSON(json: any): ApplicationStatus {
    return ApplicationStatusFromJSONTyped(json, false);
}

export function ApplicationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
    };
}

export function ApplicationStatusToJSON(value?: ApplicationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'createdAt': value.createdAt,
    };
}

