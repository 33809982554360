/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Zoom meeting join url
 * @export
 * @interface ZoomUrl
 */
export interface ZoomUrl {
    /**
     * 
     * @type {string}
     * @memberof ZoomUrl
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoomUrl
     */
    scheduleId?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoomUrl
     */
    joinUrl?: string;
}

/**
 * Check if a given object implements the ZoomUrl interface.
 */
export function instanceOfZoomUrl(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ZoomUrlFromJSON(json: any): ZoomUrl {
    return ZoomUrlFromJSONTyped(json, false);
}

export function ZoomUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoomUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classId': !exists(json, 'classId') ? undefined : json['classId'],
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
        'joinUrl': !exists(json, 'joinUrl') ? undefined : json['joinUrl'],
    };
}

export function ZoomUrlToJSON(value?: ZoomUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classId': value.classId,
        'scheduleId': value.scheduleId,
        'joinUrl': value.joinUrl,
    };
}

