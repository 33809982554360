import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import A from 'common/assets';
import { SectionTitle, SectionTitleSub } from 'common/components/Text';
import Image from 'common/components/Image';
import { Section } from 'student/layout';
import { Clazz } from 'student/api';
import CourseSlideItem from './CourseSlideItem';
import HorizontalSkeletonLoading from 'common/components/mui/SkeletonLoading';
import { useResponsive } from 'student/hooks';

const StyledSlider = styled(Slider)({
  maxWidth: '100vw',
  '.slick-slide': {
    margin: '20px 10px',
  },
  '.slick-prev:before, .slick-next:before': {
    content: "''",
  },
  '.slick-prev': {
    left: -30,
  },
  '.slick-next': {
    right: -30,
  },
});

interface Props {
  loading: boolean;
  title?: string;
  titleComponent?: any;
  titleSub?: string;
  clazzes: Clazz[];
  categoryName?: string;
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: 30,
        height: 30,
      }}
      onClick={onClick}
    >
      <Image src={A.images.icon_arrow} alt="arrow-icon" />
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: 30,
        height: 30,
      }}
      onClick={onClick}
    >
      <Image src={A.images.icon_arrow_left} alt="arrow-icon" />
    </div>
  );
}

export default function CourseSlide({ loading, title, titleComponent, titleSub, clazzes, categoryName }: Props) {
  const itemMinWidth = 300;

  const { isDesktop, isMobile } = useResponsive();
  const [isInfiniteDesktop, setIsInfiniteDesktop] = React.useState(true);
  const [isInfiniteMobile, setIsInfiniteMobile] = React.useState(true);

  React.useEffect(() => {
    if (clazzes) {
      setIsInfiniteDesktop(clazzes.length > 3);
      setIsInfiniteMobile(clazzes.length > 1);
    }
  }, [clazzes]);

  const sliderSettings = {
    className: 'slider variable-width',
    infinite: isDesktop ? isInfiniteDesktop : isInfiniteMobile,
    centerPadding: '60px',
    slidesToShow: isInfiniteDesktop || isMobile ? 1 : clazzes.length,
    swipeToSlide: true,
    variableWidth: true,
    autoplay: true,
    nextArrow: isDesktop ? <NextArrow /> : <></>,
    prevArrow: isDesktop ? <PrevArrow /> : <></>,
  };

  return (
    <Section marginBottom={categoryName && 2}>
      <div className={categoryName ? 'mb-2' : 'mb-5'}>
        {titleComponent ? titleComponent : null}
        {title ? <SectionTitle>{title}</SectionTitle> : null}
        {titleSub ? <SectionTitleSub>{titleSub}</SectionTitleSub> : null}
      </div>
      {loading ? (
        <HorizontalSkeletonLoading />
      ) : (
        <>
          {categoryName && (
            <Box
              sx={{
                fontWeight: 800,
                fontSize: '24px',
                lineHeight: '160%',
                color: '#696984',
                marginBottom: '16px',
              }}
            >
              Khóa học <span style={{ color: '#932191' }}>{categoryName}</span>
            </Box>
          )}
          <StyledSlider {...sliderSettings}>
            {clazzes.map((clazz) => (
              <CourseSlideItem key={clazz.classId} clazz={clazz} itemMinWidth={itemMinWidth} />
            ))}
          </StyledSlider>
        </>
      )}
    </Section>
  );
}
