import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FacebookIcon from '@mui/icons-material/Facebook';
import { EmailShareButton, FacebookShareButton, FacebookMessengerShareButton } from 'react-share';
// import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  handleChangeOpen: (open: boolean) => void;
  url: string;
  title: string;
  content: string;
}

export default function DialogShare(props: Props) {
  const { open, handleChangeOpen, url, title, content } = props;

  const handleClose = () => {
    handleChangeOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    toast.success('Đã sao chép đường dẫn khoá học');
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Chia sẻ qua: </DialogTitle>

      <List sx={{ pt: 0, minWidth: 300 }}>
        <ListItem disableGutters>
          <FacebookShareButton url={url} style={{ width: '100%' }}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{}}>
                  <FacebookIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Facebook'} />
            </ListItemButton>
          </FacebookShareButton>
        </ListItem>

        <ListItem disableGutters>
          {/* <EmailShareButton url={url} style={{ width: '100%' }}> */}
          <ListItemButton onClick={handleCopyLink}>
            <ListItemAvatar>
              <Avatar sx={{}}>
                <ContentCopyIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={'Sao chép đường dẫn'} />
          </ListItemButton>
          {/* </EmailShareButton> */}
        </ListItem>

        {/* <FacebookMessengerShareButton appId={'1191223854848540'} url={url}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{}}>
                  <EmailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={'Messenger'} />
            </ListItemButton>
          </FacebookMessengerShareButton> */}
      </List>
    </Dialog>
  );
}
