import { ENV } from './env';
import { SITE } from './site';
import { SiteEnum } from 'common/types';

const STUDENT_API_ENDPOINT = {
  development: 'https://o79ahqi0kf.execute-api.us-east-1.amazonaws.com/dev/v1',
  production: 'https://une63iuy5a.execute-api.ap-southeast-1.amazonaws.com/prod/v1',
}[ENV];
const TEACHER_API_ENDPOINT = {
  development: 'https://niqgpeihac.execute-api.us-east-1.amazonaws.com/dev/v1',
  production: 'TODO',
}[ENV];
export const API_ENDPOINT = {
  [SiteEnum.Student]: STUDENT_API_ENDPOINT,
  [SiteEnum.Teacher]: TEACHER_API_ENDPOINT,
}[SITE];
