import * as React from 'react';
import { Outlet as Child } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import ContactChatBubble from 'common/components/ContactChatBubble';

export function TeacherLayout() {
  return (
    <Box
      style={{
        minHeight: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        maxWidth: '100vw',
      }}
    >
      <Header />
      <Box style={{ width: '100vw' }}>
        <Child />
      </Box>
      <ContactChatBubble />
      <Footer />
    </Box>
  );
}
