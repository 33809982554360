import React from 'react';
import Box from '@mui/material/Box';

import Divider from 'common/components/Divider';
import Select, { Option } from 'common/components/Field/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CoursesFilter } from '../../types';
import A from 'common/assets';
import SelectItemButton from 'common/components/Field/SelectItemButton';
import FilterTime from './FilterTime';

interface Props {
  filter: CoursesFilter;
  selectedFilter: CoursesFilter;
  handleChangeSelectedFilter: (changedFilter: CoursesFilter) => void;
}

export default function CourseScheduleFilter({ filter, selectedFilter, handleChangeSelectedFilter }: Props) {
  const handleChangeTimeOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      timeOptions: options,
    });
  };
  const handleChangeWeekdayOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      weekDayOptions: options,
    });
  };
  const handleChangeTimeSlotOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      timeSlotOptions: options,
    });
  };

  return (
    <>
      <Box sx={{ color: A.colors.secondary, fontSize: 18, fontFamily: A.fonts.bold }}>
        <FilterAltIcon /> Lọc
      </Box>
      <Divider />
      <FilterTime
        options={filter.timeOptions}
        selectedOptions={selectedFilter.timeOptions}
        handleChangeOptions={handleChangeTimeOptions}
      />
      <Divider />
      <Select
        title="Thứ ngày"
        options={filter.weekDayOptions}
        selectedOptions={selectedFilter.weekDayOptions}
        handleChangeOptions={handleChangeWeekdayOptions}
        CustomSelectItem={(props) => <SelectItemButton {...props} />}
        optionItemStyle={{ width: 30, mr: 0.5 }}
      />
      <Divider />

      <Select
        title="Khung giờ"
        options={filter.timeSlotOptions}
        selectedOptions={selectedFilter.timeSlotOptions}
        handleChangeOptions={handleChangeTimeSlotOptions}
        optionItemStyle={{ width: '100%' }}
      />
      <Divider />
    </>
  );
}
