import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import A from 'common/assets';
import CourseScheduleList from 'teacher/features/courses/components/CourseScheduleList';
import { useResponsive } from 'teacher/hooks';
import { dispatch, useSelector } from 'teacher/store';
import { changeCourseByTeacherId, selectCoursesByTeacherId } from 'teacher/features/courses';
import { filterCourses } from '../features/courses/utils';
import { CoursesFilter } from 'teacher/features/courses/types';
import CourseScheduleFilter from 'teacher/features/courses/components/CoursesFilter/CourseScheduleFilter';

const PageSchedule = () => {
  const { isMobile } = useResponsive();

  const teacherCourses = useSelector(selectCoursesByTeacherId);
  const filteredCourses = {
    ...teacherCourses,
    items: filterCourses(teacherCourses.items, teacherCourses.selectedFilter),
  };

  const handleChangeSelectedFilter = (selectedFilter: CoursesFilter) => {
    dispatch(changeCourseByTeacherId({ selectedFilter }));
    window.scrollTo(0, 0);
  };

  return (
    <Container fixed>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
          margin: '25px 0 25px',
        }}
      >
        <Box
          sx={{
            color: A.colors.primary,
            fontSize: 30,
            fontFamily: A.fonts.bold,
            fontWeight: '900',
            marginBottom: isMobile ? '20px' : 0,
          }}
        >
          Lịch học
        </Box>
      </Box>
      <Grid container spacing={5}>
        <Grid item lg={3} xs={12} sx={{ display: { sm: 'block', xs: 'none' } }}>
          <CourseScheduleFilter
            filter={teacherCourses.filter}
            selectedFilter={teacherCourses.selectedFilter}
            handleChangeSelectedFilter={handleChangeSelectedFilter}
          />
        </Grid>
        <Grid item lg={9} xs={12}>
          <CourseScheduleList courses={filteredCourses} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageSchedule;
