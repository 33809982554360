import React from 'react';
import { Container } from '@mui/material';

import { SectionTitle, SectionTitleSub } from 'common/components/Text';
import { PAGE_HOME_SECTION_SLIDES_CATEGORY_IDS } from 'common/config';

import { useCategory } from 'student/features';
import CourseSlide from 'student/features/course/components/CourseSlide';
import { Clazz } from 'student/api';

type CategoryCourse = {
  categoryName?: string;
  courses: Clazz[];
};

interface Props {
  loading: boolean;
  title?: string;
  titleSub?: string;
  clazzes: Clazz[];
}

const PageHomeSectionCourseSlideList = ({ loading, title, titleSub, clazzes }: Props) => {
  const categoryState = useCategory();

  const categoryCourses = React.useMemo(() => {
    const categoryCourseObject = clazzes.reduce((result: Record<string, Clazz[]>, course) => {
      const categoryId = course.categoryId;
      if (categoryId && PAGE_HOME_SECTION_SLIDES_CATEGORY_IDS.includes(categoryId)) {
        if (!result[categoryId]) {
          result[categoryId] = [course];
        } else {
          result[categoryId].push(course);
        }
      } else {
        if (!result['OTHER']) {
          result['OTHER'] = [course];
        } else {
          result['OTHER'].push(course);
        }
      }
      return result;
    }, {});

    const categoryCoursesMemo: CategoryCourse[] = PAGE_HOME_SECTION_SLIDES_CATEGORY_IDS.map((categoryId) => {
      const categoryExist = categoryState.items.find((category) => category.categoryId === categoryId);
      const categoryCourseSort = categoryCourseObject[categoryId];
      if (categoryCourseSort) {
        categoryCourseSort.sort((pre, next) => {
          if (pre.averageRating !== undefined && next.averageRating !== undefined)
            return pre.averageRating > next.averageRating ? -1 : 1;
          return 0;
        });
      }
      if (categoryExist) {
        return {
          categoryName: categoryExist.categoryName,
          courses: categoryCourseSort,
        };
      }
      return {
        categoryName: 'Khác',
        courses: categoryCourseSort,
      };
    });

    return categoryCoursesMemo;
  }, [clazzes, categoryState]);

  return (
    <>
      <Container>
        {title ? <SectionTitle>{title}</SectionTitle> : null}
        {titleSub ? <SectionTitleSub>{titleSub}</SectionTitleSub> : null}
      </Container>
      {categoryCourses?.map((categoryCourse, index) => {
        return (
          <div key={index}>
            <CourseSlide
              loading={loading}
              clazzes={categoryCourse.courses}
              categoryName={categoryCourse.categoryName}
            />
          </div>
        );
      })}
    </>
  );
};

export default PageHomeSectionCourseSlideList;
