import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Category } from 'student/api';

export interface CategoryState {
  items: Category[];
  loading: boolean;
}

const initialState: CategoryState = {
  items: [] as Category[],
  loading: true,
};

export const categorySlice = createSlice({
  name: 'categoryState',
  initialState,
  reducers: {
    changeCategoryItems: (state, action: PayloadAction<Category[]>) => {
      state.items = action.payload;
    },
    changeCategoryLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCategoryItems, changeCategoryLoading } = categorySlice.actions;

export const categoryState = categorySlice.reducer;
