const fonts = {
  black: 'SVN-Poppins-Black',
  bold: 'SVN-Poppins-Bold',
  light: 'SVN-Poppins-Light',
  medium: 'SVN-Poppins-Medium',
  regular: 'SVN-Poppins-Regular',
  semiBold: 'SVN-Poppins-SemiBold',
};

export default fonts;
