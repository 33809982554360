import { Clazz as Course } from 'student/api/models';
import {
  getWeekDayNumber,
  getFlatSchedules,
  FlatSchedule,
  getClassScheduleStartMs,
  getClassScheduleEndMs,
  getHourByMs,
} from 'common/utils';
import { CoursesFilter } from '../types';

export const filterCourses = (courses: Course[], selectedFilter: CoursesFilter): Course[] => {
  let filteredCourses = [...courses];

  if (selectedFilter.categoryId) {
    filteredCourses = filteredCourses.filter((clazz: Course) => clazz.categoryId === selectedFilter.categoryId);
  }

  // Filter by age range
  if (selectedFilter.ageRangeOptions.length > 0) {
    filteredCourses = filteredCourses.filter((clazz: Course) => {
      if (clazz.ageRange === undefined) {
        return false;
      }

      const { minAge, maxAge } = clazz.ageRange;
      if (!minAge || !maxAge) return false;

      for (let i = 0; i < selectedFilter.ageRangeOptions.length; i++) {
        const { value } = selectedFilter.ageRangeOptions[i];
        if (value.minAge === undefined || value.maxAge === undefined) return false;

        if (value.minAge <= minAge && minAge <= value.maxAge) return true;
        if (value.minAge <= maxAge && maxAge <= value.maxAge) return true;
      }

      return false;
    });
  }

  // Filter by price
  if (selectedFilter.priceOptions.length > 0) {
    filteredCourses = filteredCourses.filter((clazz: Course) => {
      const { price } = clazz;
      if (price === undefined) {
        return false;
      }
      for (let i = 0; i < selectedFilter.priceOptions.length; i++) {
        const { value } = selectedFilter.priceOptions[i];
        if (value.minPrice === undefined || value.maxPrice === undefined) return false;

        if (value.minPrice <= price && price <= value.maxPrice) return true;
      }

      return false;
    });
  }

  // Refs https://github.com/amusingo/student-site-frontend/issues/134
  const ignoreWeekDayAndTimeSlotFilterBeforeMar13 = new Date() < new Date('2023-03-13');
  if (ignoreWeekDayAndTimeSlotFilterBeforeMar13) {
    return filteredCourses;
  }

  // Filter by week day
  if (selectedFilter.weekDayOptions.length > 0) {
    filteredCourses = filteredCourses.filter((clazz: Course) => {
      const classSchedules = clazz.schedules;
      if (classSchedules === undefined) return false;

      const flatSchedules: FlatSchedule[] = getFlatSchedules(classSchedules);

      for (let i = 0; i < selectedFilter.weekDayOptions.length; i++) {
        const { value } = selectedFilter.weekDayOptions[i];
        if (value === undefined) return false;

        for (let j = 0; j < flatSchedules.length; j++) {
          const schedule: FlatSchedule = flatSchedules[j];
          const isWeekDayNumber = schedule.scheduleTimes?.some((sch) => {
            const weekDayNumber = getWeekDayNumber(new Date(parseInt(sch.start) * 1000));
            return weekDayNumber === value;
          });
          if (isWeekDayNumber) return true;
        }
      }

      return false;
    });
  }

  // Filter by time slot
  if (selectedFilter.timeSlotOptions.length > 0) {
    filteredCourses = filteredCourses.filter((clazz: Course) => {
      const classSchedules = clazz.schedules;
      if (classSchedules === undefined) {
        return false;
      }

      const flatSchedules: FlatSchedule[] = getFlatSchedules(classSchedules);

      for (let i = 0; i < selectedFilter.timeSlotOptions.length; i++) {
        const { value } = selectedFilter.timeSlotOptions[i];
        if (value === undefined || value.minTimeSlot === undefined || value.maxTimeSlot === undefined) return false;

        for (let j = 0; j < flatSchedules.length; j++) {
          const schedule: FlatSchedule = flatSchedules[j];

          const isTimeSlotSatisfied = schedule.scheduleTimes?.some((sch) => {
            const startHour = getHourByMs(getClassScheduleStartMs(sch));
            const endHour = getHourByMs(getClassScheduleEndMs(sch, clazz.lengthOfLesson || 0));

            return (
              (value.minTimeSlot <= startHour && startHour <= value.maxTimeSlot) ||
              (value.minTimeSlot <= endHour && endHour <= value.maxTimeSlot)
            );
          });

          if (isTimeSlotSatisfied) return true;
        }
      }

      return false;
    });
  }

  return filteredCourses;
};
