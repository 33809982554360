/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentConfirmation } from './PaymentConfirmation';
import {
    PaymentConfirmationFromJSON,
    PaymentConfirmationFromJSONTyped,
    PaymentConfirmationToJSON,
} from './PaymentConfirmation';

/**
 * a list of payment confirmations
 * @export
 * @interface PaymentConfirmationList
 */
export interface PaymentConfirmationList {
    /**
     * 
     * @type {Array<PaymentConfirmation>}
     * @memberof PaymentConfirmationList
     */
    items: Array<PaymentConfirmation>;
}

/**
 * Check if a given object implements the PaymentConfirmationList interface.
 */
export function instanceOfPaymentConfirmationList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function PaymentConfirmationListFromJSON(json: any): PaymentConfirmationList {
    return PaymentConfirmationListFromJSONTyped(json, false);
}

export function PaymentConfirmationListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentConfirmationList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(PaymentConfirmationFromJSON)),
    };
}

export function PaymentConfirmationListToJSON(value?: PaymentConfirmationList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(PaymentConfirmationToJSON)),
    };
}

