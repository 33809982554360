import React from 'react';
import { ClassApi, Clazz } from '../api';

export function useCoursesByTeacherId(teacherId?: string) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [coursesByTeacher, setCoursesByTeacher] = React.useState<Clazz[]>([]);

  React.useEffect(() => {
    if (teacherId) {
      new ClassApi()
        .getClassesByTeacher({ teacherId })
        .then((res) => {
          setCoursesByTeacher(res.items);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [teacherId]);

  if (!teacherId) {
    setLoading(true);
  }

  return {
    coursesByTeacher,
    loading,
  };
}
