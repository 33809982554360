import React from 'react';
import Loading from 'common/components/Loading';
import { Box } from '@mui/material';

export default function LoadingPage() {
  return (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      <Loading />
    </Box>
  );
}
