const colors = {
  primary: '#932191',
  primary20p: 'rgba(147, 33, 145, 0.2)',
  primary50p: '#AC58D6',
  secondary: '#696984',
  text: '#696984',
  error: '#E95050',
  black: '#000',
  white: '#fff',
  black10p: 'rgba(0, 0, 0, 0.1)',
  black20p: 'rgba(0, 0, 0, 0.2)',
  black30p: 'rgba(0, 0, 0, 0.3)',
  transparent: 'transparent',
};

export default colors;
