import React from 'react';

// material components
import { Container, Grid, Typography, Box } from '@mui/material';

// components
import Filter from '../features/course/components/CoursesFilter/CoursesFilter';
import CourseSlide from 'student/features/course/components/CourseSlide';
import { SectionTitle } from 'common/components/Text';

import { dispatch, useSelector } from 'student/store';

import {
  changeAllCourses,
  selectAllCourses,
  selectFeaturedCourses,
  filterCourses,
  CoursesFilter,
} from 'student/features/course';
import CourseList from 'student/features/course/components/CourseList';
import { useParamsCategory } from 'student/hooks';

export default function PageCourses() {
  const allCourses = useSelector(selectAllCourses);
  const filteredCourses = {
    ...allCourses,
    items: filterCourses(allCourses.items, allCourses.selectedFilter),
  };
  const handleChangeSelectedFilter = (selectedFilter: CoursesFilter) => {
    dispatch(changeAllCourses({ selectedFilter }));
    window.scrollTo(0, 0);
  };

  const featuredCourses = useSelector(selectFeaturedCourses);

  const [loadingSlider, setLoadingSlider] = React.useState<boolean>(false);
  const paramCategory = useParamsCategory();
  React.useEffect(() => {
    dispatch(
      changeAllCourses({
        selectedFilter: {
          ...allCourses.selectedFilter,
          categoryId: paramCategory?.categoryId,
        },
      })
    );
  }, [paramCategory]);

  React.useEffect(() => {
    if (!featuredCourses.loading) {
      setLoadingSlider(true);
    }
  }, [featuredCourses]);

  return (
    <Container fixed>
      <Box py={4}>
        <Typography variant="h4" color="text.primary" mb={5}>
          {paramCategory ? (
            <>
              Khoá học{' '}
              <Typography variant="h4" component="span" color="primary.main">
                {paramCategory.categoryName}
              </Typography>
            </>
          ) : (
            <>Tất cả khoá học</>
          )}
        </Typography>
        <Grid container spacing={5}>
          <Grid item lg={3} xs={12}>
            <Filter
              filter={allCourses.filter}
              selectedFilter={allCourses.selectedFilter}
              handleChangeSelectedFilter={handleChangeSelectedFilter}
            />
          </Grid>
          <Grid item lg={9} xs={12}>
            <CourseList courses={filteredCourses} />
          </Grid>
        </Grid>

        <br />
        <br />
        <br />
        {loadingSlider && (
          <CourseSlide
            loading={featuredCourses.loading}
            clazzes={featuredCourses.items}
            title={''}
            titleComponent={<SectionTitle textAlign={'center'}>{'Có thể bạn quan tâm'}</SectionTitle>}
            titleSub={''}
          />
        )}
      </Box>
    </Container>
  );
}
