import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

import './style.scss';
import A from 'common/assets';
import { SectionTitle, SectionTitleSub, Text } from 'common/components/Text';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { useTheme } from '@mui/material/styles';
import { Section } from 'student/layout';

const Card = styled(Box)({
  marginTop: 70,
  height: 330,
  background: '#FFFFFF',
  boxShadow: '0px 10px 30px rgba(38, 45, 118, 0.08)',
  borderRadius: 20,
  textAlign: 'center',
  padding: 20,
  position: 'relative',
});

const CardTopIcon = styled(Box)({
  width: '80px',
  height: '80px',
  background: '#AC58D6',
  boxShadow: '0px 10px 40px rgba(54, 61, 136, 0.06)',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const CardTitle = styled(Text)({
  color: A.colors.primary50p,
  fontSize: '25px !important',
  height: 80,
  width: '100%',
});

const CardBody = (props: any) => {
  return (
    <Grid
      container
      direction="column"
      style={{ height: 300, gap: 20 }}
      justifyContent="center"
      alignItems="center"
      {...props}
    />
  );
};

const WhatIsDifference = () => {
  const theme = useTheme();

  return (
    <Section>
      <SectionTitle>Nền tảng giáo dục dành cho mọi người</SectionTitle>
      <SectionTitleSub>
        Nơi phụ huynh có thể tin tưởng và để con được thoả sức học những gì mình yêu thích, dưới sự hướng dẫn của những
        giáo viên tâm huyết và có nhiều kinh nghiệm trong lĩnh vực
      </SectionTitleSub>
      <Grid container spacing={3} sx={{ mt: 4, mb: 5 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardTopIcon>
              <img src={A.images.icon_light} alt="icon" className="img-fluid" style={{ width: 30 }} />
            </CardTopIcon>
            <CardBody>
              <CardTitle>
                Thuận tiện và <br />
                linh hoạt
              </CardTitle>
              <Text variant="body1">
                Lựa chọn đa dạng <br />
                Học mọi lúc mọi nơi <br />
                Hoàn tiền khi không hài lòng <br />
                Chăm sóc khách hàng 24/7
              </Text>
            </CardBody>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardTopIcon>
              {/* <img src={A.images.icon_light} alt="icon" className="img-fluid" style={{ width: 30 }} /> */}
              <AccessibilityNewIcon sx={{ fontSize: 45, color: theme.palette.common.white }} />
            </CardTopIcon>
            <CardBody>
              <CardTitle>
                Trải nghiệm học tập <br />
                tuyệt vời
              </CardTitle>
              <Text>
                Khoá học vui vẻ, gắn kết <br />
                Khơi dậy niềm đam mê học tập <br />
                Chú trọng vào vận dụng và thực hành thay vì ghi nhớ kiến thức <br />
              </Text>
            </CardBody>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardTopIcon>
              <img src={A.images.icon_tracking} alt="icon" className="img-fluid" style={{ width: 30 }} />
            </CardTopIcon>
            <CardBody>
              <CardTitle>
                Giáo viên nhiệt huyết và <br />
                nhiều kinh nghiệm
              </CardTitle>
              <Text sx={{ pb: 3 }}>
                Giáo viên giàu nhiệt huyết <br />
                và có nhiều kinh nghiệm trong <br />
                lĩnh vực giảng dạy
                <br />
              </Text>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Section>
  );
};

export default WhatIsDifference;
