import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';

import Filter from 'student/features/course/components/CoursesFilter/CoursesFilter';
import CourseList from 'student/features/course/components/CourseList';
import Loading from 'common/components/Loading';
import { dispatch, useSelector } from 'student/store';
import { filterCourses, changeMyEnrolledCourses, selectMyEnrolledCourses, CoursesFilter } from 'student/features';
import { ClazzComponent } from 'student/types/ClazzComponentEnum';

export default function PageMyEnrolledCourses() {
  const myEnrolledCourses = useSelector(selectMyEnrolledCourses);
  const { items, filter, selectedFilter, loading, fetched } = myEnrolledCourses;

  const handleChangeSelectedFilter = (changedFilter: CoursesFilter) => {
    dispatch(changeMyEnrolledCourses({ selectedFilter: changedFilter }));
  };

  React.useEffect(() => {
    const filteredClazzes = filterCourses(items, selectedFilter);
    dispatch(changeMyEnrolledCourses({ items: filteredClazzes, loading: false }));
  }, [selectedFilter]);

  if (!fetched) {
    return <Loading />;
  }

  return (
    <Container fixed>
      <Box py={4}>
        <Typography variant="h4" color="text.primary" mb={5}>
          Khóa học của tôi
        </Typography>
        <Grid container spacing={5}>
          <Grid item lg={3} xs={12} sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Filter
              filter={filter}
              selectedFilter={selectedFilter}
              handleChangeSelectedFilter={handleChangeSelectedFilter}
            />
          </Grid>
          <Grid item lg={9} xs={12}>
            <CourseList courses={myEnrolledCourses} clazzComponent={ClazzComponent.MyCourses} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
