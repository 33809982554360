import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';

import { useResponsive } from 'student/hooks';
import A from 'common/assets';

interface CustomTypographyProps extends Omit<TypographyProps, 'size'> {
  colorHex?: string | undefined;
}

// Normal
export function Text(props: CustomTypographyProps) {
  const T: any = styled(Typography)({
    color: props?.colorHex || A.colors.text,
  });
  return <T {...props} />;
}
export default Text;

export function BannerTitle(props: CustomTypographyProps) {
  const { isMobile } = useResponsive();

  const T: any = styled(Typography)({
    color: props?.colorHex || A.colors.primary,
    fontSize: isMobile ? 30 : 40,
    fontFamily: A.fonts.bold,
    fontWeight: '900',
    paddingTop: isMobile ? 10 : 50,
    display: 'flex',
    textAlign: isMobile ? 'center' : 'left',
    justifyContent: isMobile ? 'center' : 'left',
    alignItems: isMobile ? 'center' : 'left',
  });
  return <T {...{ variant: 'h1', ...props }} />;
}

export function BannerSubtitle(props: CustomTypographyProps) {
  const { isMobile } = useResponsive();

  const T: any = styled(Typography)({
    color: props?.colorHex || A.colors.text,
    fontSize: 24,
    fontWeight: '400',
    textAlign: isMobile ? 'center' : 'left',
    display: 'flex',
    justifyContent: isMobile ? 'center' : 'left',
    alignItems: isMobile ? 'center' : 'left',
  });
  return <T {...props} />;
}

export function SectionTitle(props: CustomTypographyProps) {
  const { isMobile } = useResponsive();

  const T: any = styled(Typography)({
    color: props?.colorHex || A.colors.primary,
    fontSize: isMobile ? 24 : 38,
    fontFamily: A.fonts.semiBold,
    fontWeight: '800',
    paddingBottom: 10,
    lineHeight: 1.5,
  });
  return <T {...{ variant: 'h2', ...props }} />;
}

export function SectionTitleSub(props: CustomTypographyProps) {
  const { isMobile } = useResponsive();

  const T: any = styled(Typography)({
    color: props?.colorHex || A.colors.text,
    // fontSize: 24,
    fontWeight: '400',
    display: isMobile ? 'contents' : 'block',
  });
  return <T {...props} variant="subtitle1" />;
}

export function ReviewContent(props: CustomTypographyProps) {
  const T: any = styled(Typography)({
    color: props?.colorHex || A.colors.text,
    fontSize: 22,
    fontWeight: '400',
  });
  return <T {...props} />;
}

export function ReviewAuthor(props: CustomTypographyProps) {
  const T: any = styled(Typography)({
    color: props?.colorHex || A.colors.text,
    fontSize: 22,
    fontWeight: '800',
  });
  return <T {...props} />;
}
