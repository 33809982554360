import images from './images';
import colors from './colors';
import fonts from './fonts';

const assets = {
  images,
  colors,
  fonts,
  host: 'https://amusingo.com',
};

export default assets;
