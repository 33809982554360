/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Statistics of a class
 * @export
 * @interface ClazzStatistic
 */
export interface ClazzStatistic {
    /**
     * 
     * @type {string}
     * @memberof ClazzStatistic
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzStatistic
     */
    teacherId: string;
    /**
     * 
     * @type {string}
     * @memberof ClazzStatistic
     */
    classId: string;
    /**
     * 
     * @type {number}
     * @memberof ClazzStatistic
     */
    totalStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof ClazzStatistic
     */
    confirmedPayments?: number;
    /**
     * 
     * @type {number}
     * @memberof ClazzStatistic
     */
    totalRevenue?: number;
}

/**
 * Check if a given object implements the ClazzStatistic interface.
 */
export function instanceOfClazzStatistic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "teacherId" in value;
    isInstance = isInstance && "classId" in value;

    return isInstance;
}

export function ClazzStatisticFromJSON(json: any): ClazzStatistic {
    return ClazzStatisticFromJSONTyped(json, false);
}

export function ClazzStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClazzStatistic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'teacherId': json['teacherId'],
        'classId': json['classId'],
        'totalStudents': !exists(json, 'totalStudents') ? undefined : json['totalStudents'],
        'confirmedPayments': !exists(json, 'confirmedPayments') ? undefined : json['confirmedPayments'],
        'totalRevenue': !exists(json, 'totalRevenue') ? undefined : json['totalRevenue'],
    };
}

export function ClazzStatisticToJSON(value?: ClazzStatistic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orgId': value.orgId,
        'teacherId': value.teacherId,
        'classId': value.classId,
        'totalStudents': value.totalStudents,
        'confirmedPayments': value.confirmedPayments,
        'totalRevenue': value.totalRevenue,
    };
}

