/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for Category Post Request
 * @export
 * @interface CategoryPostRequestParams
 */
export interface CategoryPostRequestParams {
    /**
     * 
     * @type {string}
     * @memberof CategoryPostRequestParams
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPostRequestParams
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryPostRequestParams
     */
    thumbnailUrl?: string;
}

/**
 * Check if a given object implements the CategoryPostRequestParams interface.
 */
export function instanceOfCategoryPostRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CategoryPostRequestParamsFromJSON(json: any): CategoryPostRequestParams {
    return CategoryPostRequestParamsFromJSONTyped(json, false);
}

export function CategoryPostRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryPostRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
    };
}

export function CategoryPostRequestParamsToJSON(value?: CategoryPostRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryName': value.categoryName,
        'description': value.description,
        'thumbnailUrl': value.thumbnailUrl,
    };
}

