import React from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

import TeacherProfileSectionBasicInfo from 'teacher/features/profile/components/TeacherProfileSectionBasicInfo';

const RootStyled = styled('div')(({ theme }) => ({
  margin: theme.spacing(4, 0),
}));

export default function PageCourse() {
  return (
    <RootStyled>
      <Container fixed>
        <TeacherProfileSectionBasicInfo />
      </Container>
    </RootStyled>
  );
}
