/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * teacher information schema
 * @export
 * @interface DummyTeacher
 */
export interface DummyTeacher {
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    teacherId?: string;
    /**
     * 
     * @type {number}
     * @memberof DummyTeacher
     */
    rate?: number;
    /**
     * 
     * @type {number}
     * @memberof DummyTeacher
     */
    numReviewReceived?: number;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    phone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DummyTeacher
     */
    teachingFields?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    introductionVideo?: string;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DummyTeacher
     */
    certificates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DummyTeacher
     */
    teachingExperience?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DummyTeacher
     */
    profileUrl?: string;
}

/**
 * Check if a given object implements the DummyTeacher interface.
 */
export function instanceOfDummyTeacher(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DummyTeacherFromJSON(json: any): DummyTeacher {
    return DummyTeacherFromJSONTyped(json, false);
}

export function DummyTeacherFromJSONTyped(json: any, ignoreDiscriminator: boolean): DummyTeacher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teacherId': !exists(json, 'teacherId') ? undefined : json['teacherId'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'numReviewReceived': !exists(json, 'numReviewReceived') ? undefined : json['numReviewReceived'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'teachingFields': !exists(json, 'teachingFields') ? undefined : json['teachingFields'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'introductionVideo': !exists(json, 'introductionVideo') ? undefined : json['introductionVideo'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'certificates': !exists(json, 'certificates') ? undefined : json['certificates'],
        'teachingExperience': !exists(json, 'teachingExperience') ? undefined : json['teachingExperience'],
        'profileUrl': !exists(json, 'profileUrl') ? undefined : json['profileUrl'],
    };
}

export function DummyTeacherToJSON(value?: DummyTeacher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teacherId': value.teacherId,
        'rate': value.rate,
        'numReviewReceived': value.numReviewReceived,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'teachingFields': value.teachingFields,
        'avatar': value.avatar,
        'address': value.address,
        'introductionVideo': value.introductionVideo,
        'description': value.description,
        'certificates': value.certificates,
        'teachingExperience': value.teachingExperience,
        'profileUrl': value.profileUrl,
    };
}

