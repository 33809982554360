import React from 'react';
import { ClazzApi, Clazz as Course } from 'teacher/api';

export function useCoursesByTeacherId(teacherId?: string) {
  const [coursesByTeacher, setcoursesByTeacher] = React.useState<Course[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (teacherId) {
      new ClazzApi()
        .getClazzesByTeacher({ teacherId })
        .then((res) => {
          const courseItems = res.items;
          setcoursesByTeacher(courseItems);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          setcoursesByTeacher([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
    }
  }, [teacherId]);

  return { coursesByTeacher, loading };
}
