import * as React from 'react';
import { Outlet as Child } from 'react-router-dom';

import { ClassApi, FavoriteApi, Favorite, Clazz, EnrollmentApi, Clazz as Course } from 'student/api';
import { useAuth } from 'student/hooks';
import { dispatch, useSelector, RootState } from 'student/store';
import {
  changeAllCourses,
  changeFeaturedCourses,
  changeMyEnrolledCourses,
  changeMySavedCourses,
  selectCoursesById,
  selectCourseById,
  changeAllEnrollments,
  selectMyEnrolledCourses,
} from 'student/features';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import ContactChatBubble from 'common/components/ContactChatBubble';
export function MainLayout() {
  const { user, isAuthenticatedWithProfile } = useAuth();
  const { allCourses } = useSelector((state: RootState) => state.courseState);
  const coursesById = useSelector(selectCoursesById);
  const myEnrolledCourses = useSelector(selectMyEnrolledCourses);

  React.useEffect(() => {
    // Fetch public data for all pages
    // TODO: This should be move to each corresponding hook
    new ClassApi()
      .getAllClasses()
      .then((clazzes) => dispatch(changeAllCourses({ items: clazzes.items, loading: false })))
      .catch((err) => console.error(err))
      .finally(() => dispatch(changeAllCourses({ loading: false, fetched: true })));

    new ClassApi()
      .getAllClasses() // Waiting api for get featured classes
      .then((clazzes) =>
        dispatch(
          changeFeaturedCourses({
            items: clazzes.items,
            loading: false,
          })
        )
      )
      .catch((err) => console.error(err))
      .finally(() => dispatch(changeFeaturedCourses({ loading: false, fetched: true })));
  }, []);

  React.useEffect(() => {
    if (isAuthenticatedWithProfile && !!user && allCourses.fetched && !myEnrolledCourses.fetched) {
      dispatch(changeMySavedCourses({ loading: true }));
      new FavoriteApi()
        .getFavoriteClasses({ studentId: user.uid })
        .then((res) => {
          const favorites: Favorite[] = res.items;
          const clazzes: Clazz[] = [];

          favorites.map((favorite) => {
            const savedClazz = allCourses.items.find((clazz) => clazz.classId == favorite.classId);
            if (savedClazz) {
              clazzes.push(savedClazz);
            }
          });

          dispatch(changeMySavedCourses({ items: clazzes, loading: false }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          dispatch(changeMySavedCourses({ loading: false, fetched: true }));
        });

      new EnrollmentApi()
        .getEnrollmentsByStudentId({ studentId: user.uid })
        .then((enrollments) => {
          dispatch(changeAllEnrollments({ items: enrollments.items }));

          const enrolledCourses: Clazz[] = [];
          enrollments.items.map((enrollment) => {
            const enrolledCourse = allCourses.items.find((clazz) => clazz.classId == enrollment.classId);
            if (enrolledCourse) {
              enrolledCourses.push(enrolledCourse);
            }
          });
          dispatch(changeMyEnrolledCourses({ items: enrolledCourses }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          dispatch(changeAllEnrollments({ loading: false, fetched: true }));
          dispatch(changeMyEnrolledCourses({ loading: false, fetched: true }));
        });
    }
  }, [isAuthenticatedWithProfile, user, allCourses.fetched]);

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        maxWidth: '100vw',
      }}
    >
      <Header />
      <div style={{ width: '100vw' }}>
        <Child />
      </div>
      <ContactChatBubble />
      <Footer />
    </div>
  );
}
