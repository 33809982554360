/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ZoomUrl,
} from '../models';
import {
    ZoomUrlFromJSON,
    ZoomUrlToJSON,
} from '../models';

export interface GetZoomUrlRequest {
    studentId: string;
    classId: string;
}

/**
 * 
 */
export class ZoomForStudentsApi extends runtime.BaseAPI {

    /**
     * Get zoom meeting join url
     */
    async getZoomUrlRaw(requestParameters: GetZoomUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ZoomUrl>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getZoomUrl.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getZoomUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/zoom/students/{studentId}/classes/{classId}/meeting`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ZoomUrlFromJSON(jsonValue));
    }

    /**
     * Get zoom meeting join url
     */
    async getZoomUrl(requestParameters: GetZoomUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ZoomUrl> {
        const response = await this.getZoomUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
