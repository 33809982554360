/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TeacherProfile } from './TeacherProfile';
import {
    TeacherProfileFromJSON,
    TeacherProfileFromJSONTyped,
    TeacherProfileToJSON,
} from './TeacherProfile';

/**
 * A list of teachers under an organization
 * @export
 * @interface TeacherList
 */
export interface TeacherList {
    /**
     * 
     * @type {Array<TeacherProfile>}
     * @memberof TeacherList
     */
    items: Array<TeacherProfile>;
}

/**
 * Check if a given object implements the TeacherList interface.
 */
export function instanceOfTeacherList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function TeacherListFromJSON(json: any): TeacherList {
    return TeacherListFromJSONTyped(json, false);
}

export function TeacherListFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeacherList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(TeacherProfileFromJSON)),
    };
}

export function TeacherListToJSON(value?: TeacherList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(TeacherProfileToJSON)),
    };
}

