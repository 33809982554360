import { ClazzClazzTypeEnum, Clazz as Course } from 'teacher/api/models';
import { CoursesFilter, fullCoursesFilter, initCoursesFilter } from './CoursesFilter';
import A from 'common/assets';

export interface Courses {
  items: Course[];
  filter: CoursesFilter;
  selectedFilter: CoursesFilter;
  loading: boolean;
  fetched: boolean;
}

// TO-DO: fetch teacher courses from server
// export const initCourses: Courses = {
//   items: [],
//   filter: fullCoursesFilter,
//   selectedFilter: initCoursesFilter,
//   loading: true,
//   fetched: false,
// };

// Mock Data:
export const initCourses: Courses = {
  items: [
    {
      clazzId: '1',
      averageRating: 5,
      numReviews: 69,
      clazzName: 'Tên khóa học',
      description: 'this is the description',
      categoryId: 'BIZ',
      thumbnailImage: null,
      numLessons: 3,
      maxStudents: 10,
      minStudents: 1,
      clazzType: ClazzClazzTypeEnum.OneLesson,
      minAge: 10,
      maxAge: 13,
      lessonTime: 10,
      price: 100000,
      syllabus: 'syllabus',
      schedules: [],
      numEnrolledStudents: 10,
      teacherId: 'TC00001',
      thumbnailImageUrl: A.images.minions,
    },
    {
      clazzId: '2',
      averageRating: 5,
      numReviews: 69,
      clazzName: 'Tên khóa học',
      description: 'this is  de',
      categoryId: 'BIZ',
      thumbnailImage: null,
      numLessons: 3,
      maxStudents: 10,
      minStudents: 1,
      clazzType: ClazzClazzTypeEnum.OneLesson,
      minAge: 10,
      maxAge: 13,
      lessonTime: 10,
      price: 100000,
      syllabus: 'syllabus',
      schedules: [],
      numEnrolledStudents: 10,
      teacherId: 'TC00001',
      thumbnailImageUrl: A.images.minions,
    },
    {
      clazzId: '3',
      averageRating: 5,
      numReviews: 69,
      clazzName: 'Tên khóa học',
      description: 'this is  de',
      categoryId: 'BIZ',
      thumbnailImage: null,
      numLessons: 3,
      maxStudents: 10,
      minStudents: 1,
      clazzType: ClazzClazzTypeEnum.OneLesson,
      minAge: 10,
      maxAge: 13,
      lessonTime: 10,
      price: 100000,
      syllabus: 'syllabus',
      schedules: [],
      numEnrolledStudents: 10,
      teacherId: 'TC00001',
      thumbnailImageUrl: A.images.minions,
    },
  ],

  filter: fullCoursesFilter,
  selectedFilter: initCoursesFilter,
  loading: false,
  fetched: true,
};
