/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeRange } from './AgeRange';
import {
    AgeRangeFromJSON,
    AgeRangeFromJSONTyped,
    AgeRangeToJSON,
} from './AgeRange';
import type { ClassSchedule } from './ClassSchedule';
import {
    ClassScheduleFromJSON,
    ClassScheduleFromJSONTyped,
    ClassScheduleToJSON,
} from './ClassSchedule';
import type { NumAllowedLearners } from './NumAllowedLearners';
import {
    NumAllowedLearnersFromJSON,
    NumAllowedLearnersFromJSONTyped,
    NumAllowedLearnersToJSON,
} from './NumAllowedLearners';

/**
 * Class information
 * @export
 * @interface Clazz
 */
export interface Clazz {
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    teacherId?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    teacherName?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    teacherAvatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    className?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    categoryId?: string;
    /**
     * 
     * @type {any}
     * @memberof Clazz
     */
    thumbnailImageUrl?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    introductionVideoUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    numLessons?: number;
    /**
     * 
     * @type {NumAllowedLearners}
     * @memberof Clazz
     */
    numAllowedLearners?: NumAllowedLearners;
    /**
     * 
     * @type {Array<ClassSchedule>}
     * @memberof Clazz
     */
    schedules?: Array<ClassSchedule>;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    classType?: ClazzClassTypeEnum;
    /**
     * 
     * @type {AgeRange}
     * @memberof Clazz
     */
    ageRange?: AgeRange;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    numEnrolledStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    numLearnedStudents?: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    averageRating?: number;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    syllabus?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Clazz
     */
    sampleFinalResults?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    numOfLessons?: number;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    lengthOfLesson?: number;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    benefits?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    references?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    requirements?: string;
    /**
     * 
     * @type {string}
     * @memberof Clazz
     */
    proposedSchedule?: string;
    /**
     * 
     * @type {number}
     * @memberof Clazz
     */
    frequencyPerWeek?: number;
}


/**
 * @export
 */
export const ClazzClassTypeEnum = {
    MultipleLessons: 'MULTIPLE_LESSONS',
    OneLesson: 'ONE_LESSON'
} as const;
export type ClazzClassTypeEnum = typeof ClazzClassTypeEnum[keyof typeof ClazzClassTypeEnum];


/**
 * Check if a given object implements the Clazz interface.
 */
export function instanceOfClazz(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClazzFromJSON(json: any): Clazz {
    return ClazzFromJSONTyped(json, false);
}

export function ClazzFromJSONTyped(json: any, ignoreDiscriminator: boolean): Clazz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'teacherId': !exists(json, 'teacherId') ? undefined : json['teacherId'],
        'classId': !exists(json, 'classId') ? undefined : json['classId'],
        'teacherName': !exists(json, 'teacherName') ? undefined : json['teacherName'],
        'teacherAvatarUrl': !exists(json, 'teacherAvatarUrl') ? undefined : json['teacherAvatarUrl'],
        'className': !exists(json, 'className') ? undefined : json['className'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'thumbnailImageUrl': !exists(json, 'thumbnailImageUrl') ? undefined : json['thumbnailImageUrl'],
        'introductionVideoUrl': !exists(json, 'introductionVideoUrl') ? undefined : json['introductionVideoUrl'],
        'numLessons': !exists(json, 'numLessons') ? undefined : json['numLessons'],
        'numAllowedLearners': !exists(json, 'numAllowedLearners') ? undefined : NumAllowedLearnersFromJSON(json['numAllowedLearners']),
        'schedules': !exists(json, 'schedules') ? undefined : ((json['schedules'] as Array<any>).map(ClassScheduleFromJSON)),
        'classType': !exists(json, 'classType') ? undefined : json['classType'],
        'ageRange': !exists(json, 'ageRange') ? undefined : AgeRangeFromJSON(json['ageRange']),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'numEnrolledStudents': !exists(json, 'numEnrolledStudents') ? undefined : json['numEnrolledStudents'],
        'numLearnedStudents': !exists(json, 'numLearnedStudents') ? undefined : json['numLearnedStudents'],
        'averageRating': !exists(json, 'averageRating') ? undefined : json['averageRating'],
        'syllabus': !exists(json, 'syllabus') ? undefined : json['syllabus'],
        'sampleFinalResults': !exists(json, 'sampleFinalResults') ? undefined : json['sampleFinalResults'],
        'numOfLessons': !exists(json, 'num_of_lessons') ? undefined : json['num_of_lessons'],
        'lengthOfLesson': !exists(json, 'length_of_lesson') ? undefined : json['length_of_lesson'],
        'benefits': !exists(json, 'benefits') ? undefined : json['benefits'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'requirements': !exists(json, 'requirements') ? undefined : json['requirements'],
        'proposedSchedule': !exists(json, 'proposedSchedule') ? undefined : json['proposedSchedule'],
        'frequencyPerWeek': !exists(json, 'frequencyPerWeek') ? undefined : json['frequencyPerWeek'],
    };
}

export function ClazzToJSON(value?: Clazz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'teacherId': value.teacherId,
        'classId': value.classId,
        'teacherName': value.teacherName,
        'teacherAvatarUrl': value.teacherAvatarUrl,
        'className': value.className,
        'description': value.description,
        'categoryId': value.categoryId,
        'thumbnailImageUrl': value.thumbnailImageUrl,
        'introductionVideoUrl': value.introductionVideoUrl,
        'numLessons': value.numLessons,
        'numAllowedLearners': NumAllowedLearnersToJSON(value.numAllowedLearners),
        'schedules': value.schedules === undefined ? undefined : ((value.schedules as Array<any>).map(ClassScheduleToJSON)),
        'classType': value.classType,
        'ageRange': AgeRangeToJSON(value.ageRange),
        'price': value.price,
        'numEnrolledStudents': value.numEnrolledStudents,
        'numLearnedStudents': value.numLearnedStudents,
        'averageRating': value.averageRating,
        'syllabus': value.syllabus,
        'sampleFinalResults': value.sampleFinalResults,
        'num_of_lessons': value.numOfLessons,
        'length_of_lesson': value.lengthOfLesson,
        'benefits': value.benefits,
        'references': value.references,
        'requirements': value.requirements,
        'proposedSchedule': value.proposedSchedule,
        'frequencyPerWeek': value.frequencyPerWeek,
    };
}

