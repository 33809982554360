import React from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Box, Button, Card, CardContent, Chip, FormHelperText, Typography } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { AchievementApi, AchievementPostRequestParams, Clazz } from 'student/api';
import Loading from 'common/components/Loading';
import { useAuth } from 'student/hooks';
import AchievementSlide from './AchievementSlide';
import { RootState, useSelector } from 'student/store';
import { isCourseEnrolled } from 'student/features/course';
import { normalizeImageName, uploadImage } from 'common/utils';
import { toast } from 'react-toastify';

const RootStyled = styled('div')(({ theme }) => ({
  '& .card-main': {
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '30px',
    gap: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    pt: theme.spacing(1),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      height: 280,
      width: 280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

interface Props {
  clazz: Clazz;
}

const CourseDetailSectionStudentAchievement = ({ clazz }: Props) => {
  const { user } = useAuth();
  const [achievementUrls, setAchievementUrls] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const isEnrolled = useSelector((state: RootState) => isCourseEnrolled(state, `${clazz.classId}`));
  const [isUpload, setIsUpload] = React.useState<boolean>(false);

  const [files, setFiles] = React.useState<File[]>([]);

  const onSubmit = () => {
    if (!clazz.classId || !user?.uid || files.length === 0) return;
    const classId = clazz.classId;
    const studentId = user.uid;
    const achievementPostRequestParams: AchievementPostRequestParams = {
      classId,
      studentId,
      achievementImageNames: [],
    };
    if (files.length > 0) {
      achievementPostRequestParams.achievementImageNames = files.map((file) =>
        normalizeImageName('achievement', classId, file.name)
      );
    }

    const callApiUploadImage =
      files.length > 0
        ? new AchievementApi().getAchievementUploadUrls({
            classId,
            studentId,
            achievementPostRequestParams,
          })
        : null;

    const callApiCreateAchievement = new AchievementApi().createAchievement({
      studentId,
      classId,
      achievementPostRequestParams,
    });

    setLoading(true);
    Promise.all([callApiUploadImage, callApiCreateAchievement])
      .then(async (values) => {
        if (files.length > 0 && values[0]) {
          const { uploadUrls } = values[0];
          if (!uploadUrls) return;
          const uploadImagePromises = uploadUrls.map((url, index) => uploadImage(files[index], url || ''));
          const uploaded = await Promise.all(uploadImagePromises);
          if (!uploaded) {
            toast.error('Lỗi tải ảnh thành quả mới.');
          }
        }

        const newAchievementUrls = values[1].achievementUrls;
        if (!newAchievementUrls) {
          toast.error('Lỗi tạo thành quả mới! Vui lòng thử lại sau!');
        } else {
          toast.success('Đã thêm thành quả mới.');
          setIsUpload(true);
          setAchievementUrls([...achievementUrls, ...newAchievementUrls]);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Lỗi tạo thành quả mới! Vui lòng thử lại sau!');
      })
      .then(() => {
        setLoading(false);
        setFiles([]);
      });
  };

  const handleChangeFiles = (e: any) => {
    const newFiles: File = e.target.files[0];
    setFiles([...files, newFiles]);
  };

  const deleteAchievement = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  React.useEffect(() => {
    if (clazz.classId) {
      setAchievementUrls([]);
      setLoading(true);
      new AchievementApi()
        .getAchievements({
          classId: clazz.classId,
        })
        .then((value) => {
          const achievementUrls = value.items[0].achievementUrls;
          if (!achievementUrls) {
            setAchievementUrls([]);
          } else {
            setAchievementUrls(achievementUrls);
          }
        })
        .catch((_err) => {
          setAchievementUrls([]);
        })
        .finally(() => {
          setLoading(false);
        });

      if (user?.uid) {
        new AchievementApi()
          .getAchievementByStudent({ classId: clazz.classId, studentId: user.uid })
          .then((res) => {
            if (res.items.length > 0) {
              setIsUpload(true);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [clazz, user]);

  if (!clazz.classId || loading) {
    return <Loading />;
  }

  return (
    <>
      {!loading && achievementUrls.length > 0 ? (
        <RootStyled>
          <Card className="card-main">
            <CardContent sx={{ py: 5, px: 6, pb: 5, width: '100%' }}>
              <Typography variant="h5" mb={3}>
                Thành quả của học sinh
              </Typography>
              <AchievementSlide achievementUrls={achievementUrls} loading={loading} />
              {isEnrolled && !isUpload ? (
                <>
                  <Box>
                    <Button
                      variant="outlined"
                      size={'small'}
                      sx={{ width: 'auto', fontSize: 14, pt: 0.5, textTransform: 'none' }}
                      component="label"
                      onChange={handleChangeFiles}
                      endIcon={<AddAPhotoIcon sx={{ marginRight: 0, marginTop: '-4px', marginLeft: '-6px' }} />}
                    >
                      Thêm ảnh
                      <input type="file" hidden accept="image/png, image/jpeg" />
                    </Button>
                    {files.length > 0 && (
                      <Box mt={2}>
                        {files.map((file, index) => (
                          <Chip
                            key={file.name}
                            label={file.name}
                            variant="outlined"
                            onDelete={() => deleteAchievement(index)}
                            sx={{ mr: 1 }}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                    <Button type="submit" variant="contained" size="large" disabled={loading} onClick={onSubmit}>
                      Gửi thành quả
                    </Button>
                  </Box>
                </>
              ) : null}
            </CardContent>
          </Card>
        </RootStyled>
      ) : null}
    </>
  );
};

export default CourseDetailSectionStudentAchievement;
