import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, Box, CardMedia, CardActionArea, CardContent, Avatar } from '@mui/material';

import { Text } from 'common/components/Text';
import Link from 'common/components/Link/Link';
import { Clazz } from 'student/api';
import { useTheme } from '@mui/material/styles';
import { getCoursePrice } from 'common/utils';
import { routes } from 'student/Routes';
import Rating from 'common/components/Rating';

const Badge = styled(Box)({
  border: 0,
  outline: 0,
  boxShadow: '0px 10px 60px 0px #262D7614 inset',
  fontWeight: 400,
  textAlign: 'center',
  color: '#696984',
  padding: '10px 10px',
  borderRadius: '999px',
  fontSize: '15px',
  minWidth: '100px',
});

interface Props {
  clazz: Clazz;
  itemMinWidth: number;
}

const CourseSlideItem = ({ clazz, itemMinWidth }: Props) => {
  const theme = useTheme();

  const [raised, setRaised] = React.useState<boolean>(false);

  return (
    <Card
      sx={{ width: itemMinWidth }}
      raised={raised}
      onMouseOver={() => setRaised(true)}
      onMouseOut={() => setRaised(false)}
    >
      <Link to={`/courses/${clazz.classId}`}>
        <CardActionArea>
          <CardMedia component="img" height="140" image={clazz.thumbnailImageUrl} alt="class name" />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Text variant="body1" sx={{ minHeight: 50 }}>
              {clazz.className}
            </Text>

            <Box className="rating-start-cont">
              <Rating readOnly name="hover-feedback" value={clazz.averageRating} precision={0.1} size="small" />
              {/* {clazz?.reviewIds && clazz?.reviewIds?.length > 0 ? <span>({clazz.reviewIds.length})</span> : null} */}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
              <Link to={routes.PageTeacher.path.replace(':teacherId', `${clazz.teacherId}`)}>
                <Avatar src={clazz.teacherAvatarUrl} alt={`Avatar giáo viên ${clazz.teacherName}`} />
              </Link>
              <Link to={routes.PageTeacher.path.replace(':teacherId', `${clazz.teacherId}`)}>
                <Text variant="body2">{clazz.teacherName}</Text>
              </Link>
              {/*
              <Avatar
                alt={`Amusingo - Ảnh giáo viên ${clazz.teacherName}`}
                src={clazz.teacherAvatarUrl}
              />
              <Text variant="body2">{clazz.teacherName}</Text> */}
            </Box>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
                overflow: 'scroll',
              }}
            >
              <Badge>
                {clazz.ageRange?.minAge}-{clazz.ageRange?.maxAge} tuổi
              </Badge>
              <Badge>{getCoursePrice(clazz.price, clazz.numLessons)}</Badge>
            </Box>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
};

export default CourseSlideItem;
