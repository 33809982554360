import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from 'common/assets/colors';
import { Section } from 'teacher/layout';
import { Link } from '@mui/material';
import CourseUpdateForm from './CourseUpdateForm';
import { Clazz, ClazzApi } from 'teacher/api';
import { useParams } from 'react-router';
import { useAuth } from 'teacher/hooks';

export type SectionId = 'teacher' | 'parent';
export type QuestionId = number;

const clazzDetail: Clazz = {
  clazzId: 'IDcuaKhoaHoc',
  teacherId: '',
  clazzName: 'Hoc tieng Viet',
  description: 'Hoc tieng Viet bang sach tieng viet lop 1',
  categoryId: 'SKILL',
  thumbnailImage: 'https://zoipet.com/wp-content/uploads/2020/03/cho-shiba-inu.jpg',
  introductionVideo: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  numLessons: 1,
  maxStudents: 1,
  minStudents: 1,
  minAge: 6,
  maxAge: 6,
  clazzType: 'MULTIPLE_LESSONS',
  lessonTime: 30,
  price: 100000000,
  syllabus: '',
  frequencyPerWeek: 1,
  schedules: [],
};

export default function PageCourseCreate() {
  const { courseId } = useParams();
  const { teacherProfile } = useAuth();
  const [clazzData, setClazzData] = useState<Clazz>();
  React.useEffect(() => {
    if (courseId && teacherProfile?.teacherId) {
      new ClazzApi()
        .getClazz({ clazzId: courseId, teacherId: teacherProfile?.teacherId })
        .then((res) => {
          setClazzData(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [teacherProfile]);
  return (
    <Section>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" color={colors.primary} my={4}>
          Cập nhật thông tin khóa học "{clazzDetail.clazzName}"
        </Typography>

        <Typography sx={{ lineHeight: 1.8 }}>
          <br />
          <br />
          <b>Lưu ý: </b>các nội dung bạn nhập ở đây sẽ được giữ nguyên và hiển thị trên trang web của Amusingo, do đó
          hãy cân nhắc sử dụng từ ngữ và giọng điệu phù hợp, sao cho phụ huynh và học sinh cảm thấy quan tâm và muốn
          đăng ký khoá học của bạn. Hãy tham khảo nội dung dưới đây để có hình dung rõ hơn những nội dung cần có khi tạo
          một khoá học mới.
          <br />
          <br />
          1. Các câu hỏi thường gặp của giáo viên mới <br />
          <Link underline="none" href="https://amusingo.com/faq">
            https://amusingo.com/faq
          </Link>
          <br />
          <br />
          2. Ví dụ các khoá học có thể dạy trên Amusingo <br />
          <Link underline="none" href="https://amusingo.com/faq/teacher/5">
            https://amusingo.com/faq/teacher/5
          </Link>
          <br />
          <br />
          3. Các lớp học đã hoặc đang diễn ra trên Amusingo <br />
          <Link underline="none" href="https://amusingo.com/courses">
            https://amusingo.com/courses
          </Link>
        </Typography>
      </Box>
      <CourseUpdateForm clazzDetail={clazzData ? clazzData : clazzDetail} />
    </Section>
  );
}
