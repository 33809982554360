import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { toast } from 'react-toastify';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { useForm } from 'react-hook-form';

const auth = getAuth();

type SignInStatus = 'init' | 'waiting_otp' | 'done';

// Input phone number
// Check valid phone number
// Convert 0 => +81
// Onsubmit => Recaptcha
// Wait user input OTP
// Done
export default function AuthFormPhone() {
  const { handleSubmit } = useForm();

  const [status, setStatus] = React.useState<SignInStatus>('init');
  const [confirmOtp, setConfirmOtp] = React.useState<any>(null); //;
  const [phone, setPhone] = React.useState<string>('+84');
  const [otp, setOtp] = React.useState<number>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handlePhoneChange = (newValue: string) => {
    setPhone(newValue);
  };
  const handleOtpChange = (newValue: number) => {
    setOtp(newValue);
  };

  const handleSubmitPhone = () => {
    if (!matchIsValidTel(phone)) {
      toast.warn('Số điện thoại không đúng định dạng');
      return;
    }
    setLoading(true);
    const recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response: any) => {},
      },
      auth
    );

    signInWithPhoneNumber(auth, phone, recaptchaVerifier)
      .then((confirmationResult) => {
        setConfirmOtp(confirmationResult);
        setStatus('waiting_otp');
        toast.success('Mã đăng nhập đã được gửi qua số điện thoại.');
      })
      .catch((error) => {
        console.error(error);
        toast.warn(`Không thể gửi mã đăng nhập tới số ${phone}!`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitOtp = () => {
    setLoading(true);
    confirmOtp
      .confirm(otp)
      .then((result: any) => {
        toast.success('Xác nhận OTP thành công!');
        // Firebase will auto change the login state and be handled at hooks/useAuth
      })
      .catch((error: any) => {
        console.error(error);
        toast.error('Lỗi đăng nhập!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    if (status === 'init') {
      handleSubmitPhone();
    }
    if (status === 'waiting_otp') {
      handleSubmitOtp();
    }
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderWidth: 1,
        pt: 2,
        pb: 4,
        px: 5,
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {status === 'init' ? 'Đăng nhập ・ Đăng ký' : ''}
        {status === 'waiting_otp' ? 'Nhập mã OTP' : ''}
        {status === 'done' ? 'Đăng nhập thành công!' : ''}
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3, mb: 1 }}>
        <div id="recaptcha-container"></div>

        <MuiTelInput
          margin="normal"
          label="Số điện thoại"
          value={phone}
          onChange={handlePhoneChange}
          onlyCountries={['VN', 'JP', 'SG']}
          required
          fullWidth
          autoFocus
          disabled={status !== 'init'}
        />

        {status === 'init' ? (
          <Button
            type="submit"
            disabled={!phone || loading}
            fullWidth
            variant="contained"
            sx={{ pt: 1.5, pb: 1.5, my: 2 }}
            onClick={handleSubmitPhone}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Đăng nhập
          </Button>
        ) : null}

        {status === 'waiting_otp' ? (
          <>
            <TextField
              margin="normal"
              required
              fullWidth
              id="otp"
              label="Nhập mã xác nhận OTP"
              name="otp"
              autoFocus
              onChange={(e: any) => handleOtpChange(e.target.value)}
            />
            <Button
              type="submit"
              disabled={!otp || loading}
              fullWidth
              variant="contained"
              sx={{ pt: 1.5, pb: 1.5, my: 2 }}
              onClick={handleSubmitOtp}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              Xác nhận
            </Button>
          </>
        ) : null}
      </Box>
    </Paper>
  );
}
