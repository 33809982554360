/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Favorite,
  FavoriteList,
  FavoritePostRequestParams,
} from '../models';
import {
    FavoriteFromJSON,
    FavoriteToJSON,
    FavoriteListFromJSON,
    FavoriteListToJSON,
    FavoritePostRequestParamsFromJSON,
    FavoritePostRequestParamsToJSON,
} from '../models';

export interface CreateFavoriteClassRequest {
    studentId: string;
    favoritePostRequestParams: FavoritePostRequestParams;
}

export interface GetFavoriteClassRequest {
    studentId: string;
    teacherId: string;
    classId: string;
}

export interface GetFavoriteClassesRequest {
    studentId: string;
}

export interface RemoveFavoriteClassRequest {
    studentId: string;
    teacherId: string;
    classId: string;
}

/**
 * 
 */
export class FavoriteApi extends runtime.BaseAPI {

    /**
     * Add a favorite class
     */
    async createFavoriteClassRaw(requestParameters: CreateFavoriteClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Favorite>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling createFavoriteClass.');
        }

        if (requestParameters.favoritePostRequestParams === null || requestParameters.favoritePostRequestParams === undefined) {
            throw new runtime.RequiredError('favoritePostRequestParams','Required parameter requestParameters.favoritePostRequestParams was null or undefined when calling createFavoriteClass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/favorites`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoritePostRequestParamsToJSON(requestParameters.favoritePostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteFromJSON(jsonValue));
    }

    /**
     * Add a favorite class
     */
    async createFavoriteClass(requestParameters: CreateFavoriteClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Favorite> {
        const response = await this.createFavoriteClassRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a favorite class
     */
    async getFavoriteClassRaw(requestParameters: GetFavoriteClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Favorite>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getFavoriteClass.');
        }

        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getFavoriteClass.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling getFavoriteClass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/teachers/{teacherId}/classes/{classId}/favorite`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteFromJSON(jsonValue));
    }

    /**
     * Get a favorite class
     */
    async getFavoriteClass(requestParameters: GetFavoriteClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Favorite> {
        const response = await this.getFavoriteClassRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all favorite classes of a student
     */
    async getFavoriteClassesRaw(requestParameters: GetFavoriteClassesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FavoriteList>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling getFavoriteClasses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/favorites`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteListFromJSON(jsonValue));
    }

    /**
     * Get all favorite classes of a student
     */
    async getFavoriteClasses(requestParameters: GetFavoriteClassesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FavoriteList> {
        const response = await this.getFavoriteClassesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a class from favorite list
     */
    async removeFavoriteClassRaw(requestParameters: RemoveFavoriteClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Favorite>> {
        if (requestParameters.studentId === null || requestParameters.studentId === undefined) {
            throw new runtime.RequiredError('studentId','Required parameter requestParameters.studentId was null or undefined when calling removeFavoriteClass.');
        }

        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling removeFavoriteClass.');
        }

        if (requestParameters.classId === null || requestParameters.classId === undefined) {
            throw new runtime.RequiredError('classId','Required parameter requestParameters.classId was null or undefined when calling removeFavoriteClass.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/students/{studentId}/teachers/{teacherId}/classes/{classId}/favorite`.replace(`{${"studentId"}}`, encodeURIComponent(String(requestParameters.studentId))).replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"classId"}}`, encodeURIComponent(String(requestParameters.classId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteFromJSON(jsonValue));
    }

    /**
     * Remove a class from favorite list
     */
    async removeFavoriteClass(requestParameters: RemoveFavoriteClassRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Favorite> {
        const response = await this.removeFavoriteClassRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
