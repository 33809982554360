/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for Favorite Post request
 * @export
 * @interface FavoritePostRequestParams
 */
export interface FavoritePostRequestParams {
    /**
     * 
     * @type {string}
     * @memberof FavoritePostRequestParams
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoritePostRequestParams
     */
    classId?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoritePostRequestParams
     */
    teacherId?: string;
}

/**
 * Check if a given object implements the FavoritePostRequestParams interface.
 */
export function instanceOfFavoritePostRequestParams(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FavoritePostRequestParamsFromJSON(json: any): FavoritePostRequestParams {
    return FavoritePostRequestParamsFromJSONTyped(json, false);
}

export function FavoritePostRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoritePostRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'studentId': !exists(json, 'studentId') ? undefined : json['studentId'],
        'classId': !exists(json, 'classId') ? undefined : json['classId'],
        'teacherId': !exists(json, 'teacherId') ? undefined : json['teacherId'],
    };
}

export function FavoritePostRequestParamsToJSON(value?: FavoritePostRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'studentId': value.studentId,
        'classId': value.classId,
        'teacherId': value.teacherId,
    };
}

