import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useSelector as reduxUseSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authState } from './features/auth';
import { statisticState } from './features/statistic';
import { courseState } from './features/courses';

const reducer = combineReducers({
  authState,
  courseState,
  statisticState,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authState'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector = reduxUseSelector;
export const dispatch = store.dispatch;
export const persistor = persistStore(store);
export default store;
