import moment from 'moment';

export const getDurationMinutes = (startTime: number, endTime: number): number => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  const minutes = duration.asMinutes();

  return minutes;
};

export const formatPrice = (price: number): string => {
  const formattedPrice = Intl.NumberFormat('vi-VI').format(Math.max(price, 0));
  return formattedPrice;
};

export const getCoursePrice = (lessonPrice = 0, numLessons = 1): string => {
  const totalPrice = lessonPrice * numLessons;
  return totalPrice > 0 ? formatPrice(totalPrice) + 'đ' : 'Miễn phí';
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const nameToAvatarText = (name: string) => {
  return name
    .split(' ')
    .map((nameSplit) => nameSplit[0])
    .join('');
};
