import React from 'react';
import { Typography, Box } from '@mui/material';

import Divider from 'common/components/Divider';
import Loading from 'common/components/Loading';
import ItemNotFound from 'common/components/ItemNotFound/ItemNotFoundPage';
import { ClazzComponent } from 'student/types/ClazzComponentEnum';

import CourseListItem from './CourseListItem';
import { Courses } from '../types';
import CourseLoadingCard from 'common/components/Cards/ClassesCard/CourseLoadingCard';

interface Props {
  title?: string;
  courses: Courses;
  clazzComponent?: ClazzComponent;
}

export default function CourseList({ title, courses, clazzComponent }: Props) {
  const { items, loading } = courses;

  if (loading) {
    return (
      <>
        <CourseLoadingCard />
        <CourseLoadingCard />
      </>
    );
  }

  return (
    <Box sx={{ mb: 10 }}>
      {title ? (
        <Typography variant="h5" color="text.primary" mb={5}>
          {title}
        </Typography>
      ) : null}

      {items.length > 0 ? (
        <>
          {items.map((clazz, index) => (
            <React.Fragment key={clazz.classId}>
              <CourseListItem course={clazz} />
              {index !== items.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ))}
        </>
      ) : (
        <ItemNotFound clazzListComponent={clazzComponent} exploreMore={true}></ItemNotFound>
      )}
    </Box>
  );
}
