/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Review } from './Review';
import {
    ReviewFromJSON,
    ReviewFromJSONTyped,
    ReviewToJSON,
} from './Review';

/**
 * A list of reviews based on class_id
 * @export
 * @interface ReviewList
 */
export interface ReviewList {
    /**
     * 
     * @type {Array<Review>}
     * @memberof ReviewList
     */
    items: Array<Review>;
}

/**
 * Check if a given object implements the ReviewList interface.
 */
export function instanceOfReviewList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function ReviewListFromJSON(json: any): ReviewList {
    return ReviewListFromJSONTyped(json, false);
}

export function ReviewListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(ReviewFromJSON)),
    };
}

export function ReviewListToJSON(value?: ReviewList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(ReviewToJSON)),
    };
}

