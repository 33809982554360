import React, { useMemo } from 'react';

import { CategoryApi } from 'student/api';
import { changeCategoryItems, changeCategoryLoading } from './categorySlice';
import { useSelector } from 'react-redux';
import { selectCategoryState } from './categorySelector';
import { dispatch } from 'student/store';
import { PUBLIC_CATEGORY_IDS } from 'common/config';

export function useCategory() {
  const categoryState = useSelector(selectCategoryState);

  React.useEffect(() => {
    new CategoryApi()
      .getCategories()
      .then((res) => {
        const publicItems = res.items.filter((cate) => PUBLIC_CATEGORY_IDS.includes(`${cate?.categoryId}`));
        dispatch(changeCategoryItems(publicItems));
      })
      .catch((err) => console.error(err))
      .finally(() => {
        dispatch(changeCategoryLoading(false));
      });
  }, []);

  return categoryState;
}
