import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import A from 'common/assets';

export default function PaymentBankInfo() {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5" color="text.primary" mb={3}>
            Thông tin thanh toán
          </Typography>
          <Image src={A.images.paymentInfo} alt="Amusingo - Thông tin thanh toán" sx={{ maxWidth: 600 }} />
        </Box>
      </Grid>
      {/* <Grid xs={12} md={6}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h5" color="text.primary">
            Thanh toán qua STK
          </Typography>
          <img src={A.images.Bidv} alt="QR code" />
          <Typography variant="h6" color="text.primary" sx={{ fontFamily: A.fonts.regular }}>
            STK: <b>51010000434387</b>
          </Typography>
          <Typography variant="h6" color="text.primary" sx={{ fontFamily: A.fonts.regular }}>
            Chủ tài khoản: <b>Phan Nguyễn Quỳnh Trang</b>
          </Typography>
          <Typography variant="h6" color="text.primary" sx={{ fontFamily: A.fonts.regular }}>
            Chi nhánh: <b>BIDV Nghệ An</b>
          </Typography>
        </Box>
      </Grid> */}
    </Grid>
  );
}
