import React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonProps, Button as MuiButton } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'common/config';

export default function Button(props: ButtonProps) {
  const Button = styled(MuiButton)({});

  const customOnClick = (event: any) => {
    // call logger function
    logEvent(analytics, 'btn_clicked', { text: event.target.innerText });
    if (props.onClick) {
      props.onClick(event);
    }
  };
  // Todo: Log event

  return <Button {...props} onClick={customOnClick} />;
}
