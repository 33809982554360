import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import colors from 'common/assets/colors';
import React from 'react';
import { TeacherStatistic } from 'teacher/api';

export default function OverallStatistics(props: { teacherStatistic: TeacherStatistic }) {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h4" color={colors.primary} my={4}>
        Thống kê chung
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <Paper
          elevation={0}
          sx={{
            px: 3,
            py: 4,
            mr: 2,
            mb: 2,
            borderRadius: 5,
            borderColor: theme.palette.grey[800],
            borderWidth: 1,
            borderStyle: 'solid',
          }}
        >
          <Typography>
            <Typography variant="h4" display="inline">
              {props.teacherStatistic.totalStudents}
            </Typography>{' '}
            học sinh
          </Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            px: 3,
            py: 4,
            mr: 2,
            mb: 2,
            borderRadius: 5,
            borderColor: theme.palette.grey[800],
            borderWidth: 1,
            borderStyle: 'solid',
          }}
        >
          <Typography>
            <Typography variant="h4" display="inline">
              {props.teacherStatistic.totalClazzes}
            </Typography>{' '}
            lớp học
          </Typography>
        </Paper>
        <Paper
          elevation={0}
          sx={{
            px: 3,
            py: 4,
            mr: 2,
            mb: 2,
            borderRadius: 5,
            borderColor: theme.palette.grey[800],
            borderWidth: 1,
            borderStyle: 'solid',
          }}
        >
          <Typography>
            <Typography variant="h4" display="inline">
              {props.teacherStatistic.totalRevenue}
            </Typography>{' '}
            VND doanh thu
          </Typography>
        </Paper>
      </Box>
    </>
  );
}
