import React from 'react';

import A from 'common/assets';
import { Button, Typography } from '@mui/material';
import { ClazzComponent } from 'student/types/ClazzComponentEnum';
import { useResponsive } from 'student/hooks';
import Link from 'common/components/Link/Link';
import { STUDENT_SITE_PATH_NAME, TEACHER_SITE_PATH_NAME, getCurrentSite } from 'common/config';
import { SiteEnum } from 'common/types';

export interface ItemNotFoundProps {
  clazzListComponent?: ClazzComponent;
  exploreMore?: boolean;
}

function createNotFoundCustomMessage(clazzComponent: ClazzComponent) {
  switch (clazzComponent) {
    case ClazzComponent.Favorites: {
      return 'Bạn chưa thêm khoá học yêu thích';
    }
    case ClazzComponent.MyCourses: {
      return 'Bạn chưa đăng kí khoá học nào';
    }
    case ClazzComponent.GeneralClazzList: {
      return 'Không tìm thấy khoá học';
    }
    default: {
      break;
    }
  }
}

const ItemNotFound = (props: ItemNotFoundProps) => {
  const { isMobile } = useResponsive();
  const currentSite = getCurrentSite();

  const imgStyle = {
    maxWidth: isMobile ? '60vw' : '40vw',
    maxHeight: isMobile ? '60vh' : '40vh',
    marginBottom: isMobile ? '20px' : '40px',
  };
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '1vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={A.images.itemnotfound} alt="Item not found" style={imgStyle} />
      {props.clazzListComponent ? (
        <>
          <Typography variant="h5" color="text.primary" mb={2.5}>
            {createNotFoundCustomMessage(props.clazzListComponent)}
          </Typography>
        </>
      ) : null}
      {props.exploreMore ? (
        <Link
          to={
            currentSite === SiteEnum.Teacher ? `${TEACHER_SITE_PATH_NAME}/courses` : `${STUDENT_SITE_PATH_NAME}/courses`
          }
        >
          <Button
            variant="contained"
            sx={{ py: isMobile ? 1 : 2, px: isMobile ? 3 : 5, fontSize: 14 }}
            style={{
              background: '#7A4495',
              width: isMobile ? '60vw' : '40vw',
            }}
          >
            <Typography variant="h6">
              <b>Khám phá khoá học</b>
            </Typography>
          </Button>
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ItemNotFound;
