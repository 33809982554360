/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Request body for Class Achievement Post Request
 * @export
 * @interface AchievementPostRequestParams
 */
export interface AchievementPostRequestParams {
    /**
     * 
     * @type {string}
     * @memberof AchievementPostRequestParams
     */
    classId: string;
    /**
     * 
     * @type {string}
     * @memberof AchievementPostRequestParams
     */
    studentId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AchievementPostRequestParams
     */
    achievementImageNames: Array<string>;
}

/**
 * Check if a given object implements the AchievementPostRequestParams interface.
 */
export function instanceOfAchievementPostRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "classId" in value;
    isInstance = isInstance && "studentId" in value;
    isInstance = isInstance && "achievementImageNames" in value;

    return isInstance;
}

export function AchievementPostRequestParamsFromJSON(json: any): AchievementPostRequestParams {
    return AchievementPostRequestParamsFromJSONTyped(json, false);
}

export function AchievementPostRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AchievementPostRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classId': json['classId'],
        'studentId': json['studentId'],
        'achievementImageNames': json['achievementImageNames'],
    };
}

export function AchievementPostRequestParamsToJSON(value?: AchievementPostRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classId': value.classId,
        'studentId': value.studentId,
        'achievementImageNames': value.achievementImageNames,
    };
}

