import React from 'react';
import { Container, Grid, Paper, Box, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Clazz, EnrollmentStatusEnum } from 'student/api';
import CourseDetailSectionAbout from 'student/features/course/components/CourseDetail/CourseDetailSectionAbout';
import CourseDetailSectionPrice from 'student/features/course/components/CourseDetail/CourseDetailSectionPrice';
import CourseDetailSectionSchedule from 'student/features/course/components/CourseDetail/SectionSchedule/CourseDetailSectionSchedule';
import PaymentBankInfo from 'student/features/payment/components/PaymentBankInfo';
import PaymentConfirm from 'student/features/payment/components/PaymentConfirm';
import PaymentStatus from 'student/features/payment/components/PaymentStatus';
import { useSelector } from 'student/store';
import { selectCoursesById } from 'student/features';
import Loading from 'common/components/Loading';
import { useAuth, useEnrollmentByCourseSchedule } from 'student/hooks';
import { scrollTo } from 'common/utils/ui';

export interface PagePaymentParams {
  courseId: string;
  courseScheduleId: string | undefined;
}

function useParamsHook(): { params: PagePaymentParams | undefined } {
  const [params, setParams] = React.useState<PagePaymentParams | undefined>(undefined);

  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const courseId = searchParams.get('courseId') || undefined;
    const courseScheduleId = searchParams.get('courseScheduleId') || undefined;
    if (courseId === undefined) {
      setParams({ courseId: '', courseScheduleId: '' });
      console.error('Params not match', params);
    } else {
      setParams({ courseId, courseScheduleId });
    }
  }, [searchParams]);

  return { params };
}

function usePaymentStatus(
  courseId: string,
  scheduleId: string
): { paymentStatus: EnrollmentStatusEnum | null | undefined } {
  const [paymentStatus, setPaymentStatus] = React.useState<EnrollmentStatusEnum | undefined | null>(undefined);
  const { enrollment } = useEnrollmentByCourseSchedule(courseId, scheduleId);

  React.useEffect(() => {
    setPaymentStatus(enrollment ? enrollment.status : null);
  }, [enrollment]);

  return { paymentStatus };
}

function useCourseWithPaymentSchedule(
  courseId?: string,
  paymentCourseScheduleId?: string
): { course: Clazz; hasSchedules: boolean; loading: boolean } {
  const coursesById = useSelector(selectCoursesById);

  const [course, setCourse] = React.useState<Clazz>({});
  const [loading, setLoading] = React.useState<boolean>(true);

  // Get course info
  React.useEffect(() => {
    const isFetchingCourses = Object.keys(coursesById).length === 0;
    if (isFetchingCourses || !courseId) {
      return;
    }

    const paymentCourse = coursesById[courseId];
    if (!paymentCourse) {
      toast.warning('Không có thông tin lớp học!');
      return;
    }

    if (paymentCourse.schedules) {
      const paymentCourseSchedule = paymentCourse.schedules.find((cS) => cS.scheduleId == paymentCourseScheduleId);

      if (paymentCourseSchedule) {
        setCourse({ ...paymentCourse, schedules: [paymentCourseSchedule] });
      } else {
        setCourse({ ...paymentCourse, schedules: [] });
      }
    }

    setLoading(false);
  }, [coursesById, courseId, paymentCourseScheduleId]);

  const hasSchedules = !!course.schedules && course.schedules.length > 0;

  return { course, hasSchedules, loading };
}

export default function PagePayment() {
  const { user, parentProfile } = useAuth();
  const { params } = useParamsHook();
  const { course, hasSchedules, loading } = useCourseWithPaymentSchedule(params?.courseId, params?.courseScheduleId);
  const { paymentStatus } = usePaymentStatus(`${params?.courseId}`, `${params?.courseScheduleId}`);

  // Handle rendered -> scroll to #section-payment-banking-info
  React.useEffect(() => {
    if (course.classId) {
      scrollTo('#section-payment-banking-info');
    }
  }, [course]);

  const renderPaymentStatus = () => {
    switch (paymentStatus) {
      case undefined:
        return <Loading />;
      case null:
        return course && course.price && course.numLessons && course.classId ? (
          <>
            <PaymentBankInfo />
            <PaymentConfirm
              price={course.price * course.numLessons || 0}
              content={`${user?.phoneNumber}_${parentProfile?.parentName}_${course.classId}`}
              studentId={`${user?.uid}`}
              courseId={`${params?.courseId}`}
              scheduleId={`${params?.courseScheduleId}`}
            />
          </>
        ) : null;
      default:
        return <PaymentStatus paymentStatus={paymentStatus} />;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Grid container sx={{ pt: 4 }}>
        <Grid item xs={12}>
          {course.classId ? (
            <>
              <CourseDetailSectionAbout clazz={course} />
              <CourseDetailSectionPrice clazz={course} />
              {hasSchedules ? (
                <CourseDetailSectionSchedule clazz={course} title="Lịch học mong muốn" enrollable={false} />
              ) : null}
            </>
          ) : (
            <Typography textAlign={'center'} component="h3" variant="h4">
              Không lấy được thông tin lớp học
            </Typography>
          )}

          <Paper elevation={0} sx={{ p: 4, my: 4, borderRadius: 5 }}>
            <Box id="section-payment-banking-info" />

            {renderPaymentStatus()}
          </Paper>
        </Grid>

        <Box mb={5} mt={8}></Box>
      </Grid>
    </Container>
  );
}
