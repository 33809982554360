import React from 'react';
import { styled } from '@mui/material/styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import A from 'common/assets';
import Image from 'common/components/Image';
import { Section } from 'student/layout';
import HorizontalSkeletonLoading from 'common/components/mui/SkeletonLoading';
import { useResponsive } from 'student/hooks';
import AchievementSlideItem from './AchievementSlideItem';
import { Box } from '@mui/material';

const StyledSlider = styled(Slider)({
  maxWidth: '100vw',
  '.slick-slide': {
    margin: '20px 10px',
  },
  '.slick-prev:before, .slick-next:before': {
    content: "''",
  },
  '.slick-prev': {
    left: -30,
  },
  '.slick-next': {
    right: -30,
  },
});

interface Props {
  achievementUrls: string[];
  loading: boolean;
}

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: 30,
        height: 30,
      }}
      onClick={onClick}
    >
      <Image src={A.images.icon_arrow} alt="arrow-icon" />
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        width: 30,
        height: 30,
      }}
      onClick={onClick}
    >
      <Image src={A.images.icon_arrow_left} alt="arrow-icon" />
    </div>
  );
}

export default function AchievementSlide({ loading, achievementUrls }: Props) {
  const { isDesktop, isMobile } = useResponsive();
  const [isInfiniteDesktop, setIsInfiniteDesktop] = React.useState(true);
  const [isInfiniteMobile, setIsInfiniteMobile] = React.useState(true);
  const itemMinWidth = isDesktop ? 320 : 240;

  React.useEffect(() => {
    if (achievementUrls) {
      setIsInfiniteDesktop(achievementUrls.length > 3);
      setIsInfiniteMobile(achievementUrls.length > 1);
    }
  }, [achievementUrls]);

  const sliderSettings = {
    className: 'slider variable-width',
    infinite: isDesktop ? isInfiniteDesktop : isInfiniteMobile,
    centerPadding: '60px',
    slidesToShow: isInfiniteDesktop || isMobile ? 1 : achievementUrls.length,
    swipeToSlide: true,
    variableWidth: true,
    autoplay: true,
    nextArrow: isDesktop ? <NextArrow /> : <></>,
    prevArrow: isDesktop ? <PrevArrow /> : <></>,
  };

  return (
    <Box mb={4}>
      {loading ? (
        <HorizontalSkeletonLoading />
      ) : (
        <StyledSlider {...sliderSettings}>
          {achievementUrls.map((achievementUrl, index) => (
            <AchievementSlideItem key={index} achievementUrl={achievementUrl} itemMinWidth={itemMinWidth} />
          ))}
        </StyledSlider>
      )}
    </Box>
  );
}
