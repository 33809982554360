import React from 'react';
import { Card, CardMedia, CardActionArea, Box } from '@mui/material';

interface Props {
  achievementUrl: string;
  itemMinWidth: number;
}

const AchievementSlideItem = ({ achievementUrl, itemMinWidth }: Props) => {
  const [raised, setRaised] = React.useState<boolean>(false);

  return (
    <Card
      sx={{ width: itemMinWidth }}
      raised={raised}
      onMouseOver={() => setRaised(true)}
      onMouseOut={() => setRaised(false)}
    >
      <CardActionArea>
        <CardMedia component="img" height="200" image={achievementUrl} />
      </CardActionArea>
    </Card>
  );
};

export default AchievementSlideItem;
