import { RootState } from '../../store';
import { Clazz } from 'student/api';
import { createSelector } from '@reduxjs/toolkit';

export const selectCourseState = (state: RootState) => state.courseState;

export const selectAllCourses = (state: RootState) => state?.courseState?.allCourses;
export const selectFeaturedCourses = (state: RootState) => state.courseState.featuredCourses;
export const selectMyEnrolledCourses = (state: RootState) => state.courseState.myEnrolledCourses;
export const selectMySavedCourses = (state: RootState) => state.courseState.mySavedCourses;
export const selectCoursesById = (state: RootState) => state.courseState.coursesById;

export const selectCoursesByCategoryId = (categoryId: string) => (state: RootState) =>
  state.courseState.allCourses.items.filter((course) => course.categoryId === categoryId);

export const selectCourseById = (state: RootState, courseId: string): Clazz | undefined =>
  state.courseState.allCourses.items.find((course) => course.classId === courseId);

export const isCourseEnrolled = (state: RootState, courseId: string): boolean =>
  state.courseState.myEnrolledCourses.items.some((course) => course.classId === courseId);
