import React from 'react';
import { styled } from '@mui/material/styles';
import { ButtonProps, Button } from '@mui/material';

import A from 'common/assets';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'common/config';

interface CustomButtonProps extends Omit<ButtonProps, 'size' | 'variant'> {
  variant?: ButtonProps['variant'] | 'pure-text';
  rounded?: boolean | undefined;
  size?: ButtonProps['size'] | 'extra' | 'huge';
}

export default function AmusingoButton(props: CustomButtonProps) {
  let MyButton: any = ButtonText;
  if (props.variant === 'pure-text') {
    MyButton = ButtonPureText;
  }
  if (props.variant === 'contained') {
    MyButton = ButtonContained;
  }
  if (props.variant === 'outlined') {
    MyButton = ButtonOutlined;
  }
  const customOnClick = (event: any) => {
    // call logger function
    logEvent(analytics, 'btn_clicked', { text: event.target.innerText });
    if (props.onClick) {
      props.onClick(event);
    }
  };
  // Custom styles
  const a = 1;
  MyButton = styled(MyButton)(styles.common);
  if (props?.size === 'extra') {
    MyButton = styled(MyButton)(styles.extra);
  }
  if (props?.size === 'huge') {
    MyButton = styled(MyButton)(styles.huge);
  }

  return <MyButton {...props} onClick={customOnClick} />;
}

const styles = {
  common: {
    borderRadius: 30,
    fontSize: 16,
  },
  extra: {
    height: 74,
    fontSize: 23,
    padding: '12px 23px',
  },
  huge: {
    height: 100,
    fontSize: 23,
    fontWeight: 800,
    padding: '20px 50px',
  },
};

const ButtonPureText = styled(Button)({
  color: A.colors.text,
});
const ButtonText = styled(Button)({
  color: A.colors.text,
  '&:hover': {
    backgroundColor: A.colors.primary,
    color: A.colors.white,
  },
});
const ButtonContained = styled(Button)({});
const ButtonOutlined = styled(Button)({});
