import * as React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import PageLoading from 'common/pages/PageLoading';
import PageMaintaining from 'common/pages/PageMaintaining';
import PageNotFound from 'common/pages/PageNotFound';
import nProgress from 'nprogress';

interface CommonRoute {
  path: string;
  name: string;
  Element: () => JSX.Element;
  params?: {
    [key: string]: string;
  };
  needAuth?: boolean;
}

export interface CommonRoutes {
  [key: string]: CommonRoute;
}

export const commonRoutes: CommonRoutes = {
  PageLoading: {
    path: '/loading',
    name: 'PageLoading',
    Element: PageLoading,
  },
  PageMaintaining: {
    // Or error page
    path: '/maintaining',
    name: 'PageMaintaining',
    Element: PageMaintaining,
  },
  PageNotFound: {
    path: '*',
    name: 'PageNotFound',
    Element: PageNotFound,
  },
};

export function RouteEnhancer() {
  const location = useLocation();

  // Scroll to top on route change
  React.useMemo(() => {
    nProgress.start();
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    nProgress.start();
    nProgress.done();
  }, [location]);

  // TODO
  // React handle route change start and done => loading NProgress

  return <></>;
}
