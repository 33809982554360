import React from 'react';
import { Box, IconButton, Avatar, Skeleton } from '@mui/material';
import {
  Search,
  School,
  Help,
  Login,
  Logout,
  Settings,
  Today,
  ArrowDropDown,
  Favorite,
  CoPresent,
} from '@mui/icons-material';

import A from 'common/assets';
import { Desktop, Mobile } from 'common/components/Responsive';
import SkeletonButton from 'common/components/Skeleton/SkeletonButton';
import { stringToColor, nameToAvatarText } from 'common/utils';

import './style.scss';
import HeaderDesktopAppBar1 from './HeaderDesktopAppBar1';
import HeaderDesktopAppBar2 from './HeaderDesktopAppBar2';
import HeaderMobileAppBar from './HeaderMobileAppBar';
import HeaderMobileDrawer from './HeaderMobileDrawer';
import { useAuth } from 'teacher/hooks';
import { routes } from 'teacher/Routes';
import { TEACHER_SITE_PATH_NAME } from 'common/config';

export interface NavItem {
  route: string;
  label: string;
  startIcon: JSX.Element;
  navItems?: NavItem[];
  active?: boolean;
  variant?: 'contained';
}

const Navigation = () => {
  const { user, isAuthenticatedWithProfile, userFetched, teacherProfile, teacherProfileFetched } = useAuth();
  const name = user?.displayName || teacherProfile?.name || 'Người dùng';

  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);

  const navDefaultItems: NavItem[] = [
    {
      route: routes.PageDashboard.path,
      label: 'Thống kê',
      startIcon: <Search />,
    },
    {
      route: routes.PageMyCourses.path,
      label: 'Lớp học của bạn',
      startIcon: <CoPresent />,
    },
    {
      route: routes.PageSchedule.path,
      label: 'Lịch học',
      startIcon: <Today />,
    },
    { route: routes.PageFAQ.path, label: 'Trợ giúp', startIcon: <Help /> },
  ];

  const navGuestItems: NavItem[] = [
    ...navDefaultItems,
    { route: routes.PageAuth.path, label: 'Đăng nhập', startIcon: <Login /> },
    {
      route: routes.PageAuth.path,
      label: 'Đăng ký',
      startIcon: <Login />,
      variant: 'contained',
    },
  ];

  const navSkeletonItems: NavItem[] = [
    ...navDefaultItems,
    {
      route: '#loading-1',
      label: '',
      startIcon: <SkeletonButton variant="rounded" width={userFetched ? 157 : 100} height={30} />,
    },
    {
      route: '#loading-2',
      label: '',
      startIcon: (
        <SkeletonButton
          variant={userFetched ? 'circular' : 'rounded'}
          width={userFetched ? 40 : 100}
          height={userFetched ? 40 : 30}
        />
      ),
    },
  ];

  const userMenuNavItems: NavItem[] = [
    {
      label: 'Thông tin',
      route: '#',
      startIcon: (
        <IconButton sx={{ p: 2.5 }}>
          <Avatar
            alt={'Amusingo - Avatar ' + name}
            sx={{
              bgcolor: stringToColor(name),
            }}
            src={user?.photoURL || ''}
          >
            {nameToAvatarText(name)}
          </Avatar>
          <ArrowDropDown />
        </IconButton>
      ),
      navItems: [
        {
          route: '/my-saved-courses',
          label: 'Khóa học yêu thích',
          startIcon: <Favorite />,
        },
        {
          route: '/profile',
          label: 'Thông tin tài khoản',
          startIcon: <Settings />,
        },
        { route: `${TEACHER_SITE_PATH_NAME}/logout`, label: 'Đăng xuất', startIcon: <Logout /> },
      ],
    },
  ];
  const navAuthItems: NavItem[] = [
    ...navDefaultItems,
    {
      route: 'routes.PageMyEnrolledCourses.path',
      label: 'Khóa học của tôi',
      startIcon: <Today />,
    },
    ...userMenuNavItems,
  ];

  let navItems: NavItem[] = navSkeletonItems;
  if (userFetched && teacherProfileFetched) {
    navItems = isAuthenticatedWithProfile ? navAuthItems : navGuestItems;
  }

  const handleDrawerToggle = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };

  return (
    <Box sx={{ zIndex: 10 }}>
      <Desktop>
        <HeaderDesktopAppBar1 navItems={navItems} />
      </Desktop>

      <Mobile sx={{ minHeight: 60 }}>
        <HeaderMobileAppBar handleDrawerToggle={handleDrawerToggle} />
        <HeaderMobileDrawer isOpenDrawer={isOpenDrawer} handleDrawerToggle={handleDrawerToggle} navItems={navItems} />
      </Mobile>
    </Box>
  );
};

export default Navigation;
