/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TeacherList,
  TeacherProfile,
  TeacherProfilePostRequest,
  TeacherProfilePutRequest,
} from '../models';
import {
    TeacherListFromJSON,
    TeacherListToJSON,
    TeacherProfileFromJSON,
    TeacherProfileToJSON,
    TeacherProfilePostRequestFromJSON,
    TeacherProfilePostRequestToJSON,
    TeacherProfilePutRequestFromJSON,
    TeacherProfilePutRequestToJSON,
} from '../models';

export interface CreateTeacherProfileRequest {
    teacherProfilePostRequest: TeacherProfilePostRequest;
    orgId?: string;
}

export interface GetOrgTeachersRequest {
    orgId: string;
}

export interface GetTeacherProfileRequest {
    teacherId: string;
    orgId?: string;
}

export interface UpdateTeacherProfileRequest {
    teacherId: string;
    teacherProfilePutRequest: TeacherProfilePutRequest;
    orgId?: string;
}

/**
 * 
 */
export class TeacherProfileApi extends runtime.BaseAPI {

    /**
     * Create a new teacher
     */
    async createTeacherProfileRaw(requestParameters: CreateTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeacherProfile>> {
        if (requestParameters.teacherProfilePostRequest === null || requestParameters.teacherProfilePostRequest === undefined) {
            throw new runtime.RequiredError('teacherProfilePostRequest','Required parameter requestParameters.teacherProfilePostRequest was null or undefined when calling createTeacherProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teacher-profiles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeacherProfilePostRequestToJSON(requestParameters.teacherProfilePostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeacherProfileFromJSON(jsonValue));
    }

    /**
     * Create a new teacher
     */
    async createTeacherProfile(requestParameters: CreateTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeacherProfile> {
        const response = await this.createTeacherProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all teachers under an organization
     */
    async getOrgTeachersRaw(requestParameters: GetOrgTeachersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeacherList>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrgTeachers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orgs/{orgId}/teachers`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeacherListFromJSON(jsonValue));
    }

    /**
     * Get all teachers under an organization
     */
    async getOrgTeachers(requestParameters: GetOrgTeachersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeacherList> {
        const response = await this.getOrgTeachersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get teacher by teacherId
     */
    async getTeacherProfileRaw(requestParameters: GetTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeacherProfile>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getTeacherProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teacher-profiles/{teacherId}/profile`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeacherProfileFromJSON(jsonValue));
    }

    /**
     * Get teacher by teacherId
     */
    async getTeacherProfile(requestParameters: GetTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeacherProfile> {
        const response = await this.getTeacherProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a teacher by teacherId
     */
    async updateTeacherProfileRaw(requestParameters: UpdateTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeacherProfile>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling updateTeacherProfile.');
        }

        if (requestParameters.teacherProfilePutRequest === null || requestParameters.teacherProfilePutRequest === undefined) {
            throw new runtime.RequiredError('teacherProfilePutRequest','Required parameter requestParameters.teacherProfilePutRequest was null or undefined when calling updateTeacherProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teacher-profiles/{teacherId}/profile`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeacherProfilePutRequestToJSON(requestParameters.teacherProfilePutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeacherProfileFromJSON(jsonValue));
    }

    /**
     * Update a teacher by teacherId
     */
    async updateTeacherProfile(requestParameters: UpdateTeacherProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeacherProfile> {
        const response = await this.updateTeacherProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
