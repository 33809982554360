import React from 'react';
import { DummyTeacher, DummyTeacherApi } from '../api';

export function useDummyTeacher(teacherId: string) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [teacher, setTeacher] = React.useState<DummyTeacher>({});

  React.useEffect(() => {
    if (teacherId) {
      new DummyTeacherApi()
        .getDummyTeacherInformation({
          teacherId,
        })
        .then((res) => {
          setTeacher(res);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [teacherId]);

  return {
    teacher,
    loading,
  };
}
