import React from 'react';
import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const DividerStyled = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2.5, 0),
  borderColor: theme.palette.text.primary,
}));
export default function DividerComponent() {
  return <DividerStyled />;
}
