export async function uploadImage(imageFile: File, presignedUrl: string): Promise<boolean> {
  try {
    await fetch(presignedUrl, {
      method: 'PUT',
      body: imageFile,
      headers: {
        'Content-Type': imageFile.type,
      },
    });

    return true;
  } catch (error) {
    console.error('Error uploading image:', error);
    return false;
  }
}

export const normalizeImageName = (prefix: string, id: string, fileName: string) => {
  const ext = fileName.split('.').pop();
  const randomValue = Math.floor(Math.random() * 1000000);
  return `${prefix}_${id}_${randomValue}.${ext}`;
};
