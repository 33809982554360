import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SiteEnum, Site } from 'common/types';

export interface AuthState {
  currentSite: Site | null;
  loading: boolean;
}

const initialState: AuthState = {
  currentSite: null,
  loading: true,
};

export const siteSlice = createSlice({
  name: 'common-siteSlice',
  initialState,
  reducers: {
    changeCurrentSite: (state, action: PayloadAction<SiteEnum>) => {
      state.currentSite = action.payload;
      state.loading = false;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCurrentSite, changeLoading } = siteSlice.actions;

export const siteState = siteSlice.reducer;
