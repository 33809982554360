import * as React from 'react';

import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

import Button from 'common/components/Button';
import Loading from 'common/components/Loading';
import Link from 'common/components/Link/Link';
import { ClassSchedule } from 'student/api';
import { routes } from 'student/Routes';
import LessonScheduleItemText from 'student/features/course/components/LessonScheduleItemText';
import { getClassAllScheduleEndMs, getClassScheduleStartMs } from 'common/utils';
import { useEnrollment } from 'student/hooks';
import { scrollTo } from 'common/utils/ui';

interface Props {
  classId: string;
  classSchedule: ClassSchedule;
  lengthOfLesson: number;
  enrollable?: boolean;
  numEnrolledStudents: number;
  maxNumAllowedLearners: number;
}

export default function CourseScheduleCard({
  classId,
  classSchedule,
  lengthOfLesson,
  enrollable,
  numEnrolledStudents,
  maxNumAllowedLearners,
}: Props) {
  const theme = useTheme();
  const { scheduleId, scheduleTimes } = classSchedule;

  if (!scheduleTimes || scheduleTimes.length === 0) {
    return <></>;
  }

  const { allEnrollments } = useEnrollment();
  const isEnrolledCourseSchedule = allEnrollments.items.some(
    (enroll) => enroll.classId === classId && enroll.scheduleId === classSchedule.scheduleId
  );

  const allStartMsTimes: number[] = scheduleTimes.map((schedule) => getClassScheduleStartMs(schedule));
  const startAtMs = Math.min(...allStartMsTimes);
  const startAtDDMM = moment(new Date(startAtMs)).locale('vi').format('DD/MM');
  const numAllowedLearners = maxNumAllowedLearners - numEnrolledStudents;

  const renderActionButton = () => {
    if (enrollable === false) {
      return null;
    } else if (isEnrolledCourseSchedule) {
      return (
        <Box textAlign={'center'}>
          <Button onClick={() => scrollTo('#course-detail-section-about')} variant="outlined">
            Đang học
          </Button>
        </Box>
      );
    } else if (Date.now() > startAtMs) {
      return null;
    } else {
      return (
        <Box textAlign={'center'}>
          <Link to={routes.PagePayment.path + `?courseId=${classId}&courseScheduleId=${scheduleId}`}>
            <Button variant="contained">Đăng ký</Button>
          </Link>
        </Box>
      );
    }
  };

  if (allEnrollments.fetched) {
    if (Date.now() > startAtMs) {
      if (!isEnrolledCourseSchedule) return <></>;
    } else if (numAllowedLearners === 0) {
      return <></>;
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{
        px: 3,
        py: 4,
        borderRadius: 5,
        borderColor: theme.palette.grey[800],
        borderWidth: 1,
        borderStyle: 'solid',
      }}
    >
      {scheduleTimes.map((lessonScheduleTime) => (
        <LessonScheduleItemText
          key={lessonScheduleTime.start + lessonScheduleTime.end}
          lessonScheduleTime={lessonScheduleTime}
          lengthOfLesson={lengthOfLesson}
        />
      ))}
      <Typography py={1}>
        Bắt đầu dự kiến vào <b>{startAtDDMM}</b>
      </Typography>
      {Date.now() > startAtMs ? (
        <Typography>Lớp đang diễn ra</Typography>
      ) : (
        <Typography>
          Chỉ còn trống <b>{numAllowedLearners}</b> vị trí
        </Typography>
      )}
      <br />
      {renderActionButton()}
    </Paper>
  );
}
