import React from 'react';
import { useSelector } from 'student/store';
import { EnrollmentState, selectEnrollmentState } from 'student/features';
import { Enrollment } from 'student/api';

export function useEnrollment(): EnrollmentState {
  const enrollmentState = useSelector(selectEnrollmentState);

  return enrollmentState;
}

export function useEnrollmentByCourseSchedule(courseId: string, scheduleId: string): { enrollment: Enrollment | null } {
  const { allEnrollments } = useEnrollment();

  const enrollment = allEnrollments.items.find(
    (enroll: Enrollment) => enroll.classId === courseId && enroll.scheduleId === scheduleId
  );

  return { enrollment: enrollment || null };
}
