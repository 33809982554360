/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUploadUrl201Response
 */
export interface GetUploadUrl201Response {
    /**
     * 
     * @type {string}
     * @memberof GetUploadUrl201Response
     */
    uploadUrl?: string;
}

/**
 * Check if a given object implements the GetUploadUrl201Response interface.
 */
export function instanceOfGetUploadUrl201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetUploadUrl201ResponseFromJSON(json: any): GetUploadUrl201Response {
    return GetUploadUrl201ResponseFromJSONTyped(json, false);
}

export function GetUploadUrl201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUploadUrl201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadUrl': !exists(json, 'uploadUrl') ? undefined : json['uploadUrl'],
    };
}

export function GetUploadUrl201ResponseToJSON(value?: GetUploadUrl201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uploadUrl': value.uploadUrl,
    };
}

