import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import A from 'common/assets';
import { Option } from 'common/components/Field/Select';
import { ECourseTime } from '../../types';
interface FilterTimeProps {
  options: Option[];
  selectedOptions: Option[];
  handleChangeOptions: (options: Option[]) => void;
}

const FilterTime = ({ options, selectedOptions, handleChangeOptions }: FilterTimeProps) => {
  const [time, setTime] = React.useState<ECourseTime>(ECourseTime.Upcoming);

  return (
    <FormControl>
      <Box
        id="demo-controlled-radio-buttons-group"
        sx={{ color: A.colors.secondary, fontSize: 20, fontFamily: A.fonts.bold, marginBottom: 1 }}
      >
        Thời gian
      </Box>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={time}
        onChange={(e) => {
          const newOption = options.find((option) => option.value === e.target.value);
          if (newOption) {
            handleChangeOptions([newOption]);
            setTime(newOption.value);
          }
        }}
      >
        <FormControlLabel value={ECourseTime.Upcoming} control={<Radio />} label="Sắp diễn ra" />
        <FormControlLabel value={ECourseTime.Finished} control={<Radio />} label="Đã kết thúc" />
      </RadioGroup>
    </FormControl>
  );
};

export default FilterTime;
