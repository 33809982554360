import React from 'react';
import { Box } from '@mui/material';

import A from 'common/assets';

export default function CustomImage(props: any) {
  const imgProps = { src: A.images.card, width: '100%', ...props };

  return <Box component="img" {...imgProps} />;
}
