import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Courses, initCourses } from './types';

export interface CourseState {
  allCourses: Courses;
  coursesById: Courses;
}

const initialState: CourseState = {
  allCourses: initCourses,
  coursesById: initCourses,
};

export const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    changeAllCourses: (state, action: PayloadAction<Partial<Courses>>) => {
      state.allCourses = { ...state.allCourses, ...action.payload };
    },
    changeCourseByTeacherId: (state, action: PayloadAction<Partial<Courses>>) => {
      state.coursesById = { ...state.coursesById, ...action.payload };
    },
  },
});

export const { changeAllCourses, changeCourseByTeacherId } = courseSlice.actions;

export const courseState = courseSlice.reducer;
