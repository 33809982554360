import { ClazzApprovalStatusEnum, Clazz as Course } from 'teacher/api/models';

export enum ECourseTime {
  Upcoming = 'Sắp diễn ra',
  Finished = 'Đã kết thúc',
}

interface StatusOption {
  label: string;
  value: ClazzApprovalStatusEnum;
}
interface TimeOption {
  label: string;
  value: ECourseTime;
}
interface WeekDayOption {
  label: string;
  value: number;
}
interface TimeSlotOption {
  label: string;
  value: {
    minTimeSlot: number;
    maxTimeSlot: number;
  };
}
export type CourseFilterOption = StatusOption | TimeOption | WeekDayOption | TimeSlotOption;

export interface CoursesFilter {
  statusOptions: StatusOption[];
  timeOptions: TimeOption[];
  weekDayOptions: WeekDayOption[];
  timeSlotOptions: TimeSlotOption[];
}

export const fullCoursesFilter: CoursesFilter = {
  statusOptions: [
    {
      label: 'Đã đăng',
      value: ClazzApprovalStatusEnum.Approved,
    },
    {
      label: 'Đang chờ',
      value: ClazzApprovalStatusEnum.Pending,
    },
    {
      label: 'Bản nháp',
      value: ClazzApprovalStatusEnum.ChangesRequested,
    },
    {
      label: 'Bị từ chối',
      value: ClazzApprovalStatusEnum.Rejected,
    },
  ],
  timeOptions: [
    {
      label: 'Sắp diễn ra',
      value: ECourseTime.Upcoming,
    },
    {
      label: 'Đã kết thúc',
      value: ECourseTime.Finished,
    },
  ],
  weekDayOptions: [
    {
      label: 'T2',
      value: 2,
    },
    {
      label: 'T3',
      value: 3,
    },
    {
      label: 'T4',
      value: 4,
    },
    {
      label: 'T5',
      value: 5,
    },
    {
      label: 'T6',
      value: 6,
    },
    {
      label: 'T7',
      value: 7,
    },
    {
      label: 'CN',
      value: 8,
    },
  ],
  timeSlotOptions: [
    {
      label: 'Bất cứ lúc nào (7:00-23:00)',
      value: {
        minTimeSlot: 7,
        maxTimeSlot: 23,
      },
    },
    {
      label: 'Buổi sáng (7:00-12:00)',
      value: {
        minTimeSlot: 7,
        maxTimeSlot: 12,
      },
    },
    {
      label: 'Buổi chiều (12:00-19:00)',
      value: {
        minTimeSlot: 12,
        maxTimeSlot: 19,
      },
    },
    {
      label: 'Buổi tối (19:00-23:00)',
      value: {
        minTimeSlot: 19,
        maxTimeSlot: 23,
      },
    },
  ],
};

export const initCoursesFilter: CoursesFilter = {
  statusOptions: [],
  timeOptions: [],
  weekDayOptions: [],
  timeSlotOptions: [],
};
