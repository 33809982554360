/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClassSchedulePostRequest,
  ClassSchedulePutRequest,
  Clazz,
  ClazzList,
  ClazzPostRequestParams,
  ClazzPutRequestParams,
} from '../models';
import {
    ClassSchedulePostRequestFromJSON,
    ClassSchedulePostRequestToJSON,
    ClassSchedulePutRequestFromJSON,
    ClassSchedulePutRequestToJSON,
    ClazzFromJSON,
    ClazzToJSON,
    ClazzListFromJSON,
    ClazzListToJSON,
    ClazzPostRequestParamsFromJSON,
    ClazzPostRequestParamsToJSON,
    ClazzPutRequestParamsFromJSON,
    ClazzPutRequestParamsToJSON,
} from '../models';

export interface AddNewSchedulesRequest {
    teacherId: string;
    clazzId: string;
    classSchedulePostRequest: Array<ClassSchedulePostRequest>;
    orgId?: string;
}

export interface CreateClazzRequest {
    teacherId: string;
    clazzPostRequestParams: ClazzPostRequestParams;
    orgId?: string;
}

export interface GetClazzRequest {
    teacherId: string;
    clazzId: string;
    orgId?: string;
}

export interface GetClazzesByOrgIdRequest {
    orgId: string;
}

export interface GetClazzesByTeacherRequest {
    teacherId: string;
    orgId?: string;
}

export interface ModifyExistingScheduleRequest {
    teacherId: string;
    clazzId: string;
    classSchedulePutRequest: Array<ClassSchedulePutRequest>;
    orgId?: string;
}

export interface UpdateClazzRequest {
    teacherId: string;
    clazzId: string;
    clazzPutRequestParams: ClazzPutRequestParams;
    orgId?: string;
}

/**
 * 
 */
export class ClazzApi extends runtime.BaseAPI {

    /**
     * update schedules of a clazz
     */
    async addNewSchedulesRaw(requestParameters: AddNewSchedulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling addNewSchedules.');
        }

        if (requestParameters.clazzId === null || requestParameters.clazzId === undefined) {
            throw new runtime.RequiredError('clazzId','Required parameter requestParameters.clazzId was null or undefined when calling addNewSchedules.');
        }

        if (requestParameters.classSchedulePostRequest === null || requestParameters.classSchedulePostRequest === undefined) {
            throw new runtime.RequiredError('classSchedulePostRequest','Required parameter requestParameters.classSchedulePostRequest was null or undefined when calling addNewSchedules.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teachers/{teacherId}/classes/{classId}/new-schedules`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"clazzId"}}`, encodeURIComponent(String(requestParameters.clazzId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.classSchedulePostRequest.map(ClassSchedulePostRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * update schedules of a clazz
     */
    async addNewSchedules(requestParameters: AddNewSchedulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.addNewSchedulesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create a new clazz
     */
    async createClazzRaw(requestParameters: CreateClazzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling createClazz.');
        }

        if (requestParameters.clazzPostRequestParams === null || requestParameters.clazzPostRequestParams === undefined) {
            throw new runtime.RequiredError('clazzPostRequestParams','Required parameter requestParameters.clazzPostRequestParams was null or undefined when calling createClazz.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teachers/{teacherId}/classes`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClazzPostRequestParamsToJSON(requestParameters.clazzPostRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * create a new clazz
     */
    async createClazz(requestParameters: CreateClazzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.createClazzRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get details of a clazz based on clazzId and teacherId
     */
    async getClazzRaw(requestParameters: GetClazzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getClazz.');
        }

        if (requestParameters.clazzId === null || requestParameters.clazzId === undefined) {
            throw new runtime.RequiredError('clazzId','Required parameter requestParameters.clazzId was null or undefined when calling getClazz.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teachers/{teacherId}/classes/{classId}/info`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"clazzId"}}`, encodeURIComponent(String(requestParameters.clazzId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * get details of a clazz based on clazzId and teacherId
     */
    async getClazz(requestParameters: GetClazzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.getClazzRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all clazzes of an organization
     */
    async getClazzesByOrgIdRaw(requestParameters: GetClazzesByOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getClazzesByOrgId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/orgs/{orgId}/classes`.replace(`{${"orgId"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * get all clazzes of an organization
     */
    async getClazzesByOrgId(requestParameters: GetClazzesByOrgIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getClazzesByOrgIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all clazzes of a teacher
     */
    async getClazzesByTeacherRaw(requestParameters: GetClazzesByTeacherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClazzList>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling getClazzesByTeacher.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teachers/{teacherId}/classes`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzListFromJSON(jsonValue));
    }

    /**
     * get all clazzes of a teacher
     */
    async getClazzesByTeacher(requestParameters: GetClazzesByTeacherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClazzList> {
        const response = await this.getClazzesByTeacherRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * modify an existing schedule of a clazz
     */
    async modifyExistingScheduleRaw(requestParameters: ModifyExistingScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling modifyExistingSchedule.');
        }

        if (requestParameters.clazzId === null || requestParameters.clazzId === undefined) {
            throw new runtime.RequiredError('clazzId','Required parameter requestParameters.clazzId was null or undefined when calling modifyExistingSchedule.');
        }

        if (requestParameters.classSchedulePutRequest === null || requestParameters.classSchedulePutRequest === undefined) {
            throw new runtime.RequiredError('classSchedulePutRequest','Required parameter requestParameters.classSchedulePutRequest was null or undefined when calling modifyExistingSchedule.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teachers/{teacherId}/classes/{classId}/schedules-modification`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"clazzId"}}`, encodeURIComponent(String(requestParameters.clazzId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.classSchedulePutRequest.map(ClassSchedulePutRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * modify an existing schedule of a clazz
     */
    async modifyExistingSchedule(requestParameters: ModifyExistingScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.modifyExistingScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update a clazz
     */
    async updateClazzRaw(requestParameters: UpdateClazzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Clazz>> {
        if (requestParameters.teacherId === null || requestParameters.teacherId === undefined) {
            throw new runtime.RequiredError('teacherId','Required parameter requestParameters.teacherId was null or undefined when calling updateClazz.');
        }

        if (requestParameters.clazzId === null || requestParameters.clazzId === undefined) {
            throw new runtime.RequiredError('clazzId','Required parameter requestParameters.clazzId was null or undefined when calling updateClazz.');
        }

        if (requestParameters.clazzPutRequestParams === null || requestParameters.clazzPutRequestParams === undefined) {
            throw new runtime.RequiredError('clazzPutRequestParams','Required parameter requestParameters.clazzPutRequestParams was null or undefined when calling updateClazz.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teachers/{teacherId}/classes/{classId}/info`.replace(`{${"teacherId"}}`, encodeURIComponent(String(requestParameters.teacherId))).replace(`{${"clazzId"}}`, encodeURIComponent(String(requestParameters.clazzId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClazzPutRequestParamsToJSON(requestParameters.clazzPutRequestParams),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClazzFromJSON(jsonValue));
    }

    /**
     * update a clazz
     */
    async updateClazz(requestParameters: UpdateClazzRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Clazz> {
        const response = await this.updateClazzRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
