import { Box, Container } from '@mui/material';
import React from 'react';
import OverallStatistics from './OverallStatistics';
import StatisticsByTime from './StatisticsByTime';
import StatisticsByCourses from './StatisticsByCourses';
import { useSelector } from 'react-redux';
import { RootState } from 'teacher/store';
import { useNavigate } from 'react-router';
import { routes } from 'teacher/Routes';
import { StatisticApi, TeacherStatistic } from 'teacher/api';
import { useStatistic } from 'teacher/hooks/useStatistic';
import moment from 'moment';

export default function PageDashboard() {
  const teacher = useSelector((state: RootState) => state.authState.teacherProfile);
  const navigate = useNavigate();
  const { overallStatistic } = useStatistic();
  // React.useEffect(() => {
  //   if (!teacher) {
  //     navigate(routes.PageApply.path);
  //   } else {

  //   }
  // }, []);
  return (
    <Box component="section">
      <Container maxWidth="lg">
        <Box sx={{ mb: 6 }}>
          {overallStatistic && <OverallStatistics teacherStatistic={overallStatistic} />}
          {/* statistic data are not done yet*/}
          <StatisticsByTime header="Thống kê hàng tháng" />
          <StatisticsByTime header="Thống kê hàng năm" />
          <StatisticsByCourses />
        </Box>
      </Container>
    </Box>
  );
}
