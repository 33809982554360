import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useSelector as reduxUseSelector } from 'react-redux';

import { siteState } from 'common/features/site';

export const store = configureStore({
  reducer: combineReducers({
    siteState,
  }),
});

export type CommonState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector = reduxUseSelector;
export const dispatch = store.dispatch;
