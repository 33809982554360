import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Category } from 'student/api';
import { useCategory } from 'student/features';

export function useParamsCategory() {
  const categoryState = useCategory();
  const [searchParams] = useSearchParams();

  const [category, setCategory] = React.useState<Category | undefined>(undefined);

  React.useEffect(() => {
    const paramCategoryId = searchParams.get('categoryId') || undefined;
    const paramCategory = categoryState.items.find((c: Category) => c.categoryId === paramCategoryId);
    setCategory(paramCategory);
  }, [searchParams, categoryState]);

  return category;
}
