import React from 'react';
import { Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, RootState } from 'student/store';

import ProfileSectionBasicInfo from 'student/features/profile/components/ProfileSectionBasicInfo';

const RootStyled = styled('div')(({ theme }) => ({
  margin: theme.spacing(4, 0),
}));

export default function PageCourse() {
  // const { user, parentProfile } = useSelector((state: RootState) => state.authState);

  // if (!user) {
  //   toast.warning('Không có thông tin!');
  // }

  return (
    <RootStyled>
      <Container fixed>
        <ProfileSectionBasicInfo />
      </Container>
    </RootStyled>
  );
}
