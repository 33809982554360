import React from 'react';
import Box from '@mui/material/Box';

import Divider from 'common/components/Divider';
import { Option } from 'common/components/Field/Select';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CoursesFilter } from '../../types';
import A from 'common/assets';
import FilterStatus from './FilterStatus';

interface Props {
  filter: CoursesFilter;
  selectedFilter: CoursesFilter;
  handleChangeSelectedFilter: (changedFilter: CoursesFilter) => void;
}

export default function CourseListFilter({ filter, selectedFilter, handleChangeSelectedFilter }: Props) {
  const handleChangeStatusOptions = (options: Option[]) => {
    handleChangeSelectedFilter({
      ...selectedFilter,
      statusOptions: options,
    });
  };

  return (
    <>
      <Box sx={{ color: A.colors.secondary, fontSize: 18, fontFamily: A.fonts.bold }}>
        <FilterAltIcon /> Lọc
      </Box>
      <Divider />
      <FilterStatus
        options={filter.statusOptions}
        selectedOptions={selectedFilter.statusOptions}
        handleChangeOptions={handleChangeStatusOptions}
      />
    </>
  );
}
