import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useSelector as reduxUseSelector } from 'react-redux';

import { authState } from './features/auth';
import { courseState } from './features/course';
import { categoryState } from '../common/features/category';
import { enrollmentState } from './features/enrollment';

export const store = configureStore({
  reducer: combineReducers({
    authState,
    courseState,
    categoryState,
    enrollmentState,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelector = reduxUseSelector;
export const dispatch = store.dispatch;
export default store;
