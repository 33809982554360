/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LessonScheduleTime } from './LessonScheduleTime';
import {
    LessonScheduleTimeFromJSON,
    LessonScheduleTimeFromJSONTyped,
    LessonScheduleTimeToJSON,
} from './LessonScheduleTime';

/**
 * A class schedule post request param
 * @export
 * @interface ClassSchedulePutRequest
 */
export interface ClassSchedulePutRequest {
    /**
     * 
     * @type {string}
     * @memberof ClassSchedulePutRequest
     */
    scheduleId?: string;
    /**
     * 
     * @type {Array<LessonScheduleTime>}
     * @memberof ClassSchedulePutRequest
     */
    scheduleTime?: Array<LessonScheduleTime>;
}

/**
 * Check if a given object implements the ClassSchedulePutRequest interface.
 */
export function instanceOfClassSchedulePutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassSchedulePutRequestFromJSON(json: any): ClassSchedulePutRequest {
    return ClassSchedulePutRequestFromJSONTyped(json, false);
}

export function ClassSchedulePutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassSchedulePutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
        'scheduleTime': !exists(json, 'scheduleTime') ? undefined : ((json['scheduleTime'] as Array<any>).map(LessonScheduleTimeFromJSON)),
    };
}

export function ClassSchedulePutRequestToJSON(value?: ClassSchedulePutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduleId': value.scheduleId,
        'scheduleTime': value.scheduleTime === undefined ? undefined : ((value.scheduleTime as Array<any>).map(LessonScheduleTimeToJSON)),
    };
}

