/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Enrollment } from './Enrollment';
import {
    EnrollmentFromJSON,
    EnrollmentFromJSONTyped,
    EnrollmentToJSON,
} from './Enrollment';

/**
 * List of enrollments
 * @export
 * @interface EnrollmentList
 */
export interface EnrollmentList {
    /**
     * 
     * @type {Array<Enrollment>}
     * @memberof EnrollmentList
     */
    items: Array<Enrollment>;
}

/**
 * Check if a given object implements the EnrollmentList interface.
 */
export function instanceOfEnrollmentList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function EnrollmentListFromJSON(json: any): EnrollmentList {
    return EnrollmentListFromJSONTyped(json, false);
}

export function EnrollmentListFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnrollmentList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(EnrollmentFromJSON)),
    };
}

export function EnrollmentListToJSON(value?: EnrollmentList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(EnrollmentToJSON)),
    };
}

