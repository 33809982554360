import React from 'react';

import { ZoomForStudentsApi } from 'student/api';
import Button from 'common/components/Button';
import Link from 'common/components/Link/Link';
import { useSelector } from 'student/store';
import { selectAuthState } from 'student/features/auth';
import { useCourse } from 'student/hooks';
import { scrollTo } from 'common/utils/ui';

interface Props {
  courseId: string;
}

function CourseDetailSectionAboutNotEnrolled() {
  return (
    <Button
      onClick={() => scrollTo('#course-detail-section-schedules')}
      variant="contained"
      size="large"
      sx={{ px: 2, fontWeight: 800, minWidth: 200 }}
    >
      Xem lịch học
    </Button>
  );
}

function CourseDetailSectionAboutEnrolled({ courseId }: Props) {
  const [zoomUrl, setZoomUrl] = React.useState('');

  const { user } = useSelector(selectAuthState);

  React.useEffect(() => {
    new ZoomForStudentsApi()
      .getZoomUrl({
        classId: courseId,
        studentId: `${user?.uid}`,
      })
      .then((value) => {
        if (value.joinUrl) {
          setZoomUrl(value.joinUrl);
        } else {
          console.error('Do not have zoom url');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [courseId]);

  if (zoomUrl) {
    return (
      <Link to={zoomUrl} target="_blank">
        <Button variant="contained" size="large" sx={{ px: 3, fontWeight: 800, minWidth: 200, fontSize: 18 }}>
          Vào lớp ngay
        </Button>
      </Link>
    );
  } else {
    return (
      <Link to={zoomUrl} target="_blank">
        <Button variant="contained" size="medium" disabled sx={{ px: 2, fontWeight: 800, minWidth: 200, fontSize: 18 }}>
          Lớp đang chuẩn bị
        </Button>
      </Link>
    );
  }
}

export default function CourseDetailSectionAboutEnroll({ courseId }: Props) {
  const { myEnrolledCourses } = useCourse();
  const isEnrolledCourse = myEnrolledCourses.items.some((c) => c.classId === courseId);

  if (isEnrolledCourse) {
    return <CourseDetailSectionAboutEnrolled courseId={courseId} />;
  } else {
    return <CourseDetailSectionAboutNotEnrolled />;
  }
}
