import React from 'react';
import { styled } from '@mui/material/styles';
import { Skeleton, SkeletonProps } from '@mui/material';

export default function SkeletonButton(props: SkeletonProps) {
  const StyledSkeleton = styled(Skeleton)({
    '& span': {
      borderRadius: '30px !important',
    },
  });
  return <StyledSkeleton {...props} />;
}
