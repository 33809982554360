/* tslint:disable */
/* eslint-disable */
/**
 * amusingo
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Category information
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryId?: string;
}

/**
 * Check if a given object implements the Category interface.
 */
export function instanceOfCategory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryName': !exists(json, 'categoryName') ? undefined : json['categoryName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryName': value.categoryName,
        'description': value.description,
        'thumbnailUrl': value.thumbnailUrl,
        'categoryId': value.categoryId,
    };
}

