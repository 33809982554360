import { AddCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useSelector, RootState, dispatch } from 'student/store';
import FieldLabel from 'common/components/Field/FormFieldLabel';
import { Section } from 'student/layout';
import { toast } from 'react-toastify';
import { useResponsive } from 'student/hooks';
import { ParentProfileApi, ParentProfilePutRequest } from 'student/api';
import { useNavigate } from 'react-router-dom';
import { routes } from 'student/Routes';
import { changeParentProfile } from 'student/features';

const PageProfileUpdate = () => {
  const spaces = { mb: 2, mt: 1 };
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const { user, parentProfile } = useSelector((state: RootState) => state.authState);
  const [parentProfileData, setParentProfileData] = React.useState<ParentProfilePutRequest>({
    parentName: parentProfile?.parentName,
    studentNames: parentProfile?.studentNames,
    email: parentProfile?.email,
    phone: parentProfile?.phone,
    interestedFields: parentProfile?.interestedFields,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [studentName, setStudentName] = React.useState('');
  const [fileImg, setFileImg] = React.useState<File>();
  const [img, setImg] = React.useState<string>('');

  const handleChooseImg = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      if (file.type.indexOf('image/') === -1) {
        toast.warning('Định dạng file không hợp lệ');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        setImg(result as string);
      };
      reader.readAsDataURL(files[0]);

      setFileImg(file);
    }
  };

  const addStudentName = () => {
    if (studentName === '' || !parentProfileData.studentNames) return;
    setParentProfileData({
      ...parentProfileData,
      studentNames: [...parentProfileData.studentNames, studentName],
    });
    setStudentName('');
  };

  const deleteStudentName = (index: number) => {
    if (!parentProfileData.studentNames) return;
    const newStudentNames = [...parentProfileData.studentNames];
    newStudentNames.splice(index, 1);
    setParentProfileData({
      ...parentProfileData,
      studentNames: newStudentNames,
    });
  };

  const handleFieldChange = (e: any) => {
    if (!parentProfileData.interestedFields) return;
    let newInterestedFields = [...parentProfileData.interestedFields];
    const field = parentProfileData.interestedFields.find((field) => field === e.target.value);
    if (!field) {
      newInterestedFields.push(e.target.value);
    } else {
      newInterestedFields = newInterestedFields.filter((newField) => newField !== field);
    }
    setParentProfileData({
      ...parentProfileData,
      interestedFields: newInterestedFields,
    });
  };

  const onChangeParentProfileData: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setParentProfileData({
      ...parentProfileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!user?.uid) return;
    setLoading(true);
    new ParentProfileApi()
      .updateParentProfile({
        parentProfilePutRequest: parentProfileData,
        userId: user.uid,
      })
      .then((res) => {
        if (res) {
          dispatch(changeParentProfile(res));
          toast.success('Cập nhật hồ sơ phụ huynh thành công!');
          navigate(routes.PageHome.path);
        }
        setLoading(false);
      })
      .catch((err) => {
        //if the response is an error
        console.error(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Section>
      <Box component="form" sx={{ mt: 4, mb: 1 }} onSubmit={handleSubmit}>
        <FieldLabel text="TÊN PHỤ HUYNH (họ và tên đầy đủ)" isRequired={true} />
        <TextField
          margin="normal"
          type="text"
          required
          fullWidth
          label=""
          value={parentProfileData.parentName}
          name="parentName"
          onChange={onChangeParentProfileData}
          sx={spaces}
        />

        <FieldLabel text="SỐ ĐIỆN THOẠI" isRequired={true} />
        <TextField
          margin="normal"
          type="text"
          required
          fullWidth
          label=""
          value={parentProfileData.phone}
          name="phone"
          onChange={onChangeParentProfileData}
          sx={spaces}
        />

        <FieldLabel text="EMAIL" isRequired={true} />
        <TextField
          margin="normal"
          type="email"
          required
          fullWidth
          label=""
          value={parentProfileData.email}
          name="email"
          onChange={onChangeParentProfileData}
          sx={spaces}
        />

        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', mb: 2 }}
        >
          <Box width={isMobile ? '100%' : '60%'}>
            <FieldLabel
              text="AVATAR"
              isRequired={true}
              desc="Hãy chọn 1 ảnh cá nhân để làm hình nền đại diện. Lưu ý, hãy chọn ảnh vuông, hiển thị rõ gương mặt bạn, có kích cỡ không quá nhỏ, không bị mờ."
            />
            <InputLabel
              htmlFor="image"
              sx={{
                width: '132px',
                height: '40px',
                border: '1px solid #932191',
                borderRadius: '20px',
                padding: '8px 20px',
                color: '#932191',
                cursor: 'pointer',
                mt: '20px',
              }}
            >
              Tải ảnh lên
            </InputLabel>
          </Box>

          <Box
            sx={{
              position: 'relative',
              mt: '20px',
              width: '300px',
              height: '300px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px dashed #696984',
              img: {
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                objectFit: 'center',
              },
            }}
          >
            <p>Hình thu nhỏ</p>
            {img && <img src={img} alt="file" />}
          </Box>
          <input id="image" hidden accept="image/*" type="file" onChange={handleChooseImg} />
        </Box>

        <FieldLabel
          text="TÊN HỌC SINH"
          isRequired={true}
          desc="Vui lòng liệt kê tên các học sinh bạn đang cho theo học tại Amusingo."
        />
        <Box sx={{ display: 'flex', alignItems: 'center', height: 'fit-content' }}>
          <TextField
            margin="normal"
            type="text"
            label=""
            size="small"
            sx={{ mt: 1, mb: 2 }}
            fullWidth
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
          />
          <Tooltip title="Thêm tên học sinh" placement="top">
            <IconButton type="button" aria-label="search" onClick={addStudentName}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
          {parentProfileData.studentNames?.map((studentName, index) => (
            <Chip
              key={index}
              label={studentName}
              variant="outlined"
              onDelete={() => deleteStudentName(index)}
              sx={{ mr: 1 }}
            />
          ))}
        </Box>

        <FieldLabel text="LĨNH VỰC YÊU THÍCH" isRequired={true} />
        <FormGroup sx={spaces}>
          <FormControlLabel
            control={
              <Checkbox checked={!!parentProfileData.interestedFields?.find((field) => field === 'Nghệ Thuật')} />
            }
            label="Nghệ Thuật"
            value="Nghệ Thuật"
            onChange={handleFieldChange}
          />
          <FormControlLabel
            control={
              <Checkbox checked={!!parentProfileData.interestedFields?.find((field) => field === 'Kinh Doanh')} />
            }
            label="Kinh Doanh"
            value="Kinh Doanh"
            onChange={handleFieldChange}
          />
          <FormControlLabel
            control={
              <Checkbox checked={!!parentProfileData.interestedFields?.find((field) => field === 'Lập Trình/STEM')} />
            }
            label="Lập Trình/STEM"
            value="Lập Trình/STEM"
            onChange={handleFieldChange}
          />
          <FormControlLabel
            control={
              <Checkbox checked={!!parentProfileData.interestedFields?.find((field) => field === 'Ngoại Ngữ')} />
            }
            label="Ngoại Ngữ"
            value="Ngoại Ngữ"
            onChange={handleFieldChange}
          />
          <FormControlLabel
            control={<Checkbox checked={!!parentProfileData.interestedFields?.find((field) => field === 'Kỹ Năng')} />}
            label="Kỹ Năng"
            value="Kỹ Năng"
            onChange={handleFieldChange}
          />
        </FormGroup>

        <Button
          type="submit"
          disabled={loading}
          fullWidth
          variant="contained"
          sx={{ pt: 1.5, pb: 1.5, mt: 4 }}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          Cập nhật
        </Button>
      </Box>
    </Section>
  );
};

export default PageProfileUpdate;
