import * as React from 'react';
// material
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import A from 'common/assets';
import { Clazz, ReviewApi, Review } from 'student/api';
import CourseDetailSectionReviewsForm from './CourseDetailSectionReviewsForm';
import CourseDetailSectionReviewsList from './CourseDetailSectionReviewsList';
import Loading from 'common/components/Loading';
import { useAuth } from 'student/hooks';

const RootStyled = styled('div')(({ theme }) => ({
  '& .card-main': {
    display: 'flex',
    boxShadow: 'none',
    borderRadius: '30px',
    gap: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    pt: theme.spacing(1),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardMedia-root': {
      height: 280,
      width: 280,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

const imagesData = [A.images.eye, A.images.gentleMen, A.images.rabbit, A.images.lion];
const imagesData1 = [A.images.eye, A.images.gentleMen];

interface Props {
  clazz: Clazz;
}

export default function CourseDetailSectionReviews({ clazz }: Props) {
  const [reviews, setReviews] = React.useState<Review[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [displayReviews, setDisplayReviews] = React.useState(true);
  const { user } = useAuth();

  React.useEffect(() => {
    if (clazz.classId) {
      setReviews([]);
      setLoading(true);
      new ReviewApi()
        .getReviews({
          classId: clazz.classId,
        })
        .then((value) => {
          setReviews(value.items);
        })
        .catch((_err) => {
          setReviews([]);
        })
        .finally(() => {
          setLoading(false);
        });

      if (user?.uid) {
        new ReviewApi()
          .getReview({ classId: clazz.classId, studentId: user.uid })
          .then((res) => {
            if (res) {
              setDisplayReviews(false);
            }
          })
          .catch((_err) => {
            setDisplayReviews(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [clazz]);

  const onCreateNewReview = (review: Review) => setReviews([review, ...reviews]);

  if (!clazz.classId) {
    return <Loading />;
  }

  return (
    <RootStyled>
      <Card className="card-main">
        <CardContent sx={{ py: 5, px: 6, pb: 5, width: '100%' }}>
          <Typography variant="h5" mb={3}>
            Đánh giá về khóa học
          </Typography>
          {displayReviews && (
            <CourseDetailSectionReviewsForm classId={clazz.classId} onCreateNewReview={onCreateNewReview} />
          )}
          <CourseDetailSectionReviewsList classId={clazz.classId} reviews={reviews} />
        </CardContent>
      </Card>
    </RootStyled>
  );
}
