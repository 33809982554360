/* tslint:disable */
/* eslint-disable */
/**
 * amusingo teacher service
 * API definition
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationPostRequest,
  ApplicationStatus,
  GetUploadUrl201Response,
  GetUploadUrlRequest,
} from '../models';
import {
    ApplicationPostRequestFromJSON,
    ApplicationPostRequestToJSON,
    ApplicationStatusFromJSON,
    ApplicationStatusToJSON,
    GetUploadUrl201ResponseFromJSON,
    GetUploadUrl201ResponseToJSON,
    GetUploadUrlRequestFromJSON,
    GetUploadUrlRequestToJSON,
} from '../models';

export interface GetUploadUrlOperationRequest {
    getUploadUrlRequest: GetUploadUrlRequest;
}

export interface SubmitTeacherApplicationRequest {
    applicationPostRequest: ApplicationPostRequest;
}

/**
 * 
 */
export class IndividualTeacherApplicationApi extends runtime.BaseAPI {

    /**
     * Get a signed URL for uploading a file
     */
    async getUploadUrlRaw(requestParameters: GetUploadUrlOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUploadUrl201Response>> {
        if (requestParameters.getUploadUrlRequest === null || requestParameters.getUploadUrlRequest === undefined) {
            throw new runtime.RequiredError('getUploadUrlRequest','Required parameter requestParameters.getUploadUrlRequest was null or undefined when calling getUploadUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/teachers/application-submissions/upload_url`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetUploadUrlRequestToJSON(requestParameters.getUploadUrlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUploadUrl201ResponseFromJSON(jsonValue));
    }

    /**
     * Get a signed URL for uploading a file
     */
    async getUploadUrl(requestParameters: GetUploadUrlOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUploadUrl201Response> {
        const response = await this.getUploadUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit a new application
     */
    async submitTeacherApplicationRaw(requestParameters: SubmitTeacherApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationStatus>> {
        if (requestParameters.applicationPostRequest === null || requestParameters.applicationPostRequest === undefined) {
            throw new runtime.RequiredError('applicationPostRequest','Required parameter requestParameters.applicationPostRequest was null or undefined when calling submitTeacherApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/teachers/application-submissions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationPostRequestToJSON(requestParameters.applicationPostRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationStatusFromJSON(jsonValue));
    }

    /**
     * Submit a new application
     */
    async submitTeacherApplication(requestParameters: SubmitTeacherApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationStatus> {
        const response = await this.submitTeacherApplicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
