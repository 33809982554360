import * as React from 'react';
// material
import { Box, Grid, Card, CardContent, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Clazz } from 'student/api';
import CourseScheduleList from './CourseScheduleList';
import Loading from 'common/components/Loading';

const RootStyled = styled('div')(({ theme }) => ({
  '& .card-main': {
    display: 'flex',
    boxShadow: 'none',
    gap: theme.spacing(3),
    pt: theme.spacing(1),
    borderRadius: theme.spacing(2.5),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiCardContent-root': {
      width: '100%',
    },
  },
}));

interface Props {
  title?: string;
  clazz: Clazz;
  enrollable?: boolean;
}

export default function CourseDetailSectionSchedule({ title, clazz, enrollable }: Props) {
  const RenderSchedule = () => {
    if (clazz.schedules === undefined) {
      return <Loading />;
    }

    if (clazz.schedules.length === 0) {
      return (
        <Typography align="center" variant="body1">
          {'Chưa có lịch học'}
        </Typography>
      );
    }

    return <CourseScheduleList clazz={clazz} enrollable={enrollable} />;
  };

  return (
    <RootStyled id="course-detail-section-schedules">
      <Card className="card-main" sx={{ height: 'auto' }}>
        <CardContent sx={{ py: 3, px: 6 }}>
          <Stack spacing={3}>
            <Typography variant="h5">{title ? title : 'Lịch học'}</Typography>
            {RenderSchedule()}
          </Stack>
        </CardContent>
      </Card>
    </RootStyled>
  );
}
