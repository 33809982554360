import React from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'student/Routes';

import { SectionId, QuestionId } from './PageFAQ';

export function usePageFAQParams() {
  const [sectionId, setSectionId] = React.useState<SectionId>('teacher');
  const [questionId, setQuestionId] = React.useState<QuestionId>(-1);

  const defaultSectionId = 'teacher';

  const params = useParams();

  React.useEffect(() => {
    setSectionId((params.sectionId as SectionId) || defaultSectionId);

    if (params.questionId !== undefined) {
      setQuestionId(parseInt(params.questionId));
    }
  }, [params]);

  const changeSectionId = (sId: SectionId): void => {
    setSectionId(sId);
    setQuestionId(-1);
    window.history.replaceState(null, '', routes.PageFAQSection.path.replace(':sectionId', sId));
  };

  const changeQuestionId = (qId: QuestionId): void => {
    setQuestionId(qId);
    window.history.replaceState(
      null,
      '',
      routes.PageFAQSectionQuestion.path.replace(':sectionId', sectionId).replace(':questionId', qId + '')
    );
  };

  return { sectionId, questionId, changeSectionId, changeQuestionId };
}
